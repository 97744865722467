import React from "react";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";

import { FaButton } from "./helpers";

const ConfirmationDialog = ({
  title,
  message,
  shown,
  onHide,
  onConfirm,
  children,
}) => {
  return (
    <Modal id="confirmationDialog" show={shown} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && parse(message)}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <FaButton
          icon="check"
          text="Yes"
          bsStyle="primary"
          className="pull-left confirm commit"
          onClick={onConfirm}
        />
        <FaButton
          icon="remove"
          text="No"
          bsStyle="default"
          className="cancel"
          onClick={onHide}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
