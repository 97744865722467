import React from "react";
import { FormGroup, InputGroup, HelpBlock } from "react-bootstrap";
import { TextAreaField } from "components/helpers";

const TextAreaFieldInputGroup = ({
  label,
  field,
  value,
  disabled,
  hasErrors,
  errors,
  onChange,
}) => {
  return (
    <>
      <FormGroup
        className="text-input-group"
        validationState={hasErrors ? "error" : null}
      >
        <InputGroup>
          <InputGroup.Addon>
            <span>{label}</span>
          </InputGroup.Addon>
          <TextAreaField
            controlId={field}
            fieldName={field}
            label={label}
            labelClassName="sr-only"
            value={value}
            disabled={disabled}
            onChange={onChange}
            rows={2}
          />
        </InputGroup>
        {errors && (
          <HelpBlock bsClass="inline-help-block">{errors.join(", ")}</HelpBlock>
        )}
      </FormGroup>
    </>
  );
};

export default TextAreaFieldInputGroup;
