import _ from "lodash";
import ReactAce from "react-ace";
import React, { Component } from "react";
import { Row, Col, Button, ButtonGroup, HelpBlock } from "react-bootstrap";

import { Select } from "components/helpers";

import "ace-builds/src-noconflict/theme-textmate";
import "./helpers/ace-mode-dd_dsl";

class AceEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const editor = this.ace.editor;

    let clearValue = false;
    let currentValue = this.props.value;
    if (currentValue) {
      editor.setValue(currentValue.trim(), 1);
    } else {
      clearValue = true;
      editor.setValue(" ", 1);
    }

    if (currentValue || this.props.setFocus) editor.focus();
    // This is a "hack" to fix issues with
    // entering an initial value in Firefox when set to blank
    if (clearValue) editor.setValue("", 1);
  }

  onChange() {
    const editor = this.ace.editor;
    this.props.handleUpdateValue(
      this.props.fieldName,
      editor.getValue().trim()
    );
  }

  insertValue(value) {
    const editor = this.ace.editor;
    editor.insert(value);
    if (value == "()") {
      let currentPosition = editor.getCursorPosition();
      editor.moveCursorTo(currentPosition.row, currentPosition.column - 1);
    }
    editor.focus();
  }

  validationDslButton(text, beforeSpacing = " ", afterSpacing = " ") {
    return (
      <Button
        bsStyle="default"
        disabled={this.props.disabled}
        bsSize="small"
        onClick={() => this.insertValue(beforeSpacing + text + afterSpacing)}
      >
        {text}
      </Button>
    );
  }

  render() {
    return (
      <div>
        <Row className="margin-bottom-10">
          <Col sm={4}>
            <Select
              classNamePrefix="select-field-dependency"
              className="select-field-dependency"
              value={""}
              onChange={(option) => this.insertValue(option.value)}
              options={this.props.fieldsForDependencies}
              isDisabled={
                this.props.loadingFields ||
                (!_.isUndefined(this.props.loadedFields) &&
                  !this.props.loadedFields) ||
                this.props.disabled
              }
              placeholder="Select field"
            />
          </Col>
          <Col sm={8}>
            <Button
              className="margin-right-5"
              bsStyle="default"
              disabled={this.props.disabled}
              bsSize="small"
              onClick={() => this.insertValue("()")}
            >
              ( &nbsp; )
            </Button>
            <ButtonGroup>
              {this.validationDslButton("AND")}
              {this.validationDslButton("OR")}
            </ButtonGroup>
            <ButtonGroup className="margin-left-5">
              {this.validationDslButton("NOT")}
            </ButtonGroup>
            <ButtonGroup className="margin-left-5">
              {this.validationDslButton("TODAY", "", "")}
            </ButtonGroup>
            {this.props.loadingFields && (
              <span className="loading-status">
                <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                Loading fields
              </span>
            )}
          </Col>
        </Row>
        <Row className="margin-bottom-10">
          <Col sm={12}>
            <ButtonGroup className="margin-right-5">
              {this.validationDslButton("==")}
              {this.validationDslButton("!=")}
            </ButtonGroup>
            <ButtonGroup className="margin-right-5">
              {this.validationDslButton("<")}
              {this.validationDslButton("<=")}
              {this.validationDslButton(">=")}
              {this.validationDslButton(">")}
            </ButtonGroup>
            <span className="margin-right-5">
              {this.validationDslButton("BLANK")}
            </span>
            <span className="margin-right-5">
              {this.validationDslButton("CONTAINS")}
            </span>
            <span className="margin-right-5">
              {this.validationDslButton("EXISTS")}
            </span>
            <ButtonGroup className="margin-right-5">
              {this.validationDslButton('"Yes"', "", "")}
              {this.validationDslButton('"No"', "", "")}
            </ButtonGroup>
            <ButtonGroup>
              {this.validationDslButton("TRUE", "", "")}
              {this.validationDslButton("FALSE", "", "")}
            </ButtonGroup>
          </Col>
        </Row>
        <div
          data-testid={`${
            this.props.testId || this.props.fieldName
          }-dependencies`}
          className={this.props.errors ? "dependency-error" : null}
        >
          <ReactAce
            name={`${this.props.fieldName}AceEditor`}
            mode="dd_dsl"
            theme="textmate"
            onChange={this.onChange}
            height={this.props.height || "100px"}
            width="100%"
            fontSize="14px"
            showPrintMargin={false}
            ref={(instance) => {
              this.ace = instance;
            }} // Let's put things into scope
          />
          {this.props.errors && (
            <HelpBlock bsClass="inline-help-block">
              {this.props.errors.join(", ")}
            </HelpBlock>
          )}
        </div>
      </div>
    );
  }
}

export default AceEditor;
