import * as Sentry from "@sentry/react";
import { getCsrfToken } from "./csrf_helper";

export const spinnerTimeout = 500;

const encodeParamAsQueryString = (key, value) => {
  if (Array.isArray(value) === true) {
    return value
      .map((innerValue) => `${encodeURIComponent(key)}[]=${innerValue}`)
      .join("&");
  } else {
    return `${encodeURIComponent(key)}=${value}`;
  }
};

const parsedParams = (params) => {
  if (params) {
    return (
      "?" +
      Object.keys(params)
        .map((key) => encodeParamAsQueryString(key, params[key]))
        .join("&")
    );
  } else {
    return "";
  }
};

export function getRequest({ url, params }) {
  return fetch(`${url}${parsedParams(params)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
  });
}

export function postRequest({ url, body }) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
    body: JSON.stringify(body),
  });
}

export function putRequest({ url, body }) {
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
    body: JSON.stringify(body),
  });
}

export function patchRequest({ url, body }) {
  return fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
    body: JSON.stringify(body),
  });
}

export function deleteRequest({ url, body }) {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-csrf-token": getCsrfToken(),
    },
    body: JSON.stringify(body),
  });
}

export function processRequest(
  request,
  successFunction,
  failureFunction = () => {},
  errorFunction = (ex) => {}
) {
  return request
    .then(async (res) => {
      if (res.redirected && res.url.match(/\/users\/sign_in/)) {
        const message = "Your session has expired. Please log in again.";
        alert(message);
        errorFunction(new Error(message));
      } else if (res.status == 500) {
        const message =
          "An error has occurred, please try again and contact support if the problem persists.";
        errorFunction(new Error(message));
      } else if (res.status >= 400) {
        let resData = await res.json();
        failureFunction(resData);
      } else {
        let resData = await res.json();
        successFunction(resData);
      }
    })
    .catch((ex) => {
      Sentry.captureException(ex);
      errorFunction(ex);
    });
}

export function spinnerTimeoutWrapper(func) {
  return setTimeout(() => {
    func();
  }, spinnerTimeout);
}

export function errorWithDispatch(dispatch, errors) {
  return (action) => (ex) => {
    console.error(ex.stack);

    spinnerTimeoutWrapper(() => {
      dispatch(action(errors));
    });
  };
}

export function dispatchWithTimeout(dispatch, flashType = "alert-success") {
  return (action, message) =>
    (...params) => {
      spinnerTimeoutWrapper(() => {
        if (message) {
          $.flashAlert(message, flashType);
        }
        dispatch(action(...params));
      });
    };
}
