import React from "react";
import { FormGroup, InputGroup, HelpBlock } from "react-bootstrap";

const SelectInputGroup = ({
  label,
  field,
  value,
  disabled,
  errors,
  includeBlank,
  onChange,
  optionsForSelect,
}) => {
  const labelId = `select_${field}`;

  return (
    <>
      <FormGroup
        className="select-input-group"
        validationState={errors ? "error" : null}
      >
        <InputGroup>
          <InputGroup.Addon>
            <label htmlFor={labelId} className="margin-bottom-0">
              {label}
            </label>
          </InputGroup.Addon>
          <select
            id={labelId}
            value={value}
            className="form-control select-in-input-group"
            disabled={disabled}
            onChange={(e) => onChange(field, e.target.value)}
          >
            {includeBlank && <option value=""></option>}
            {optionsForSelect.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>
        </InputGroup>
        {errors && (
          <HelpBlock bsClass="inline-help-block">{errors.join(", ")}</HelpBlock>
        )}
      </FormGroup>
    </>
  );
};

export default SelectInputGroup;
