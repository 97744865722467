import { defaultErrorMessage } from "components/helpers";

import { processRequest, dispatchWithTimeout } from "services/base_requests";
import { saveError, saveSuccess } from "actions/form.base.actions";
import { updateManagementInformation } from "services/revisions_page/management_information_requests";

export const saveFormMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type !== "UPDATE_FORM") {
      return next(action);
    }

    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");

    const body = action.details;
    const study_id = action.study_id;
    const revision_id = action.revision_id;
    const request = updateManagementInformation(study_id, revision_id, {
      revision: body,
    });

    const success = successHandlerFor(
      saveSuccess,
      "Management information has been saved successfully"
    );
    const failure = failureHandlerFor(
      saveError,
      "Management information has failed to save"
    );
    const error = () => {
      $.flashAlert(defaultErrorMessage, "alert-danger");
    };
    processRequest(request, success, failure, error);

    return next(action);
  };
