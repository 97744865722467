import { ActionTypes } from "../../actions/fields_page";

export const bulkActionsDefaultState = {
  deleting: false,
  moving: false,
  copying: false,
  copyNTimes: "1",
  targetForm: "",
  selectedFields: [],
  loadingReferencedFields: false,
  referencedFields: [],
  displayingConfirmation: false,
  confirmationType: "",
  displayingError: false,
  failedFields: [],
  failureReason: "",
};

export const BulkActionsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.HANDLE_SELECT_ALL_FIELDS_FOR_BULK_ACTION: {
      const newState = {
        ...state,
        selectedFields: action.fields,
        loadingReferencedFields: true,
      };
      return newState;
    }

    case ActionTypes.HANDLE_REMOVE_ALL_SELECTED_FIELDS_FOR_BULK_ACTION: {
      return { ...state, ...bulkActionsDefaultState };
    }

    case ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_OPEN: {
      return { ...state, ...bulkActionsDefaultState };
    }

    case ActionTypes.EDIT_TYPE_OPEN: {
      return { ...state, ...bulkActionsDefaultState };
    }

    case ActionTypes.HANDLE_SELECT_FIELD_FOR_BULK_ACTION: {
      const updatedFields = [...state.selectedFields, action.field];
      const newState = {
        ...state,
        selectedFields: updatedFields,
        loadingReferencedFields: true,
      };
      return newState;
    }

    case ActionTypes.HANDLE_REFERENCED_FIELDS_SUCCESS: {
      let newReferencedFields = state.referencedFields;

      action.result.forEach((reference) => {
        const { referenced, ...fieldReferences } = reference;

        if (referenced) {
          newReferencedFields = newReferencedFields.filter(
            (row) => reference.field_id != row.field_id
          );
          newReferencedFields.push(fieldReferences);
        }
      });

      return {
        ...state,
        referencedFields: newReferencedFields,
        loadingReferencedFields: false,
      };
    }

    case ActionTypes.HANDLE_REFERENCED_FIELDS_FAILURE: {
      $.flashAlert(
        "Unable to check field referencing, please refresh the page and try again.",
        "alert-danger"
      );
      const newState = { ...state, ...bulkActionsDefaultState };
      return newState;
    }

    case ActionTypes.HANDLE_REMOVE_SELECTED_FIELD_FOR_BULK_ACTION: {
      const newSelectedFields = state.selectedFields.filter(
        (fieldId) => action.field != fieldId
      );
      if (newSelectedFields.length == 0) {
        return { ...state, ...bulkActionsDefaultState };
      } else {
        const newReferencedFields = state.referencedFields.filter(
          (row) => action.field != row.field_id
        );

        return {
          ...state,
          referencedFields: newReferencedFields,
          selectedFields: newSelectedFields,
        };
      }
    }

    case ActionTypes.HANDLE_SELECT_TARGET_FORM: {
      let newState = { ...state };
      newState.targetForm = action.targetForm;
      return newState;
    }

    case ActionTypes.HANDLE_SELECT_COPY_N_TIMES: {
      let newState = { ...state };
      newState.copyNTimes = action.nTimes;
      return newState;
    }

    case ActionTypes.HANDLE_SHOW_BULK_ACTION_CONFIRMATION: {
      const newState = {
        ...state,
        displayingConfirmation: true,
        confirmationType: action.confirmationType,
      };
      return newState;
    }

    case ActionTypes.HANDLE_HIDE_BULK_ACTION_CONFIRMATION: {
      const newState = {
        ...state,
        displayingConfirmation: true,
        confirmationType: "",
      };
      return newState;
    }

    case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS: {
      const newState = {
        ...state,
        deleting: true,
        displayingConfirmation: false,
        confirmationType: "",
      };
      return newState;
    }

    case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS: {
      const newState = {
        ...state,
        moving: true,
        displayingConfirmation: false,
        confirmationType: "",
      };

      return newState;
    }

    case ActionTypes.HANDLE_COPY_SELECTED_FIELDS: {
      const newState = {
        ...state,
        copying: true,
        displayingConfirmation: false,
        confirmationType: "",
      };

      return newState;
    }

    case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_SUCCESS: {
      return { ...state, ...bulkActionsDefaultState };
    }

    case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_WITH_ERRORS: {
      const newSelectedFields = state.selectedFields.filter(
        (fieldId) => !action.successFields.includes(fieldId)
      );

      const newState = {
        ...state,
        selectedFields: newSelectedFields,
        deleting: false,
        displayingError: true,
        failureReason: "delete",
        failedFields: action.failedFields,
      };

      return newState;
    }

    case ActionTypes.HANDLE_BULK_ACTIONS_ERROR: {
      return {
        ...state,
        displayingError: true,
        failureReason: "server_error",
        deleting: false,
        moving: false,
        copying: false,
      };
    }

    case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_SUCCESS: {
      return { ...state, ...bulkActionsDefaultState };
    }

    case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_WITH_ERRORS: {
      const movedFields = action.moveData.successFields.map(
        (field) => field.id
      );
      const failedFields = action.moveData.failedFields;
      const newSelectedFields = state.selectedFields.filter(
        (fieldId) => !movedFields.includes(fieldId)
      );

      const newState = {
        ...state,
        selectedFields: newSelectedFields,
        moving: false,
        displayingError: true,
        failureReason: "move",
        failedFields: failedFields,
      };

      return newState;
    }

    case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_WITH_ERRORS: {
      const newSelectedFields = state.selectedFields.filter(
        (fieldId) => !action.successFields.includes(fieldId)
      );

      const newState = {
        ...state,
        selectedFields: newSelectedFields,
        copying: false,
        displayingError: true,
        failureReason: "copy",
        failedFields: action.failedFields,
      };

      return newState;
    }

    case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_SUCCESS: {
      return { ...state, ...bulkActionsDefaultState };
    }

    case ActionTypes.HANDLE_HIDE_BULK_ACTION_ERROR: {
      return {
        ...state,
        displayingError: false,
        failureReason: "",
        failedFields: [],
      };
    }
  }

  return state;
};
