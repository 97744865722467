import { QuestionnaireActionTypes } from "../../actions/questionnaires_page";

export const questionnaireFormDefaultState = {
  saving: false,
  editing: false,
  values: {
    identifier: "",
    name: "",
    description: "",
    inclusionCriteria: "",
    withdrawalCriteria: "",
    requestExpirationValue: "",
    requestExpirationUnit: "",
    reminderExpirationValue: "",
    reminderExpirationUnit: "",
    reminderDueValue: "",
    reminderDueUnit: "",
    nonResponderValue: "",
    nonResponderUnit: "",
    eventIds: [],
    formIds: [],
    meansOfInitialAttemptDelivery: [
      {
        type: "onlineEmail",
        label: "Email",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "onlineSms",
        label: "SMS",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "post",
        label: "Post",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "telephone",
        label: "Telephone",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "inPerson",
        label: "In person",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
    ],
    meansOfReminderAttemptDelivery: [
      {
        type: "onlineEmail",
        label: "Email",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "onlineSms",
        label: "SMS",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "post",
        label: "Post",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "telephone",
        label: "Telephone",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
      {
        type: "inPerson",
        label: "In person",
        selected: false,
        condition: "",
        messageTemplateId: null,
      },
    ],
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactAddress1: null,
    contactAddress2: null,
    contactTownCity: null,
    contactPostcode: null,
    contactLandlineNumber: null,
    contactMobileNumber: null,
    introText: "",
    introPageDisplayMethod: "not_required",
  },
  errors: {},
  questionnaireId: null,
  showForm: false,
  loadingFieldsForInclusionCriteria: false,
  fieldsForInclusionCriteria: [],
  fieldsForDeliveryCriteria: [],
  fieldsForContactFields: [],
  formsAvailableAtSelectedEvents: [],
  availableExpirationUnits: [
    { value: "days", label: "day(s)" },
    { value: "weeks", label: "week(s)" },
    { value: "months", label: "month(s)" },
    { value: "years", label: "year(s)" },
  ],
  availableIntroPageDisplayMethods: [
    { value: "separate_page", label: "Separate page" },
    { value: "top_of_first_page", label: "Top of the first page" },
    { value: "not_required", label: "Not required" },
  ],
};

const getFormsAvailableAtSelectedEvents = (allForms, selectedEventIds) => {
  return allForms.filter((studyForm) => {
    // If the form is linked to any event that we have selected then it is included
    return selectedEventIds.some((eventId) => {
      return studyForm.event_ids.includes(eventId);
    });
  });
};

const loadMeansOfDelivery = (parsedMeansOfDelivery, section) => {
  const defaultMeansOfDelivery =
    questionnaireFormDefaultState["values"][section];

  const selectedMeans = parsedMeansOfDelivery.map((means) => means.type);

  const meansOfDelivery = defaultMeansOfDelivery.map((defaultMeans) => {
    if (selectedMeans.includes(defaultMeans.type)) {
      const parsedMeans = parsedMeansOfDelivery.find(
        (parsedMeans) => parsedMeans.type === defaultMeans.type
      );

      if (parsedMeans) {
        return {
          ...defaultMeans,
          selected: true,
          condition: parsedMeans["condition"],
          messageTemplateId: parsedMeans["messageTemplateId"],
        };
      } else {
        return defaultMeans;
      }
    } else {
      return defaultMeans;
    }
  });

  return meansOfDelivery;
};

export const loadQuestionnaireFormState = (
  questionnaire,
  studyId,
  entityGroupId,
  events,
  messageTemplates,
  studyForms
) => {
  const eventIds = questionnaire.events.map((event) => event.id);
  const formIds = questionnaire.study_forms.map((form) => form.id);

  return {
    ...questionnaireFormDefaultState,
    editing: true,
    questionnaireId: questionnaire.id,
    studyId: studyId,
    entityGroupId: entityGroupId,
    events: events,
    messageTemplates: messageTemplates,
    studyForms: studyForms,
    formsAvailableAtSelectedEvents: getFormsAvailableAtSelectedEvents(
      studyForms,
      eventIds
    ),
    values: {
      identifier: questionnaire.identifier,
      name: questionnaire.name,
      description: questionnaire.description,
      introText: questionnaire.intro_text || "",
      introPageDisplayMethod: questionnaire.intro_page_display_method,
      inclusionCriteria: questionnaire.parsed_inclusion_criteria,
      withdrawalCriteria: questionnaire.parsed_withdrawal_criteria,
      eventIds: eventIds,
      formIds: formIds,
      meansOfInitialAttemptDelivery: loadMeansOfDelivery(
        questionnaire.parsed_means_of_initial_attempt_delivery,
        "meansOfInitialAttemptDelivery"
      ),
      meansOfReminderAttemptDelivery: loadMeansOfDelivery(
        questionnaire.parsed_means_of_reminder_attempt_delivery,
        "meansOfReminderAttemptDelivery"
      ),
      contactFirstName: questionnaire.parsed_contact_first_name,
      contactLastName: questionnaire.parsed_contact_last_name,
      contactEmail: questionnaire.parsed_contact_email,
      contactLandlineNumber: questionnaire.parsed_contact_landline_number,
      contactMobileNumber: questionnaire.parsed_contact_mobile_number,
      contactAddress1: questionnaire.parsed_contact_address1,
      contactAddress2: questionnaire.parsed_contact_address2,
      contactTownCity: questionnaire.parsed_contact_town_city,
      contactPostcode: questionnaire.parsed_contact_postcode,
      requestExpirationValue: questionnaire.request_expiration_value || "",
      requestExpirationUnit: questionnaire.request_expiration_unit,
      reminderExpirationValue: questionnaire.reminder_expiration_value || "",
      reminderExpirationUnit: questionnaire.reminder_expiration_unit,
      reminderDueValue: questionnaire.reminder_due_value || "",
      reminderDueUnit: questionnaire.reminder_due_unit,
      nonResponderValue: questionnaire.non_responder_value || "",
      nonResponderUnit: questionnaire.non_responder_unit,
    },
  };
};

const updateValue = (values, identifier, newValue) => {
  return { ...values, [identifier]: newValue };
};

const addMeansOfDelivery = (meansOfDelivery, typeToAdd) => {
  const newMeansOfDelivery = meansOfDelivery.map((administration) => {
    if (administration.type === typeToAdd) {
      return { ...administration, selected: true };
    } else {
      return administration;
    }
  });
  return newMeansOfDelivery;
};

const removeMeansOfDelivery = (meansOfDelivery, typeToRemove) => {
  const newMeansOfDelivery = meansOfDelivery.map((administration) => {
    if (administration.type === typeToRemove) {
      return { ...administration, selected: false };
    } else {
      return administration;
    }
  });
  return newMeansOfDelivery;
};

export const QuestionnaireFormReducer = (
  state = questionnaireFormDefaultState,
  action
) => {
  switch (action.type) {
    case QuestionnaireActionTypes.HANDLE_SELECT_EVENTS_CHANGE: {
      const formsAvailableAtSelectedEvents = getFormsAvailableAtSelectedEvents(
        state.studyForms,
        action.selectedEventsIds
      );

      const selectedForms = state.values.formIds.filter((studyFormId) =>
        formsAvailableAtSelectedEvents
          .map((studyForm) => studyForm.value)
          .includes(studyFormId)
      );

      const newValues = {
        ...state.values,
        eventIds: action.selectedEventsIds,
        formIds: selectedForms,
      };

      const newState = {
        ...state,
        values: newValues,
        formsAvailableAtSelectedEvents: formsAvailableAtSelectedEvents,
        loadingFieldsForInclusionCriteria: true,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_SELECT_FORMS_CHANGE: {
      let newValues = {
        ...state.values,
        formIds: action.selectedFormsIds,
      };

      const newState = {
        ...state,
        values: newValues,
        loadingFieldsForInclusionCriteria: true,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_QUESTIONNAIRE_FORM_VALUE_CHANGE: {
      let newValues = {
        ...state.values,
        [action.fieldName]: action.value,
      };

      const newState = {
        ...state,
        values: newValues,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_OPEN_NEW_QUESTIONNAIRE_FORM: {
      let newState = {
        ...state,
        showForm: true,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT:
    case QuestionnaireActionTypes.HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT: {
      let newState = {
        ...state,
        saving: true,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_QUESTIONNAIRE_FORM_SUBMIT_FAILURE: {
      let newState = {
        ...state,
        errors: action.errors,
        saving: false,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_GET_AVAILABLE_FIELDS_SUCCESS: {
      let newState = {
        ...state,
        loadingFieldsForInclusionCriteria: false,
        fieldsForInclusionCriteria: action.inclusionCriteriaAvailableFields,
        fieldsForDeliveryCriteria: action.deliveryCriteriaAvailableFields,
        fieldsForContactFields: action.contactFieldAvailableFields,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_OPEN_EDIT_QUESTIONNAIRE_FORM: {
      let newState = {
        ...state,
        loadingFieldsForInclusionCriteria: true,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_MEANS_OF_DELIVERY_CHANGE: {
      const { section, deliveryType } = action;
      const meansOfDelivery = [...state.values[section]];
      const selectedMeans = meansOfDelivery.find((means) => {
        return means.type === deliveryType;
      });

      let newValues = { ...state.values };

      if (selectedMeans.selected === true) {
        const newMeansOfDelivery = removeMeansOfDelivery(
          meansOfDelivery,
          deliveryType
        );
        newValues = updateValue(newValues, section, newMeansOfDelivery);
      } else {
        const newMeansOfDelivery = addMeansOfDelivery(
          meansOfDelivery,
          deliveryType
        );
        newValues = updateValue(newValues, section, newMeansOfDelivery);
      }

      let newState = {
        ...state,
        values: newValues,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_MEANS_OF_DELIVERY_FIELD_VALUE_CHANGE: {
      const { section, deliveryType, fieldName, value } = action;

      const meansOfDelivery = [...state.values[section]];
      const newMeansOfDelivery = meansOfDelivery.map((administration) => {
        if (administration.type === deliveryType) {
          return { ...administration, [fieldName]: value };
        } else {
          return administration;
        }
      });

      let newValues = {
        ...state.values,
        [section]: newMeansOfDelivery,
      };

      const newState = {
        ...state,
        values: newValues,
      };

      return newState;
    }

    case QuestionnaireActionTypes.HANDLE_QUESTIONNAIRE_MEANS_OF_DELIVERY_MESSAGE_TEMPLATE_CHANGE: {
      const { section, deliveryType, value } = action;

      const meansOfDelivery = [...state.values[section]];
      const newMeansOfDelivery = meansOfDelivery.map((administration) => {
        if (administration.type === deliveryType) {
          return { ...administration, messageTemplateId: value };
        } else {
          return administration;
        }
      });

      let newValues = {
        ...state.values,
        [section]: newMeansOfDelivery,
      };

      const newState = {
        ...state,
        values: newValues,
      };

      return newState;
    }
  }

  return state;
};
