import { ActionTypes } from "../../actions/fields_page";

export const EditDependencies = (state = {}, action) => {
  const closeForm = () => {
    let newState = {
      ...state,
      values: { dependencies: "" },
      errors: {},
      showForm: false,
      loadingFields: false,
      field: {},
      fieldsForDependencies: [],
    };
    return newState;
  };

  switch (action.type) {
    case ActionTypes.UPDATE_EDIT_DEPENDENCIES_FORM_VALUE: {
      let values = { ...state.values };
      values["dependencies"] = action.value;

      let newState = {
        ...state,
        values: values,
      };
      return newState;
    }

    case ActionTypes.HANDLE_OPEN_EDIT_DEPENDENCIES_FORM: {
      let newState = {
        ...state,
        showForm: true,
        loadingFields: true,
        field: action.field,
        values: { dependencies: action.field.parsed_dependency },
      };
      return newState;
    }

    case ActionTypes.HANDLE_GET_FIELDS_FOR_DEPENDENCIES: {
      const newState = { ...state };

      if (newState.loadingFields) {
        newState.loadingFields = false;
        newState.fieldsForDependencies = action.fields;
      }

      return newState;
    }

    case ActionTypes.HANDLE_SUBMIT_EDIT_DEPENDENCIES_FORM: {
      let newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.HANDLE_EDIT_DEPENDENCIES_FORM_ERROR: {
      let newState = {
        ...state,
        saving: false,
        errors: action.errors,
      };
      return newState;
    }

    case ActionTypes.HANDLE_CLOSE_EDIT_DEPENDENCIES_FORM: {
      return closeForm();
    }

    case ActionTypes.HANDLE_EDIT_DEPENDENCIES_FORM_SUCCESS: {
      let newState = {
        ...closeForm(),
        saving: false,
      };
      return newState;
    }
  }

  return state;
};
