import { deleteRequest, postRequest, patchRequest } from "./base_requests";

export function moveElement(params) {
  return postRequest({
    url: `/study/layout/move_element`,
    body: params,
  });
}

export function removeSection(studyId, sectionId) {
  return deleteRequest({
    url: `/study/${studyId}/layout/${sectionId}`,
    body: {
      studyId: studyId,
      sectionId: sectionId,
    },
  });
}

export function updateSection(studyId, sectionId, details) {
  return patchRequest({
    url: `/study/${studyId}/layout/${sectionId}`,
    body: { form_section: { ...details } },
  });
}

export function createSection(studyId, studyFormId, details) {
  return postRequest({
    url: `/study/${studyId}/forms/${studyFormId}/layout`,
    body: { form_section: { ...details } },
  });
}

export function moveFieldsTo(studyId, targetSectionId, fieldsToProcess) {
  return patchRequest({
    url: `/study/${studyId}/layout/move_to`,
    body: {
      targetSectionId: targetSectionId,
      fieldsToProcess: fieldsToProcess,
    },
  });
}
