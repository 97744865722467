import actions, { ActionTypes } from "actions/tables_page";

import { processRequest, dispatchWithTimeout } from "services/base_requests";
import { createTable, updateTable } from "services/table_requests";

export const TableModalMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);

    switch (action.type) {
      case ActionTypes.HANDLE_NEW_FORM_SUBMIT: {
        const request = createTable(action.studyId, action.values);

        successHandlerFor(actions.handleSubmitSuccess);

        const successHandler = successHandlerFor(actions.handleSubmitSuccess);
        const failureHandler = successHandlerFor(actions.handleSubmitFailure);

        processRequest(request, successHandler, failureHandler, failureHandler);
        break;
      }
      case ActionTypes.HANDLE_EDIT_FORM_SUBMIT: {
        const request = updateTable(
          action.studyId,
          action.studyTableId,
          action.values
        );

        successHandlerFor(actions.handleSubmitSuccess);

        const successHandler = successHandlerFor(actions.handleSubmitSuccess);
        const failureHandler = successHandlerFor(actions.handleSubmitFailure);

        processRequest(request, successHandler, failureHandler, failureHandler);
        break;
      }
    }
    return next(action);
  };
