import React from "react";

import { TextField, SelectField } from "components/helpers";
import SiteSpecificList from "./SiteSpecificList";

const AdditionalSitePropertiesFields = ({
  additionalSiteProperties,
  values,
  errors,
  disabled,
  propertyValueChange,
  listValueChange,
  addListOption,
  removeListOption,
  reorderSiteFormListOption,
}) => {
  const orderedAdditionalSiteProperties = additionalSiteProperties.map(
    (siteProperty, index) => {
      return { ...siteProperty, position: index };
    }
  );

  const siteSpecificLists = orderedAdditionalSiteProperties.filter(
    (siteProperty) => siteProperty.data_type == "site_specific_list"
  );

  const siteProperties = orderedAdditionalSiteProperties.filter(
    (siteProperty) => siteProperty.data_type != "site_specific_list"
  );

  const selectOptionsFor = (siteProperty) => {
    if (siteProperty.data_type == "boolean") {
      return [
        { value: "TRUE", label: "TRUE" },
        { value: "FALSE", label: "FALSE" },
      ];
    } else if (siteProperty.data_type == "single_value") {
      return siteProperty.metadatum_field_options.map((option) => {
        return { value: option.id, label: option.name };
      });
    }
  };

  if (additionalSiteProperties.length == 0) {
    return null;
  } else {
    return (
      <>
        {siteSpecificLists.length > 0 && (
          <div className="margin-top-20" key="siteSpecificLists">
            <legend>Site-specific lists</legend>
            {siteSpecificLists.map((siteList, index) => {
              return (
                <SiteSpecificList
                  key={`site-specific-list-${index}`}
                  siteList={siteList}
                  values={values}
                  errors={errors}
                  disabled={disabled}
                  listValueChange={listValueChange}
                  addListOption={addListOption}
                  removeListOption={removeListOption}
                  reorderSiteFormListOption={reorderSiteFormListOption}
                />
              );
            })}
          </div>
        )}

        {siteProperties.length > 0 && (
          <div className="margin-top-20" key="siteProperties">
            <legend>Additional properties</legend>
            {siteProperties.map((siteProperty, index) => {
              switch (siteProperty.data_type) {
                case "boolean":
                case "single_value":
                  return (
                    <SelectField
                      key={index}
                      fieldName={siteProperty.identifier}
                      required={siteProperty.required}
                      label={siteProperty.name}
                      className={`select-field-${siteProperty.identifier} react-select`}
                      value={
                        values.metadatum_entries[siteProperty.position]
                          ? values.metadatum_entries[siteProperty.position]
                              .metadatum_field_option_id
                          : ""
                      }
                      disabled={disabled}
                      errors={
                        errors[
                          `metadatum_entries[${siteProperty.position}].metadatum_field_option_id`
                        ]
                      }
                      onChange={(option) => {
                        return propertyValueChange(
                          siteProperty.position,
                          "metadatum_field_option_id",
                          option.value
                        );
                      }}
                      options={selectOptionsFor(siteProperty)}
                    />
                  );
                case "date":
                case "float":
                case "integer":
                case "string":
                  return (
                    <TextField
                      key={index}
                      controlId={siteProperty.identifier}
                      fieldName={siteProperty.position}
                      required={siteProperty.required}
                      label={siteProperty.name}
                      value={
                        values.metadatum_entries[siteProperty.position]
                          ? values.metadatum_entries[siteProperty.position]
                              .string_value
                          : ""
                      }
                      disabled={disabled}
                      errors={
                        errors[
                          `metadatum_entries[${siteProperty.position}].string_value`
                        ]
                      }
                      onChange={(fieldName, option) => {
                        return propertyValueChange(
                          siteProperty.position,
                          "string_value",
                          option
                        );
                      }}
                    />
                  );
              }
            })}
          </div>
        )}
      </>
    );
  }
};

export default AdditionalSitePropertiesFields;
