import ChangeObject from "./ChangeObject";

class StudyChange extends ChangeObject {
  constructor(change) {
    const identifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;

    const attributeData = [
      ["Identifier", "identifier"],
      ["Short name", "short_name"],
      ["Long name", "long_name"],
    ];

    super(change, "Study", identifier, attributeData);
  }
}

export default StudyChange;
