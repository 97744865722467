export const UPDATE_FORM_VALUE = "UPDATE_FORM_VALUE";
export const EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_OPEN =
  "EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_OPEN";
export const EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_CLOSE =
  "EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_CLOSE";
export const HANDLE_FIELD_LABEL_OR_IDENTIFIER_FORM_SUBMIT =
  "HANDLE_FIELD_LABEL_OR_IDENTIFIER_FORM_SUBMIT";
export const EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_ERROR =
  "EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_ERROR";
export const EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_SUCCESS =
  "EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_SUCCESS";
export const HANDLE_SELECT_ALL_FIELDS_FOR_BULK_ACTION =
  "HANDLE_SELECT_ALL_FIELDS_FOR_BULK_ACTION";
export const HANDLE_REMOVE_ALL_SELECTED_FIELDS_FOR_BULK_ACTION =
  "HANDLE_REMOVE_ALL_SELECTED_FIELDS_FOR_BULK_ACTION";
export const HANDLE_SELECT_FIELD_FOR_BULK_ACTION =
  "HANDLE_SELECT_FIELD_FOR_BULK_ACTION";
export const HANDLE_REMOVE_SELECTED_FIELD_FOR_BULK_ACTION =
  "HANDLE_REMOVE_SELECTED_FIELD_FOR_BULK_ACTION";
export const HANDLE_SELECT_TARGET_FORM = "HANDLE_SELECT_TARGET_FORM";
export const HANDLE_SELECT_COPY_N_TIMES = "HANDLE_SELECT_COPY_N_TIMES";
export const HANDLE_SHOW_BULK_ACTION_CONFIRMATION =
  "HANDLE_SHOW_BULK_ACTION_CONFIRMATION";
export const HANDLE_HIDE_BULK_ACTION_CONFIRMATION =
  "HANDLE_HIDE_BULK_ACTION_CONFIRMATION";
export const HANDLE_DELETE_SELECTED_FIELDS = "HANDLE_DELETE_SELECTED_FIELDS";
export const HANDLE_MOVE_SELECTED_FIELDS = "HANDLE_MOVE_SELECTED_FIELDS";
export const HANDLE_COPY_SELECTED_FIELDS = "HANDLE_COPY_SELECTED_FIELDS";
export const HANDLE_DELETE_SELECTED_FIELDS_SUCCESS =
  "HANDLE_DELETE_SELECTED_FIELDS_SUCCESS";
export const HANDLE_DELETE_SELECTED_FIELDS_WITH_ERRORS =
  "HANDLE_DELETE_SELECTED_FIELDS_WITH_ERRORS";
export const HANDLE_BULK_ACTIONS_ERROR = "HANDLE_BULK_ACTIONS_ERROR";
export const HANDLE_MOVE_SELECTED_FIELDS_SUCCESS =
  "HANDLE_MOVE_SELECTED_FIELDS_SUCCESS";
export const HANDLE_MOVE_SELECTED_FIELDS_WITH_ERRORS =
  "HANDLE_MOVE_SELECTED_FIELDS_WITH_ERRORS";
export const HANDLE_COPY_SELECTED_FIELDS_SUCCESS =
  "HANDLE_COPY_SELECTED_FIELDS_SUCCESS";
export const HANDLE_COPY_SELECTED_FIELDS_WITH_ERRORS =
  "HANDLE_COPY_SELECTED_FIELDS_WITH_ERRORS";
export const HANDLE_HIDE_BULK_ACTION_ERROR = "HANDLE_HIDE_BULK_ACTION_ERROR";
export const HANDLE_SET_NAV_FILTER = "HANDLE_SET_NAV_FILTER";
export const HANDLE_NAV_SEARCH = "HANDLE_NAV_SEARCH";
export const HANDLE_NAV_SEARCH_SUCCESS = "HANDLE_NAV_SEARCH_SUCCESS";
export const HANDLE_OPEN_NEW_FIELD_FORM = "HANDLE_OPEN_NEW_FIELD_FORM";
export const HANDLE_CLOSE_NEW_FIELD_FORM = "HANDLE_CLOSE_NEW_FIELD_FORM";
export const UPDATE_NEW_FIELD_FORM_VALUE = "UPDATE_NEW_FIELD_FORM_VALUE";
export const HANDLE_NEW_FIELD_FORM_SUBMIT = "HANDLE_NEW_FIELD_FORM_SUBMIT";
export const HANDLE_NEW_FIELD_FORM_SUBMIT_AND_CLOSE =
  "HANDLE_NEW_FIELD_FORM_SUBMIT_AND_CLOSE";
export const HANDLE_NEW_FIELD_FORM_ERROR = "HANDLE_NEW_FIELD_FORM_ERROR";
export const HANDLE_NEW_FIELD_FORM_SUCCESS = "HANDLE_NEW_FIELD_FORM_SUCCESS";
export const HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE =
  "HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE";
export const HANDLE_OPEN_EDIT_DEPENDENCIES_FORM =
  "HANDLE_OPEN_EDIT_DEPENDENCIES_FORM";
export const HANDLE_GET_FIELDS_FOR_DEPENDENCIES =
  "HANDLE_GET_FIELDS_FOR_DEPENDENCIES";
export const HANDLE_CLOSE_EDIT_DEPENDENCIES_FORM =
  "HANDLE_CLOSE_EDIT_DEPENDENCIES_FORM";
export const UPDATE_EDIT_DEPENDENCIES_FORM_VALUE =
  "UPDATE_EDIT_DEPENDENCIES_FORM_VALUE";
export const HANDLE_SUBMIT_EDIT_DEPENDENCIES_FORM =
  "HANDLE_SUBMIT_EDIT_DEPENDENCIES_FORM";
export const HANDLE_EDIT_DEPENDENCIES_FORM_SUCCESS =
  "HANDLE_EDIT_DEPENDENCIES_FORM_SUCCESS";
export const HANDLE_EDIT_DEPENDENCIES_FORM_ERROR =
  "HANDLE_EDIT_DEPENDENCIES_FORM_ERROR";
export const HANDLE_OPEN_SETTINGS_FORM = "HANDLE_OPEN_SETTINGS_FORM";
export const UPDATE_SETTINGS_FORM_VALUE = "UPDATE_SETTINGS_FORM_VALUE";
export const HANDLE_CLOSE_SETTINGS_FORM = "HANDLE_CLOSE_SETTINGS_FORM";
export const HANDLE_SUBMIT_SETTINGS_FORM = "HANDLE_SUBMIT_SETTINGS_FORM";
export const HANDLE_SETTINGS_FORM_SUCCESS = "HANDLE_SETTINGS_FORM_SUCCESS";
export const HANDLE_SETTINGS_FORM_ERROR = "HANDLE_SETTINGS_FORM_ERROR";
export const EDIT_TYPE_OPEN = "EDIT_TYPE_OPEN";
export const EDIT_TYPE_CLOSE = "EDIT_TYPE_CLOSE";
export const EDIT_TYPE_FIELD_TYPE_CHANGE = "EDIT_TYPE_FIELD_TYPE_CHANGE";
export const EDIT_TYPE_WIDGET_TYPE_CHANGE = "EDIT_TYPE_WIDGET_TYPE_CHANGE";
export const EDIT_TYPE_UNIT_CHANGE = "EDIT_TYPE_UNIT_CHANGE";
export const EDIT_TYPE_PRECISION_CHANGE = "EDIT_TYPE_PRECISION_CHANGE";
export const EDIT_TYPE_LOOKUP_CHANGE = "EDIT_TYPE_LOOKUP_CHANGE";
export const EDIT_TYPE_SUBMIT = "EDIT_TYPE_SUBMIT";
export const EDIT_TYPE_SUBMIT_SUCCESS = "EDIT_TYPE_SUBMIT_SUCCESS";
export const EDIT_FIELD_TYPE_ERROR = "EDIT_FIELD_TYPE_ERROR";
export const ADD_LOOKUP_OPTION = "ADD_LOOKUP_OPTION";
export const REORDER_LOOKUP_OPTION = "REORDER_LOOKUP_OPTION";
export const REMOVE_LOOKUP_OPTION = "REMOVE_LOOKUP_OPTION";
export const HANDLE_NEW_LOOKUP_VALUE_CHANGE = "HANDLE_NEW_LOOKUP_VALUE_CHANGE";
export const HANDLE_NEW_LOOKUP_OPTION_VALUE_CHANGE =
  "HANDLE_NEW_LOOKUP_OPTION_VALUE_CHANGE";
export const UPDATE_EDITABLE = "UPDATE_EDITABLE";
export const HANDLE_OPEN_NEW_VALIDATION_RULES_FORM =
  "HANDLE_OPEN_NEW_VALIDATION_RULES_FORM";
export const HANDLE_NEW_VALIDATION_RULES_FORM_SUBMIT =
  "HANDLE_NEW_VALIDATION_RULES_FORM_SUBMIT";
export const HANDLE_EDIT_VALIDATION_RULES_FORM_SUBMIT =
  "HANDLE_EDIT_VALIDATION_RULES_FORM_SUBMIT";
export const HANDLE_NEW_VALIDATION_RULES_FORM_ERROR =
  "HANDLE_NEW_VALIDATION_RULES_FORM_ERROR";
export const HANDLE_EDIT_VALIDATION_RULES_FORM_ERROR =
  "HANDLE_EDIT_VALIDATION_RULES_FORM_ERROR";
export const HANDLE_NEW_VALIDATION_RULES_FORM_SUCCESS =
  "HANDLE_NEW_VALIDATION_RULES_FORM_SUCCESS";
export const HANDLE_EDIT_VALIDATION_RULES_FORM_SUCCESS =
  "HANDLE_EDIT_VALIDATION_RULES_FORM_SUCCESS";
export const HANDLE_OPEN_EDIT_VALIDATION_RULES_FORM =
  "HANDLE_OPEN_EDIT_VALIDATION_RULES_FORM";
export const UPDATE_VALIDATION_RULES_FORM_VALUE =
  "UPDATE_VALIDATION_RULES_FORM_VALUE";
export const HANDLE_CLOSE_VALIDATION_RULES_FORM =
  "HANDLE_CLOSE_VALIDATION_RULES_FORM";
export const HANDLE_GET_FIELDS_FOR_VALIDATION =
  "HANDLE_GET_FIELDS_FOR_VALIDATION";
export const HANDLE_DELETE_VALIDATION = "HANDLE_DELETE_VALIDATION";
export const HANDLE_DELETE_VALIDATION_SUCCESS =
  "HANDLE_DELETE_VALIDATION_SUCCESS";
export const HANDLE_REFERENCED_FIELDS_SUCCESS =
  "HANDLE_REFERENCED_FIELDS_SUCCESS";
export const HANDLE_REFERENCED_FIELDS_FAILURE =
  "HANDLE_REFERENCED_FIELDS_FAILURE";
