import React from "react";

const FaIconLink = ({
  icon,
  text,
  title,
  href,
  linkClassName = "btn btn-link",
}) => {
  return (
    <a href={href} title={title} className={linkClassName}>
      <i className={`fa ${icon ? `fa-${icon}` : ""} margin-right-5`} />
      {text}
    </a>
  );
};

export default FaIconLink;
