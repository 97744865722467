import React from "react";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  FormLayoutReducer,
  SectionFormReducer,
  FieldsMoveReducer,
  NavigationReducer,
} from "reducers/layout_page";
import {
  formLayoutMiddleware,
  navigationMiddleware,
} from "middleware/layout_page";

import {
  NavigationScreen,
  LayoutScreen,
  BulkActionsScreen,
  SectionFormScreen,
} from "components/layout_page";

const LayoutPage = (props) => {
  const store = createStore(
    combineReducers({
      formLayout: FormLayoutReducer,
      sectionForm: SectionFormReducer,
      fieldsMove: FieldsMoveReducer,
      navigation: NavigationReducer,
    }),
    {
      formLayout: props.current_form,
      navigation: {
        currentFormId: props.current_form.id,
        studyId: props.current_form.study_id,
        forms: props.forms,
        searchTerm: props.nav_filter,
      },
    },
    applyMiddleware(formLayoutMiddleware, navigationMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <LayoutScreen />
        <BulkActionsScreen />
        <SectionFormScreen />
        <NavigationScreen />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default LayoutPage;
