import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, Table } from "react-bootstrap";

import { FaButton, DeletionModal } from "../helpers";
import actions from "actions/entity_groups_page";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableEntityGroupRow from "./DraggableEntityGroupRow";

const EntityGroupTableScreen = ({
  studyId,
  entityGroups,
  processingRequest,
  entityGroupToDelete,
  showDeleteModal,
  addEntityGroup,
  editEntityGroup,
  orderEntityGroups,
  deleteEntityGroupModalToggle,
  deleteEntityGroup,
}) => {
  const handleDrag = (dragResult) => {
    const orderedEntityGroupIds = entityGroups.map(
      (entityGroup) => entityGroup.id
    );

    const fromIndex = dragResult.source.index;
    const toIndex = dragResult.destination.index;
    const entityGroupToMove = orderedEntityGroupIds[fromIndex];

    orderedEntityGroupIds.splice(fromIndex, 1);
    orderedEntityGroupIds.splice(toIndex, 0, entityGroupToMove);

    orderEntityGroups(studyId, orderedEntityGroupIds);
  };

  const handleConfirmEntityGroupDelete = () => {
    deleteEntityGroup(studyId, entityGroupToDelete.id);
  };

  const handleEntityGroupEdit = (entityGroup) => {
    editEntityGroup(studyId, entityGroup);
  };

  return (
    <>
      <DeletionModal
        title={"Are you sure?"}
        message={
          entityGroupToDelete
            ? `You are removing the '${entityGroupToDelete.name}' entity group.`
            : ""
        }
        show={showDeleteModal}
        onHide={() => deleteEntityGroupModalToggle(entityGroupToDelete)}
        onConfirm={handleConfirmEntityGroupDelete}
        processingRequest={processingRequest}
        canCancel={!processingRequest}
        canMakeDeleteRequest={!processingRequest}
      />
      <Panel
        className="entity-group-panel"
        bsStyle="primary"
        id="entity-group-details"
      >
        <Panel.Heading className="panel-heading-sticky">
          <Panel.Title componentClass="h3">
            <i className="fa fa-folder-open margin-right-5" />
            {"Entity Groups"}
          </Panel.Title>
          <ul className="panel-buttons">
            <li>
              <FaButton
                icon="plus"
                text="Add"
                title="Add a new entity group"
                className="btn btn-link"
                onClick={() => addEntityGroup()}
              />
            </li>
          </ul>
        </Panel.Heading>
        <Table hover style={{ tableLayout: "auto" }}>
          <thead className="table-header">
            <tr className="entity-group">
              <th className="mini"></th>
              <th>Name</th>
              <th>Identifier</th>
              <th nowrap="nowrap">Entity Identifier</th>
              <th>Entity Term</th>
              <th className="mini"></th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={(dragResult) => handleDrag(dragResult)}>
            <Droppable droppableId={`entity_group_drop_area_${studyId}`}>
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {entityGroups.map((entityGroup, index) => {
                    return (
                      <DraggableEntityGroupRow
                        entityGroup={entityGroup}
                        index={index}
                        key={entityGroup.id}
                        deleteEntityGroup={deleteEntityGroupModalToggle}
                        editEntityGroup={handleEntityGroupEdit}
                      />
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </Panel>
    </>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.entityGroupTable;
  return {
    studyId: relevantState.studyId,
    entityGroups: relevantState.entityGroups,
    processingRequest: relevantState.processingRequest,
    entityGroupToDelete: relevantState.entityGroupToDelete,
    showDeleteModal: relevantState.showDeleteModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addEntityGroup: actions.addEntityGroup,
      orderEntityGroups: actions.orderEntityGroups,
      deleteEntityGroup: actions.deleteEntityGroup,
      editEntityGroup: actions.editEntityGroup,
      deleteEntityGroupModalToggle: actions.deleteEntityGroupModalToggle,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityGroupTableScreen);
