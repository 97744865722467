export const UPDATE_FORM_VALUE = "UPDATE_FORM_VALUE";
export const MOVE_ELEMENT = "MOVE_ELEMENT";
export const MOVE_ELEMENT_FAILURE = "MOVE_ELEMENT_FAILURE";
export const BULK_MOVE = "BULK_MOVE";
export const BULK_REMOVE = "BULK_REMOVE";
export const CANCEL_BULK_MOVE = "CANCEL_BULK_MOVE";
export const BULK_MOVE_SUCCESS = "BULK_MOVE_SUCCESS";
export const BULK_MOVE_FAILURE = "BULK_MOVE_FAILURE";
export const TARGET_SECTION_SELECTED = "TARGET_SECTION_SELECTED";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const FORM_SECTION_SELECTED_FOR_EDIT = "FORM_SECTION_SELECTED_FOR_EDIT";
export const REMOVE_SECTION_SUCCESS = "REMOVE_SECTION_SUCCESS";
export const CLOSE_SECTION_FORM = "CLOSE_SECTION_FORM";
export const SHOW_SECTION_FORM = "SHOW_SECTION_FORM";
export const SAVE_SECTION_FORM = "SAVE_SECTION_FORM";
export const UPDATE_SECTION_FORM = "UPDATE_SECTION_FORM";
export const SAVE_SECTION_FORM_ERROR = "SAVE_SECTION_FORM_ERROR";
export const UPDATE_SECTION_FORM_ERROR = "UPDATE_SECTION_FORM_ERROR";
export const UPDATE_SECTION_FORM_SUCCESS = "UPDATE_SECTION_FORM_SUCCESS";
export const HIDE_SECTION_FORM_SUCCESS_NOTICE =
  "HIDE_SECTION_FORM_SUCCESS_NOTICE";
export const SAVE_SECTION_FORM_SUCCESS = "SAVE_SECTION_FORM_SUCCESS";
export const SAVE_AND_CLOSE_SECTION_FORM = "SAVE_AND_CLOSE_SECTION_FORM";
export const SAVE_AND_CLOSE_SECTION_FORM_SUCCESS =
  "SAVE_AND_CLOSE_SECTION_FORM_SUCCESS";
export const ADD_FIELDS_TO_MOVE = "ADD_FIELDS_TO_MOVE";
export const REMOVE_FIELDS_TO_MOVE = "REMOVE_FIELDS_TO_MOVE";
export const DRAG_START = "DRAG_START";
export const NAV_SEARCH = "NAV_SEARCH";
export const NAV_SEARCH_SUCCESS = "NAV_SEARCH_SUCCESS";
export const NAV_SET_SEARCH_TERM = "NAV_SET_SEARCH_TERM";
