import {
  siteFormActionTypes,
  sitePropertyActionTypes,
} from "actions/sites_page";

export const siteFormReducerDefaultState = {
  shown: false,
  editing: false,
  saving: false,
  values: {
    code: "",
    short_name: "",
    extra_code: "",
    long_name: "",
    recruitment_start_date: "",
    recruitment_end_date: "",
    metadatum_entries: [],
  },
  errors: {},
};

const replaceItemAtPosition = (array, position, replacementFunction) => {
  return array.map((item, index) => {
    if (index === position) return replacementFunction(item);
    else {
      return item;
    }
  });
};

const editValueFor = (siteProperty, metadatumEntry) => {
  let editVal = {
    id: metadatumEntry ? metadatumEntry.id : undefined,
    metadatum_id: siteProperty.id,
  };

  if (siteProperty.data_type == "site_specific_list") {
    editVal = {
      ...editVal,
      metadatum_list_options: metadatumEntry
        ? metadatumEntry.metadatum_list_options_json
        : [],
    };
  } else if (siteProperty.data_type == "single_value") {
    editVal = {
      ...editVal,
      metadatum_field_option_id: metadatumEntry
        ? metadatumEntry.metadatum_field_option_id || ""
        : "",
    };
  } else {
    editVal = {
      ...editVal,
      string_value: metadatumEntry ? metadatumEntry.value_for_json || "" : "",
    };
  }

  return editVal;
};

export const SiteFormReducer = (
  state = siteFormReducerDefaultState,
  action
) => {
  switch (action.type) {
    case siteFormActionTypes.OPEN_NEW_SITE_FORM: {
      const defaultValuesForSiteProperties = state.additionalSiteProperties.map(
        (siteProperty) => {
          if (siteProperty.data_type == "site_specific_list") {
            return {
              metadatum_id: siteProperty.id,
              metadatum_list_options: [],
            };
          } else if (siteProperty.data_type == "single_value") {
            return {
              metadatum_id: siteProperty.id,
              metadatum_field_option_id: "",
            };
          } else {
            return {
              metadatum_id: siteProperty.id,
              string_value: "",
            };
          }
        }
      );

      const newState = {
        ...state,
        shown: true,
        editing: false,
        values: {
          ...state.values,
          ...siteFormReducerDefaultState.values,
          metadatum_entries: defaultValuesForSiteProperties,
        },
        errors: {},
      };

      return newState;
    }

    case siteFormActionTypes.OPEN_EDIT_SITE_FORM: {
      const site = action.site;

      const metadatumEntries = state.additionalSiteProperties.map(
        (siteProperty) => {
          const metadatumEntry = site.metadatum_entries.find(
            (entry) => entry.metadatum_id == siteProperty.id
          );
          return editValueFor(siteProperty, metadatumEntry);
        }
      );

      const newState = {
        ...state,
        shown: true,
        editing: true,
        siteId: site.id,
        values: {
          ...state.values,
          code: site.code || "",
          short_name: site.short_name || "",
          extra_code: site.extra_code || "",
          long_name: site.long_name || "",
          recruitment_start_date: site.recruitment_start_date || "",
          recruitment_end_date: site.recruitment_end_date || "",
          metadatum_entries: metadatumEntries,
        },
        errors: {},
      };

      return newState;
    }

    case siteFormActionTypes.EDIT_SITE_FORM_SAVE_SUCCESS:
    case siteFormActionTypes.CLOSE_SITE_FORM:
    case siteFormActionTypes.NEW_SITE_FORM_SAVE_AND_CLOSE_SUCCESS: {
      const newState = {
        ...state,
        shown: false,
        saving: false,
      };
      return newState;
    }

    case siteFormActionTypes.NEW_SITE_FORM_SAVE_SUCCESS: {
      const newState = {
        ...state,
        ...siteFormReducerDefaultState,
        shown: true,
      };
      return newState;
    }

    case siteFormActionTypes.SITE_FORM_VALUE_CHANGE: {
      const newState = {
        ...state,
        values: {
          ...state.values,
          [action.fieldName]: action.value,
        },
      };
      return newState;
    }

    case siteFormActionTypes.SITE_FORM_PROPERTY_VALUE_CHANGE: {
      const newMetadatumEntries = replaceItemAtPosition(
        state.values.metadatum_entries,
        action.position,
        (entry) => {
          return { ...entry, [action.fieldName]: action.value };
        }
      );

      const newState = {
        ...state,
        values: {
          ...state.values,
          metadatum_entries: newMetadatumEntries,
        },
      };
      return newState;
    }

    case siteFormActionTypes.SITE_FORM_LIST_VALUE_CHANGE: {
      const metadatumEntry =
        state.values.metadatum_entries[action.metadatumEntryPosition];
      const newListOptions = replaceItemAtPosition(
        metadatumEntry.metadatum_list_options,
        action.optionIndex,
        (listOption) => {
          return { ...listOption, [action.fieldName]: action.value };
        }
      );
      const newMetadatumEntries = replaceItemAtPosition(
        state.values.metadatum_entries,
        action.metadatumEntryPosition,
        (entry) => {
          return { ...entry, metadatum_list_options: newListOptions };
        }
      );

      const newState = {
        ...state,
        values: {
          ...state.values,
          metadatum_entries: newMetadatumEntries,
        },
      };
      return newState;
    }

    case siteFormActionTypes.SITE_FORM_ADD_LIST_OPTION: {
      // Add the new list option just before the ones marked for destruction

      const metadatumEntry =
        state.values.metadatum_entries[action.metadatumEntryPosition];
      const markedForDestructionPosition =
        metadatumEntry.metadatum_list_options.findIndex(
          (option) => option.marked_for_destruction === true
        );

      const newMetadatumFieldOptions = [
        ...metadatumEntry.metadatum_list_options,
      ];
      if (markedForDestructionPosition === -1) {
        newMetadatumFieldOptions.push({ identifier: "", name: "" });
      } else {
        newMetadatumFieldOptions.splice(markedForDestructionPosition, 0, {
          identifier: "",
          name: "",
        });
      }

      const newMetadatumEntries = replaceItemAtPosition(
        state.values.metadatum_entries,
        action.metadatumEntryPosition,
        (entry) => {
          return {
            ...entry,
            metadatum_list_options: newMetadatumFieldOptions,
          };
        }
      );

      const newState = {
        ...state,
        values: {
          ...state.values,
          metadatum_entries: newMetadatumEntries,
        },
      };

      return newState;
    }

    case siteFormActionTypes.SITE_FORM_REMOVE_LIST_OPTION: {
      let newListOptions = [
        ...state.values.metadatum_entries[action.metadatumEntryPosition]
          .metadatum_list_options,
      ];

      if (newListOptions[action.optionIndex].id) {
        // This option has already been saved in the database so we need to mark it for destruction

        // Add marked for destruction to the removed option
        const removedOption = {
          ...newListOptions[action.optionIndex],
          marked_for_destruction: true,
        };
        // Move the removed option to the end of the list
        newListOptions.splice(action.optionIndex, 1);
        newListOptions.push(removedOption);
      } else {
        // This option has not been saved in the database so we can just remove it
        newListOptions.splice(action.optionIndex, 1);
      }

      const newMetadatumEntries = replaceItemAtPosition(
        state.values.metadatum_entries,
        action.metadatumEntryPosition,
        (entry) => {
          return { ...entry, metadatum_list_options: newListOptions };
        }
      );

      // Remove the errors along with the list option
      const listOptionErrorString = `metadatum_entries[${action.metadatumEntryPosition}].metadatum_list_options[${action.optionIndex}]`;
      const newErrors = state.errors;
      delete newErrors[`${listOptionErrorString}.identifier`];
      delete newErrors[`${listOptionErrorString}.name`];

      const newState = {
        ...state,
        values: {
          ...state.values,
          metadatum_entries: newMetadatumEntries,
        },
        errors: newErrors,
      };

      return newState;
    }

    case siteFormActionTypes.REORDER_SITE_FORM_LIST_OPTION: {
      let listOptions = [
        ...state.values.metadatum_entries[action.metadatumEntryPosition]
          .metadatum_list_options,
      ];
      const [movedOption] = listOptions.splice(action.result.source.index, 1);
      listOptions.splice(action.result.destination.index, 0, movedOption);

      // Note: Rather than reordering the error messages, we just remove them all as it is far simpler to do.
      // We don't expect users to reorder, without fixing the errors and saving the form again will repopulate the relevant errors.
      const matchingRegex = new RegExp(
        `metadatum_entries\\[${action.metadatumEntryPosition}\\]`
      );
      const newErrors = Object.fromEntries(
        Object.entries(state.errors).filter(([attribute, _]) => {
          return attribute.match(matchingRegex) === null;
        })
      );

      const newState = {
        ...state,
        values: {
          ...state.values,
          metadatum_entries: replaceItemAtPosition(
            state.values.metadatum_entries,
            action.metadatumEntryPosition,
            (entry) => {
              return { ...entry, metadatum_list_options: listOptions };
            }
          ),
        },
        errors: newErrors,
      };

      return newState;
    }

    case siteFormActionTypes.EDIT_SITE_FORM_SAVE:
    case siteFormActionTypes.NEW_SITE_FORM_SAVE_AND_CLOSE:
    case siteFormActionTypes.NEW_SITE_FORM_SAVE: {
      const newState = { ...state, saving: true };
      return newState;
    }

    case siteFormActionTypes.SITE_FORM_ERROR: {
      const newState = {
        ...state,
        saving: false,
        errors: action.errors,
      };
      return newState;
    }

    case sitePropertyActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE_SUCCESS:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE_SUCCESS:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_SUCCESS: {
      const newState = {
        ...state,
        additionalSiteProperties: action.additionalSiteProperties,
      };
      return newState;
    }

    case sitePropertyActionTypes.REMOVE_SITE_PROPERTY: {
      const newState = {
        ...state,
        additionalSiteProperties: state.additionalSiteProperties.filter(
          (siteProperty) => siteProperty.id != action.sitePropertyId
        ),
      };
      return newState;
    }
  }
  return state;
};
