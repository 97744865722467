import ChangeObject from "./ChangeObject";

class FormChange extends ChangeObject {
  constructor(change) {
    const formIdentifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;

    const attributeData = [
      ["Identifier", "identifier"],
      ["Name", "name"],
      [
        "Privacy tags",
        "privacy_tags",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `${associatedObject.name.value}`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map((associatedObject) => {
                return associatedObject.name.value;
              })
              .join(", ");
          },
        },
      ],
      ["Annotations", "annotations", { attributeType: "boolean" }],
      ["Unavailability", "unavailability", { attributeType: "boolean" }],
      ["Import", "import", { attributeType: "boolean" }],
      ["Export", "export", { attributeType: "boolean" }],
      [
        "Subforms",
        "subforms",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `<strong>${associatedObject.label.value} - ${associatedObject.identifier.value}</strong>`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject) =>
                  `${associatedObject.label.value} - ${associatedObject.identifier.value}`
              )
              .join(", ");
          },
        },
      ],
    ];

    super(change, "Form", formIdentifier, attributeData);
  }
}

export default FormChange;
