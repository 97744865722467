export const HANDLE_OPEN_NEW_MESSAGE_TEMPLATE_FORM =
  "HANDLE_OPEN_NEW_MESSAGE_TEMPLATE_FORM";

export const HANDLE_CLOSE_MESSAGE_TEMPLATE_FORM =
  "HANDLE_OPEN_MESSAGE_TEMPLATE_FORM";

export const HANDLE_MESSAGE_TEMPLATE_FORM_VALUE_CHANGE =
  "HANDLE_MESSAGE_TEMPLATE_FORM_VALUE_CHANGE";

export const HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT =
  "HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT";

export const HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS =
  "HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS";

export const HANDLE_MESSAGE_TEMPLATE_FORM_SUBMIT_FAILURE =
  "HANDLE_MESSAGE_TEMPLATE_FORM_SUBMIT_FAILURE";

export const HANDLE_MESSAGE_TEMPLATE_DELETE_MODAL_TOGGLE =
  "HANDLE_MESSAGE_TEMPLATE_DELETE_MODAL_TOGGLE";

export const HANDLE_MESSAGE_TEMPLATE_DELETE = "HANDLE_MESSAGE_TEMPLATE_DELETE";

export const HANDLE_MESSAGE_TEMPLATE_DELETE_SUCCESS =
  "HANDLE_MESSAGE_TEMPLATE_DELETE_SUCCESS";

export const HANDLE_MESSAGE_TEMPLATE_DELETE_FAILURE =
  "HANDLE_MESSAGE_TEMPLATE_DELETE_FAILURE";

export const HANDLE_MESSAGE_TEMPLATE_EDIT_CLICK =
  "HANDLE_MESSAGE_TEMPLATE_EDIT_CLICK";

export const HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT =
  "HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT";

export const HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS =
  "HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS";
