import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, Table, ButtonGroup } from "react-bootstrap";

import { siteFormActions, siteActions } from "actions/sites_page";
import { FaButton, NoRecordsRow } from "components/helpers";

const SitesTable = ({
  sites,
  additionalSiteProperties,
  openNewSiteForm,
  openEditSiteForm,
  removeSite,
}) => {
  const additionalProperties = additionalSiteProperties.filter(
    (metadatum) => metadatum.data_type !== "site_specific_list"
  );
  const siteSpecificLists = additionalSiteProperties.filter(
    (metadatum) => metadatum.data_type === "site_specific_list"
  );

  const recruitmentDateDisplay = (date) =>
    date ? date : <span className="text-muted">None</span>;

  return (
    <Panel bsStyle="primary">
      <Panel.Heading className="panel-heading-sticky">
        <ul className="panel-buttons">
          <li>
            <FaButton
              icon="plus"
              bsStyle="link"
              text="Add"
              title="Add a new site"
              onClick={() => openNewSiteForm()}
            />
          </li>
        </ul>
        <Panel.Title componentClass="h2">
          <i className="fa fa-reorder margin-right-5" />
          Sites
        </Panel.Title>
      </Panel.Heading>
      <Table id="sites-table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Extra code</th>
            <th>Short Name</th>
            <th>Long Name</th>
            <th>Recruitment dates</th>
            <th>Site-specific Lists</th>
            <th>Additional Properties</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sites.map((site) => {
            // We need to display the additional site properties that there is not an entry for as well.
            const siteSpecificListEntries = site.metadatum_entries.filter(
              (entry) => entry.metadatum.data_type === "site_specific_list"
            );
            const additionalPropertyEntries = site.metadatum_entries.filter(
              (entry) => entry.metadatum.data_type !== "site_specific_list"
            );
            return (
              <tr key={`site-${site.id}`} id={`site-${site.id}`}>
                <td>{site.code}</td>
                <td>
                  {site.extra_code ? site.extra_code : <span>Empty</span>}
                </td>
                <td>{site.short_name}</td>
                <td>{site.long_name}</td>
                <td>
                  {recruitmentDateDisplay(site.recruitment_start_date_display)}{" "}
                  - {recruitmentDateDisplay(site.recruitment_end_date_display)}
                </td>
                <td className="small site-specific-lists-column">
                  <ul className="list-unstyled no-whitespace">
                    {siteSpecificLists.map((metadatum) => {
                      const matchingEntry = siteSpecificListEntries.find(
                        (entry) =>
                          entry.metadatum.identifier === metadatum.identifier
                      );
                      if (
                        matchingEntry &&
                        matchingEntry.value_for_json !== ""
                      ) {
                        return (
                          <li
                            key={`${site.short_name}-${metadatum.identifier}`}
                          >
                            <strong>{`${metadatum.name}: `}</strong>
                            {matchingEntry.value_for_json}
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={`${site.short_name}-${metadatum.identifier}`}
                          >
                            <strong>{`${metadatum.name}: `}</strong>
                            <em className="text-muted">Entries missing</em>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </td>
                <td className="small site-metadata-column">
                  <ul className="list-unstyled no-whitespace">
                    {additionalProperties.map((metadatum) => {
                      const matchingEntry = additionalPropertyEntries.find(
                        (entry) =>
                          entry.metadatum.identifier === metadatum.identifier
                      );
                      if (matchingEntry) {
                        return (
                          <li
                            key={`${site.short_name}-${metadatum.identifier}`}
                          >
                            <strong>{`${metadatum.name}: `}</strong>
                            {matchingEntry.value_for_json}
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={`${site.short_name}-${metadatum.identifier}`}
                          >
                            <strong>{`${metadatum.name}: `}</strong>
                            <em className="text-muted">Property missing</em>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </td>
                <td>
                  <ButtonGroup bsSize="sm" style={{ display: "flex" }}>
                    <FaButton
                      icon="pencil"
                      text="Edit"
                      title={`Edit ${site.short_name}`}
                      onClick={() => {
                        openEditSiteForm(site);
                      }}
                    />
                    {sites.length == 1 && (
                      <button
                        onClick={() => {}}
                        title="Cannot remove all sites from the study"
                        className="btn btn-default"
                        disabled={true}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    )}
                    {sites.length > 1 && (
                      <button
                        onClick={() => {
                          removeSite(site.study_id, site.id);
                        }}
                        title={`Delete site '${site.short_name}'`}
                        className="btn btn-default"
                        data-confirm="Are you sure you want to delete this site?"
                      >
                        <i className="fa fa-trash text-danger" />
                      </button>
                    )}
                  </ButtonGroup>
                </td>
              </tr>
            );
          })}
          {sites.length == 0 && <NoRecordsRow colSpan={9} />}
        </tbody>
      </Table>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.siteData;

  return {
    sites: relevantState.sites,
    additionalSiteProperties: relevantState.additionalSiteProperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      openNewSiteForm: siteFormActions.openNewSiteForm,
      openEditSiteForm: siteFormActions.openEditSiteForm,
      removeSite: siteActions.removeSite,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesTable);
