import React from "react";
import { connect } from "react-redux";

import { CSVDownloadButton } from "components/helpers";

const RevisionExport = ({
  studyName,
  revisionNumber,
  ticketNumber,
  changeObjects,
}) => {
  const headers = [
    "Revision #",
    "Ticket #",
    "Change type",
    "Element type",
    "Element",
    "Property / Attribute",
    "Original value",
    "New value",
    "Details",
  ];

  const attributeToRow = (changeClass, attribute) => {
    return [
      revisionNumber,
      ticketNumber,
      changeClass.change.action_type,
      changeClass.elementType,
      changeClass.elementLocation,
      attribute.title,
      attribute.displayOriginalValueForExport(),
      attribute.displayNewValueForExport(),
      "",
    ];
  };

  const orderChangeToRow = (changeClass) => {
    return [
      revisionNumber,
      ticketNumber,
      changeClass.change.action_type,
      changeClass.elementType,
      changeClass.elementLocation,
      "ordering",
      changeClass.displayForExport(changeClass.change.original_attributes),
      changeClass.displayForExport(changeClass.change.new_attributes),
    ];
  };

  const groupedAttributesRow = (changeClass) => {
    const originalValues = changeClass.groupedAttributesToExport
      .map((attribute) => {
        return `${
          attribute.title
        }: ${attribute.displayOriginalValueForExport()}`;
      })
      .join("\n");
    const newValues = changeClass.groupedAttributesToExport
      .map((attribute) => {
        return `${attribute.title}: ${attribute.displayNewValueForExport()}`;
      })
      .join("\n");
    return [
      revisionNumber,
      ticketNumber,
      changeClass.change.action_type,
      changeClass.elementType,
      changeClass.elementLocation,
      "All",
      changeClass.change.action_type === "delete" ? originalValues : null,
      changeClass.change.action_type === "create" ? newValues : null,
    ];
  };

  const changeToRows = (changeClass) => {
    if (changeClass.change.action_type === "order_update") {
      return [orderChangeToRow(changeClass)];
    } else if (changeClass.change.action_type === "update") {
      // For update changes display one row per attribute
      return changeClass.attributesToExport.map((attribute) =>
        attributeToRow(changeClass, attribute)
      );
    } else {
      const groupedAttributes = groupedAttributesRow(changeClass);
      const ungroupedAttributes = changeClass.ungroupedAttributesToExport.map(
        (attribute) => attributeToRow(changeClass, attribute)
      );
      return [groupedAttributes, ...ungroupedAttributes];
    }
  };

  const data = changeObjects
    .filter((change) => change !== undefined)
    .map((change) => {
      return changeToRows(change);
    })
    .flat();

  const cleanedStudyName = studyName.replace(/ /g, "_");

  return (
    <CSVDownloadButton
      headers={headers}
      data={data}
      addTimeStamp={true}
      filename={`${cleanedStudyName}_revision_${revisionNumber}_export`}
      active={true}
    >
      <i className="fa fa-arrow-down margin-right-5" />
      Download revision
    </CSVDownloadButton>
  );
};

const mapStateToProps = (state) => {
  return {
    studyName: state.revision.studyName,
    revisionNumber: state.revision.revisionNumber,
    ticketNumber: state.form.values.ticket_number,
    changeObjects: state.revision.changeObjects,
  };
};

export default connect(mapStateToProps)(RevisionExport);
