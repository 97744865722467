import React from "react";

export const changeTitle = (
  attributes,
  boldTitle = false,
  emptyLabel = "Empty"
) => {
  const AttributeLabel = ({ label, boldTitle }) => {
    const labelEmpty = !label || label.length === 0;

    return React.createElement(
      boldTitle ? "strong" : "span",
      { className: "change-label" },
      (labelEmpty && <em>{emptyLabel}</em>) || label
    );
  };

  // Support either { label: ... } or { name: ... } for the 'label'
  const attributeLabel = attributes.label ? attributes.label : attributes.name;

  if (attributeLabel && attributes.identifier) {
    return (
      <>
        <AttributeLabel label={attributeLabel.value} boldTitle={boldTitle} />
        <span className="change-separator">-</span>
        {attributes.identifier.value}
      </>
    );
  }
};

export const subheadingNameFor = (change) => {
  return (
    (change.new_attributes &&
      change.new_attributes.subheading_information.name.value) ||
    change.original_attributes.subheading_information.name.value
  );
};

const subheadingPositionFor = (change) => {
  if (change.new_attributes) {
    return change.new_attributes.subheading_information.position.value;
  } else {
    return change.original_attributes.subheading_information.position.value;
  }
};

export const groupChangesBySubheading = (changes, groupingFunction) => {
  const reducer = (changesBySubheading, change) => {
    const subheadingTitle = groupingFunction(change);
    changesBySubheading[subheadingTitle] =
      changesBySubheading[subheadingTitle] || [];
    changesBySubheading[subheadingTitle].push(change);
    return changesBySubheading;
  };

  const changesOrderedBySubheading = changes.sort(
    (a, b) => subheadingPositionFor(a) - subheadingPositionFor(b)
  );
  return changesOrderedBySubheading.reduce(reducer, {});
};
