import React from "react";

import { Button } from "react-bootstrap";
import { Draggable } from "react-beautiful-dnd";
import {
  TextField,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";

const ListOption = ({
  siteList,
  listOption,
  errors,
  optionIndex,
  disabled,
  listValueChange,
  removeListOption,
}) => {
  return (
    <Draggable
      key={`listOptionDraggable_${listOption.id}_${optionIndex}`}
      draggableId={`listOptionDraggable_${listOption.id}_${optionIndex}`}
      index={optionIndex}
    >
      {(provided) => (
        <tr
          id={`list${siteList.id}-option${optionIndex}`}
          className="field-group nested-fields draggable-row"
          key={optionIndex}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <td className="vertical-center" style={{ width: "30px" }}>
            <span className="sorTable-handler" {...provided.dragHandleProps}>
              <i className="fa fa-reorder" />
            </span>
          </td>
          <td style={{ width: "200px" }}>
            <TextField
              controlId={`list${siteList.id}-option${optionIndex}-identifier`}
              fieldName="identifier"
              pattern={IDENTIFIER_LIMIT_PATTERN}
              title={IDENTIFIER_LIMIT_TITLE}
              required={true}
              label="Identifier"
              placeholder="Identifier"
              labelClassName="sr-only"
              value={listOption.identifier}
              disabled={disabled}
              errors={
                errors[
                  `metadatum_entries[${siteList.position}].metadatum_list_options[${optionIndex}].identifier`
                ]
              }
              onChange={(fieldName, value) => {
                listValueChange(
                  siteList.position,
                  optionIndex,
                  fieldName,
                  value
                );
              }}
            />
          </td>
          <td style={{ width: "250px" }}>
            <TextField
              controlId={`list${siteList.id}-option${optionIndex}-name`}
              fieldName="name"
              required={true}
              label="Name"
              placeholder="Name"
              labelClassName="sr-only"
              value={listOption.name}
              disabled={disabled}
              errors={
                errors[
                  `metadatum_entries[${siteList.position}].metadatum_list_options[${optionIndex}].name`
                ]
              }
              onChange={(fieldName, value) => {
                listValueChange(
                  siteList.position,
                  optionIndex,
                  fieldName,
                  value
                );
              }}
            />
          </td>
          <td className="mini">
            <Button
              onClick={() => {
                removeListOption(siteList.position, optionIndex);
              }}
              disabled={disabled}
              bsStyle="link"
              title="Remove this option"
            >
              <i className="fa fa-trash-o text-danger text-large" />
            </Button>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default ListOption;
