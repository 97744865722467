import React from "react";

const renderIssuesList = (list) => {
  return (
    <ul>
      {list.map((issue, index) => {
        return <li key={index}>{issue}</li>;
      })}
    </ul>
  );
};

const fieldReferencesPreventingAction = (references) => {
  const fieldReferences = [];
  references.forEach((reference) => {
    reference.field_references.forEach((fieldReference) => {
      fieldReferences.push(
        <span>
          <em>{reference.identifier}</em> is referenced in the dependency for{" "}
          <em> {fieldReference.field_identifier} </em> on &apos;
          <em>{fieldReference.form_name}</em>&apos;
        </span>
      );
    });
  });

  return fieldReferences;
};

const validationRuleReferencesPreventingAction = (references) => {
  const validationReferences = [];
  references.forEach((reference) => {
    reference.validation_rule_condition_references.forEach(
      (validationRuleReference) => {
        validationReferences.push(
          <span>
            <em>{reference.identifier}</em> is referenced in a validation rule
            condition for <em>{validationRuleReference.field_identifier}</em> (
            {validationRuleReference.type}) on &apos;
            <em>{validationRuleReference.form_name}</em>&apos;
          </span>
        );
      }
    );
  });

  return validationReferences;
};

const formBindingReferencesPreventingAction = (references) => {
  const formBindingReferences = [];
  references.forEach((reference) => {
    reference.form_binding_references.forEach((formBindingReference) => {
      formBindingReferences.push(
        <span>
          <em>{reference.identifier}</em> is referenced in the dependency for
          &apos;
          <em>{formBindingReference.form_name}</em>&apos; at &apos;
          <em>{formBindingReference.event_name}</em>&apos;
        </span>
      );
    });
  });

  return formBindingReferences;
};

const eventReferencesPreventingAction = (references) => {
  const eventReferences = [];
  references.forEach((reference) => {
    reference.event_references.dependencies.forEach((eventReference) => {
      eventReferences.push(
        <span>
          <em>{reference.identifier}</em> is referenced in the dependency for
          &apos;
          <em>{eventReference.event_name}</em>&apos;
        </span>
      );
    });
    reference.event_references.date.forEach((eventReference) => {
      eventReferences.push(
        <span>
          <em>{reference.identifier}</em> is referenced in the event date for
          &apos;
          <em>{eventReference.event_name}</em>&apos;
        </span>
      );
    });
    reference.event_references.scheduling.forEach((eventReference) => {
      eventReferences.push(
        <span>
          <em>{reference.identifier}</em> is referenced in the scheduling for
          &apos;
          <em>{eventReference.event_name}</em>&apos;
        </span>
      );
    });
  });

  return eventReferences;
};

const questionnaireFieldReferencesPreventingAction = (references) => {
  const parsedReferences = [];

  references.forEach((reference) => {
    const questionnaireFieldReferences = reference.questionnaire_references;

    const inclusionCriteriaReferences =
      questionnaireFieldReferences.inclusion_criteria;
    const meansOfInitialAttemptDeliveryReferences =
      questionnaireFieldReferences.means_of_initial_attempt_delivery;
    const meansOfReminderAttemptDeliveryReferences =
      questionnaireFieldReferences.means_of_reminder_attempt_delivery;
    const contactDetailsReferences =
      questionnaireFieldReferences.contact_details;

    inclusionCriteriaReferences.forEach((inclusionCriteriaReference) => {
      parsedReferences.push(
        <span>
          <em>{reference.identifier}</em> is referenced in the dependency for
          the inclusion criteria of &apos;
          <em>{inclusionCriteriaReference.questionnaire_name}</em>
          &apos;
        </span>
      );
    });

    meansOfInitialAttemptDeliveryReferences.forEach(
      (meansOfInitialAttemptDeliveryReference) => {
        parsedReferences.push(
          <span>
            <em>{reference.identifier}</em> is referenced in the dependency for
            one of the means of initial attempt delivery of &apos;
            <em>{meansOfInitialAttemptDeliveryReference.questionnaire_name}</em>
            &apos;
          </span>
        );
      }
    );

    meansOfReminderAttemptDeliveryReferences.forEach(
      (meansOfReminderAttemptDeliveryReference) => {
        parsedReferences.push(
          <span>
            <em>{reference.identifier}</em> is referenced in the dependency for
            one of the means of reminder attempt delivery of &apos;
            <em>
              {meansOfReminderAttemptDeliveryReference.questionnaire_name}
            </em>
            &apos;
          </span>
        );
      }
    );

    contactDetailsReferences.forEach((contactDetailsReference) => {
      parsedReferences.push(
        <span>
          <em>{reference.identifier}</em> is referenced in the dependency for
          the contact details of &apos;
          <em>{contactDetailsReference.questionnaire_name}</em>
          &apos;
        </span>
      );
    });
  });

  return parsedReferences;
};

export const referencesPreventingAction = (references) => {
  const fieldReferences = fieldReferencesPreventingAction(
    references["fieldsPreventingDelete"]
  );
  const validationRuleReferences = validationRuleReferencesPreventingAction(
    references["fieldsPreventingDelete"]
  );
  const formBindingReferences = formBindingReferencesPreventingAction(
    references["fieldsPreventingDelete"]
  );
  const eventReferences = eventReferencesPreventingAction(
    references["fieldsPreventingDelete"]
  );
  const questionnaireFieldReferences =
    questionnaireFieldReferencesPreventingAction(
      references["fieldsPreventingDelete"]
    );

  const questionnaireReferences = references[
    "questionnairesPreventingDelete"
  ].map((reference, index) => {
    return (
      <span key={index}>
        <em>{reference.name}</em> is linked to this {reference.source}
      </span>
    );
  });

  return (
    <>
      {fieldReferences.length > 0 && (
        <>
          <strong>Fields referenced in field dependencies</strong>
          <div className="field-references">
            {renderIssuesList(fieldReferences)}
          </div>
        </>
      )}
      {validationRuleReferences.length > 0 && (
        <>
          <strong>Fields referenced in validation rule conditions</strong>
          <div className="validation-rule-references">
            {renderIssuesList(validationRuleReferences)}
          </div>
        </>
      )}
      {formBindingReferences.length > 0 && (
        <>
          <strong>Fields referenced in form binding dependencies</strong>
          <div className="form-binding-references">
            {renderIssuesList(formBindingReferences)}
          </div>
        </>
      )}
      {eventReferences.length > 0 && (
        <>
          <strong>Fields referenced in events</strong>
          <div className="event-references">
            {renderIssuesList(eventReferences)}
          </div>
        </>
      )}
      {questionnaireFieldReferences.length > 0 && (
        <>
          <strong>Fields referenced in questionnaire dependencies</strong>
          <div className="questionnaire-field-references">
            {renderIssuesList(questionnaireFieldReferences)}
          </div>
        </>
      )}
      {questionnaireReferences.length > 0 && (
        <>
          <strong>Questionnaires</strong>
          <div className="questionnaire-references">
            {renderIssuesList(questionnaireReferences)}
          </div>
        </>
      )}
    </>
  );
};
