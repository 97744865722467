import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { NavSearch } from "../fields_layout_pages";
import FormAndSubformList from "./FormAndSubformList";
import actions from "actions/fields_page";

const NavigationScreen = ({
  studyId,
  forms,
  subforms,
  currentFormOrSubform,
  navFilter,
  searching,
  handleSetNavFilter,
  handleNavSearch,
}) => {
  const handleSearch = (e, navFilter) => {
    handleNavSearch(studyId, navFilter);
  };

  return (
    <div id="sidebar-nav">
      <button id="sidebar-toggle" type="button" title="Show menu">
        <i className="fa fa-ellipsis-v" />
      </button>

      <NavSearch
        searchTerm={navFilter}
        handleSearch={handleSearch}
        handleSetSearchTerm={handleSetNavFilter}
      />

      <div id="sidebar-scrolling">
        <FormAndSubformList
          studyId={studyId}
          currentClass={"form"}
          forms={forms}
          subforms={subforms}
          currentFormOrSubform={currentFormOrSubform}
          searchTerm={navFilter}
          searching={searching}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    studyId: state.navigation.studyId,
    forms: state.navigation.forms,
    subforms: state.navigation.subforms,
    currentFormOrSubform: state.navigation.currentFormOrSubform,
    navFilter: state.navigation.navFilter,
    searching: state.navigation.searching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleSetNavFilter: actions.handleSetNavFilter,
      handleNavSearch: actions.handleNavSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationScreen);
