export const OPEN_ADD_TABLE_MODAL = "OPEN_ADD_TABLE_MODAL";
export const OPEN_EDIT_TABLE_MODAL = "OPEN_EDIT_TABLE_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_FORM_VALUE = "UPDATE_FORM_VALUE";
export const HANDLE_ADD_COLUMN = "HANDLE_ADD_COLUMN";
export const HANDLE_CHANGE_COLUMN = "HANDLE_CHANGE_COLUMN";
export const HANDLE_REORDER_COLUMNS = "HANDLE_REORDER_COLUMNS";
export const HANDLE_REMOVE_COLUMN = "HANDLE_REMOVE_COLUMN";
export const HANDLE_NEW_FORM_SUBMIT = "HANDLE_NEW_FORM_SUBMIT";
export const HANDLE_EDIT_FORM_SUBMIT = "HANDLE_EDIT_FORM_SUBMIT";
export const HANDLE_SUBMIT_SUCCESS = "HANDLE_SUBMIT_SUCCESS";
export const HANDLE_SUBMIT_FAILURE = "HANDLE_SUBMIT_FAILURE";
