import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import actions from "actions/revisions_page";

import ConfirmationDialog from "components/ConfirmationDialog";
import ButtonWithSpinner from "../helpers/ButtonWithSpinner";
import RevisionExport from "./RevisionExport";

const RevisionHeaderScreen = ({
  title,
  studyId,
  revisionId,
  commitRevision,
  committed,
  committing,
  hasChanges,
}) => {
  const [confirmationShown, setConfirmationShown] = useState(false);

  return (
    <>
      <h1>{title}</h1>
      <div className="margin-bottom-20">
        {committed === false && hasChanges === true && (
          <ButtonWithSpinner
            saving={committing}
            buttonText="Mark as done"
            faIcon="check"
            savingText="Committing"
            onClick={() => {
              setConfirmationShown(true);
            }}
            className="margin-right-5"
          />
        )}
        <RevisionExport />
      </div>
      {committed === false && hasChanges === true && (
        <ConfirmationDialog
          title="Mark Revision As Done"
          message="This action CANNOT be undone. Please check that all of the changes are correct before continuing."
          shown={confirmationShown}
          onHide={() => {
            setConfirmationShown(false);
          }}
          onConfirm={() => {
            setConfirmationShown(false);
            commitRevision(studyId, revisionId);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    title: state.revision.title,
    studyId: state.revision.studyId,
    revisionId: state.revision.revisionId,
    committed: state.revision.committed,
    committing: state.form.committing,
    hasChanges: state.revision.hasChanges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      commitRevision: actions.commitRevision,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevisionHeaderScreen);
