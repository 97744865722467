import React from "react";
import { Row, Col } from "react-bootstrap";
import { Droppable } from "react-beautiful-dnd";

import DraggableFormSectionElement from "./DraggableFormSectionElement";
import SubformField from "./SubformField";

const SubformSectionLayout = ({
  subformSection,
  actionDisabled,
  isDraggable,
  checkedForMove,
  handleMoveCheckoboxChange,
  dragHandleProps,
  sectionId,
}) => {
  let subformSectionElement = subformSection.element;

  const subformElements = subformSectionElement.subformElements;
  const label = subformSectionElement.label;
  const identifier = subformSectionElement.identifier;

  const sectionHeader = (
    <>
      SUBFORM: {label}
      <small>{` - ${identifier}`}</small>
    </>
  );

  return (
    <li
      className={`subform_section_element ${
        checkedForMove ? "selected-for-move" : ""
      }`}
      id={`subform-section-element-${subformSection.id}`}
    >
      <Row>
        <Col md={6}>
          {isDraggable && (
            <span className="sortable-handler" {...dragHandleProps}>
              <i className="fa fa-reorder" />
            </span>
          )}
          {sectionHeader}
        </Col>
        <Col md={6}>
          <div>
            <div className="btn-group section-actions">
              <a
                href={`#fields-for-subform-${subformSection.id}`}
                className="field-count btn btn-text"
                data-toggle="collapse"
              >
                {`${subformElements.length} ${
                  subformElements.length == 1 ? "field" : "fields"
                }`}
              </a>
              <span className="section-select-all">
                {subformElements.length > 0 && (
                  <input
                    className=""
                    type="checkbox"
                    disabled={actionDisabled}
                    value={subformSection.id}
                    title={`Select subform ${subformSection.label}`}
                    checked={checkedForMove}
                    onChange={(e) => {
                      handleMoveCheckoboxChange(e);
                    }}
                  />
                )}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div
        className="subform-fields-container collapse in"
        id={`fields-for-subform-${subformSection.id}`}
      >
        <ul className="list-unstyled sortable section-sortable">
          <Droppable
            droppableId={`subform_drop_area_${subformSection.id}`}
            type={`subform-${subformSection.id}-section-${sectionId}`}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {subformElements.map((sectionElement, index) => {
                  return (
                    <DraggableFormSectionElement
                      key={sectionElement.id}
                      index={index}
                      element={sectionElement}
                      type={`subform-${subformSection.id}-section-${sectionId}`}
                      isDragDisabled={!isDraggable}
                    >
                      <SubformField
                        id={sectionElement.id}
                        identifier={sectionElement.element.identifier}
                        label={sectionElement.element.label}
                        isDraggable={isDraggable}
                      />
                    </DraggableFormSectionElement>
                  );
                })}
                {subformElements.length == 0 && (
                  <li className="no-record-li">
                    There are no fields in this subform.
                  </li>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ul>
      </div>
    </li>
  );
};

export default SubformSectionLayout;
