import React from "react";
import { FormGroup, InputGroup, HelpBlock } from "react-bootstrap";

import { Select, TextField } from "components/helpers";

const TextSelectInputGroup = ({
  label,
  field,
  value,
  disabled,
  errors,
  onChange,
  optionsForSelect,
}) => {
  return (
    <>
      <FormGroup
        className="text-select-input-group"
        validationState={errors ? "error" : null}
      >
        <InputGroup>
          <InputGroup.Addon>{label}</InputGroup.Addon>
          <TextField
            controlId={field}
            fieldName={field}
            value={value}
            disabled={disabled}
            onChange={onChange}
          />
          <Select
            classNamePrefix="select-validation-rule-field"
            className="select-validation-rule-field"
            value={""}
            onChange={(option) => onChange(field, option.value)}
            options={optionsForSelect}
            isDisabled={disabled}
            placeholder="..."
          />
        </InputGroup>
        {errors && (
          <HelpBlock bsClass="inline-help-block">{errors.join(", ")}</HelpBlock>
        )}
      </FormGroup>
    </>
  );
};

export default TextSelectInputGroup;
