export const baseFormReducer = (state = { values: {}, errors: {} }, action) => {
  switch (action.type) {
    case "UPDATE_FORM_VALUE": {
      let newState = { ...state };

      newState["values"][action.name] = action.value;
      return newState;
    }
    case "SAVE_START": {
      return {
        ...state,
        addingElement: true,
      };
    }
    case "SAVE_SUCCESS": {
      return {
        ...state,
        errors: {},
        type: undefined,
        addingElement: false,
      };
    }
    case "SAVE_FAILURE": {
      return {
        ...state,
        errors: action.errors,
      };
    }
    case "CANCEL_EDIT": {
      return {
        ...state,
        itemType: undefined,
        addingElement: false,
        values: {},
      };
    }
    default:
      return state;
  }
};
