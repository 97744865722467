import { ActionTypes } from "../../actions/fields_page";

export const EditSettings = (state = {}, action) => {
  const closeForm = () => {
    let newState = {
      ...state,
      values: {
        hint: "",
        default_value: "",
        field_format: "",
        calculation: "",
        dynamic_calculation: false,
        notes: "",
        exclude_from_export: false,
        do_not_show: false,
        inline_field: false,
        study_table_id: "",
        library_calculation_id: "",
        library_calculation_attributes: {},
        study_table_row_label: "",
      },
      errors: {},
      showForm: false,
      saving: false,
      field: {},
    };
    return newState;
  };

  switch (action.type) {
    case ActionTypes.UPDATE_SETTINGS_FORM_VALUE: {
      let values = { ...state.values };
      values[action.fieldName] = action.value;
      if (action.fieldName == "library_calculation_id") {
        if (action.value) {
          values.calculation = "";
        }
      }

      if (action.fieldName == "calculation") {
        if (action.value) {
          values.library_calculation_id = "";
          values.library_calculation_attributes = {};
        }
      }

      let newState = {
        ...state,
        values: values,
      };
      return newState;
    }

    case ActionTypes.HANDLE_OPEN_SETTINGS_FORM: {
      let field = action.field;
      let newState = {
        ...state,
        showForm: true,
        field: field,
        values: {
          hint: field.hint || "",
          default_value: field.default_value || "",
          field_format: field.field_format || "",
          calculation: field.calculation || "",
          dynamic_calculation: field.dynamic_calculation || false,
          notes: field.notes || "",
          exclude_from_export: field.exclude_from_export || false,
          do_not_show: field.do_not_show || false,
          inline_field: field.inline_field || false,
          library_calculation_id: field.library_calculation_id || "",
          library_calculation_attributes:
            field.library_calculation_attributes || {},
          study_table_id: field.inline_field
            ? null
            : field.study_table_id || null,
          study_table_row_label: field.inline_field
            ? ""
            : field.study_table_row_label || "",
        },
      };
      return newState;
    }

    case ActionTypes.HANDLE_SUBMIT_SETTINGS_FORM: {
      let newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.HANDLE_SETTINGS_FORM_ERROR: {
      let newState = {
        ...state,
        saving: false,
        errors: action.errors,
      };
      return newState;
    }

    case ActionTypes.HANDLE_CLOSE_SETTINGS_FORM: {
      return closeForm();
    }

    case ActionTypes.HANDLE_SETTINGS_FORM_SUCCESS: {
      return closeForm();
    }
  }

  return state;
};
