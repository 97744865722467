import {
  siteFormActionTypes,
  siteActionTypes,
  sitePropertyActionTypes,
} from "actions/sites_page";

export const sitesReducerDefaultState = {
  sites: [],
  additionalSiteProperties: [],
};

export const SitesReducer = (state = sitesReducerDefaultState, action) => {
  switch (action.type) {
    case siteFormActionTypes.NEW_SITE_FORM_SAVE_AND_CLOSE_SUCCESS:
    case siteFormActionTypes.NEW_SITE_FORM_SAVE_SUCCESS: {
      const newState = {
        ...state,
        sites: action.sites,
      };
      return newState;
    }

    case siteActionTypes.REMOVE_SITE: {
      const newState = {
        ...state,
        sites: state.sites.filter((site) => site.id != action.siteId),
      };
      return newState;
    }

    case siteFormActionTypes.EDIT_SITE_FORM_SAVE_SUCCESS: {
      const newState = {
        ...state,
        sites: state.sites.map((site) => {
          if (site.id == action.site.id) {
            return action.site;
          } else {
            return site;
          }
        }),
      };
      return newState;
    }
    case siteFormActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE_SUCCESS:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE_SUCCESS:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_SUCCESS: {
      const newState = {
        ...state,
        additionalSiteProperties: action.additionalSiteProperties,
      };

      return newState;
    }

    case sitePropertyActionTypes.REMOVE_SITE_PROPERTY: {
      const newState = {
        ...state,
        additionalSiteProperties: state.additionalSiteProperties.filter(
          (siteProperty) => siteProperty.id != action.sitePropertyId
        ),
      };
      return newState;
    }
  }

  return state;
};
