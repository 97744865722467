import React from "react";
import { FaIconLink, FaButton } from "components/helpers";
import { ButtonGroup } from "react-bootstrap";

const QuestionnaireRow = ({
  questionnaire,
  studyId,
  entityGroupId,
  handleDelete,
}) => {
  const { identifier, name, description, ordered_events, ordered_study_forms } =
    questionnaire;

  return (
    <tr id={`questionnaire_${questionnaire.id}`}>
      <td className="questionnaire-name">{name}</td>
      <td className="questionnaire-identifier">{identifier}</td>
      <td className="questionnaire-description">
        {description ? description : <span className="text-muted">None</span>}
      </td>
      <td className="questionnaire-events">
        {ordered_events
          .map((event) => {
            return event.name;
          })
          .join(", ")}
      </td>
      <td className="questionnaire-forms">
        {ordered_study_forms
          .map((form) => {
            return form.name;
          })
          .join(", ")}
      </td>
      <td className="questionnaire-actions">
        <ButtonGroup>
          <FaIconLink
            icon="pencil"
            text="Edit"
            title={`Edit ${questionnaire.name}`}
            linkClassName="btn btn-default"
            href={`/study/${studyId}/entity_groups/${entityGroupId}/questionnaires/${questionnaire.id}/edit`}
          />
          <FaButton
            icon="trash"
            title="Remove questionnaire"
            onClick={() => handleDelete()}
          />
        </ButtonGroup>
      </td>
    </tr>
  );
};

export default QuestionnaireRow;
