import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { ButtonGroup } from "react-bootstrap";
import { FaButton } from "../helpers";

const DraggableEntityGroupRow = ({
  entityGroup,
  index,
  type,
  deleteEntityGroup,
  editEntityGroup,
}) => {
  return (
    <Draggable draggableId={`${entityGroup.id}`} index={index} type={type}>
      {(provided, snapshot) => (
        <tr
          id={`entity_group_${entityGroup.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={snapshot.isDragging ? "dragging" : ""}
        >
          <td style={{ width: "5%" }}>
            <span className="sortable-handler" {...provided.dragHandleProps}>
              <i className="fa fa-reorder" />
            </span>
          </td>
          <td style={{ width: "10%" }}>{entityGroup.name}</td>
          <td style={{ width: "10%" }} data-label="Identifier">
            {entityGroup.identifier}
          </td>
          <td style={{ width: "15%" }} className="mini nowrap">
            {`${entityGroup.entity_identifier_name} ${entityGroup.entity_identifier_format}`}
          </td>
          <td style={{ width: "45%" }}>{entityGroup.entity_term_name}</td>
          <td style={{ width: "15%" }} className="text-right">
            <ButtonGroup>
              <FaButton
                icon="pencil"
                text="Edit"
                title="Edit entity group"
                onClick={() => editEntityGroup(entityGroup)}
              />
              <FaButton
                icon="trash"
                title={
                  entityGroup.has_linked_events
                    ? "Please remove associated events first"
                    : "Remove entity group"
                }
                disabled={entityGroup.has_linked_events}
                onClick={() => deleteEntityGroup(entityGroup)}
              />
            </ButtonGroup>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default DraggableEntityGroupRow;
