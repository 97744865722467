import React from "react";
import { Collapse } from "react-bootstrap";
import { Chevron } from "components/CollapsiblePanel";

const CollapsableChange = ({ change, title, toggleExpandChange, children }) => {
  const { id, action_type, section, expanded } = change;

  return (
    <div id={`change-${id}`} className={`change change-${action_type}`}>
      <div
        className="change-header"
        onClick={(e) => {
          toggleExpandChange(section, id);
        }}
      >
        <h3 className="change-title">
          {title}
          <Chevron expanded={expanded} />
        </h3>
      </div>
      <hr></hr>
      <Collapse className="change-body" in={expanded}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsableChange;
