export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_FORM_VALUE = "UPDATE_FORM_VALUE";
export const HANDLE_FORM_SUBMIT = "HANDLE_FORM_SUBMIT";
export const HANDLE_SUBMIT_SUCCESS = "HANDLE_SUBMIT_SUCCESS";
export const HANDLE_SUBMIT_FAILURE = "HANDLE_SUBMIT_FAILURE";
export const HANDLE_SUBMIT_ERROR = "HANDLE_SUBMIT_ERROR";
export const HANDLE_SELECT_STUDY = "HANDLE_SELECT_STUDY";
export const HANDLE_SELECT_FORM = "HANDLE_SELECT_FORM";
export const HANDLE_DEPENDENCIES_CHECK_SUCCESS =
  "HANDLE_DEPENDENCIES_CHECK_SUCCESS";
export const HANDLE_DEPENDENCIES_CHECK_FAILURE =
  "HANDLE_DEPENDENCIES_CHECK_FAILURE";
