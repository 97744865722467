class OrderUpdateChange {
  constructor(change, ordering_key = null) {
    this.change = change;
    this.ordering_key = ordering_key;
    this.elementType = change.section;

    const newOrOriginalAttributes = change.new_attributes
      ? change.new_attributes
      : change.original_attributes;

    if (newOrOriginalAttributes.subheading_information) {
      this.elementLocation =
        newOrOriginalAttributes.subheading_information.identifier.value;
    } else {
      this.elementLocation = "";
    }
  }

  displayForExport(newOrOriginalAttributes) {
    let orderedElements = [];
    if (this.ordering_key) {
      orderedElements = newOrOriginalAttributes[this.ordering_key].value;
    } else {
      orderedElements = newOrOriginalAttributes.value;
    }
    return orderedElements
      .map((element, index) => {
        return `${index + 1}. ${element.name.value}`;
      })
      .join("\n");
  }
}

export default OrderUpdateChange;
