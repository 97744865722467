$.fn.select2.defaults.set("theme", "bootstrap")

$ ->
  $(document).on 'select2-init', '.select2', ->
    $(this).select2(
      allowClear: ->
        true unless $(this).data('allow-clear') is false
    )

  $('.select2').each ->
    $(this).trigger('select2-init')

  $(document).on 'ajax-modal-show', ->
    # moved here due to bug when init-select2 causes 2 boxes to show
    if $('#modalWindow form').length > 0
      $('#modalWindow').data('original-content', $('#modalWindow form').html())
      
    $('#modalWindow .select2').each ->
      $(this).trigger('select2-init')
