import React from "react";
import { Row, Col, Alert } from "react-bootstrap";

import AddValidationButton from "./AddValidationButton";

const ValidationTypeButtons = ({
  rowMargin = 10,
  validationTypes,
  errors,
  saving,
  hasValidation,
  selectedRuleTypeIs,
  updateFormValue,
}) => {
  return (
    <>
      <Row className={`margin-bottom-${rowMargin}`}>
        <Col lg={12} className="validation-type-buttons btn-toggles">
          {validationTypes.map((validationIdentifier) => {
            return (
              <AddValidationButton
                key={validationIdentifier}
                validationIdentifier={validationIdentifier}
                saving={saving}
                hasValidation={hasValidation}
                selectedRuleTypeIs={selectedRuleTypeIs}
                updateFormValue={updateFormValue}
              />
            );
          })}
        </Col>
      </Row>
      {errors.validation_rule_type && (
        <Row>
          <Col lg={8}>
            <Alert
              bsStyle="danger"
              className="alert-error margin-top-0 margin-bottom-0"
            >
              {errors.validation_rule_type[0]}
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ValidationTypeButtons;
