import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import actions from "actions/revisions_page";
import { loadChange } from "services/revisions_page/changes";
import AttributeRow from "./AttributeRow";

import CollapsableChange from "./CollapsableChange";
import ChangeTable from "./ChangeTable";

const Change = ({
  change,
  renderTitle,
  renderUpdateOrdering,
  toggleExpandChange,
}) => {
  const actionType = change.action_type;
  const originalAttributes = change.original_attributes || {};
  const newAttributes = change.new_attributes || {};

  const displayAttributeList = (change) => {
    const changeObject = loadChange(change);

    return (
      <tbody>
        {changeObject.attributesToDisplay.map((attribute) => {
          return <AttributeRow key={attribute.title} attribute={attribute} />;
        })}
      </tbody>
    );
  };

  switch (actionType) {
    case "create":
      return (
        <CollapsableChange
          change={change}
          title={
            <>
              <i className="fa fa-plus-square-o change-icon" />
              {renderTitle(newAttributes)}
            </>
          }
          toggleExpandChange={toggleExpandChange}
        >
          <ChangeTable
            originalAttributes={originalAttributes}
            newAttributes={newAttributes}
          >
            {displayAttributeList(change)}
          </ChangeTable>
        </CollapsableChange>
      );
    case "update":
      return (
        <CollapsableChange
          change={change}
          title={
            <>
              <i className="fa fa-pencil-square-o change-icon" />
              {renderTitle(newAttributes)}
            </>
          }
          toggleExpandChange={toggleExpandChange}
        >
          <ChangeTable
            originalAttributes={originalAttributes}
            newAttributes={newAttributes}
          >
            {displayAttributeList(change)}
          </ChangeTable>
        </CollapsableChange>
      );
    case "delete":
      return (
        <CollapsableChange
          change={change}
          title={
            <>
              <i className="fa fa-minus-square-o change-icon" />
              {renderTitle(originalAttributes)}
            </>
          }
          toggleExpandChange={toggleExpandChange}
        >
          <ChangeTable
            originalAttributes={originalAttributes}
            newAttributes={newAttributes}
          >
            {displayAttributeList(change)}
          </ChangeTable>
        </CollapsableChange>
      );
    case "order_update":
      return (
        <CollapsableChange
          change={change}
          title={
            <>
              <i className="fa fa-list-ol change-icon" />
              {`Order`}
            </>
          }
          toggleExpandChange={toggleExpandChange}
        >
          {renderUpdateOrdering(originalAttributes, newAttributes)}
        </CollapsableChange>
      );
    default:
      return <p>{`Unknown type: ${actionType}`}</p>;
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    change: ownProps.change,
    renderTitle: ownProps.renderTitle,
    renderUpdateOrdering: ownProps.renderUpdateOrdering,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleExpandChange: actions.toggleExpandChange,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Change);
