import _ from "lodash/core";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Alert, Button } from "react-bootstrap";
import { TextField } from "components/helpers";

import actions from "actions/tables_page";

const ColumnProperties = ({
  columnsInUse,
  values,
  saving,
  errors,
  handleAddColumn,
  handleChangeColumn,
  handleReorderColumns,
  handleRemoveColumn,
  updateFormValue,
}) => {
  const leadColumnNameValue = values["lead_column_name"];
  const COLUMN_IN_USE_MESSAGE =
    "Column cannot be changed as field already assigned";

  return (
    <fieldset className="margin-top-10">
      <legend>Columns</legend>
      {errors.columns && (
        <Alert bsStyle="danger">Columns {errors.columns}</Alert>
      )}
      <DragDropContext onDragEnd={handleReorderColumns}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {values.columns.map((value, index) => {
                const draggableKey = "draggable" + index;
                const columnInUse = columnsInUse.includes(value) || saving;

                return (
                  <Draggable
                    key={draggableKey}
                    draggableId={draggableKey}
                    index={index}
                    isDragDisabled={saving}
                  >
                    {(provided) => {
                      return (
                        <div
                          className={`draggable-row row-` + index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <span
                            className="sortable-handler"
                            {...provided.dragHandleProps}
                          >
                            <i className="fa fa-reorder" />
                          </span>
                          <TextField
                            controlId={`label_` + index}
                            placeholder="Label"
                            value={value}
                            disabled={columnInUse}
                            onChange={(identifier, value) =>
                              handleChangeColumn(index, value)
                            }
                            title={columnInUse ? COLUMN_IN_USE_MESSAGE : ""}
                          />
                          <Button
                            onClick={() =>
                              updateFormValue("lead_column_name", value)
                            }
                            disabled={_.isEmpty(value) || saving}
                            bsStyle={
                              (!_.isEmpty(leadColumnNameValue) &&
                                leadColumnNameValue === value &&
                                "primary") ||
                              "link"
                            }
                            bsSize="small"
                            title="Mark this as the lead column"
                            className="lead-column-button"
                          >
                            <i className="fa fa-table text-large" />
                          </Button>
                          <Button
                            onClick={() => handleRemoveColumn(index)}
                            disabled={columnInUse}
                            bsStyle="link"
                            bsSize="small"
                            title={
                              columnInUse
                                ? COLUMN_IN_USE_MESSAGE
                                : "Remove this column"
                            }
                            className="remove-button"
                          >
                            <i className="fa fa-trash-o text-danger text-large" />
                          </Button>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              <Button
                onClick={handleAddColumn}
                bsStyle="success"
                bsSize="xsmall"
                disabled={saving}
              >
                <i className="fa fa-plus margin-right-5" />
                Add a column
              </Button>
              {_.isEmpty(leadColumnNameValue) || (
                <Button
                  onClick={() => updateFormValue("lead_column_name", "")}
                  bsStyle="danger"
                  bsSize="xsmall"
                  disabled={saving}
                  className="pull-right"
                >
                  <i className="fa fa-table margin-right-5" />
                  Remove lead column
                </Button>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </fieldset>
  );
};

const mapStateToProps = (state) => {
  const modalState = state.tableModal;
  return {
    columnsInUse: modalState.columnsInUse,
    values: modalState.values,
    errors: modalState.errors,
    saving: modalState.saving,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleAddColumn: actions.handleAddColumn,
      handleChangeColumn: actions.handleChangeColumn,
      handleReorderColumns: actions.handleReorderColumns,
      handleRemoveColumn: actions.handleRemoveColumn,
      updateFormValue: actions.updateFormValue,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnProperties);
