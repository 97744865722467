import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import actions from "actions/fields_page";

import { FaButton } from "components/helpers";
import ReferencesWarning from "./ReferencesWarning";
import ConfirmationDialog from "components/ConfirmationDialog";

const BaseConfirmationModal = ({
  title,
  noReferencedFieldsMessage,
  referencedFieldsMessage,
  referencedFields,
  remainingFields,
  referenceAction,
  shown,
  onHide,
  onConfirm,
}) => {
  return (
    <Modal id="confirmationDialog" show={shown} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {referencedFields.length === 0 && noReferencedFieldsMessage}
        {referencedFields.length > 0 && (
          <>
            <ReferencesWarning
              referencedFields={referencedFields}
              remainingFields={remainingFields}
              referenceAction={referenceAction}
            />
            {remainingFields > 0 && referencedFieldsMessage}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {remainingFields > 0 && (
          <>
            <FaButton
              icon="check"
              text="Yes"
              bsStyle="primary"
              className="pull-left confirm commit"
              onClick={onConfirm}
            />
            <FaButton
              icon="remove"
              text="No"
              bsStyle="default"
              className="cancel"
              onClick={onHide}
            />
          </>
        )}
        {remainingFields === 0 && (
          <FaButton
            icon=""
            text="Select new fields"
            bsStyle="primary"
            className="pull-left confirm commit"
            onClick={onHide}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

const BulkActionConfirmation = ({
  type,
  selectedFields,
  referencedFields,
  copyNTimes,
  currentFormOrSubform,
  targetForm,
  shown,
  studyId,
  onHide,
  handleDeleteSelectedFields,
  handleMoveSelectedFields,
  handleCopySelectedFields,
}) => {
  const isExternallyReferenced = (referencedField) => {
    let referencedProperties = Object.keys(referencedField)
      .filter((key) => key.match(/_references/))
      .filter(
        (key) => key != "questionnaire_references" && key != "field_references"
      )
      .map((key) => referencedField[key].length);
    let questionnaireReferencedProperties = Object.keys(
      referencedField.questionnaire_references
    ).map((key) => referencedField.questionnaire_references[key].length);

    if (
      referencedProperties.some((length) => length > 0) ||
      questionnaireReferencedProperties.some((length) => length > 0)
    ) {
      return true;
    }

    if (referencedField.field_references.length > 0) {
      const referencedFieldIds = referencedField.field_references.map(
        (reference) => reference.id
      );
      if (
        referencedFieldIds.every((referencedId) =>
          selectedFields.includes(referencedId)
        )
      ) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  const fieldsUnableToProcess = referencedFields.filter((referencedField) =>
    isExternallyReferenced(referencedField)
  );

  const remainingFields = selectedFields.length - fieldsUnableToProcess.length;
  const fieldPluralized = remainingFields === 1 ? "field" : "fields";
  const thisTheseFields = remainingFields === 1 ? "this field" : "these fields";

  const handleDelete = () => {
    const referencedFieldIds = fieldsUnableToProcess.map(
      (field) => field.field_id
    );
    const fieldIdsToDelete = selectedFields.filter(
      (fieldId) => !referencedFieldIds.includes(fieldId)
    );
    return handleDeleteSelectedFields(studyId, fieldIdsToDelete);
  };

  const handleCopy = () => {
    return handleCopySelectedFields(
      studyId,
      targetForm.value,
      targetForm.type,
      currentFormOrSubform.id,
      currentFormOrSubform.type,
      selectedFields,
      copyNTimes
    );
  };

  const handleMove = () => {
    const referencedFieldIds = fieldsUnableToProcess.map(
      (field) => field.field_id
    );
    const fieldIdsToMove = selectedFields.filter(
      (fieldId) => !referencedFieldIds.includes(fieldId)
    );
    handleMoveSelectedFields(
      studyId,
      targetForm.value,
      targetForm.type,
      currentFormOrSubform.id,
      currentFormOrSubform.type,
      fieldIdsToMove
    );
  };

  switch (type) {
    case "Delete": {
      const noReferencedFieldsMessage = (
        <strong>{`Are you sure you want to delete ${remainingFields} ${fieldPluralized}?`}</strong>
      );
      const referencedFieldsMessage = (
        <strong>{`Are you sure you want to delete the ${remainingFields} remaining ${fieldPluralized}?`}</strong>
      );
      return (
        <BaseConfirmationModal
          title="Delete all selected fields?"
          noReferencedFieldsMessage={noReferencedFieldsMessage}
          referencedFieldsMessage={referencedFieldsMessage}
          referencedFields={fieldsUnableToProcess}
          remainingFields={remainingFields}
          referenceAction="deleted"
          shown={shown}
          onHide={onHide}
          onConfirm={handleDelete}
        />
      );
    }
    case "Copy": {
      let nTimes;
      if (copyNTimes === "1") {
        nTimes = "once";
      } else if (copyNTimes === "2") {
        nTimes = "twice";
      } else {
        nTimes = `${copyNTimes} times`;
      }

      return (
        <ConfirmationDialog
          title="Copy selected fields to the target form?"
          shown={shown}
          onHide={onHide}
          onConfirm={handleCopy}
        >
          <>
            <strong>{`Are you sure you want to copy ${selectedFields.length} ${
              selectedFields.length === 1 ? "field" : "fields"
            } ${nTimes} to ${targetForm.label}`}</strong>
            <p>{`Any dependencies, validations or settings referring to ${
              selectedFields.length === 1 ? "this field" : "these fields"
            } will be removed`}</p>
          </>
        </ConfirmationDialog>
      );
    }
    case "Move": {
      const noReferencedFieldsMessage = (
        <>
          <strong>{`Are you sure you want to move ${remainingFields} ${fieldPluralized} to ${targetForm.label}?`}</strong>
          <p>{`Any dependencies, validations or settings referring to ${thisTheseFields} will be removed.`}</p>
        </>
      );
      const referencedFieldsMessage = (
        <>
          <strong>{`Are you sure you want to move the ${remainingFields} remaining ${fieldPluralized} to ${targetForm.label}?`}</strong>
          <p>{`Any dependencies, validations or settings referring to ${thisTheseFields} will be removed.`}</p>
        </>
      );
      return (
        <BaseConfirmationModal
          title="Move selected fields to the target form?"
          noReferencedFieldsMessage={noReferencedFieldsMessage}
          referencedFieldsMessage={referencedFieldsMessage}
          referencedFields={fieldsUnableToProcess}
          remainingFields={remainingFields}
          referenceAction="moved"
          shown={shown}
          onHide={onHide}
          onConfirm={handleMove}
        />
      );
    }
  }
  return <></>;
};

const mapStateToProps = (state) => {
  const relevantState = state.bulkActions;

  return {
    type: relevantState.confirmationType,
    selectedFields: relevantState.selectedFields,
    referencedFields: relevantState.referencedFields,
    copyNTimes: relevantState.copyNTimes,
    currentFormOrSubform: state.currentFormOrSubform,
    targetForm: relevantState.targetForm,
    shown: relevantState.displayingConfirmation,
    studyId: relevantState.studyId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onHide: actions.handleHideBulkActionConfirmation,
      handleDeleteSelectedFields: actions.handleDeleteSelectedFields,
      handleMoveSelectedFields: actions.handleMoveSelectedFields,
      handleCopySelectedFields: actions.handleCopySelectedFields,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkActionConfirmation);
