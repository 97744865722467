import ChangeObject from "./ChangeObject";

class AdditionalSitePropertyChange extends ChangeObject {
  constructor(change) {
    const identifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;

    const attributeData = [
      ["Name", "name"],
      ["Identifier", "identifier"],
      ["Required?", "required", { attributeType: "boolean" }],
      ["Type", "human_data_type"],
      [
        "Field options",
        "metadatum_field_options",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `${associatedObject.name.value}`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject, index) =>
                  `${index + 1}. ${associatedObject?.name?.value}`
              )
              .join("\n");
          },
        },
      ],
    ];

    super(change, "AdditionalSiteProperty", identifier, attributeData);
  }
}

export default AdditionalSitePropertyChange;
