import React from "react";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  TableModalReducer,
  tableModalDefaultState,
} from "reducers/tables_page";
import actions from "actions/tables_page";
import { TableModalMiddleware } from "middleware/tables_page";

import { TableModalScreen } from "components/tables_page";

import { addClickListener } from "services/browser_dom_support";

const TablesPage = ({ studyId }) => {
  const openAddTableModal = () => {
    store.dispatch(actions.openAddTableModal());
  };
  addClickListener("#add-table-link", openAddTableModal);

  const openEditTableModal = (e) => {
    store.dispatch(actions.openEditTableModal(e.dataset.reactProps));
  };
  addClickListener(".edit-table-link", openEditTableModal);

  const store = createStore(
    combineReducers({
      tableModal: TableModalReducer,
    }),
    {
      tableModal: {
        ...tableModalDefaultState,
        studyId: studyId,
      },
    },
    applyMiddleware(TableModalMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <TableModalScreen />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default TablesPage;
