import React, { useEffect } from "react";
import _ from "lodash";

const FormAndSubformList = ({
  studyId,
  forms,
  subforms,
  currentFormOrSubform,
  searchTerm,
  searching,
}) => {
  const activeFormOrSubform = React.createRef();

  useEffect(() => {
    if (activeFormOrSubform.current) {
      activeFormOrSubform.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeFormOrSubform]);

  const urlFor = (form) => {
    if (searchTerm) {
      return `/study/${studyId}/${form.class}s/${form.id}/${form.view}?nav_filter=${searchTerm}`;
    } else {
      return `/study/${studyId}/${form.class}s/${form.id}/${form.view}`;
    }
  };

  const FormTitle = () => {
    return (
      <div className="sidebar-title">
        {searching && (
          <span id="nav-filter-spinner" className="pull-right">
            <i className="fa fa-circle-o-notch fa-spin" />
          </span>
        )}
        Forms
      </div>
    );
  };

  const subformsForForm = (form) => {
    const filteredSubforms = subforms.filter(
      (subform) => form.id === subform.formId
    );

    return _.sortBy(filteredSubforms, [
      "position_of_form_section",
      "position_within_form_section",
    ]);
  };

  const FormList = () => {
    if (forms && forms.length > 0) {
      return (
        <ul className={`nav nav-forms-list`}>
          {forms.map((form) => {
            const isFormActive =
              currentFormOrSubform.class === "form" &&
              currentFormOrSubform.id === form.id;

            return (
              <li
                key={form.id}
                ref={isFormActive ? activeFormOrSubform : undefined}
              >
                <a
                  href={urlFor(form)}
                  className={`${isFormActive ? "active" : ""}`}
                >
                  {form.name}{" "}
                  <span className="text-muted">{`(${form.countForNav})`}</span>
                </a>
                <ul className={`nav nav-subforms-list`}>
                  {subformsForForm(form).map((subform) => {
                    const isSubformActive =
                      currentFormOrSubform.class === "subform" &&
                      currentFormOrSubform.id === subform.id;

                    return (
                      <li
                        key={subform.id}
                        ref={isSubformActive ? activeFormOrSubform : undefined}
                      >
                        <a
                          icon="angle-double-right"
                          href={urlFor(subform)}
                          className={`${isSubformActive ? "active" : ""}`}
                        >
                          <i
                            className={`fa fa-angle-double-right margin-right-5`}
                          />
                          {subform.name}{" "}
                          <span className="text-muted">{`(${subform.countForNav})`}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <ul className="nav nav-forms-list">
          <li className="no-results">No Results</li>
        </ul>
      );
    }
  };

  return (
    <>
      <FormTitle />
      <FormList />
    </>
  );
};

export default FormAndSubformList;
