import React from "react";
import { connect } from "react-redux";

import { changeTitle } from "services/group_changes_by_subheading";

import {
  SectionPanelNew,
  OrderingChangesList,
} from "components/revisions_page";

const SectionPanelsScreen = ({ studyId }) => {
  return (
    <>
      <SectionPanelNew
        section="Study"
        id="study"
        title="Study"
        renderTitle={(attributes) =>
          changeTitle({ ...attributes, name: attributes.short_name })
        }
      />
      <SectionPanelNew
        section="EntityGroup"
        id="entity_groups"
        title="Entity Groups"
        linkHref={`/study/${studyId}`}
        linkTitle="View current entity groups"
        renderTitle={changeTitle}
        renderUpdateOrdering={(originalAttributes, newAttributes) => {
          return (
            <OrderingChangesList
              originalOrderedModels={originalAttributes.value}
              newOrderedModels={newAttributes.value}
            />
          );
        }}
      />
      <SectionPanelNew
        section="Event"
        id="events"
        title="Events"
        linkHref={`/study/${studyId}/events`}
        linkTitle="View current events"
        renderTitle={changeTitle}
        groupBySubheading={true}
        renderUpdateOrdering={(originalAttributes, newAttributes) => {
          const originalOrderedEvents = originalAttributes.events
            ? originalAttributes.events.value
            : [];
          const newOrderedEvents = newAttributes.events
            ? newAttributes.events.value
            : [];
          return (
            <OrderingChangesList
              originalOrderedModels={originalOrderedEvents}
              newOrderedModels={newOrderedEvents}
            />
          );
        }}
      />
      <SectionPanelNew
        section="Form"
        id="forms"
        title="Forms"
        linkHref={`/study/${studyId}/forms`}
        linkTitle="View current forms"
        renderTitle={changeTitle}
        renderUpdateOrdering={(originalAttributes, newAttributes) => {
          const originalOrderedForms = originalAttributes
            ? originalAttributes.value
            : [];
          const newOrderedForms = newAttributes ? newAttributes.value : [];
          return (
            <OrderingChangesList
              originalOrderedModels={originalOrderedForms}
              newOrderedModels={newOrderedForms}
            />
          );
        }}
      />
      <SectionPanelNew
        section="SubformTemplate"
        id="subform_templates"
        title="Subforms"
        linkHref={`/study/${studyId}/forms`}
        linkTitle="View current subforms"
        renderTitle={changeTitle}
        renderUpdateOrdering={(originalAttributes, newAttributes) => {
          return (
            <OrderingChangesList
              originalOrderedModels={originalAttributes}
              newOrderedModels={newAttributes}
            />
          );
        }}
      />
      <SectionPanelNew
        section="Field"
        id="fields"
        title="Fields"
        linkHref={`/study/${studyId}/fields`}
        linkTitle="View current fields"
        renderTitle={changeTitle}
        groupBySubheading={true}
      />
      <SectionPanelNew
        section="Lookup"
        id="lookup"
        title="Shared Lookups"
        linkHref={`/study/${studyId}/lookups`}
        linkTitle="View current lookups"
        renderTitle={(attributes) => {
          if (attributes.identifier) {
            return attributes.identifier.value;
          }
        }}
      />
      <SectionPanelNew
        section="Site"
        id="sites"
        title="Sites"
        linkHref={`/study/${studyId}/sites`}
        linkTitle="View current sites"
        renderTitle={(attributes) =>
          changeTitle({
            ...attributes,
            name: attributes.short_name,
            identifier: attributes.code,
          })
        }
      />
      <SectionPanelNew
        section="AdditionalSiteProperty"
        id="additional_site_properties"
        title="Additional site properties"
        linkHref={`/study/${studyId}/sites`}
        linkTitle="View current additional site properties"
        renderTitle={changeTitle}
      />
      <SectionPanelNew
        section="ValidationRule"
        id="validation_rule"
        title="Validation Rules"
        linkHref={`/study/${studyId}/validation_rules`}
        linkTitle="View current validation rules"
        renderTitle={(attributes) => {
          if (attributes.rule_name) {
            return <span>{attributes.rule_name.value}</span>;
          }
        }}
        groupBySubheading={true}
        groupByAdditionalSubheading={true}
      />
      <SectionPanelNew
        section="Layout"
        id="layout"
        title="Layout"
        linkHref={`/study/${studyId}/layout`}
        linkTitle="View current layout"
        renderTitle={(attributes) => changeTitle(attributes, false, "Untitled")}
        groupBySubheading={true}
        renderUpdateOrdering={(originalAttributes, newAttributes) => {
          const originalOrderedSections = originalAttributes.form_sections
            ? originalAttributes.form_sections.value
            : [];
          const newOrderedSections = newAttributes.form_sections
            ? newAttributes.form_sections.value
            : [];
          return (
            <OrderingChangesList
              originalOrderedModels={originalOrderedSections}
              newOrderedModels={newOrderedSections}
            />
          );
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    studyId: state.revision.studyId,
  };
};

export default connect(mapStateToProps)(SectionPanelsScreen);
