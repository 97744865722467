import { ActionTypes } from "../../actions/fields_page";

export const CurrentFormOrSubform = (state = [], action) => {
  const updatedFields = (fields) => {
    const fieldIds = fields.map((field) => field.id);

    return state.fields.map((field) => {
      if (fieldIds.includes(field.id)) {
        return fields.filter((updatedField) => updatedField.id === field.id)[0];
      } else {
        return field;
      }
    });
  };

  switch (action.type) {
    case ActionTypes.UPDATE_EDITABLE: {
      const newFields = state.fields.map((field) => {
        if (field.id === action.fieldId) {
          return { ...field, [action.identifier]: action.newValue };
        } else {
          return field;
        }
      });
      return { ...state, fields: newFields };
    }

    case ActionTypes.SUBMIT_EDIT_TYPE: {
      return state;
    }

    case ActionTypes.EDIT_TYPE_SUBMIT_SUCCESS: {
      const fields = [action.data.field];
      const newFields = updatedFields(fields);

      return { ...state, fields: newFields };
    }

    case ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_SUCCESS: {
      const fields = action.fields;
      const newFields = updatedFields(fields);

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_SUCCESS: {
      const updatedFieldsState = updatedFields(action.removedFields);
      const newFields = updatedFieldsState.filter(
        (field) => !action.removedFields.includes(field.id)
      );

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_WITH_ERRORS: {
      const removeFieldsIds = action.successFields;
      const newFields = state.fields.filter(
        (field) => !removeFieldsIds.includes(field.id)
      );

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_SUCCESS: {
      const movedFieldsIds = action.fields;
      const newFields = state.fields.filter(
        (field) => !movedFieldsIds.includes(field.id)
      );

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_WITH_ERRORS: {
      const movedFieldsIds = action.moveData.successFields;
      const newFields = state.fields.filter(
        (field) => !movedFieldsIds.includes(field.id)
      );

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_SUCCESS: {
      if (action.target.id === state.id && action.target.type === state.type) {
        return { ...state, fields: action.allFormFields };
      } else {
        return state;
      }
    }

    case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_WITH_ERRORS: {
      if (action.target === state.id && action.target.type === state.type) {
        return { ...state, fields: action.allFormFields };
      } else {
        return state;
      }
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS: {
      const newField = action.data.field;
      const newFields = [...state.fields];
      newFields.push(newField);

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE: {
      const newField = action.data.field;
      const newFields = [...state.fields];
      newFields.push(newField);

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_EDIT_DEPENDENCIES_FORM_SUCCESS: {
      const fields = action.fields;
      const newFields = updatedFields(fields);

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_SETTINGS_FORM_SUCCESS: {
      const fields = [action.field];
      const newFields = updatedFields(fields);

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_SUCCESS: {
      const fields = action.responseData.fields;
      const newFields = updatedFields(fields);

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_SUCCESS: {
      const fields = action.fields;
      const newFields = updatedFields(fields);

      return { ...state, fields: newFields };
    }

    case ActionTypes.HANDLE_DELETE_VALIDATION_SUCCESS: {
      const fields = action.fields;
      const newFields = updatedFields(fields);

      return { ...state, fields: newFields };
    }
  }

  return state;
};
