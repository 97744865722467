import actions, { ActionTypes } from "../../actions/revisions_page";
import { defaultErrorMessage } from "../../components/helpers";

import { processRequest, dispatchWithTimeout } from "services/base_requests";
import { commitRevision } from "services/revisions_page/commit_revision_request";

export const CommittingRevisionMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type !== ActionTypes.COMMIT_REVISION) {
      return next(action);
    }

    const request = commitRevision(action.study_id, action.revision_id, {
      revision: getState().form.values,
    });

    const success = () => {
      // NOTE: On success we redirect to the index page.
      window.location.href = `/study/${getState().revision.studyId}/revisions`;
    };

    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");
    const failure = failureHandlerFor(
      actions.commitError,
      "Revision has failed to commit"
    );

    const error = () => {
      $.flashAlert(defaultErrorMessage, "alert-danger");
    };

    processRequest(request, success, failure, error);

    return next(action);
  };
