import { ActionTypes } from "../../actions/layout_page";

export const FieldsMoveReducer = (
  state = {
    selectedFields: [],
    targetSection: null,
    movingInProgress: false,
    deleting: false,
  },
  action
) => {
  const clearSelection = () => {
    return {
      ...state,
      selectedFields: [],
      targetSection: null,
      movingInProgress: false,
      deleting: false,
    };
  };

  switch (action.type) {
    case ActionTypes.ADD_FIELDS_TO_MOVE: {
      let newState = { ...state };
      let newSetOfSelectedFields = newState.selectedFields.concat(
        action.fieldsToAdd
      );
      newState.selectedFields = newSetOfSelectedFields.filter(
        (field, index) => newSetOfSelectedFields.indexOf(field) === index
      );
      newState.selectedFields = newSetOfSelectedFields.sort(
        (a, b) => a.position - b.position
      );
      return newState;
    }
    case ActionTypes.REMOVE_FIELDS_TO_MOVE: {
      let newState = { ...state };
      newState.selectedFields = newState.selectedFields.filter(
        (field) =>
          !action.fieldsToRemove
            .map((field) => field["fieldId"])
            .includes(field["fieldId"])
      );
      return newState;
    }
    case ActionTypes.DRAG_START: {
      return clearSelection();
    }
    case ActionTypes.BULK_MOVE: {
      let newState = { ...state };
      newState.movingInProgress = true;
      return newState;
    }
    case ActionTypes.BULK_REMOVE: {
      let newState = { ...state };
      newState.deleting = true;
      return newState;
    }
    case ActionTypes.CANCEL_BULK_MOVE: {
      return clearSelection();
    }
    case ActionTypes.BULK_MOVE_SUCCESS: {
      return clearSelection();
    }
    case ActionTypes.BULK_MOVE_FAILURE: {
      return clearSelection();
    }
    case ActionTypes.TARGET_SECTION_SELECTED: {
      let newState = { ...state };
      newState.targetSection = action.targetSection;
      return newState;
    }
  }

  return state;
};
