import React from "react";
import { Button } from "react-bootstrap";

const ToggleButton = ({ fieldName, value, label, onChange }) => {
  const isChecked = value === true;

  return (
    <Button
      onClick={() => onChange(fieldName)}
      className={`administration-button ${
        isChecked ? fieldName + "-checked" : ""
      }`}
    >
      {isChecked && <i className="fa fa-check margin-right-5" />}
      {label}
    </Button>
  );
};

export default ToggleButton;
