export const toCamelCase = (e) => {
  return e.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
};

export const toSnakeCase = (e) => {
  return e
    .match(/([A-Z])/g)
    .reduce((str, c) => str.replace(new RegExp(c), "_" + c.toLowerCase()), e)
    .substring(e.slice(0, 1).match(/([A-Z])/g) ? 1 : 0);
};
