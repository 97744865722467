import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, Table } from "react-bootstrap";

import { questionnaireTableActions } from "actions/questionnaires_page";
import { QuestionnaireRow } from "components/questionnaires_page";
import {
  FaIconLink,
  PanelSubheading,
  DeletionModal,
  NoRecordsRow,
} from "components/helpers";

const QuestionnairesTableScreen = ({
  studyId,
  entityGroups,
  questionnaires,
  showDeleteModal,
  deleting,
  questionnaireToDelete,
  handleOpenModal,
  handleCloseModal,
  handleQuestionnaireDelete,
  errorMessage,
}) => {
  const questionnairesForEntityGroup = (entityGroup) => {
    return questionnaires.filter(
      (questionnaire) => questionnaire.entity_group_id === entityGroup.id
    );
  };

  const deleteConfirmMessage = () => {
    return questionnaireToDelete
      ? `Are you sure you want to delete the '${questionnaireToDelete.name}' questionnaire?`
      : "";
  };

  return (
    <>
      <DeletionModal
        title={"Are you sure?"}
        message={deleteConfirmMessage()}
        show={showDeleteModal}
        onHide={() => handleCloseModal()}
        onConfirm={() =>
          handleQuestionnaireDelete(
            studyId,
            questionnaireToDelete.entity_group_id,
            questionnaireToDelete.id
          )
        }
        processingRequest={deleting}
        canCancel={!deleting}
        canMakeDeleteRequest={!deleting}
        hasErrors={!!errorMessage}
        errorMessage={errorMessage}
        referencesPreventingDelete={[]}
      />
      <Panel className="form-panel" bsStyle="primary" id="questionnaires">
        <Panel.Heading className="panel-heading-sticky">
          <Panel.Title componentClass="h3">Questionnaires</Panel.Title>
        </Panel.Heading>
        {entityGroups.map((entityGroup) => {
          return (
            <React.Fragment key={`entity-group-${entityGroup.name}`}>
              <PanelSubheading title={entityGroup.name}>
                <ul className="panel-buttons">
                  <li>
                    <FaIconLink
                      icon="plus"
                      text="Add questionnaire"
                      title="Add a new questionnaire"
                      href={`/study/${studyId}/entity_groups/${entityGroup.id}/questionnaires/new`}
                    />
                  </li>
                </ul>
              </PanelSubheading>
              <Table hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Identifier</th>
                    <th>Description</th>
                    <th>Events</th>
                    <th>Forms</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {questionnairesForEntityGroup(entityGroup).length === 0 && (
                    <NoRecordsRow colSpan={6} />
                  )}
                  {questionnairesForEntityGroup(entityGroup).map((q) => {
                    return (
                      <QuestionnaireRow
                        questionnaire={q}
                        studyId={studyId}
                        entityGroupId={entityGroup.id}
                        key={`questionnaire-${q.id}`}
                        handleDelete={() => {
                          handleOpenModal(q);
                        }}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </React.Fragment>
          );
        })}
      </Panel>
    </>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.questionnaireTable;

  return {
    studyId: relevantState.studyId,
    entityGroups: relevantState.entityGroups,
    questionnaires: relevantState.questionnaires,
    showDeleteModal: relevantState.showDeleteModal,
    deleting: relevantState.deleting,
    questionnaireToDelete: relevantState.questionnaireToDelete,
    errorMessage: relevantState.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleOpenModal: questionnaireTableActions.handleOpenDeleteModal,
      handleCloseModal: questionnaireTableActions.handleCloseDeleteModal,
      handleQuestionnaireDelete:
        questionnaireTableActions.handleQuestionnaireDelete,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnairesTableScreen);
