import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ButtonGroup,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Button,
  Row,
  Col,
  Modal,
  Alert,
} from "react-bootstrap";

import { updateFormValue } from "actions/form.base.actions";
import actions from "actions/layout_page";

import {
  ButtonWithSpinner,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";

const SectionFormScreen = ({
  studyId,
  studyFormId,
  formSectionId,
  show,
  editing,
  saving,
  showSaveSuccessMessage,
  values,
  errors,
  updateFormValue,
  handleClose,
  handleSave,
  handleUpdate,
  handleSaveAndClose,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFormSubmit(studyId, studyFormId, values);
    }
  };

  const handleFormSubmit = () => {
    if (editing) {
      handleUpdate(studyId, formSectionId, values);
    } else {
      handleSave(studyId, studyFormId, values);
    }
  };

  const handleNameInputLostFocus = (e) => {
    if (editing == false && Object.keys(errors).length === 0) {
      const valueOfNameField = e.target.value;
      if (valueOfNameField.length > 0 && values.identifier.length == 0) {
        updateFormValue(
          "identifier",
          valueOfNameField
            .trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9 -]/, "")
            .replace(/\s/g, "_")
        );
      }
    }
  };

  return (
    <Modal show={show} onHide={saving ? undefined : handleClose}>
      <form
        id="sectionForm"
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
      >
        <Modal.Header closeButton={!saving}>
          <Modal.Title>
            {editing ? "Edit Form Section" : "New Form Section"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="section-form-body">
          {errors.base && <Alert bsStyle="danger">{errors.base[0]}</Alert>}
          <Row className="margin-top-10">
            <Col sm={6}>
              <FormGroup
                bsClass="form-group no-margin"
                controlId="name"
                validationState={errors.name ? "error" : null}
              >
                <ControlLabel>Name</ControlLabel>
                <FormControl
                  type="text"
                  autoFocus
                  value={values.name}
                  onBlur={(e) => handleNameInputLostFocus(e)}
                  onChange={(e) => updateFormValue("name", e.target.value)}
                />
                {errors.name && (
                  <HelpBlock bsClass="inline-help-block">
                    {errors.name[0]}
                  </HelpBlock>
                )}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup
                bsClass="form-group no-margin"
                controlId="identifier"
                validationState={errors.identifier ? "error" : null}
              >
                <ControlLabel>
                  <abbr>* </abbr>
                  Identifier
                </ControlLabel>
                <FormControl
                  type="text"
                  value={values.identifier}
                  pattern={IDENTIFIER_LIMIT_PATTERN}
                  title={IDENTIFIER_LIMIT_TITLE}
                  onChange={(e) =>
                    updateFormValue("identifier", e.target.value)
                  }
                />
                {errors.identifier && (
                  <HelpBlock bsClass="inline-help-block">
                    {errors.identifier[0]}
                  </HelpBlock>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div
            className={`save-notice ${showSaveSuccessMessage ? "" : "no-show"}`}
          >
            New section has been added. You can now add another one.
          </div>
        </Modal.Body>
        <Modal.Footer>
          {editing === false && (
            <ButtonGroup className="pull-left">
              <ButtonWithSpinner
                saving={saving}
                buttonText="Save"
                faIcon="check"
                bsStyle="primary"
                onClick={() => handleFormSubmit(studyId, studyFormId, values)}
              />
              <ButtonWithSpinner
                saving={saving}
                buttonText="Save and close"
                bsStyle="info"
                onClick={() => handleSaveAndClose(studyId, studyFormId, values)}
              />
            </ButtonGroup>
          )}
          {editing === true && (
            <ButtonWithSpinner
              saving={saving}
              buttonText="Save"
              faIcon="check"
              bsStyle="primary"
              className="pull-left"
              onClick={() => handleFormSubmit(studyId, studyFormId, values)}
            />
          )}
          <Button onClick={handleClose}>
            <i className="fa fa-times" /> Cancel
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    studyFormId: state.formLayout.id,
    studyId: state.formLayout.study_id,
    form: state.sectionForm,
    show: state.sectionForm.showForm,
    saving: state.sectionForm.saving,
    editing: state.sectionForm.editing,
    formSectionId: state.sectionForm.formSectionId,
    showSaveSuccessMessage: state.sectionForm.showSaveSuccessMessage,
    values: state.sectionForm.values,
    errors: state.sectionForm.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: updateFormValue,
      handleClose: actions.handleSectionFormClose,
      handleSave: actions.handleSectionFormSave,
      handleUpdate: actions.handleSectionFormUpdate,
      handleSaveAndClose: actions.handleSectionFormSaveAndClose,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionFormScreen);
