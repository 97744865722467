import React from "react";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  DuplicateModalReducer,
  duplicateModelDefaultState,
} from "reducers/duplicate_study_forms";
import actions from "actions/duplicate_study_forms";
import { ModalMiddleware } from "middleware/duplicate_study_forms_page";

import { DuplicateStudyFormsModal } from "../components/duplicate_study_forms_page";

import { addClickListener } from "../services/browser_dom_support";

const DuplicateStudyFormsPage = ({
  studyId,
  studyName,
  studiesToCopyFrom,
  formsToCopy,
}) => {
  const openModal = () => {
    store.dispatch(actions.openModal());
  };
  addClickListener("#duplicate-study-form-link", openModal);

  const store = createStore(
    combineReducers({
      Modal: DuplicateModalReducer,
    }),
    {
      Modal: {
        ...duplicateModelDefaultState,
        studyId: studyId,
        studyName: studyName,
        studiesToCopyFrom: studiesToCopyFrom,
        formsToCopy: formsToCopy,
      },
    },
    applyMiddleware(ModalMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <DuplicateStudyFormsModal
          studyId={studyId}
          studyName={studyName}
          studiesToCopyFrom={studiesToCopyFrom}
          formsToCopy={formsToCopy}
        />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default DuplicateStudyFormsPage;
