import React from "react";
import { Row, Col } from "react-bootstrap";
import { Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FormField from "./FormField";
import SubformSectionLayout from "./SubformSectionLayout";
import { element, elementType } from "prop-types";
import DraggableFormSectionElement from "./DraggableFormSectionElement";
import actions from "actions/layout_page";

const FormSectionLayout = ({
  formSection,
  studyId,
  sectionToDeleteId,
  selectedFieldsToMove,
  handleAddFieldsToMove,
  handleRemoveFieldsToMove,
  handleRemoveSection,
  handleFormSectionForEdit,
  sectionClass,
  dragHandleProps,
}) => {
  const movableFields = formSection.sectionElements.map(
    (element) => element.id
  );
  const allFieldsSelectedForMove =
    movableFields.length > 0 &&
    movableFields.every((field) =>
      selectedFieldsToMove.map((field) => field["fieldId"]).includes(field)
    );
  const actionDisabled = formSection.id == sectionToDeleteId;

  const formSectionToRender = (sectionElement) => {
    let element = sectionElement.element;

    const checkedForMove = selectedFieldsToMove
      .map((field) => field["fieldId"])
      .includes(sectionElement.id);
    const sharedProps = {
      key: element.id,
      actionDisabled: actionDisabled,
    };

    if (element.class_name == "FormField") {
      return (
        <FormField
          {...sharedProps}
          id={sectionElement.id}
          identifier={element.identifier}
          label={element.label}
          checked={checkedForMove}
          handleOnChange={handleFieldSelect}
          isDraggable={!formSection.default_form_section}
        />
      );
    } else if (element.class_name == "Subform") {
      return (
        <SubformSectionLayout
          {...sharedProps}
          checkedForMove={checkedForMove}
          handleMoveCheckoboxChange={handleFieldSelect}
          subformSection={sectionElement}
          sectionId={formSection.id}
          isDraggable={!formSection.default_form_section}
        />
      );
    }
  };

  let sectionHeader;
  if (formSection.default_form_section) {
    sectionHeader = (
      <>
        <span className="section-name">Unallocated</span>
      </>
    );
  } else {
    sectionHeader = (
      <>
        <span className="margin-right-10">
          <span className="section-name">
            {formSection.name || <em className="text-muted">Untitled</em>}
          </span>
          <small>{` - ${formSection.identifier}`}</small>
        </span>
        <div className="btn-group">
          <button
            onClick={() => {
              handleFormSectionForEdit(studyId, formSection);
            }}
            disabled={actionDisabled}
            title={`Edit Section '${
              formSection.name || formSection.identifier
            }'`}
            className="btn btn-link"
          >
            <i className="fa fa-pencil" />
          </button>
          {sectionToDeleteId != formSection.id && (
            <button
              onClick={() => {
                handleRemoveSection(studyId, formSection.id);
              }}
              title={`Delete Section '${
                formSection.name || formSection.identifier
              }'`}
              className="btn btn-link section-remove"
              data-confirm="Are you sure you want to delete this section? All the fields in this section will be moved to 'Unallocated'."
            >
              <i className="fa fa-trash-o text-danger" />
            </button>
          )}
        </div>
      </>
    );
  }

  const handleSelectAllFieldsInSection = (e) => {
    const fields = formSection.sectionElements.map((field, index) => {
      return {
        default_section: formSection.default_form_section,
        position: index,
        fieldId: field.id,
      };
    });
    if (e.target.checked) {
      handleAddFieldsToMove(fields);
    } else {
      handleRemoveFieldsToMove(fields);
    }
  };

  const handleFieldSelect = (e) => {
    const fieldId = parseInt(e.target.value, 10);
    const position = movableFields.indexOf(fieldId);
    const fieldData = {
      default_section: formSection.default_form_section,
      position: position,
      fieldId: fieldId,
    };

    if (e.target.checked) {
      handleAddFieldsToMove([fieldData]);
    } else {
      handleRemoveFieldsToMove([fieldData]);
    }
  };

  const DefaultFormSectionElements = () => {
    return (
      <>
        {formSection.sectionElements.map((element, index) => {
          return formSectionToRender(element);
        })}
        {formSection.sectionElements.length == 0 && (
          <li className="no-record-li">There are no fields in this section.</li>
        )}
      </>
    );
  };

  let sectionClassNames = ["form_section"];
  if (sectionClass) {
    sectionClassNames.push(sectionClass);
  }
  if (allFieldsSelectedForMove) {
    sectionClassNames.push("selected-for-move");
  }

  return (
    <li
      className={sectionClassNames.join(" ")}
      id={`section-wrapper-${formSection.id}`}
    >
      <Row className="form-section-header">
        <Col md={6}>
          {!formSection.default_form_section && (
            <span className="sortable-handler" {...dragHandleProps}>
              <i className="fa fa-reorder" />
            </span>
          )}
          {sectionHeader}
        </Col>
        <Col md={6}>
          <div>
            <div className="btn-group section-actions">
              {formSection.default_form_section === false &&
                sectionToDeleteId == formSection.id && (
                  <button
                    className="btn btn-link section-remove"
                    disabled={actionDisabled}
                  >
                    <i className="fa fa-circle-o-notch fa-spin text-danger" />
                  </button>
                )}
              <a
                href={`#fields-for-section-${formSection.id}`}
                className="field-count btn btn-text"
                data-toggle="collapse"
              >
                {`${formSection.sectionElements.length} ${
                  formSection.sectionElements.length == 1 ? "item" : "items"
                }`}
              </a>
              <span className="section-select-all">
                {movableFields.length > 0 && (
                  <input
                    className=""
                    type="checkbox"
                    value={element.id}
                    disabled={actionDisabled}
                    title={`Select all fields for ${
                      element.label || "untitled"
                    }`}
                    checked={allFieldsSelectedForMove}
                    onChange={(e) => {
                      handleSelectAllFieldsInSection(e);
                    }}
                  />
                )}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div
        className="fields-container collapse in"
        id={`fields-for-section-${formSection.id}`}
      >
        <ul className="list-unstyled sortable section-sortable">
          {(formSection.default_form_section && (
            <DefaultFormSectionElements />
          )) || (
            <Droppable
              droppableId={`section_drop_area_${formSection.id}`}
              type="SectionField"
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {formSection.sectionElements.map((element, index) => {
                    return (
                      <DraggableFormSectionElement
                        key={element.id}
                        index={index}
                        element={element}
                        type="SectionField"
                      >
                        {formSectionToRender(element)}
                      </DraggableFormSectionElement>
                    );
                  })}
                  {formSection.sectionElements.length == 0 && (
                    <li className="no-record-li">
                      There are no fields in this section.
                    </li>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </ul>
      </div>
    </li>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    formSection: ownProps.formSection,
    studyId: ownProps.studyId,
    sectionClass: ownProps.sectionClass,
    dragHandleProps: ownProps.dragHandleProps,
    selectedFieldsToMove: state.fieldsMove.selectedFields,
    sectionToDeleteId: state.formLayout.sectionToDeleteId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleRemoveSection: actions.handleRemoveSection,
      handleFormSectionForEdit: actions.handleFormSectionForEdit,
      handleAddFieldsToMove: actions.handleAddFieldsToMove,
      handleRemoveFieldsToMove: actions.handleRemoveFieldsToMove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSectionLayout);
