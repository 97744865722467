import React from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";

import { SelectField } from "components/helpers";

const StudyTableDropdown = ({
  colSize,
  tableOptions,
  values,
  saving,
  errors,
  handleTableChange,
  studyId,
}) => {
  return (
    <>
      {tableOptions && tableOptions.length > 0 && (
        <Col sm={colSize}>
          <SelectField
            fieldName="study_table_id"
            label="Table"
            value={values.study_table_id}
            options={tableOptions}
            onChange={handleTableChange}
            errors={errors.study_table_id}
            placeholder="Select table"
            isMulti={false}
            isClearable={true}
            required={false}
            disabled={
              saving || values.inline_field || values.do_not_show == true
            }
            className="select-study-table"
            classNamePrefix="select-study-table"
          />
        </Col>
      )}
      {tableOptions && tableOptions.length == 0 && (
        <Col sm={colSize}>
          <div className="alert alert-danger margin-bottom-0">
            No tables have been defined. You can add new tables on the{" "}
            <a
              href={"/study/" + studyId + "/study_tables"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Tables
            </a>{" "}
            tab.
          </div>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  let modalState, errors;

  if (state.addField.showForm) {
    modalState = state.addField;
    errors = modalState.errors.field;
  } else {
    modalState = state.editSettings;
    errors = modalState.errors;
  }

  return {
    tableOptions: modalState.tableOptions,
    values: modalState.values,
    lookupOptions: modalState.lookupOptions,
    saving: modalState.saving,
    errors: errors,
    studyId: state.navigation.studyId,
  };
};

export default connect(mapStateToProps)(StudyTableDropdown);
