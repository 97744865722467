import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  HelpBlock,
  Table,
  Button,
} from "react-bootstrap";
import {
  TextField,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
  LOOKUP_OPTION_IDENTIFIER_LIMIT_PATTERN,
  LOOKUP_OPTION_IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";

import actions from "actions/fields_page";

const NewLookup = ({
  newLookup,
  newLookupOptions,
  handleAddLookupOption,
  handleReorderLookupOption,
  handleRemoveLookupOption,
  handleNewLookupValueChange,
  handleNewLookupOptionValueChange,
  saving,
  errors,
}) => {
  return (
    <>
      <fieldset className="margin-top-20 new-lookup-details">
        <legend>New Lookup</legend>
        <Row>
          <Col md={6}>
            <TextField
              required={true}
              controlId="lookup_identifier"
              label="Identifier"
              fieldName="identifier"
              pattern={IDENTIFIER_LIMIT_PATTERN}
              title={IDENTIFIER_LIMIT_TITLE}
              value={newLookup.identifier}
              disabled={saving}
              errors={errors.newLookup.identifier}
              onChange={handleNewLookupValueChange}
            />
          </Col>
        </Row>
      </fieldset>
      <fieldset className="margin-top-10 new-lookup-options">
        <legend>Lookup Options</legend>
        <DragDropContext onDragEnd={handleReorderLookupOption}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Table>
                <thead>
                  <tr>
                    <th colSpan="2" className="col-sm-4">
                      Identifier
                    </th>
                    <th>Label</th>
                    <th className="mini"></th>
                  </tr>
                </thead>
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {newLookupOptions.map((option, index) => {
                    const optionErrors = errors.newLookupOptions[index] || {};
                    const draggableKey = `newLookupDraggable_${index}`;
                    const rowKey = `newLookupOption${index}`;
                    return (
                      <Draggable
                        key={draggableKey}
                        draggableId={draggableKey}
                        index={index}
                      >
                        {(provided) => (
                          <tr
                            className="draggable-row"
                            id={rowKey}
                            key={rowKey}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <td className="vertical-center">
                              <span
                                className="sortable-handler"
                                {...provided.dragHandleProps}
                              >
                                <i className="fa fa-reorder" />
                              </span>
                            </td>
                            <td>
                              <FormGroup
                                className={`option_identifier_${index}-input margin-bottom-0`}
                                controlId={`option_identifier_${index}`}
                                validationState={
                                  optionErrors.identifier ? "error" : null
                                }
                              >
                                <FormControl
                                  name={`option_identifier_${index}`}
                                  type="text"
                                  placeholder="Identifier"
                                  pattern={
                                    LOOKUP_OPTION_IDENTIFIER_LIMIT_PATTERN
                                  }
                                  title={LOOKUP_OPTION_IDENTIFIER_LIMIT_TITLE}
                                  value={option.identifier}
                                  onChange={(e) =>
                                    handleNewLookupOptionValueChange(
                                      index,
                                      "identifier",
                                      e.target.value
                                    )
                                  }
                                  disabled={saving}
                                  autoFocus={
                                    newLookupOptions.length == index + 1
                                  }
                                />
                                {optionErrors.identifier && (
                                  <HelpBlock bsClass="inline-help-block">
                                    {optionErrors.identifier[0]}
                                  </HelpBlock>
                                )}
                              </FormGroup>
                            </td>
                            <td>
                              <FormGroup
                                className={`option_label_${index}-input margin-bottom-0`}
                                controlId={`option_label_${index}`}
                                validationState={
                                  optionErrors.label ? "error" : null
                                }
                              >
                                <FormControl
                                  name={`option_label_${index}`}
                                  type="text"
                                  placeholder="Label"
                                  value={option.label}
                                  onChange={(e) =>
                                    handleNewLookupOptionValueChange(
                                      index,
                                      "label",
                                      e.target.value
                                    )
                                  }
                                  disabled={saving}
                                />
                                {optionErrors.label && (
                                  <HelpBlock bsClass="inline-help-block">
                                    {optionErrors.label[0]}
                                  </HelpBlock>
                                )}
                              </FormGroup>
                            </td>
                            <td className={`option_remove_${index}_button`}>
                              <Button
                                onClick={() => handleRemoveLookupOption(index)}
                                disabled={saving}
                                bsStyle="link"
                                bsSize="small"
                                title="Remove lookup option"
                              >
                                <i className="fa fa-trash-o text-danger text-large" />
                              </Button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                  <tr>
                    <td colSpan="4">
                      <Button
                        onClick={() => handleAddLookupOption()}
                        bsStyle="success"
                        bsSize="xsmall"
                        disabled={saving}
                      >
                        <i className="fa fa-plus margin-right-5" />
                        Add an option
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </fieldset>
    </>
  );
};

const mapStateToProps = (state) => {
  let modalState;
  if (state.addField.showForm) {
    modalState = state.addField;
  } else {
    modalState = state.editTypeModal;
  }
  return {
    newLookup: modalState.newLookup,
    newLookupOptions: modalState.newLookupOptions,

    saving: modalState.saving,
    errors: modalState.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleAddLookupOption: actions.handleAddLookupOption,
      handleReorderLookupOption: actions.handleReorderLookupOption,
      handleRemoveLookupOption: actions.handleRemoveLookupOption,
      handleNewLookupValueChange: actions.handleNewLookupValueChange,
      handleNewLookupOptionValueChange:
        actions.handleNewLookupOptionValueChange,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLookup);
