import React from "react";

import { Panel } from "react-bootstrap";
import Change from "./Change";

const renderChanges = (changes, renderTitle, renderUpdateOrdering) => {
  return changes.map((change) => {
    return (
      <Change
        key={change.id}
        change={change}
        renderTitle={renderTitle}
        renderUpdateOrdering={renderUpdateOrdering}
      />
    );
  });
};

const ChangesList = ({
  changes,
  renderTitle,
  renderUpdateOrdering,
  includeAdditionalSubheading = false,
}) => {
  if (includeAdditionalSubheading === true) {
    return (
      <Panel.Body>
        {Object.entries(changes).map(([, changeData], i) => {
          return (
            <div className="additional-subheading" key={i}>
              <h3 className="additional-subheading-title">
                {changeData.title}
              </h3>
              {renderChanges(
                changeData.changes,
                renderTitle,
                renderUpdateOrdering
              )}
            </div>
          );
        })}
      </Panel.Body>
    );
  } else {
    return (
      <Panel.Body>
        {renderChanges(changes, renderTitle, renderUpdateOrdering)}
      </Panel.Body>
    );
  }
};

export default ChangesList;
