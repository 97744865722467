import React from "react";
import { connect } from "react-redux";
import { Panel, Table } from "react-bootstrap";
import _ from "lodash";

import FieldRow from "./FieldRow";
import SubformRow from "./SubformRow";
import actions from "actions/fields_page";
import { bindActionCreators } from "redux";

const FieldTableScreen = ({
  studyId,
  showSectionRows,
  formSections,
  formName,
  formIdentifier,
  fields,
  subforms,
  selectedFields,
  highlightedFields,
  handleSelectAllFieldsForBulkAction,
  handleRemoveAllSelectedFieldsForBulkAction,
  handleOpenNewFieldForm,
}) => {
  const handleSelectAllFields = (e) => {
    const fieldIds = fields.map((field) => field.id);
    if (e.target.checked) {
      handleSelectAllFieldsForBulkAction(studyId, fieldIds);
    } else {
      handleRemoveAllSelectedFieldsForBulkAction();
    }
  };

  const selectAllIsChecked =
    selectedFields.length > 0 && fields.length == selectedFields.length;

  const FormSectionGroupedFields = () => {
    const NoFormFields = () => {
      return (
        <tr>
          <td colSpan={6} className="text-muted text-center">
            There are no fields on this form.
          </td>
        </tr>
      );
    };

    // If there are no elements at all, then display a message
    if (fields.length == 0 && subforms.length == 0) {
      return <NoFormFields key={`no-form-fields`} />;
    }

    const elementsForSection = (section, collection, extraAttributes = {}) => {
      return collection
        .filter((field) => field.form_section_identifier === section.identifier)
        .map((object) => ({ ...object, ...extraAttributes }));
    };

    // Loop over the form sections
    return formSections.map((section) => {
      // Get the fields (and subforms) for this section
      const sectionElements = _.sortBy(
        [
          ...elementsForSection(section, fields, { subformSection: false }),
          ...elementsForSection(section, subforms, { subformSection: true }),
        ],
        ["position_within_form_section"]
      );

      if (sectionElements.length > 0) {
        return (
          // Display the section header
          <React.Fragment key={`form-section-${section.id}`}>
            {showSectionRows && (
              <tr>
                <td
                  colSpan={6}
                  className={
                    (section.default_form_section && "bg-warning") ||
                    "bg-silver"
                  }
                >
                  <strong>
                    {(section.default_form_section && "Unallocated") ||
                      section.name || <em>Untitled</em>}
                  </strong>
                </td>
              </tr>
            )}
            {sectionElements.map((element) => {
              if (element.subformSection) {
                return (
                  <SubformRow key={element.identifier} subform={element} />
                );
              } else {
                return (
                  <FieldRow
                    key={element.id}
                    field={element}
                    fieldSelected={selectedFields.includes(element.id)}
                    fieldHighlighted={highlightedFields
                      .map((highlighted) => highlighted.id)
                      .includes(element.id)}
                  />
                );
              }
            })}
          </React.Fragment>
        );
      }
    });
  };

  return (
    <>
      <Panel className="form-panel" bsStyle="primary" id="form-and-fields">
        <Panel.Heading className="panel-heading-sticky">
          <ul className="panel-buttons">
            <li>
              <button
                onClick={() => {
                  handleOpenNewFieldForm();
                }}
                title={`Add a new field to ${formName}`}
                className="btn btn-link"
              >
                <i className="fa fa-plus margin-right-5" />
                Add field
              </button>
            </li>
          </ul>
          <Panel.Title componentClass="h3">
            {formName}
            <small className="no-transform"> - {formIdentifier}</small>
          </Panel.Title>
        </Panel.Heading>
        <Table hover>
          <thead className="table-header table-heading-sticky">
            <tr>
              <th style={{ minWidth: "150px" }}>Field / Identifier</th>
              <th>Type / Widget</th>
              <th className="nowrap">Dependency</th>
              <th className="nowrap" style={{ minWidth: "150px" }}>
                Validation
              </th>
              <th>Settings</th>
              <th className="mini text-center">
                {fields.length > 0 && (
                  <input
                    type="checkbox"
                    checked={selectAllIsChecked}
                    onChange={(e) => {
                      handleSelectAllFields(e);
                    }}
                  />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            <FormSectionGroupedFields />
          </tbody>
        </Table>
      </Panel>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    studyId: state.currentFormOrSubform.study_id,
    formSections: state.currentFormOrSubform.form_sections,
    showSectionRows: state.currentFormOrSubform.type != "Subform",
    formName: state.currentFormOrSubform.name,
    formIdentifier: state.currentFormOrSubform.identifier,
    fields: state.currentFormOrSubform.fields,
    subforms: state.currentFormOrSubform.subforms,
    selectedFields: state.bulkActions.selectedFields,
    highlightedFields: state.navigation.highlightedFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleSelectAllFieldsForBulkAction:
        actions.handleSelectAllFieldsForBulkAction,
      handleRemoveAllSelectedFieldsForBulkAction:
        actions.handleRemoveAllSelectedFieldsForBulkAction,
      handleOpenNewFieldForm: actions.handleOpenNewFieldForm,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldTableScreen);
