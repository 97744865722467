import ChangeObject from "./ChangeObject";

class LookupChange extends ChangeObject {
  constructor(change) {
    const identifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;

    const attributeData = [
      ["Identifier", "identifier"],
      ["Type", "human_lookup_type"],
      [
        "Options",
        "lookup_options",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `${associatedObject.identifier.value} - ${associatedObject.label.value}`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject, index) =>
                  `${index + 1}. ${associatedObject?.identifier?.value} - ${
                    associatedObject?.label?.value
                  }`
              )
              .join("\n");
          },
        },
      ],
    ];

    super(change, "Lookup", identifier, attributeData);
  }
}

export default LookupChange;
