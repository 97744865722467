import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, Row, Col, Alert } from "react-bootstrap";

import CopyTo from "./CopyTo";
import { Select } from "components/helpers";
import actions from "actions/fields_page";
import BulkActionConfirmation from "./BulkActionConfirmation";
import ButtonWithSpinner from "../helpers/ButtonWithSpinner";
import BulkActionErrors from "./BulkActionErrors";

const BulkActionsScreen = ({
  deleting,
  moving,
  copying,
  copyNTimes,
  availableForms,
  targetForm,
  loadingReferencedFields,
  selectedFields,
  cancel,
  currentFormOrSubform,
  handleSelectTargetForm,
  handleSelectCopyNTimes,
  handleShowBulkActionConfirmation,
}) => {
  const performingAction =
    deleting || moving || copying || loadingReferencedFields;

  const moveToButtonDisabled =
    targetForm === "" ||
    (targetForm.value === currentFormOrSubform.id &&
      targetForm.type === currentFormOrSubform.type) ||
    (performingAction ? true : false);

  return (
    <>
      <BulkActionErrors />
      <BulkActionConfirmation />
      <div
        id="bulk-actions-container"
        className={selectedFields.length === 0 ? "hide" : ""}
      >
        <Panel>
          <Panel.Heading>
            <ul className="panel-buttons">
              <li>
                <button
                  onClick={() => cancel()}
                  title="Close bulk actions"
                  className="btn btn-link"
                  disabled={performingAction ? true : false}
                >
                  <i className="fa fa-times margin-right-5" />
                  Cancel
                </button>
              </li>
            </ul>
            <h3 className="panel-title">
              <i className="fa fa-magic" /> Actions for Selected
            </h3>
          </Panel.Heading>
          <Panel.Body className="panel-body-sm">
            <ButtonWithSpinner
              bsStyle="danger"
              faIcon="trash-o"
              className="btn-block"
              buttonText="Delete"
              savingText="Deleting..."
              title="Delete all selected fields"
              onClick={() => handleShowBulkActionConfirmation("Delete")}
              saving={deleting}
              disabled={performingAction ? true : false}
            />
            <div className="text-with-line">
              <span>OR</span>
            </div>
            <div className="margin-bottom-5">
              <Select
                options={availableForms}
                className="target-form"
                classNamePrefix="select"
                value={targetForm}
                isDisabled={performingAction ? true : false}
                onChange={(e) => handleSelectTargetForm(e)}
                isSearchable
                isClearable
                placeholder="- Choose a form or subform -"
              />
            </div>
            <Row>
              <Col xs={4}>
                <ButtonWithSpinner
                  bsStyle="default"
                  faIcon="share-square"
                  className="btn-block"
                  buttonText="Move to"
                  savingText="Moving..."
                  title="Move selected fields to the target form"
                  onClick={() => handleShowBulkActionConfirmation("Move")}
                  saving={moving}
                  disabled={moveToButtonDisabled}
                />
              </Col>
              <Col xs={8}>
                <CopyTo
                  copying={copying}
                  copyNTimes={copyNTimes}
                  confirmed={selectedFields.length > 0 ? null : false}
                  confirmCopy={() => handleShowBulkActionConfirmation("Copy")}
                  setCopyNTimes={(e) => handleSelectCopyNTimes(e)}
                  disabled={
                    targetForm === "" || (performingAction ? true : false)
                  }
                />
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const relevantState = state.bulkActions;

  return {
    studyId: relevantState.studyId,
    deleting: relevantState.deleting,
    moving: relevantState.moving,
    copying: relevantState.copying,
    copyNTimes: relevantState.copyNTimes || "1",
    availableForms: relevantState.availableForms,
    targetForm: relevantState.targetForm || "",
    loadingReferencedFields: relevantState.loadingReferencedFields,
    referencedFields: relevantState.referencedFields,
    selectedFields: relevantState.selectedFields,
    displayingConfirmation: relevantState.displayingConfirmation,
    confirmationType: relevantState.confirmationType,
    failedFields: relevantState.failedFields,
    failureReason: relevantState.failureReason,
    currentFormOrSubform: state.currentFormOrSubform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      cancel: actions.handleRemoveAllSelectedFieldsForBulkAction,
      handleSelectTargetForm: actions.handleSelectTargetForm,
      handleSelectCopyNTimes: actions.handleSelectCopyNTimes,
      handleShowBulkActionConfirmation:
        actions.handleShowBulkActionConfirmation,
      handleHideBulkActionConfirmation:
        actions.handleHideBulkActionConfirmation,
      handleDeleteSelectedFields: actions.handleDeleteSelectedFields,
      handleMoveSelectedFields: actions.handleMoveSelectedFields,
      handleCopySelectedFields: actions.handleCopySelectedFields,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionsScreen);
