import { MessageTemplateActionTypes } from "actions/questionnaires_page";

export const messageTemplateFormDefaultState = {
  showForm: false,
  saving: false,
  editing: false,
  messageTemplateId: undefined,
  values: {
    name: "",
    identifier: "",
    content: "",
  },
  errors: {
    name: undefined,
    identifier: undefined,
    content: undefined,
  },
};

export const MessageTemplateForm = (
  state = messageTemplateFormDefaultState,
  action
) => {
  switch (action.type) {
    case MessageTemplateActionTypes.HANDLE_OPEN_NEW_MESSAGE_TEMPLATE_FORM: {
      return { ...state, showForm: true };
    }

    case MessageTemplateActionTypes.HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS:
    case MessageTemplateActionTypes.HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS:
    case MessageTemplateActionTypes.HANDLE_CLOSE_MESSAGE_TEMPLATE_FORM: {
      return { ...state, ...messageTemplateFormDefaultState };
    }

    case MessageTemplateActionTypes.HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT: {
      return { ...state, saving: true };
    }

    case MessageTemplateActionTypes.HANDLE_MESSAGE_TEMPLATE_FORM_VALUE_CHANGE: {
      let newValues = {
        ...state.values,
        [action.fieldName]: action.value,
      };

      const newState = {
        ...state,
        values: newValues,
      };

      return newState;
    }

    case MessageTemplateActionTypes.HANDLE_MESSAGE_TEMPLATE_FORM_SUBMIT_FAILURE: {
      return { ...state, saving: false, errors: action.errors };
    }

    case MessageTemplateActionTypes.HANDLE_MESSAGE_TEMPLATE_EDIT_CLICK: {
      let messageTemplateToEdit = action.messageTemplate;
      const editValues = {
        name: messageTemplateToEdit.name,
        identifier: messageTemplateToEdit.identifier,
        content: messageTemplateToEdit.content,
      };
      return {
        ...state,
        showForm: true,
        editing: true,
        values: editValues,
        messageTemplateId: messageTemplateToEdit.id,
      };
    }

    case MessageTemplateActionTypes.HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT: {
      return { ...state, saving: true };
    }
  }
  return state;
};
