import React from "react";

const AttributeRow = ({ attribute }) => {
  const {
    title,
    actionType,
    originalValue,
    newValue,
    attributeType,
    displayAssociatedObjectFunction,
  } = attribute;
  const asHTML =
    attribute.displayFunction || attribute.displayAssociatedObjectFunction;

  const basicDisplayFunction = (value) => {
    return value;
  };
  const booleanDisplayFunction = (value) => {
    return value ? "True" : "False";
  };
  const associationDisplayFunction = (value) => {
    if (value && value.length > 0) {
      return `<ul>${value
        .map((associatedObject, i) => {
          return `<li${associatedObject.changed ? ' class="has-change"' : ""}>${
            i + 1
          }. ${displayAssociatedObjectFunction(associatedObject)}</li>`;
        })
        .join("")}</ul>`;
    }
  };

  let displayFunction = attribute.displayFunction;
  if (displayFunction === undefined) {
    if (attributeType === "boolean") {
      displayFunction = booleanDisplayFunction;
    } else if (attributeType === "association") {
      displayFunction = associationDisplayFunction;
    } else {
      displayFunction = basicDisplayFunction;
    }
  }

  return (
    <tr>
      <th>{title}</th>
      {actionType !== "create" &&
        (asHTML ? (
          <td
            dangerouslySetInnerHTML={{
              __html: displayFunction(originalValue),
            }}
          />
        ) : (
          <td>{displayFunction(originalValue)}</td>
        ))}
      {actionType !== "delete" &&
        (asHTML ? (
          <td
            dangerouslySetInnerHTML={{
              __html: displayFunction(newValue),
            }}
          />
        ) : (
          <td>{displayFunction(newValue)}</td>
        ))}
    </tr>
  );
};

export default AttributeRow;
