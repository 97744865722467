import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Row, Col, ButtonToolbar, Button, Alert } from "react-bootstrap";

import {
  TextField,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";
import actions from "actions/fields_page";

const EditLabelIdentifierFormScreen = ({
  fieldId,
  studyId,
  show,
  saving,
  values,
  errors,
  handleClose,
  handleSubmit,
  updateFormValue,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(studyId, fieldId, values);
  };

  return (
    <Modal
      id="modalWindow"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : handleClose}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>Edit field details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.base && <Alert bsStyle="danger">{errors.base[0]}</Alert>}
          <TextField
            controlId="label"
            fieldName="label"
            label="Label"
            value={values.label}
            disabled={saving}
            errors={errors.label}
            onChange={updateFormValue}
            autoFocus={true}
          />
          <TextField
            required={true}
            controlId="identifier"
            fieldName="identifier"
            label="Identifier"
            pattern={IDENTIFIER_LIMIT_PATTERN}
            title={IDENTIFIER_LIMIT_TITLE}
            value={values.identifier}
            disabled={saving}
            errors={errors.identifier}
            onChange={updateFormValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  Saving
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  Save
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.editLabelIdentifierForm;

  return {
    fieldId: relevantState.fieldId,
    studyId: relevantState.studyId,
    show: relevantState.showForm,
    saving: relevantState.saving,
    values: relevantState.values,
    errors: relevantState.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: actions.updateFormValue,
      handleClose: actions.handleEditLabelIdentifierFormClose,
      handleSubmit: actions.handleEditLabelIdentifierFormSubmit,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLabelIdentifierFormScreen);
