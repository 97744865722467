import * as ActionTypes from "./questionnaireTableActionTypes";

export const handleOpenDeleteModal = (questionnaire) => ({
  type: ActionTypes.HANDLE_OPEN_DELETE_MODAL,
  questionnaire: questionnaire,
});

export const handleCloseDeleteModal = () => ({
  type: ActionTypes.HANDLE_CLOSE_DELETE_MODAL,
});

export const handleQuestionnaireDelete = (
  studyId,
  entityGroupId,
  questionnaireId
) => ({
  type: ActionTypes.HANDLE_QUESTIONNAIRE_DELETE,
  studyId: studyId,
  entityGroupId: entityGroupId,
  questionnaireId: questionnaireId,
});

export const handleQuestionnaireDeleteSuccess = (responseData) => ({
  type: ActionTypes.HANDLE_QUESTIONNAIRE_DELETE_SUCCESS,
  responseData: responseData,
});

export const handleQuestionnaireDeleteFailure = (responseData) => ({
  type: ActionTypes.HANDLE_QUESTIONNAIRE_DELETE_FAILURE,
  responseData: responseData,
});
