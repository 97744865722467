import * as ActionTypes from "./action_types";

export const openAddTableModal = () => ({
  type: ActionTypes.OPEN_ADD_TABLE_MODAL,
});

export const openEditTableModal = (props) => ({
  type: ActionTypes.OPEN_EDIT_TABLE_MODAL,
  props: JSON.parse(props),
});

export const closeModal = () => ({
  type: ActionTypes.CLOSE_MODAL,
});

export const updateFormValue = (fieldName, value) => ({
  type: ActionTypes.UPDATE_FORM_VALUE,
  fieldName,
  value,
});

export const handleAddColumn = () => ({
  type: ActionTypes.HANDLE_ADD_COLUMN,
});

export const handleChangeColumn = (index, value) => ({
  type: ActionTypes.HANDLE_CHANGE_COLUMN,
  index: index,
  value: value,
});

export const handleReorderColumns = (result) => ({
  type: ActionTypes.HANDLE_REORDER_COLUMNS,
  result: result,
});

export const handleRemoveColumn = (index) => ({
  type: ActionTypes.HANDLE_REMOVE_COLUMN,
  index: index,
});

export const handleNewFormSubmit = (studyId, values) => ({
  type: ActionTypes.HANDLE_NEW_FORM_SUBMIT,
  studyId: studyId,
  values: values,
});

export const handleEditFormSubmit = (studyId, studyTableId, values) => ({
  type: ActionTypes.HANDLE_EDIT_FORM_SUBMIT,
  studyId: studyId,
  studyTableId: studyTableId,
  values: values,
});

export const handleSubmitSuccess = () => ({
  type: ActionTypes.HANDLE_SUBMIT_SUCCESS,
});

export const handleSubmitFailure = (resData) => ({
  type: ActionTypes.HANDLE_SUBMIT_FAILURE,
  resData: resData,
});
