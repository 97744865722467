import { defaultErrorMessage } from "components/helpers";

import { processRequest } from "services/base_requests";
import { search } from "services/layout_navigation_requests";

import actions from "actions/layout_page";

export const navigationMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case "NAV_SEARCH": {
        const request = search(action.studyId, action.searchTerm);

        const success = (resData) => {
          dispatch(actions.handleNavSearchSuccess(resData["study_forms"]));
        };

        const error = () => {
          $.flashAlert(defaultErrorMessage, "alert-danger");
        };

        processRequest(request, success, error, error);
      }
    }

    return next(action);
  };
