import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Row, Col } from "react-bootstrap";

import actions from "actions/tables_page";

import {
  TextField,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";

const TableProperties = ({ values, saving, errors, updateFormValue }) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <TextField
            required={true}
            controlId="identifier"
            fieldName="identifier"
            label="Identifier"
            pattern={IDENTIFIER_LIMIT_PATTERN}
            title={IDENTIFIER_LIMIT_TITLE}
            value={values.identifier}
            disabled={saving}
            errors={errors.identifier}
            onChange={updateFormValue}
          />
        </Col>
        <Col md={6}>
          <TextField
            controlId="lead_column_header"
            fieldName="lead_column_header"
            label="Lead column header row label"
            value={values.lead_column_header}
            disabled={saving}
            errors={errors.lead_column_header}
            onChange={updateFormValue}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  const modalState = state.tableModal;
  return {
    values: modalState.values,
    errors: modalState.errors,
    saving: modalState.saving,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: actions.updateFormValue,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TableProperties);
