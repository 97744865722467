import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Radio,
  HelpBlock,
  Row,
  Col,
} from "react-bootstrap";

import { updateFormValue } from "actions/form.base.actions";
import actions from "actions/revisions_page";

import ButtonWithSpinner from "../helpers/ButtonWithSpinner";

const ManagementInformationForm = ({
  form,
  values,
  saving,
  errors,
  studyId,
  revisionId,
  handleFormUpdate,
  updateForm,
}) => {
  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    updateForm(form, values, studyId, revisionId);
  };

  const RadioGroup = ({ name, valueKey, options }) => {
    return (
      <div>
        {options.map((option) => {
          return (
            <Radio
              key={`${name}-${option.value}`}
              inline
              name={name}
              checked={values[valueKey] === option.value}
              onChange={(e) => handleFormUpdate(valueKey, option.value)}
            >
              {option.label}
            </Radio>
          );
        })}
      </div>
    );
  };

  return (
    <form
      noValidate
      onSubmit={handleUpdateSubmit}
      id="management-information-form"
    >
      <FormGroup
        controlId="management-information-ticket"
        validationState={errors.ticket_number ? "error" : null}
      >
        <ControlLabel>Ticket #</ControlLabel>
        <FormControl
          type="text"
          value={values.ticket_number}
          onChange={(e) => handleFormUpdate("ticket_number", e.target.value)}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.ticket_number}</HelpBlock>
      </FormGroup>

      <FormGroup
        controlId="management-information-request-date"
        validationState={errors.request_date ? "error" : null}
      >
        <ControlLabel>Request date</ControlLabel>
        <FormControl
          type="date"
          value={values.request_date}
          onChange={(e) => handleFormUpdate("request_date", e.target.value)}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.request_date}</HelpBlock>
      </FormGroup>

      <FormGroup
        controlId="management-information-request-source"
        validationState={errors.request_source ? "error" : null}
      >
        <ControlLabel>Request source</ControlLabel>
        <FormControl
          type="text"
          value={values.request_source}
          onChange={(e) => handleFormUpdate("request_source", e.target.value)}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.request_source}</HelpBlock>
      </FormGroup>

      <FormGroup
        controlId="management-information-comment"
        validationState={errors.comment ? "error" : null}
      >
        <ControlLabel>
          Please provide the details for this revision
        </ControlLabel>
        <FormControl
          componentClass="textarea"
          value={values.comment}
          placeholder="Enter comment"
          onChange={(e) => handleFormUpdate("comment", e.target.value)}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.comment}</HelpBlock>
      </FormGroup>

      <FormGroup
        id="risk-to-existing-data"
        controlId="management-information-risk-to-existing-data"
        validationState={errors.risk_to_existing_data ? "error" : null}
      >
        <ControlLabel>Risk to existing data?</ControlLabel>
        <RadioGroup
          name="management-information-risk-to-existing-data"
          valueKey="risk_to_existing_data"
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.risk_to_existing_data}</HelpBlock>
      </FormGroup>

      {values.risk_to_existing_data == "yes" && (
        <FormGroup
          controlId="management-information-details-of-risk-to-existing-data"
          validationState={
            errors.details_of_risk_to_existing_data ? "error" : null
          }
        >
          <ControlLabel>Details of risk to existing data</ControlLabel>
          <FormControl
            componentClass="textarea"
            value={values.details_of_risk_to_existing_data}
            onChange={(e) =>
              handleFormUpdate(
                "details_of_risk_to_existing_data",
                e.target.value
              )
            }
          />
          <FormControl.Feedback />
          <HelpBlock>{errors.details_of_risk_to_existing_data}</HelpBlock>
        </FormGroup>
      )}

      <FormGroup
        id="data-migration-required"
        controlId="management-information-data-migration-required"
        validationState={errors.data_migration_required ? "error" : null}
      >
        <ControlLabel>Data migration required?</ControlLabel>
        <RadioGroup
          name="management-information-data-migration-required"
          valueKey="data_migration_required"
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.data_migration_required}</HelpBlock>
      </FormGroup>

      {values.data_migration_required == "yes" && (
        <FormGroup
          controlId="management-information-details-of-data-migration"
          validationState={errors.details_of_data_migration ? "error" : null}
        >
          <ControlLabel>Details of data migration</ControlLabel>
          <FormControl
            componentClass="textarea"
            value={values.details_of_data_migration}
            onChange={(e) =>
              handleFormUpdate("details_of_data_migration", e.target.value)
            }
          />
          <FormControl.Feedback />
          <HelpBlock>{errors.details_of_data_migration}</HelpBlock>
        </FormGroup>
      )}

      <FormGroup
        id="documentation-of-testing"
        controlId="management-information-evidence-of-testing"
        validationState={errors.evidence_of_testing ? "error" : null}
      >
        <ControlLabel>Documentation of testing required?</ControlLabel>
        <RadioGroup
          name="management-information-evidence-of-testing"
          valueKey="evidence_of_testing"
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.evidence_of_testing}</HelpBlock>
      </FormGroup>

      {values.evidence_of_testing == "yes" && (
        <FormGroup
          controlId="management-information-details-of-testing"
          validationState={errors.details_of_testing ? "error" : null}
        >
          <ControlLabel>Details of testing (e.g. file location)</ControlLabel>
          <FormControl
            componentClass="textarea"
            value={values.details_of_testing}
            onChange={(e) =>
              handleFormUpdate("details_of_testing", e.target.value)
            }
          />
          <FormControl.Feedback />
          <HelpBlock>{errors.details_of_testing}</HelpBlock>
        </FormGroup>
      )}

      <FormGroup
        id="validation-checks-updated"
        controlId="management-information-checks-updated"
        validationState={errors.checks_updated ? "error" : null}
      >
        <ControlLabel>Associated validation checks updated?</ControlLabel>
        <RadioGroup
          name="management-information-checks-updated"
          valueKey="checks_updated"
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
            { label: "N/A", value: "na" },
          ]}
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.checks_updated}</HelpBlock>
      </FormGroup>

      <FormGroup
        controlId="management-information-prospect-live-date"
        validationState={errors.prospect_live_date ? "error" : null}
      >
        <ControlLabel>Prospect live date</ControlLabel>
        <FormControl
          type="date"
          value={values.prospect_live_date}
          onChange={(e) =>
            handleFormUpdate("prospect_live_date", e.target.value)
          }
        />
        <FormControl.Feedback />
        <HelpBlock>{errors.prospect_live_date}</HelpBlock>
      </FormGroup>

      <Row>
        <Col md={12}>
          <ButtonWithSpinner
            bsStyle="primary"
            type="submit"
            buttonText="Save"
            savingText="Saving"
            saving={saving}
          />
        </Col>
      </Row>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    form: state.form,
    values: state.form.values,
    saving: state.form.saving,
    errors: state.form.errors,
    studyId: state.revision.studyId,
    revisionId: state.revision.revisionId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleFormUpdate: updateFormValue,
      updateForm: actions.updateForm,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementInformationForm);
