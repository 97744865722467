import React from "react";

const SubformField = ({
  id,
  identifier,
  label,
  isDraggable,
  dragHandleProps,
}) => {
  return (
    <li key={id} id={`subform-element-${id}`}>
      {isDraggable && (
        <span className="sortable-handler" {...dragHandleProps}>
          <i className="fa fa-reorder" />
        </span>
      )}
      {label}
      <small className="text-muted">{` - ${identifier}`}</small>
    </li>
  );
};

export default SubformField;
