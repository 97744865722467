import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import actions from "actions/revisions_page";
import { changeTitle } from "services/group_changes_by_subheading";
import {
  groupChangesBySubheading,
  subheadingNameFor,
} from "services/group_changes_by_subheading";

import SectionPanel from "./SectionPanel";
import ChangesList from "./ChangesList";
import { PanelSubheading } from "../helpers";

const PanelBody = ({
  changes,
  groupBySubheading,
  groupByAdditionalSubheading,
  renderTitle,
  renderUpdateOrdering,
}) => {
  if (groupByAdditionalSubheading === true) {
    const changesBySubheading = groupChangesBySubheading(
      changes,
      subheadingNameFor
    );

    const additionalSubheadingAttributes = (change) => {
      if (change.new_attributes) {
        return change.new_attributes.additional_subheading_information;
      } else {
        return change.original_attributes.additional_subheading_information;
      }
    };

    const additionalSubheadingPositionFor = (change) => {
      return additionalSubheadingAttributes(change).position.value;
    };

    const additionalSubheadingReducer = (
      changesByAdditionalSubheading,
      change
    ) => {
      const subheadingAttributes = additionalSubheadingAttributes(change);
      const additionalSubheadingIdentifier =
        subheadingAttributes.identifier.value;

      changesByAdditionalSubheading[additionalSubheadingIdentifier] =
        changesByAdditionalSubheading[additionalSubheadingIdentifier] || {
          title: changeTitle(subheadingAttributes, true),
          changes: [],
        };
      changesByAdditionalSubheading[
        additionalSubheadingIdentifier
      ].changes.push(change);
      return changesByAdditionalSubheading;
    };

    const changesBySubheadingAndAdditionalSubheading = {};
    Object.entries(changesBySubheading).forEach(
      ([subheadingIdentifier, changes]) => {
        const orderedChanges = changes.sort(
          (a, b) =>
            additionalSubheadingPositionFor(a) -
            additionalSubheadingPositionFor(b)
        );
        const changesByAdditionalSubheading = orderedChanges.reduce(
          additionalSubheadingReducer,
          {}
        );
        changesBySubheadingAndAdditionalSubheading[subheadingIdentifier] =
          changesByAdditionalSubheading;
      }
    );

    return (
      <>
        {Object.entries(changesBySubheadingAndAdditionalSubheading).map(
          ([subheadingIdentifier, subheadingChanges], index) => {
            return (
              <React.Fragment key={index}>
                <PanelSubheading title={subheadingIdentifier} />
                <ChangesList
                  changes={subheadingChanges}
                  renderTitle={renderTitle}
                  renderUpdateOrdering={renderUpdateOrdering}
                  includeAdditionalSubheading={true}
                />
              </React.Fragment>
            );
          }
        )}
      </>
    );
  } else if (groupBySubheading === true) {
    const changesBySubheading = groupChangesBySubheading(
      changes,
      subheadingNameFor
    );

    return (
      <>
        {Object.entries(changesBySubheading).map(
          ([subheadingName, changes], index) => {
            return (
              <React.Fragment key={index}>
                <PanelSubheading title={subheadingName} />
                <ChangesList
                  changes={changes}
                  renderTitle={renderTitle}
                  renderUpdateOrdering={renderUpdateOrdering}
                />
              </React.Fragment>
            );
          }
        )}
      </>
    );
  } else {
    return (
      <ChangesList
        changes={changes}
        renderTitle={renderTitle}
        renderUpdateOrdering={renderUpdateOrdering}
      />
    );
  }
};

const SectionPanelNew = ({
  section,
  id,
  title,
  linkHref,
  linkTitle,
  changes,
  renderTitle,
  groupBySubheading = false,
  groupByAdditionalSubheading = false,
  renderUpdateOrdering,
  expandAllChangesInSection,
  collapseAllChangesInSection,
}) => {
  if (changes.length > 0) {
    return (
      <SectionPanel
        section={section}
        id={id}
        title={title}
        linkHref={linkHref}
        linkTitle={linkTitle}
        expandAllChangesInSection={expandAllChangesInSection}
        collapseAllChangesInSection={collapseAllChangesInSection}
      >
        <PanelBody
          changes={changes}
          groupBySubheading={groupBySubheading}
          groupByAdditionalSubheading={groupByAdditionalSubheading}
          renderTitle={renderTitle}
          renderUpdateOrdering={renderUpdateOrdering}
        />
      </SectionPanel>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    section: ownProps.section,
    id: ownProps.id,
    title: ownProps.title,
    linkHref: ownProps.linkHref,
    linkTitle: ownProps.linkTitle,
    changes: state.revision[ownProps.section].changes,
    renderTitle: ownProps.renderTitle,
    groupBySubheading: ownProps.groupBySubheading,
    groupByAdditionalSubheading: ownProps.groupByAdditionalSubheading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      expandAllChangesInSection: actions.expandAllChangesInSection,
      collapseAllChangesInSection: actions.collapseAllChangesInSection,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionPanelNew);
