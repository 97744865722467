import { ActionTypes } from "../../actions/entity_groups_page";

export const entityGroupFormDefaultState = {
  showForm: false,
  saving: false,
  editing: false,
  entityGroupId: undefined,
  loadingWithdrawalCriteriaFields: false,
  withdrawalCriteriaFields: [],
  values: {
    name: "",
    identifier: "",
    description: "",
    entity_identifier_name: "",
    entity_identifier_format: "",
    entity_term_name: "",
  },
  errors: {
    name: undefined,
    identifier: undefined,
    description: undefined,
    entity_identifier_name: undefined,
    entity_identifier_format: undefined,
    entity_term_name: undefined,
  },
};

export const EntityGroupFormReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.ADD_ENTITY_GROUP: {
      return {
        ...state,
        showForm: true,
      };
    }
    case ActionTypes.EDIT_ENTITY_GROUP: {
      const { entityGroup } = action;
      return {
        ...state,
        showForm: true,
        editing: true,
        loadingWithdrawalCriteriaFields: true,
        entityGroupId: entityGroup.id,
        values: {
          name: entityGroup.name,
          identifier: entityGroup.identifier,
          description: entityGroup.description,
          entity_identifier_name: entityGroup.entity_identifier_name,
          entity_identifier_format: entityGroup.entity_identifier_format,
          entity_term_name: entityGroup.entity_term_name,
        },
      };
    }
    case ActionTypes.CLOSE_ENTITY_GROUP_FORM: {
      return {
        ...entityGroupFormDefaultState,
      };
    }
    case ActionTypes.ENTITY_GROUP_FORM_VALUE_CHANGE: {
      let newValues = {
        ...state.values,
        [action.fieldName]: action.value,
      };

      const newState = {
        ...state,
        values: newValues,
      };

      return newState;
    }

    case ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT:
    case ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT: {
      return { ...state, saving: true };
    }

    case ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT_FAILURE:
    case ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT_FAILURE: {
      return { ...state, saving: false, errors: action.errors };
    }

    case ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT_SUCCESS:
    case ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT_SUCCESS: {
      return { ...state, ...entityGroupFormDefaultState };
    }
  }
  return state;
};
