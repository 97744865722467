import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";

import { deleteRequest } from "../services/base_requests";
import { addClickListener } from "../services/browser_dom_support";

import { DeletionModal, NoRecordsRow } from "components/helpers";

const StudyFormDeletionPage = () => {
  const [processingRequest, setProcessingRequest] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);
  const [requestURL, setRequestURL] = useState("");
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [requestMade, setRequestMade] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [referencesPreventingDelete, setReferencesPreventingDelete] = useState(
    {}
  );
  const canMakeDeleteRequest =
    processingRequest == false && requestMade == false;
  const canCloseModal = processingRequest == false && requestMade == true;
  const onHide = processingRequest ? undefined : () => setConfirmShow(false);
  const onConfirm = () => makeDeleteRequest();
  const canCancel = showCancelButton && !processingRequest;

  const makeDeleteRequest = () => {
    setProcessingRequest(true);
    setRequestMade(true);

    deleteRequest({
      url: requestURL,
      body: {},
    }).then(async (res) => {
      let resData = await res.json();
      setProcessingRequest(false);

      if (res.status === 200) {
        setConfirmShow(false);

        const subformRow = document.querySelectorAll(
          `[data-sub-row-for="#study_form_${resData.study_form_id}"]`
        )[0];
        subformRow.remove();

        const studyForm = document.getElementById(
          `study_form_${resData.study_form_id}`
        );
        studyForm.remove();

        // Add a no records row if there are no records left
        if (document.querySelectorAll(".study_form").length === 0) {
          const noRecordRow = <NoRecordsRow colSpan={7} />;
          document.getElementById("study-forms-tbody").innerHTML =
            ReactDOMServer.renderToStaticMarkup(noRecordRow);
        }

        // Update the forms count
        window
          .findComponentCounter("study_forms")
          .trigger(window.COMPONENT_COUNT_DECREMENT);

        // Update the fields count
        window
          .findComponentCounter("form_fields")
          .trigger(
            window.COMPONENT_COUNT_UPDATE,
            resData.total_number_of_fields
          );

        // Update the validations count
        window
          .findComponentCounter("validation_rules")
          .trigger(
            window.COMPONENT_COUNT_UPDATE,
            resData.total_number_of_validations
          );
      }

      if (res.status === 422) {
        setConfirmTitle("Study form could not be deleted");
        setShowCancelButton(false);
        setHasErrors(true);
        setConfirmShow(true);
        setReferencesPreventingDelete(resData.referencesPreventingDelete);
      }
    });
  };

  const openModal = (element) => {
    setConfirmTitle(element.title);
    setConfirmMessage(element.dataset.customConfirmMessage);
    setRequestURL(element.dataset.requestUrl);
    setShowCancelButton(true);
    setHasErrors(false);
    setRequestMade(false);
    setReferencesPreventingDelete({});
    setConfirmShow(true);
  };
  addClickListener(".delete-form-button", openModal);

  return (
    <DeletionModal
      title={confirmTitle}
      message={confirmMessage}
      show={confirmShow}
      onHide={onHide}
      onConfirm={onConfirm}
      processingRequest={processingRequest}
      hasErrors={hasErrors}
      referencesPreventingDelete={referencesPreventingDelete}
      canMakeDeleteRequest={canMakeDeleteRequest}
      canCloseModal={canCloseModal}
      canCancel={canCancel}
    />
  );
};

export default StudyFormDeletionPage;
