import React from "react";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  SitesTable,
  AdditionalSitePropertiesTable,
  SiteFormScreen,
  AdditionalSitePropertiesFormScreen,
} from "components/sites_page";
import {
  SitesReducer,
  sitesReducerDefaultState,
  SiteFormReducer,
  siteFormReducerDefaultState,
  SitePropertyFormReducer,
  sitePropertyFormReducerDefaultState,
  SitePropertiesReducer,
} from "reducers/sites_page";
import {
  SitesPageMiddleware,
  SitePropertyMiddleware,
} from "middleware/sites_page";

const SitesPage = ({
  studyId,
  sites,
  additionalSiteProperties,
  sitePropertyDataTypes,
}) => {
  const store = createStore(
    combineReducers({
      siteData: SitesReducer,
      siteForm: SiteFormReducer,
      siteProperties: SitePropertiesReducer,
      sitePropertyForm: SitePropertyFormReducer,
    }),
    {
      siteData: {
        ...sitesReducerDefaultState,
        sites: sites,
        additionalSiteProperties: additionalSiteProperties,
      },
      siteForm: {
        ...siteFormReducerDefaultState,
        studyId: studyId,
        additionalSiteProperties: additionalSiteProperties,
      },
      siteProperties: {
        additionalSiteProperties: additionalSiteProperties,
      },
      sitePropertyForm: {
        ...sitePropertyFormReducerDefaultState,
        sitePropertyDataTypes: sitePropertyDataTypes,
        studyId: studyId,
      },
    },
    applyMiddleware(SitesPageMiddleware, SitePropertyMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <SiteFormScreen />
        <SitesTable />
        <AdditionalSitePropertiesTable />
        <AdditionalSitePropertiesFormScreen />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default SitesPage;
