import React from "react";

const noRecordRow = ({ colSpan }) => {
  return (
    <tr className="tr-no-record">
      <td colSpan={colSpan} className="text-center text-muted">
        No records to display
      </td>
    </tr>
  );
};

export default noRecordRow;
