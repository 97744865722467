import { processRequest, dispatchWithTimeout } from "services/base_requests";
import { createSite, updateSite, removeSite } from "services/site_requests";
import {
  siteFormActions,
  siteFormActionTypes,
  siteActionTypes,
} from "actions/sites_page";
import $ from "jquery";

const valuesToSubmit = (values) => {
  return {
    code: values.code,
    short_name: values.short_name,
    long_name: values.long_name,
    extra_code: values.extra_code,
    recruitment_start_date: values.recruitment_start_date,
    recruitment_end_date: values.recruitment_end_date,
    metadatum_entries: values.metadatum_entries,
  };
};

export const SitesPageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");

    const newSiteSuccessHandler = (successAction) => {
      return (...params) => {
        // Update the sites counter
        window
          .findComponentCounter("sites")
          .trigger(window.COMPONENT_COUNT_INCREMENT);
        successHandlerFor(successAction)(...params);
      };
    };

    switch (action.type) {
      case siteFormActionTypes.NEW_SITE_FORM_SAVE: {
        const { studyId, values } = action;

        const request = createSite(studyId, valuesToSubmit(values));

        const success = (...params) => {
          newSiteSuccessHandler(siteFormActions.newSiteFormSaveSuccess)(
            ...params
          );
        };
        const failure = failureHandlerFor(siteFormActions.siteFormSaveErrors);

        processRequest(request, success, failure);
        break;
      }

      case siteFormActionTypes.NEW_SITE_FORM_SAVE_AND_CLOSE: {
        const { studyId, values } = action;

        const request = createSite(studyId, valuesToSubmit(values));

        const success = (...params) => {
          $.flashAlert("Site has been created successfully", "alert-success");
          newSiteSuccessHandler(siteFormActions.newSiteFormSaveAndCloseSuccess)(
            ...params
          );
        };
        const failure = failureHandlerFor(siteFormActions.siteFormSaveErrors);

        processRequest(request, success, failure);
        break;
      }

      case siteFormActionTypes.EDIT_SITE_FORM_SAVE: {
        const { studyId, siteId, values } = action;

        const request = updateSite(studyId, siteId, values);
        const success = (...params) => {
          $.flashAlert("Site has been updated successfully", "alert-success");
          successHandlerFor(siteFormActions.editSiteFormSaveSuccess)(...params);
        };
        const failure = failureHandlerFor(siteFormActions.siteFormSaveErrors);

        processRequest(request, success, failure);
        break;
      }

      case siteActionTypes.REMOVE_SITE: {
        const { studyId, siteId } = action;

        const request = removeSite(studyId, siteId);

        const success = () => {
          // Update the sites counter
          window
            .findComponentCounter("sites")
            .trigger(window.COMPONENT_COUNT_DECREMENT);
          $.flashAlert("Site has been removed successfully", "alert-success");
        };

        processRequest(request, success);
        break;
      }
    }

    return next(action);
  };
