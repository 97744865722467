import React from "react";

import { Panel, Button } from "react-bootstrap";

const SectionPanel = ({
  section,
  id,
  title,
  linkHref,
  linkTitle,
  children,
  expandAllChangesInSection,
  collapseAllChangesInSection,
}) => {
  return (
    <Panel id={id} expanded={true} bsStyle="primary" onToggle={() => {}}>
      <Panel.Heading>
        <Panel.Title componentClass="h2" style={{ display: "inline" }}>
          {title}
        </Panel.Title>
        {linkHref && (
          <a href={linkHref} className="margin-left-5" title={linkTitle}>
            <i className="fa fa-external-link" />
          </a>
        )}
        <Button
          bsStyle="link"
          className="pull-right padding-top-0"
          onClick={() => expandAllChangesInSection(section)}
        >
          Expand all
        </Button>
        <Button
          bsStyle="link"
          className="pull-right padding-top-0"
          onClick={() => collapseAllChangesInSection(section)}
        >
          Collapse all
        </Button>
      </Panel.Heading>
      <Panel.Collapse>{children}</Panel.Collapse>
    </Panel>
  );
};

export default SectionPanel;
