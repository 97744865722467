import { processRequest, dispatchWithTimeout } from "services/base_requests";
import {
  createQuestionnaire,
  updateQuestionnaire,
  getAvailableFields,
} from "services/questionnaires_requests";

import {
  questionnairesActions,
  QuestionnaireActionTypes,
} from "actions/questionnaires_page";

export const QuestionnairesPageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");

    const makeGetRequestForAvailableFields = (
      selectedFormsIds,
      selectedEventsIds,
      studyId,
      entityGroupId
    ) => {
      if (selectedFormsIds.length > 0 && selectedEventsIds.length > 0) {
        const request = getAvailableFields(
          studyId,
          entityGroupId,
          selectedEventsIds,
          selectedFormsIds
        );

        const success = successHandlerFor(
          questionnairesActions.handleGetAvailableFieldsSuccess
        );
        processRequest(request, success);
      }
    };

    const meansOfDeliveryToSend = (meansOfDelivery) => {
      return meansOfDelivery
        .filter((means) => means.selected === true)
        .map((means) => {
          return {
            type: means["type"],
            condition: means["condition"],
            message_template_id: means["messageTemplateId"],
          };
        });
    };

    const valuesToSend = (values) => {
      return {
        identifier: values.identifier,
        name: values.name,
        description: values.description,
        intro_text:
          values.introPageDisplayMethod !== "not_required"
            ? values.introText
            : "",
        intro_page_display_method: values.introPageDisplayMethod,
        inclusion_criteria: values.inclusionCriteria,
        withdrawal_criteria: values.withdrawalCriteria,
        event_ids: values.eventIds,
        form_ids: values.formIds,
        means_of_initial_attempt_delivery: meansOfDeliveryToSend(
          values.meansOfInitialAttemptDelivery
        ),
        means_of_reminder_attempt_delivery: meansOfDeliveryToSend(
          values.meansOfReminderAttemptDelivery
        ),
        contact_first_name: values.contactFirstName || "",
        contact_last_name: values.contactLastName || "",
        contact_email: values.contactEmail || "",
        contact_landline_number: values.contactLandlineNumber || "",
        contact_mobile_number: values.contactMobileNumber || "",
        contact_address1: values.contactAddress1 || "",
        contact_address2: values.contactAddress2 || "",
        contact_town_city: values.contactTownCity || "",
        contact_postcode: values.contactPostcode || "",
        request_expiration_value: values.requestExpirationValue || "",
        request_expiration_unit: values.requestExpirationUnit || "",
        reminder_expiration_value: values.reminderExpirationValue || "",
        reminder_expiration_unit: values.reminderExpirationUnit || "",
        reminder_due_value: values.reminderDueValue || "",
        reminder_due_unit: values.reminderDueUnit || "",
        non_responder_value: values.nonResponderValue || "",
        non_responder_unit: values.nonResponderUnit || "",
      };
    };

    switch (action.type) {
      case QuestionnaireActionTypes.HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT: {
        const { studyId, entityGroupId, values } = action;

        const request = createQuestionnaire(
          studyId,
          entityGroupId,
          valuesToSend(values)
        );

        const success = successHandlerFor(
          questionnairesActions.handleNewQuestionnaireFormSubmitSuccess,
          "Questionnaire has been successfully created"
        );
        const failure = failureHandlerFor(
          questionnairesActions.handleQuestionnaireFormSubmitFailure
        );
        const error = failureHandlerFor(
          questionnairesActions.handleQuestionnaireFormSubmitFailure,
          "Questionnaire hass failed to save, please try again and contact support if the problem persists"
        );

        processRequest(request, success, failure, error);
        break;
      }

      case QuestionnaireActionTypes.HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT: {
        const { studyId, entityGroupId, questionnaireId, values } = action;

        const request = updateQuestionnaire(
          studyId,
          entityGroupId,
          questionnaireId,
          valuesToSend(values)
        );

        const success = successHandlerFor(
          questionnairesActions.handleEditQuestionnaireFormSubmitSuccess,
          "Questionnaire has been successfully updated"
        );
        const failure = failureHandlerFor(
          questionnairesActions.handleQuestionnaireFormSubmitFailure
        );
        const error = failureHandlerFor(
          questionnairesActions.handleQuestionnaireFormSubmitFailure,
          "Questionnaire hass failed to save, please try again and contact support if the problem persists"
        );

        processRequest(request, success, failure, error);
        break;
      }

      case QuestionnaireActionTypes.HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS: {
        // Redirect to the index page
        window.location.href = `/study/${action.newQuestionnaire.study_id}/questionnaires`;
        break;
      }

      case QuestionnaireActionTypes.HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS: {
        // Redirect to the index page
        window.location.href = `/study/${action.updatedQuestionnaire.study_id}/questionnaires`;
        break;
      }

      case QuestionnaireActionTypes.HANDLE_SELECT_EVENTS_CHANGE: {
        const { selectedFormsIds, selectedEventsIds, studyId, entityGroupId } =
          action;

        makeGetRequestForAvailableFields(
          selectedFormsIds,
          selectedEventsIds,
          studyId,
          entityGroupId
        );
        break;
      }

      case QuestionnaireActionTypes.HANDLE_SELECT_FORMS_CHANGE: {
        const { selectedFormsIds, selectedEventsIds, studyId, entityGroupId } =
          action;

        makeGetRequestForAvailableFields(
          selectedFormsIds,
          selectedEventsIds,
          studyId,
          entityGroupId
        );
        break;
      }

      case QuestionnaireActionTypes.HANDLE_OPEN_EDIT_QUESTIONNAIRE_FORM: {
        const { selectedFormsIds, selectedEventsIds, studyId, entityGroupId } =
          action;

        makeGetRequestForAvailableFields(
          selectedFormsIds,
          selectedEventsIds,
          studyId,
          entityGroupId
        );
        break;
      }
    }

    return next(action);
  };
