import React from "react";

import { ControlLabel, Table, Button, Alert } from "react-bootstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ListOption from "./ListOption";

const SiteSpecificList = ({
  siteList,
  values,
  errors,
  disabled,
  listValueChange,
  addListOption,
  removeListOption,
  reorderSiteFormListOption,
}) => {
  const listOptions = values.metadatum_entries[siteList.position]
    ? values.metadatum_entries[siteList.position].metadatum_list_options
    : [];

  return (
    <div>
      <ControlLabel>
        {siteList.required && (
          <>
            <abbr title="required">*</abbr>
            &nbsp;
          </>
        )}

        {siteList.name}
      </ControlLabel>
      <fieldset
        className="well well-sm margin-bottom-10 padding-5"
        id={`fields-for-list-${siteList.id}`}
      >
        <DragDropContext
          onDragEnd={(result) => {
            reorderSiteFormListOption(siteList.position, result);
          }}
        >
          <Table
            style={{ width: "500px" }}
            className="metadatum-field-options "
          >
            <thead>
              <tr>
                <th colSpan="2" style={{ width: "230px" }}>
                  Identifier
                </th>
                <th style={{ width: "250px" }}>Name</th>
                <th style={{ width: "20px" }}></th>
              </tr>
            </thead>
            <Droppable droppableId={`droppable_${siteList.id}`}>
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {listOptions
                    .filter((listOption) => {
                      return listOption.marked_for_destruction !== true;
                    })
                    .map((listOption, optionIndex) => {
                      return (
                        <ListOption
                          key={`${siteList.id}-${optionIndex}`}
                          siteList={siteList}
                          listOption={listOption}
                          errors={errors}
                          optionIndex={optionIndex}
                          disabled={disabled}
                          listValueChange={listValueChange}
                          removeListOption={removeListOption}
                        />
                      );
                    })}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
            <tfoot>
              <tr>
                <td colSpan={4}>
                  <Button
                    className="margin-top-10"
                    onClick={() => {
                      addListOption(siteList.position);
                    }}
                    disabled={disabled}
                    bsStyle="success"
                    bsSize="xs"
                    title="Add an option"
                  >
                    <i className="fa fa-plus  margin-right-5" />
                    Add an option
                  </Button>
                </td>
              </tr>
              {errors[`metadatum_entries[${siteList.position}].base`] && (
                <tr>
                  <td colSpan={4}>
                    <Alert bsStyle="danger" className="margin-bottom-0">
                      {
                        errors[
                          `metadatum_entries[${siteList.position}].base`
                        ][0]
                      }
                    </Alert>
                  </td>
                </tr>
              )}
            </tfoot>
          </Table>
        </DragDropContext>
      </fieldset>
    </div>
  );
};

export default SiteSpecificList;
