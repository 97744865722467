import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel } from "react-bootstrap";

import { Select } from "components/helpers";
import ConfirmationDialog from "../ConfirmationDialog";
import actions from "actions/layout_page";

const BulkActionsScreen = ({
  studyId,
  allowFieldRemoval,
  defaultFormSectionId,
  deleting,
  availableSections,
  targetSection,
  movingInProgress,
  selectedFields,
  handleBulkMove,
  handleBulkRemove,
  handleCancelBulkMove,
  handleTargetSectionSelect,
}) => {
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);
  const confirmOnHide = () => setConfirmShow(false);
  const [confirmOnConfirm, setConfirmOnConfirm] = useState(() => {});

  const confirmMove = () => {
    setConfirmOnConfirm(() => () => {
      setConfirmShow(false);
      handleBulkMove(studyId, targetSection.value, selectedFields);
    });
    setConfirmTitle("Move selected fields to the target form ?");
    setConfirmMessage(moveMessage());
    setConfirmShow(true);
  };

  const confirmDelete = () => {
    setConfirmOnConfirm(() => () => {
      setConfirmShow(false);
      handleBulkRemove(studyId, defaultFormSectionId, selectedFields);
    });
    setConfirmTitle("Remove selected fields ?");
    setConfirmMessage(deleteMessage());
    setConfirmShow(true);
  };

  const performingAction = deleting || movingInProgress;

  const deleteMessage = () => {
    return `<strong>Are you sure you want to remove ${
      selectedFields.length
    } field${selectedFields.length === 1 ? "" : "s"}?</strong>`;
  };

  const moveMessage = () => {
    return `<strong>Are you sure you want to move ${
      selectedFields.length
    } field${selectedFields.length === 1 ? "" : "s"} to ${
      targetSection ? targetSection.label : ""
    }?</strong>`;
  };

  const FieldRemovalButton = () => {
    if (allowFieldRemoval) {
      return (
        <>
          {deleting === false && (
            <button
              title="Remove all selected fields"
              className="btn btn-danger btn-block"
              disabled={performingAction ? true : false}
              onClick={() => confirmDelete()}
            >
              <i className="fa fa-trash-o margin-right-5" />
              Remove
            </button>
          )}
          {deleting === true && (
            <button className="btn btn-danger btn-block" disabled={true}>
              <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
              Removing
            </button>
          )}
          <div className="text-with-line">
            <span>OR</span>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <ConfirmationDialog
        title={confirmTitle}
        message={confirmMessage}
        shown={confirmShow}
        onHide={confirmOnHide}
        onConfirm={confirmOnConfirm}
      />
      <div
        id="bulk-actions-container"
        className={`${selectedFields.length > 0 ? "" : "hide"}`}
      >
        <Panel>
          <Panel.Heading>
            <ul className="panel-buttons">
              <li>
                <button
                  onClick={() => {
                    handleCancelBulkMove();
                  }}
                  title="Close bulk actions"
                  className="btn btn-link"
                  disabled={performingAction ? true : false}
                >
                  <i className="fa fa-times margin-right-5" />
                  Cancel
                </button>
              </li>
            </ul>
            <h3 className="panel-title">
              <i className="fa fa-magic" /> Actions For Selected
            </h3>
          </Panel.Heading>
          <Panel.Body className="panel-body-sm">
            <FieldRemovalButton />
            <Select
              options={availableSections}
              className="target-section"
              classNamePrefix="select"
              value={targetSection}
              isDisabled={performingAction ? true : false}
              onChange={(e) => {
                handleTargetSectionSelect(e);
              }}
              isSearchable
              isClearable
              placeholder="- Choose a section -"
            />
          </Panel.Body>
          <Panel.Footer>
            {movingInProgress && (
              <button className="btn btn-link btn-block" disabled={true}>
                <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                Moving...
              </button>
            )}
            {movingInProgress == false && (
              <button
                onClick={() => confirmMove()}
                disabled={targetSection === null}
                title="Move selected field to the target section"
                className="btn btn-link btn-block"
              >
                <i className="fa fa-share-square margin-right-5" />
                Move to
              </button>
            )}
          </Panel.Footer>
        </Panel>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  let studyForm = state.formLayout;

  let defaultFormSectionId;
  let availableSections = [];
  studyForm.form_sections.forEach((section) => {
    let label;
    if (section.default_form_section) {
      defaultFormSectionId = section.id;
    } else {
      label = `${section.name || "Untitled"} - ${section.identifier}`;
      availableSections.push({
        label: label,
        value: section.id,
      });
    }
  });

  const allowFieldRemoval =
    state.fieldsMove.selectedFields.filter((field) => field.default_section)
      .length == 0;

  return {
    studyId: studyForm.study_id,
    targetSection: state.fieldsMove.targetSection,
    selectedFields: state.fieldsMove.selectedFields,
    movingInProgress: state.fieldsMove.movingInProgress,
    deleting: state.fieldsMove.deleting,
    defaultFormSectionId: defaultFormSectionId,
    availableSections: availableSections,
    allowFieldRemoval: allowFieldRemoval,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleBulkMove: actions.handleBulkMove,
      handleBulkRemove: actions.handleBulkRemove,
      handleCancelBulkMove: actions.handleCancelBulkMove,
      handleTargetSectionSelect: actions.handleTargetSectionSelect,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionsScreen);
