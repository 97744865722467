import { ActionTypes } from "../../actions/layout_page";

const defaultState = {
  showForm: false,
  editing: false,
  saving: false,
  showSaveSuccessMessage: false,
  values: { name: "", identifier: "" },
  errors: {},
};

const defaultFormValues = () => {
  return {
    showForm: false,
    saving: false,
    showSaveSuccessMessage: false,
    values: { name: "", identifier: "" },
    errors: {},
  };
};

export const SectionFormReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FORM_VALUE: {
      let newState = { ...state };
      newState["values"][action.name] = action.value;
      return newState;
    }
    case ActionTypes.CLOSE_SECTION_FORM: {
      let newState = { ...state };
      newState.showForm = false;
      return newState;
    }
    case ActionTypes.FORM_SECTION_SELECTED_FOR_EDIT: {
      let newState = defaultFormValues();
      const sectionToEdit = action.formSection;
      newState.editing = true;
      newState.showForm = true;
      newState.formSectionId = sectionToEdit.id;
      newState.values = {
        name: sectionToEdit.name,
        identifier: sectionToEdit.identifier,
      };
      return newState;
    }
    case ActionTypes.SHOW_SECTION_FORM: {
      let newState = defaultFormValues();
      newState.showForm = true;
      newState.editing = false;
      return newState;
    }
    case ActionTypes.HIDE_SECTION_FORM_SUCCESS_NOTICE: {
      let newState = { ...state };
      newState.showSaveSuccessMessage = false;
      return newState;
    }
    case ActionTypes.SAVE_SECTION_FORM_SUCCESS: {
      let newState = defaultFormValues();
      newState.showForm = true;
      newState.editing = false;
      newState.showSaveSuccessMessage = true;
      return newState;
    }
    case ActionTypes.UPDATE_SECTION_FORM_SUCCESS: {
      let newState = defaultFormValues();
      return newState;
    }
    case ActionTypes.SAVE_AND_CLOSE_SECTION_FORM_SUCCESS: {
      let newState = defaultFormValues();
      return newState;
    }
    case ActionTypes.SAVE_SECTION_FORM_ERROR: {
      let newState = { ...state };
      newState.errors = action.newSection.json_errors;
      newState.saving = false;
      return newState;
    }

    case ActionTypes.UPDATE_SECTION_FORM_ERROR: {
      let newState = { ...state };
      newState.errors = action.formSection.json_errors;
      newState.saving = false;
      return newState;
    }

    case ActionTypes.SAVE_SECTION_FORM: {
      let newState = { ...state };
      newState.saving = true;
      return newState;
    }
    case ActionTypes.SAVE_AND_CLOSE_SECTION_FORM: {
      let newState = { ...state };
      newState.saving = true;
      return newState;
    }
    case ActionTypes.UPDATE_SECTION_FORM: {
      let newState = { ...state };
      newState.saving = true;
      return newState;
    }
  }

  return state;
};
