import React from "react";
import { Button } from "react-bootstrap";

const AddValidationButton = ({
  validationIdentifier,
  saving,
  hasValidation,
  selectedRuleTypeIs,
  updateFormValue,
}) => {
  const validationTypeMap = {
    presence: {
      humanReadableName: "Presence",
      faIcon: "asterisk",
    },
    range: {
      humanReadableName: "Range",
      faIcon: "sliders",
    },
    generic: {
      humanReadableName: "Generic",
      faIcon: "pencil",
    },
    equal: {
      humanReadableName: "Equal",
      faIcon: "pause rotated-90deg",
    },
    not_equal: {
      humanReadableName: "Not equal",
      faIcon: "ban",
    },
    uniqueness: {
      humanReadableName: "Uniqueness",
      faIcon: "key",
    },
    contain: {
      humanReadableName: "Contains",
      faIcon: "pause rotated-90deg",
    },
    not_contain: {
      humanReadableName: "Not contains",
      faIcon: "ban",
    },
    format_custom: {
      humanReadableName: "Custom",
      faIcon: "pencil",
    },
    format_email: {
      humanReadableName: "Email",
      faIcon: "envelope-o",
    },
    format_phone: {
      humanReadableName: "UK telephone",
      faIcon: "phone",
    },
    format_postcode: {
      humanReadableName: "UK postcode",
      faIcon: "map-marker",
    },
    format_short_postcode: {
      humanReadableName: "Short UK postcode",
      faIcon: "map-marker",
    },
    format_nhs_number: {
      humanReadableName: "NHS number",
      faIcon: "hospital-o",
    },
    form_exist: {
      humanReadableName: "Form exist",
      faIcon: "calendar-check-o",
    },
    form_not_exist: {
      humanReadableName: "Form not exist",
      faIcon: "calendar-times-o",
    },
  };

  const validationName =
    validationTypeMap[validationIdentifier].humanReadableName;
  const faIcon = validationTypeMap[validationIdentifier].faIcon;

  return (
    <Button
      bsStyle="default"
      disabled={saving}
      onClick={() =>
        updateFormValue("validation_rule_type", validationIdentifier)
      }
      className={`margin-bottom-10${
        hasValidation(validationName) ? " in-use" : ""
      }${selectedRuleTypeIs(validationIdentifier) ? " active" : ""}`}
    >
      <span>
        <i
          className={`fa fa-${
            hasValidation(validationName) ? "check" : faIcon
          } margin-right-5`}
        />
        {validationName}
      </span>
    </Button>
  );
};

export default AddValidationButton;
