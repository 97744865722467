import * as ActionTypes from "./questionnaireActionTypes";

export const handleOpenNewQuestionnaireForm = () => ({
  type: ActionTypes.HANDLE_OPEN_NEW_QUESTIONNAIRE_FORM,
});

export const handleOpenEditQuestionnaireForm = (
  studyId,
  entityGroupId,
  selectedFormsIds,
  selectedEventsIds
) => ({
  type: ActionTypes.HANDLE_OPEN_EDIT_QUESTIONNAIRE_FORM,
  studyId,
  entityGroupId,
  selectedFormsIds,
  selectedEventsIds,
});

export const handleNewQuestionnaireFormSubmit = (
  studyId,
  entityGroupId,
  values
) => ({
  type: ActionTypes.HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT,
  studyId,
  entityGroupId,
  values,
});

export const handleEditQuestionnaireFormSubmit = (
  studyId,
  entityGroupId,
  questionnaireId,
  values
) => ({
  type: ActionTypes.HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT,
  studyId,
  entityGroupId,
  questionnaireId,
  values,
});

export const handleNewQuestionnaireFormSubmitSuccess = (newQuestionnaire) => ({
  type: ActionTypes.HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS,
  newQuestionnaire,
});

export const handleEditQuestionnaireFormSubmitSuccess = (
  updatedQuestionnaire
) => ({
  type: ActionTypes.HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS,
  updatedQuestionnaire,
});

export const handleQuestionnaireFormSubmitFailure = (errors) => ({
  type: ActionTypes.HANDLE_QUESTIONNAIRE_FORM_SUBMIT_FAILURE,
  errors,
});

export const handleQuestionnaireFormValueChange = (fieldName, value) => ({
  type: ActionTypes.HANDLE_QUESTIONNAIRE_FORM_VALUE_CHANGE,
  fieldName,
  value,
});

export const handleMessageTemplateChange = (section, deliveryType, value) => ({
  type: ActionTypes.HANDLE_QUESTIONNAIRE_MEANS_OF_DELIVERY_MESSAGE_TEMPLATE_CHANGE,
  section,
  deliveryType,
  value,
});

export const handleSelectEventsChange = (
  studyId,
  entityGroupId,
  selectedEventsIds,
  selectedFormsIds
) => ({
  type: ActionTypes.HANDLE_SELECT_EVENTS_CHANGE,
  studyId,
  entityGroupId,
  selectedEventsIds,
  selectedFormsIds,
});

export const handleSelectFormsChange = (
  studyId,
  entityGroupId,
  selectedEventsIds,
  selectedFormsIds
) => ({
  type: ActionTypes.HANDLE_SELECT_FORMS_CHANGE,
  studyId,
  entityGroupId,
  selectedEventsIds,
  selectedFormsIds,
});

export const handleGetAvailableFieldsSuccess = (fields) => ({
  type: ActionTypes.HANDLE_GET_AVAILABLE_FIELDS_SUCCESS,
  inclusionCriteriaAvailableFields: fields.inclusion_criteria,
  deliveryCriteriaAvailableFields: fields.means_of_delivery,
  contactFieldAvailableFields: fields.contact_fields,
});

export const handleMeansOfDeliveryChange = (section, deliveryType) => ({
  type: ActionTypes.HANDLE_MEANS_OF_DELIVERY_CHANGE,
  section,
  deliveryType,
});

export const handleMeansOfDeliveryFieldValueChange = (
  section,
  deliveryType,
  fieldName,
  value
) => ({
  type: ActionTypes.HANDLE_MEANS_OF_DELIVERY_FIELD_VALUE_CHANGE,
  section,
  deliveryType,
  fieldName,
  value,
});
