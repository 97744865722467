import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ButtonToolbar, Button, Row, Col } from "react-bootstrap";

import {
  TextField,
  TextAreaField,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";
import AceEditor from "../AceEditor";
import actions from "actions/entity_groups_page";
import { entityGroupFormDefaultState } from "reducers/entity_groups_page";

const EntityGroupFormScreen = ({
  show,
  editing,
  studyId,
  entityGroupId,
  saving,
  values,
  errors,
  loadingWithdrawalCriteriaFields,
  withdrawalCriteriaFields,
  closeForm,
  formValueChange,
  newSubmit,
  editSubmit,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editing) {
      editSubmit(studyId, entityGroupId, values);
    } else {
      newSubmit(studyId, values);
    }
  };

  return (
    <Modal
      id="modalWindow"
      dialogClassName="modal-lg"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : closeForm}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>
            {editing ? "Edit entity group" : "New entity group"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-details">
            <Row>
              <Col md={6}>
                <TextField
                  required={true}
                  controlId="name"
                  fieldName="name"
                  label="Name"
                  value={values.name}
                  disabled={saving}
                  errors={errors.name}
                  onChange={formValueChange}
                />
              </Col>
              <Col md={6}>
                <TextField
                  required={true}
                  controlId="identifier"
                  fieldName="identifier"
                  label="Identifier"
                  pattern={IDENTIFIER_LIMIT_PATTERN}
                  title={IDENTIFIER_LIMIT_TITLE}
                  value={values.identifier}
                  disabled={saving}
                  errors={errors.identifier}
                  onChange={formValueChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <TextAreaField
                  required={false}
                  rows={3}
                  controlId="description"
                  fieldName="description"
                  label="Description"
                  value={values.description}
                  disabled={saving}
                  errors={errors.description}
                  onChange={formValueChange}
                />
              </Col>
            </Row>
          </div>
          <div className="margin-top-20 entity-identifier">
            <legend>Entity identifier</legend>
            <Row>
              <Col xs={6}>
                <TextField
                  required={true}
                  controlId="entity_identifier_name"
                  fieldName="entity_identifier_name"
                  label="Name"
                  value={values.entity_identifier_name}
                  disabled={saving}
                  errors={errors.entity_identifier_name}
                  onChange={formValueChange}
                />
              </Col>
              <Col xs={6}>
                <TextField
                  required={true}
                  controlId="entity_identifier_format"
                  fieldName="entity_identifier_format"
                  label="Format"
                  value={values.entity_identifier_format}
                  disabled={saving}
                  errors={errors.entity_identifier_format}
                  onChange={formValueChange}
                />
              </Col>
            </Row>
          </div>
          <div className="margin-top-20 entity-term">
            <legend>Entity term</legend>
            <Row>
              <Col xs={6}>
                <TextField
                  required={true}
                  controlId="entity_term_name"
                  fieldName="entity_term_name"
                  label="Name"
                  value={values.entity_term_name}
                  disabled={saving}
                  errors={errors.entity_term_name}
                  onChange={formValueChange}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  {editing ? "Updating" : "Saving"}
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  {editing ? "Update" : "Save"}
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={closeForm}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.entityGroupForm;

  return {
    studyId: relevantState.studyId,
    show: relevantState.showForm,
    editing: relevantState.editing,
    entityGroupId: relevantState.entityGroupId,
    values: relevantState.values,
    saving: relevantState.saving,
    errors: relevantState.errors,
    loadingWithdrawalCriteriaFields:
      relevantState.loadingWithdrawalCriteriaFields,
    withdrawalCriteriaFields: relevantState.withdrawalCriteriaFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      formValueChange: actions.formValueChange,
      closeForm: actions.closeForm,
      newSubmit: actions.newEntityGroupFormSubmit,
      editSubmit: actions.editEntityGroupFormSubmit,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityGroupFormScreen);
