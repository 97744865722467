import React from "react";

const FormField = ({
  id,
  identifier,
  label,
  checked,
  actionDisabled,
  isDraggable,
  handleOnChange,
  dragHandleProps,
}) => {
  return (
    <li
      className={`form_section_element field ${
        checked ? "selected-for-move" : ""
      }`}
      id={`form-section-element-${id}`}
    >
      {isDraggable && (
        <span className="sortable-handler" {...dragHandleProps}>
          <i className="fa fa-reorder" />
        </span>
      )}
      <span className="field-text">
        {label}
        <small className="text-muted">{` - ${identifier}`}</small>
      </span>
      <span className="field-select">
        <input
          className="select-row layout-select-checkbox"
          id={`checkbox-for-move-${id}`}
          type="checkbox"
          value={id}
          disabled={actionDisabled}
          title={`Select ${label}`}
          checked={checked}
          onChange={(e) => {
            handleOnChange(e);
          }}
        />
      </span>
    </li>
  );
};

export default FormField;
