import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";

const TextField = ({
  controlId,
  inputRef,
  fieldName,
  label,
  labelClassName,
  className,
  title = null,
  value,
  placeholder,
  disabled,
  errors,
  required,
  pattern,
  autoFocus,
  onChange,
  onBlur = () => {},
}) => {
  const handleOnChange = (e) => {
    onChange(fieldName, e.target.value);
  };

  return (
    <FormGroup
      controlId={controlId}
      validationState={errors ? "error" : null}
      className={`${controlId}-input`}
    >
      {label && (
        <ControlLabel className={labelClassName}>
          {required && (
            <>
              <abbr title="required">*</abbr>
              &nbsp;
            </>
          )}
          {label}
        </ControlLabel>
      )}
      <FormControl
        name={controlId}
        type="text"
        title={title || label}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={(e) => handleOnChange(e)}
        onBlur={(e) => onBlur(e)}
        pattern={pattern}
        disabled={disabled}
        data-testid={`${controlId}`}
        autoFocus={autoFocus}
        inputRef={inputRef}
      />
      {errors && (
        <HelpBlock bsClass="inline-help-block">{errors.join(", ")}</HelpBlock>
      )}
    </FormGroup>
  );
};

export const IDENTIFIER_LIMIT_PATTERN = ".{1,20}";
export const IDENTIFIER_LIMIT_TITLE = "Must not be more than 20 characters";
export const LOOKUP_OPTION_IDENTIFIER_LIMIT_PATTERN = ".{1,16}";
export const LOOKUP_OPTION_IDENTIFIER_LIMIT_TITLE =
  "Must not be more than 16 characters";
export default TextField;
