export const ADD_ENTITY_GROUP = "ADD_ENTITY_GROUP";
export const ORDER_ENTITY_GROUPS = "ORDER_ENTITY_GROUPS";
export const ORDER_ENTITY_GROUPS_FAILURE = "ORDER_ENTITY_GROUPS_FAILURE";
export const ORDER_ENTITY_GROUPS_SUCCESS = "ORDER_ENTITY_GROUPS_SUCCESS";
export const DELETE_ENTITY_GROUP = "DELETE_ENTITY_GROUP";
export const DELETE_ENTITY_GROUP_MODAL_TOGGLE =
  "DELETE_ENTITY_GROUP_MODAL_TOGGLE";
export const DELETE_ENTITY_GROUP_SUCCESS = "DELETE_ENTITY_GROUP_SUCCESS";
export const ENTITY_GROUP_FORM_VALUE_CHANGE = "ENTITY_GROUP_FORM_VALUE_CHANGE";
export const CLOSE_ENTITY_GROUP_FORM = "CLOSE_ENTITY_GROUP_FORM";
export const NEW_ENTITY_GROUP_FORM_SUBMIT = "NEW_ENTITY_GROUP_FORM_SUBMIT";
export const NEW_ENTITY_GROUP_FORM_SUBMIT_FAILURE =
  "NEW_ENTITY_GROUP_FORM_SUBMIT_FAILURE";
export const NEW_ENTITY_GROUP_FORM_SUBMIT_SUCCESS =
  "NEW_ENTITY_GROUP_FORM_SUBMIT_SUCCESS";
export const EDIT_ENTITY_GROUP_FORM_SUBMIT = "EDIT_ENTITY_GROUP_FORM_SUBMIT";
export const EDIT_ENTITY_GROUP_FORM_SUBMIT_SUCCESS =
  "EDIT_ENTITY_GROUP_FORM_SUBMIT_SUCCESS";
export const EDIT_ENTITY_GROUP_FORM_SUBMIT_FAILURE =
  "EDIT_ENTITY_GROUP_FORM_SUBMIT_FAILURE";
export const EDIT_ENTITY_GROUP = "EDIT_ENTITY_GROUP";
