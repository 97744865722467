import {
  patchRequest,
  deleteRequest,
  putRequest,
  getRequest,
  postRequest,
} from "./base_requests";

export function updateDetails(studyId, fieldId, values) {
  return patchRequest({
    url: `/study/${studyId}/fields/${fieldId}`,
    body: { form_field: { ...values } },
  });
}

export function deleteSelectedFields(studyId, fields) {
  return deleteRequest({
    url: `/study/${studyId}/fields/bulk_delete`,
    body: { bulk_delete: { fields_to_process: fields } },
  });
}

export function moveSelectedFields(
  studyId,
  target_id,
  target_type,
  source_id,
  source_type,
  fields
) {
  return putRequest({
    url: `/study/${studyId}/fields/move_to`,
    body: {
      bulk_move: {
        target_id: target_id,
        target_type: target_type,
        source_id: source_id,
        source_type: source_type,
        fields_to_process: fields,
      },
    },
  });
}

export function copySelectedFields(
  studyId,
  target_id,
  target_type,
  source_id,
  source_type,
  fields,
  nTimes
) {
  return postRequest({
    url: `/study/${studyId}/fields/bulk_copy_to`,
    body: {
      bulk_copy: {
        target_id: target_id,
        target_type: target_type,
        source_id: source_id,
        source_type: source_type,
        fields_to_process: fields,
        copy_times: nTimes,
      },
    },
  });
}

export function search(studyId, term) {
  return getRequest({
    url: `/study/${studyId}/fields/ajax_nav_filter.json`,
    params: { nav_filter: term },
  });
}

export function createField(
  studyId,
  values,
  fieldableId,
  fieldableType,
  formSectionId,
  newLookup,
  newLookupOptions
) {
  return postRequest({
    url: `/study/${studyId}/fields`,
    body: {
      form_field: {
        ...values,
        fieldable_id: fieldableId,
        fieldable_type: fieldableType,
        form_section_id: formSectionId,
        lookup_attributes: {
          ...newLookup,
          lookup_options_attributes: newLookupOptions,
        },
      },
    },
  });
}

export function getSelectDependecyOptions(field) {
  return getRequest({
    url: `/study/${field.study_id}/fields/${field.id}/dependency_fields`,
    params: {},
  });
}

export function updateType(studyId, fieldId, values) {
  return patchRequest({
    url: `/study/${studyId}/fields/${fieldId}/update_type`,
    body: { form_field: { ...values } },
  });
}

export function updateDependencies(studyId, fieldId, values) {
  return patchRequest({
    url: `/study/${studyId}/fields/${fieldId}/update_dependencies`,
    body: { form_field: { ...values } },
  });
}

export function updateSettings(studyId, fieldId, values) {
  return patchRequest({
    url: `/study/${studyId}/fields/${fieldId}/update_settings`,
    body: { form_field: { ...values } },
  });
}

export function referencedFields(studyId, fieldIds) {
  return getRequest({
    url: `/study/${studyId}/fields/referenced_fields.json`,
    params: { id: fieldIds },
  });
}
