import actions, { ActionTypes } from "actions/entity_groups_page";
import { processRequest, dispatchWithTimeout } from "services/base_requests";
import {
  orderEntityGroups,
  createEntityGroup,
  deleteEntityGroup,
  updateEntityGroup,
  getAvailableFieldsForWithdrawalCriteria,
} from "services/entity_group_requests";

export const EntityGroupPageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");

    switch (action.type) {
      case ActionTypes.ORDER_ENTITY_GROUPS: {
        const request = orderEntityGroups(
          action.orderedEntityGroupIds,
          action.studyId
        );

        const failure = failureHandlerFor(
          actions.orderEntityGroupsFailure,
          "Entity groups order could not be updated"
        );

        processRequest(request, failure);
        break;
      }

      case ActionTypes.DELETE_ENTITY_GROUP: {
        const { studyId, entityGroupId } = action;
        const request = deleteEntityGroup(studyId, entityGroupId);

        const success = successHandlerFor(
          actions.deleteEntityGroupSuccess,
          "Entity group has been deleted successfully"
        );
        const failure = failureHandlerFor(
          actions.deleteEntityGroupFailure,
          "Entity group could not be deleted"
        );

        processRequest(request, success, failure, failure);
        break;
      }
      case ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT: {
        const { studyId, values } = action;
        const request = createEntityGroup(studyId, values);

        const success = successHandlerFor(
          actions.newEntityGroupFormSubmitSuccess,
          "Entity group has been created successfully"
        );
        const failure = failureHandlerFor(
          actions.newEntityGroupFormSubmitFailure
        );

        processRequest(request, success, failure, failure);
        break;
      }
      case ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT: {
        const { studyId, entityGroupId, values } = action;
        const request = updateEntityGroup(studyId, entityGroupId, values);

        const success = successHandlerFor(
          actions.editEntityGroupFormSubmitSuccess,
          "Entity group has been updated successfully"
        );
        const failure = failureHandlerFor(
          actions.editEntityGroupFormSubmitFailure
        );

        processRequest(request, success, failure, failure);
        break;
      }
    }
    return next(action);
  };
