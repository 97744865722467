import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Panel, ButtonToolbar } from "react-bootstrap";

import {
  ButtonWithSpinner,
  TextField,
  TextAreaField,
  PanelSubheading,
  SelectField,
  FaIconLink,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";
import AceEditor from "components/AceEditor";
import { questionnairesActions } from "actions/questionnaires_page";
import { questionnaireFormDefaultState } from "reducers/questionnaires_page";
import { MeansOfDelivery } from "components/questionnaires_page";

const QuestionnaireFormScreen = ({
  events,
  messageTemplates,
  formsAvailableAtSelectedEvents,
  values,
  errors,
  saving,
  editing,
  studyId,
  entityGroupId,
  questionnaireId,
  loadingFieldsForInclusionCriteria,
  fieldsForInclusionCriteria,
  fieldsForDeliveryCriteria,
  fieldsForContactFields,
  updateFormValue,
  handleNewQuestionnaireFormSubmit,
  handleEditQuestionnaireFormSubmit,
  handleEventsChange,
  handleFormsChange,
  handleMeansOfDeliveryChange,
  handleMeansOfDeliveryFieldValueChange,
  handleMessageTemplateChange,
  handleOpenEditQuestionnaireForm,
  availableExpirationUnits,
  availableIntroPageDisplayMethods,
}) => {
  useEffect(() => {
    if (editing === true) {
      handleOpenEditQuestionnaireForm(
        studyId,
        entityGroupId,
        values.formIds,
        values.eventIds
      );
    }
  }, [
    editing,
    handleOpenEditQuestionnaireForm,
    values.formIds,
    values.eventIds,
    studyId,
    entityGroupId,
  ]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editing === true) {
      handleEditQuestionnaireFormSubmit(
        studyId,
        entityGroupId,
        questionnaireId,
        values
      );
    } else {
      handleNewQuestionnaireFormSubmit(studyId, entityGroupId, values);
    }
  };

  const handleSelectEventsChange = (selectedOptions) => {
    handleEventsChange(studyId, entityGroupId, selectedOptions, values.formIds);
  };

  const handleSelectedFormsChange = (selectedOptions) => {
    handleFormsChange(studyId, entityGroupId, values.eventIds, selectedOptions);
  };

  const eventsAndFieldsHaveBeenSelected =
    values.eventIds.length > 0 && values.formIds.length > 0;

  const selectedMeansOfInitialAttemptDelivery =
    values.meansOfInitialAttemptDelivery
      .filter((means) => means.selected === true)
      .map((means) => means.type);
  const selectedMeansOfReminderAttemptDelivery =
    values.meansOfReminderAttemptDelivery
      .filter((means) => means.selected === true)
      .map((means) => means.type);

  return (
    <form
      onSubmit={(e) => {
        handleFormSubmit(e);
      }}
    >
      <Panel bsStyle="primary" className="questionnaire-panel">
        <Panel.Heading>
          <h2 className="panel-title">
            <i className={`fa fa-list-alt margin-right-5`} />
            {editing ? "Edit Questionnaire" : "New Questionnaire"}
          </h2>
        </Panel.Heading>
        <PanelSubheading title="General Details" />
        <Panel.Body>
          <Row>
            <Col md={6}>
              <TextField
                required={true}
                controlId="name"
                fieldName="name"
                label="Name"
                value={values.name}
                disabled={saving}
                errors={errors.name}
                onChange={updateFormValue}
              />
            </Col>
            <Col md={6}>
              <TextField
                required={true}
                controlId="identifier"
                fieldName="identifier"
                label="Identifier"
                pattern={IDENTIFIER_LIMIT_PATTERN}
                title={IDENTIFIER_LIMIT_TITLE}
                value={values.identifier}
                disabled={saving}
                errors={errors.identifier}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextAreaField
                controlId="description"
                fieldName="description"
                label="Description"
                rows={3}
                value={values.description}
                disabled={saving}
                errors={errors.description}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <SelectField
                required={true}
                isMulti={true}
                fieldName="event-ids"
                placeholder="Select events"
                className="select-events"
                classNamePrefix="select-events"
                label="Events"
                options={events}
                value={values.eventIds}
                errors={errors.event_ids}
                saving={saving}
                onChange={handleSelectEventsChange}
              />
            </Col>
            {values.eventIds.length > 0 && (
              <Col md={6}>
                <SelectField
                  required={true}
                  isMulti={true}
                  fieldName="form-ids"
                  placeholder="Select forms"
                  className="select-forms"
                  classNamePrefix="select-forms"
                  label="Forms"
                  options={formsAvailableAtSelectedEvents}
                  value={values.formIds}
                  errors={errors.form_ids}
                  saving={saving}
                  onChange={handleSelectedFormsChange}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col md={6}>
              <SelectField
                fieldName="intro_page_display_method"
                label="Intro text display"
                className="select-intro_page_display_method"
                value={values.introPageDisplayMethod}
                errors={errors.intro_page_display_method}
                disabled={saving}
                onChange={(option) => {
                  return updateFormValue(
                    "introPageDisplayMethod",
                    option.value
                  );
                }}
                options={availableIntroPageDisplayMethods}
              />
            </Col>
          </Row>
          {values.introPageDisplayMethod !== "not_required" && (
            <Row>
              <Col md={12}>
                <TextAreaField
                  required={true} // This field is hidden when it is not required
                  controlId="intro-text"
                  fieldName="introText"
                  label="Intro text content"
                  value={values.introText}
                  disabled={saving}
                  errors={errors.intro_text}
                  onChange={updateFormValue}
                />
              </Col>
            </Row>
          )}
        </Panel.Body>

        {eventsAndFieldsHaveBeenSelected && (
          <>
            <PanelSubheading title="Dependencies" />
            <Panel.Body>
              <Row className="margin-bottom-15">
                <Col md={12}>
                  <label>Inclusion criteria</label>
                  <AceEditor
                    disabled={saving}
                    fieldName="inclusionCriteria"
                    value={values.inclusionCriteria}
                    errors={errors.inclusion_criteria}
                    handleUpdateValue={updateFormValue}
                    loadingFields={loadingFieldsForInclusionCriteria}
                    fieldsForDependencies={fieldsForInclusionCriteria}
                    setFocus={false}
                  />
                </Col>
              </Row>
              <Row className="margin-bottom-15">
                <Col md={12}>
                  <label>Withdrawal criteria</label>
                  <AceEditor
                    disabled={saving}
                    fieldName="withdrawalCriteria"
                    value={values.withdrawalCriteria}
                    errors={errors.withdrawal_criteria}
                    handleUpdateValue={updateFormValue}
                    loadingFields={loadingFieldsForInclusionCriteria}
                    fieldsForDependencies={fieldsForInclusionCriteria}
                    setFocus={false}
                  />
                </Col>
              </Row>
            </Panel.Body>
            <PanelSubheading title="Contact Details" />
            <Panel.Body>
              <Row>
                <Col md={6}>
                  <SelectField
                    required={true}
                    isClearable={true}
                    label="First Name"
                    fieldName="contact_first_name"
                    value={values.contactFirstName}
                    disabled={saving}
                    errors={errors.contact_first_name}
                    onChange={(option) => {
                      return updateFormValue("contactFirstName", option.value);
                    }}
                    options={fieldsForContactFields}
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    required={true}
                    isClearable={true}
                    label="Last Name"
                    fieldName="contact_last_name"
                    value={values.contactLastName}
                    disabled={saving}
                    errors={errors.contact_last_name}
                    onChange={(option) => {
                      return updateFormValue("contactLastName", option.value);
                    }}
                    options={fieldsForContactFields}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <SelectField
                    required={
                      selectedMeansOfInitialAttemptDelivery.includes(
                        "onlineEmail"
                      ) ||
                      selectedMeansOfReminderAttemptDelivery.includes(
                        "onlineEmail"
                      )
                    }
                    isClearable={true}
                    label="Email"
                    fieldName="contact_email"
                    value={values.contactEmail}
                    disabled={saving}
                    errors={errors.contact_email}
                    onChange={(option) =>
                      updateFormValue("contactEmail", option.value)
                    }
                    options={fieldsForContactFields}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <SelectField
                    required={
                      (selectedMeansOfInitialAttemptDelivery.includes(
                        "telephone"
                      ) ||
                        selectedMeansOfReminderAttemptDelivery.includes(
                          "telephone"
                        )) &&
                      values.contactMobileNumber === null
                    }
                    isClearable={true}
                    label="Landline Number"
                    fieldName="contact_landline_number"
                    value={values.contactLandlineNumber}
                    disabled={saving}
                    errors={errors.contact_landline_number}
                    onChange={(option) =>
                      updateFormValue("contactLandlineNumber", option.value)
                    }
                    options={fieldsForContactFields}
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    required={
                      selectedMeansOfInitialAttemptDelivery.includes(
                        "onlineSms"
                      ) ||
                      selectedMeansOfReminderAttemptDelivery.includes(
                        "onlineSms"
                      )
                    }
                    isClearable={true}
                    label="Mobile Number"
                    fieldName="contact_mobile_number"
                    value={values.contactMobileNumber}
                    disabled={saving}
                    errors={errors.contact_mobile_number}
                    onChange={(option) =>
                      updateFormValue("contactMobileNumber", option.value)
                    }
                    options={fieldsForContactFields}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <SelectField
                    required={
                      selectedMeansOfInitialAttemptDelivery.includes("post") ||
                      selectedMeansOfReminderAttemptDelivery.includes("post")
                    }
                    isClearable={true}
                    label="Address 1"
                    fieldName="contact_address1"
                    value={values.contactAddress1}
                    disabled={saving}
                    errors={errors.contact_address1}
                    onChange={(option) =>
                      updateFormValue("contactAddress1", option.value)
                    }
                    options={fieldsForContactFields}
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    required={false}
                    isClearable={true}
                    label="Address 2"
                    fieldName="contact_address2"
                    value={values.contactAddress2}
                    disabled={saving}
                    errors={errors.contact_address2}
                    onChange={(option) =>
                      updateFormValue("contactAddress2", option.value)
                    }
                    options={fieldsForContactFields}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <SelectField
                    required={false}
                    isClearable={true}
                    label="Town / City"
                    fieldName="contact_town_city"
                    value={values.contactTownCity}
                    disabled={saving}
                    errors={errors.contact_town_city}
                    onChange={(option) =>
                      updateFormValue("contactTownCity", option.value)
                    }
                    options={fieldsForContactFields}
                  />
                </Col>
                <Col md={6}>
                  <SelectField
                    required={
                      selectedMeansOfInitialAttemptDelivery.includes("post") ||
                      selectedMeansOfReminderAttemptDelivery.includes("post")
                    }
                    isClearable={true}
                    label="Postcode"
                    fieldName="contact_postcode"
                    value={values.contactPostcode}
                    disabled={saving}
                    errors={errors.contact_postcode}
                    onChange={(option) =>
                      updateFormValue("contactPostcode", option.value)
                    }
                    options={fieldsForContactFields}
                  />
                </Col>
              </Row>
            </Panel.Body>
            <PanelSubheading title="Initial Attempt" />
            <Panel.Body id="means-of-initial-attempt-delivery">
              <MeansOfDelivery
                section="request"
                messageTemplates={messageTemplates}
                values={values}
                errors={errors}
                saving={saving}
                meansOfDeliveryToggled={(deliveryType) => {
                  handleMeansOfDeliveryChange(
                    "meansOfInitialAttemptDelivery",
                    deliveryType
                  );
                }}
                handleMeansOfDeliveryFieldValueChange={
                  handleMeansOfDeliveryFieldValueChange
                }
                handleMessageTemplateChange={handleMessageTemplateChange}
                loadingFieldsForInclusionCriteria={
                  loadingFieldsForInclusionCriteria
                }
                fieldsForDeliveryCriteria={fieldsForDeliveryCriteria}
                updateFormValue={updateFormValue}
                availableExpirationUnits={availableExpirationUnits}
              />
            </Panel.Body>
            <PanelSubheading title="Reminder Attempt" />
            <Panel.Body id="means-of-reminder-attempt-delivery">
              <MeansOfDelivery
                section="reminder"
                messageTemplates={messageTemplates}
                values={values}
                errors={errors}
                saving={saving}
                meansOfDeliveryToggled={(deliveryType) => {
                  handleMeansOfDeliveryChange(
                    "meansOfReminderAttemptDelivery",
                    deliveryType
                  );
                }}
                handleMeansOfDeliveryFieldValueChange={
                  handleMeansOfDeliveryFieldValueChange
                }
                handleMessageTemplateChange={handleMessageTemplateChange}
                loadingFieldsForInclusionCriteria={
                  loadingFieldsForInclusionCriteria
                }
                fieldsForDeliveryCriteria={fieldsForDeliveryCriteria}
                updateFormValue={updateFormValue}
                availableExpirationUnits={availableExpirationUnits}
              />
            </Panel.Body>
          </>
        )}
        <Panel.Footer>
          <ButtonToolbar>
            <ButtonWithSpinner
              saving={saving}
              buttonText={editing ? "Update" : "Save"}
              savingText={editing ? "Updating" : "Saving"}
              faIcon="check"
              bsStyle="primary"
              type="submit"
            />
            <div className="pull-right">
              <FaIconLink
                href={`/study/${studyId}/questionnaires`}
                linkClassName="btn btn-default"
                icon="times"
                text="Cancel"
                title={`Cancel ${
                  editing ? "updating" : "adding"
                } the questionnaire`}
              />
            </div>
          </ButtonToolbar>
        </Panel.Footer>
      </Panel>
    </form>
  );
};

const mapStateToProps = (state) => {
  let questionnaireFormState = state.questionnaireForm;

  return {
    editing: questionnaireFormState.editing,
    saving: questionnaireFormState.saving,
    values: questionnaireFormState.values,
    errors: questionnaireFormState.errors,
    studyId: questionnaireFormState.studyId,
    entityGroupId: questionnaireFormState.entityGroupId,
    questionnaireId: questionnaireFormState.questionnaireId,
    events: questionnaireFormState.events,
    messageTemplates: questionnaireFormState.messageTemplates,
    formsAvailableAtSelectedEvents:
      questionnaireFormState.formsAvailableAtSelectedEvents,
    loadingFieldsForInclusionCriteria:
      questionnaireFormState.loadingFieldsForInclusionCriteria,
    fieldsForInclusionCriteria:
      questionnaireFormState.fieldsForInclusionCriteria,
    fieldsForDeliveryCriteria: questionnaireFormState.fieldsForDeliveryCriteria,
    availableExpirationUnits: questionnaireFormState.availableExpirationUnits,
    availableIntroPageDisplayMethods:
      questionnaireFormState.availableIntroPageDisplayMethods,
    fieldsForContactFields: questionnaireFormState.fieldsForContactFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleClose: questionnairesActions.handleCloseQuestionnaireForm,
      handleNewQuestionnaireFormSubmit:
        questionnairesActions.handleNewQuestionnaireFormSubmit,
      handleEditQuestionnaireFormSubmit:
        questionnairesActions.handleEditQuestionnaireFormSubmit,
      updateFormValue: questionnairesActions.handleQuestionnaireFormValueChange,
      handleEventsChange: questionnairesActions.handleSelectEventsChange,
      handleFormsChange: questionnairesActions.handleSelectFormsChange,
      handleMeansOfDeliveryChange:
        questionnairesActions.handleMeansOfDeliveryChange,
      handleMeansOfDeliveryFieldValueChange:
        questionnairesActions.handleMeansOfDeliveryFieldValueChange,
      handleMessageTemplateChange:
        questionnairesActions.handleMessageTemplateChange,
      handleOpenEditQuestionnaireForm:
        questionnairesActions.handleOpenEditQuestionnaireForm,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireFormScreen);
