export const addClickListener = (selector, clickHandler) => {
  document.addEventListener(
    "click",
    function (event) {
      if (event.target && event.target.matches(selector)) {
        event.preventDefault();
        clickHandler(event.target);
      } else if (
        event.target.parentElement &&
        event.target.parentElement.matches(selector)
      ) {
        event.preventDefault();
        clickHandler(event.target.parentElement);
      }
    },
    false
  );
};
