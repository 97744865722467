export const COMMIT_REVISION = "COMMIT_REVISION";
export const COMMIT_SUCCESS = "COMMIT_SUCCESS";
export const COMMIT_FAILURE = "COMMIT_FAILURE";
export const UPDATE_FORM = "UPDATE_FORM";
export const SAVE_SUCCESS = "SAVE_SUCCESS";
export const SAVE_FAILURE = "SAVE_FAILURE";
export const TOGGLE_EXPAND_SECTION = "TOGGLE_EXPAND_SECTION";
export const EXPAND_ALL_CHANGES_IN_SECTION = "EXPAND_ALL_CHANGES_IN_SECTION";
export const COLLAPSE_ALL_CHANGES_IN_SECTION =
  "COLLAPSE_ALL_CHANGES_IN_SECTION";
export const TOGGLE_EXPAND_CHANGE = "TOGGLE_EXPAND_CHANGE";
