export const OPEN_NEW_SITE_PROPERTY_FORM = "OPEN_NEW_SITE_PROPERTY_FORM";
export const OPEN_EDIT_SITE_PROPERTY_FORM = "OPEN_EDIT_SITE_PROPERTY_FORM";
export const CLOSE_SITE_PROPERTY_FORM = "CLOSE_SITE_PROPERTY_FORM";
export const SITE_PROPERTY_FORM_VALUE_CHANGE =
  "SITE_PROPERTY_FORM_VALUE_CHANGE";
export const SITE_PROPERTY_FORM_OPTION_VALUE_CHANGE =
  "SITE_PROPERTY_FORM_OPTION_VALUE_CHANGE";
export const ADD_SITE_PROPERTY_SINGLE_VALUE_OPTION =
  "ADD_SITE_PROPERTY_SINGLE_VALUE_OPTION";
export const REMOVE_SITE_PROPERTY_SINGLE_VALUE_OPTION =
  "REMOVE_SITE_PROPERTY_SINGLE_VALUE_OPTION";
export const NEW_SITE_PROPERTY_FORM_SAVE = "NEW_SITE_PROPERTY_FORM_SAVE";
export const EDIT_SITE_PROPERTY_FORM_SAVE = "EDIT_SITE_PROPERTY_FORM_SAVE";
export const NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE =
  "NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE";
export const NEW_SITE_PROPERTY_FORM_SAVE_SUCCESS =
  "NEW_SITE_PROPERTY_FORM_SAVE_SUCCESS";
export const NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE_SUCCESS =
  "NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE_SUCCESS";
export const REMOVE_SITE_PROPERTY = "REMOVE_SITE_PROPERTY";
export const EDIT_SITE_PROPERTY_FORM_SAVE_SUCCESS =
  "EDIT_SITE_PROPERTY_FORM_SAVE_SUCCESS";
export const SITE_PROPERTY_FORM_ERROR = "SITE_PROPERTY_FORM_ERROR";
