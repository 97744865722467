import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";

const TextAreaField = ({
  controlId,
  fieldName,
  labelClassName,
  label,
  value,
  disabled,
  errors,
  required,
  onChange,
  rows = 5,
  cols = 10,
}) => {
  return (
    <FormGroup
      controlId={controlId}
      className={`${controlId}-input`}
      validationState={errors ? "error" : null}
    >
      <ControlLabel className={labelClassName}>
        {required && (
          <>
            <abbr title="required">*</abbr>
            &nbsp;
          </>
        )}
        {label}
      </ControlLabel>
      <FormControl
        componentClass="textarea"
        rows={rows}
        cols={cols}
        value={value}
        title={label}
        onChange={(e) => onChange(fieldName, e.target.value)}
        disabled={disabled}
      />
      {errors && (
        <HelpBlock bsClass="inline-help-block">{errors.join(", ")}</HelpBlock>
      )}
    </FormGroup>
  );
};

export default TextAreaField;
