import { ActionTypes } from "../../actions/revisions_page";
import { baseFormReducer } from "reducers/form.base.reducer";

export const RevisionFormReducer = (
  state = { values: {}, errors: {}, saving: false, committing: false },
  action
) => {
  state = baseFormReducer(state, action);

  switch (action.type) {
    case ActionTypes.SAVE_FAILURE:
    case ActionTypes.COMMIT_FAILURE: {
      return {
        ...state,
        errors: action.errors,
        committing: false,
        saving: false,
      };
    }
    case ActionTypes.UPDATE_FORM:
    case ActionTypes.COMMIT_REVISION: {
      return {
        ...state,
        committing: true,
        saving: true,
      };
    }
    case ActionTypes.SAVE_SUCCESS:
    case ActionTypes.COMMIT_SUCCESS: {
      return {
        ...state,
        committing: false,
        saving: false,
      };
    }
    default:
      return state;
  }
};
