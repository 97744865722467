import React from "react";
import { Alert } from "react-bootstrap";

import { referencesPreventingAction } from "services/references_preventing_action";

const ReferencesWarning = ({
  referencedFields,
  remainingFields,
  referenceAction,
}) => {
  return (
    <Alert
      bsStyle={remainingFields > 0 ? "warning" : "danger"}
      className="well-sm"
    >
      <p className="margin-bottom-10">
        <strong>{`${referencedFields.length} of the selected fields ${
          referencedFields.length === 1 ? "is" : "are"
        } referenced elsewhere so cannot be ${referenceAction}.`}</strong>
      </p>
      {referencesPreventingAction({
        fieldsPreventingDelete: referencedFields,
        questionnairesPreventingDelete: [],
      })}
    </Alert>
  );
};

export default ReferencesWarning;
