import { ActionTypes } from "../../actions/layout_page";

export const FormLayoutReducer = (state = [], action) => {
  const moveElement = (params) => {
    let {
      elementType,
      sourcePosition,
      destinationPosition,
      sourceId,
      destinationId,
      subformSectionId,
    } = params;
    let newState = { ...state };

    switch (elementType) {
      // sections moving in the form
      case "FormSection": {
        let formSections = newState["form_sections"];
        const sectionToMove = formSections[sourcePosition];

        formSections.splice(sourcePosition, 1);
        formSections.splice(destinationPosition, 0, sectionToMove);
        break;
      }
      // section elements moving within sections
      case "SectionField": {
        let sourceSection = newState["form_sections"].filter(
          (section) => section.id == sourceId
        )[0];
        let destinationSection = newState["form_sections"].filter(
          (section) => section.id == destinationId
        )[0];

        let sourceSectionElements = sourceSection["sectionElements"];
        let destinationSectionElements = destinationSection["sectionElements"];

        const elementToMove = sourceSectionElements[sourcePosition];
        if (sourceId != destinationId) {
          sourceSectionElements.splice(sourcePosition, 1);
          destinationSectionElements.splice(
            destinationPosition,
            0,
            elementToMove
          );
        } else {
          sourceSectionElements.splice(sourcePosition, 1);
          sourceSectionElements.splice(destinationPosition, 0, elementToMove);
        }

        break;
      }
      // subform elements moving within the subform
      case "SubformField": {
        let sourceSection = newState.form_sections.filter(
          (section) => section.id == subformSectionId
        )[0];
        let sourceSubform = sourceSection.sectionElements.filter(
          (element) => element.id == sourceId
        )[0];
        let sourceSubformElements = sourceSubform.element.subformElements;

        const elementToMove = sourceSubformElements[sourcePosition];
        sourceSubformElements.splice(sourcePosition, 1);
        sourceSubformElements.splice(destinationPosition, 0, elementToMove);
        break;
      }
      default: {
        $.flashAlert("Invalid element move requested", "alert-danger");
      }
    }

    return newState;
  };

  switch (action.type) {
    case ActionTypes.MOVE_ELEMENT: {
      return moveElement(action.params);
    }
    case ActionTypes.MOVE_ELEMENT_FAILURE: {
      return moveElement(action.params);
    }
    case ActionTypes.REMOVE_SECTION: {
      let newState = { ...state };
      newState.sectionToDeleteId = action.sectionId;

      return newState;
    }
    case ActionTypes.REMOVE_SECTION_SUCCESS: {
      let newState = action.studyForm;

      return newState;
    }
    case ActionTypes.SAVE_SECTION_FORM_SUCCESS: {
      let newState = { ...state };
      newState["form_sections"] = [
        ...newState["form_sections"],
        action.newSection,
      ];

      return newState;
    }
    case ActionTypes.UPDATE_SECTION_FORM_SUCCESS: {
      let newState = { ...state };
      const updatedSection = action.formSection;
      const updatedFormSections = newState["form_sections"].map((section) => {
        return section.id == updatedSection.id ? updatedSection : section;
      });
      newState["form_sections"] = updatedFormSections;

      return newState;
    }
    case ActionTypes.SAVE_AND_CLOSE_SECTION_FORM_SUCCESS: {
      let newState = { ...state };
      newState["form_sections"] = [
        ...newState["form_sections"],
        action.newSection,
      ];

      return newState;
    }
    case ActionTypes.BULK_MOVE_SUCCESS: {
      let newState = action.studyForm;

      return newState;
    }
  }

  return state;
};
