import ChangeObject from "./ChangeObject";

class EventChange extends ChangeObject {
  constructor(change) {
    // TODO: Generalise these helpers for use in all of the Change classes
    const originalAttributes = change.original_attributes;
    const newAttributes = change.new_attributes;
    const attributeChanged = (identifier) => {
      return (
        originalAttributes?.[identifier]?.changed ||
        newAttributes?.[identifier]?.changed
      );
    };

    const identifier = newAttributes
      ? newAttributes.identifier.value
      : originalAttributes.identifier.value;

    const unitAfterScheduledEvent = (attributes) => {
      if (attributes.unit_after_scheduled_event) {
        switch (attributes.unit_after_scheduled_event.value) {
          case 1:
            return "day(s)";
          case 2:
            return "week(s)";
          case 3:
            return "month(s)";
          case 4:
            return "year(s)";
        }
      }
    };

    const scheduledValueFor = (attributes) => {
      if (attributes === null || attributes === undefined) {
        return undefined;
      }

      return attributes.scheduled.value
        ? `${
            attributes.number_after_scheduled_event.value
          } ${unitAfterScheduledEvent(attributes)} after ${
            attributes.scheduled_event_reference.value
          }`
        : "";
    };

    const normalWindowMessage = (attributes) => {
      if (attributes === null || attributes === undefined) {
        return undefined;
      }

      if (attributes.normal_range_window.value) {
        if (
          attributes.days_before_scheduled_date_normal.value &&
          attributes.days_after_scheduled_date_normal.value
        ) {
          return `${attributes.days_before_scheduled_date_normal.value} days before, and ${attributes.days_after_scheduled_date_normal.value} days after`;
        } else if (attributes.days_before_scheduled_date_normal.value) {
          return `${attributes.days_before_scheduled_date_normal.value} days before`;
        } else if (attributes.days_after_scheduled_date_normal.value) {
          return `${attributes.days_after_scheduled_date_normal.value} days after`;
        }
      } else {
        return "";
      }
    };

    const extendedWindowMessage = (attributes) => {
      if (attributes === null || attributes === undefined) {
        return undefined;
      }

      if (attributes.extended_range_window.value) {
        if (
          attributes.days_before_scheduled_date_extended.value &&
          attributes.days_after_scheduled_date_extended.value
        ) {
          return `${attributes.days_before_scheduled_date_extended.value} days before, and ${attributes.days_after_scheduled_date_extended.value} days after`;
        } else if (attributes.days_before_scheduled_date_extended.value) {
          return `${attributes.days_before_scheduled_date_extended.value} days before`;
        } else if (attributes.days_after_scheduled_date_extended.value) {
          return `${attributes.days_after_scheduled_date_extended.value} days after`;
        }
      } else {
        return "";
      }
    };

    const eventDateFormValueFor = (attributes) => {
      if (attributes === null || attributes === undefined) {
        return undefined;
      }

      return attributes.event_date_form.id.value
        ? `${attributes.event_date_form.name.value} - ${attributes.event_date_form.identifier.value}`
        : "";
    };

    const eventDateFieldValueFor = (attributes) => {
      if (attributes === null || attributes === undefined) {
        return undefined;
      }

      return attributes.event_date_field.id.value
        ? `${attributes.event_date_field.label.value} - ${attributes.event_date_field.identifier.value}`
        : "";
    };

    const attributeData = [
      ["Identifier", "identifier"],
      ["Name", "name"],
      ["Type", "human_type_name"],
      ["Scheduled?", "scheduled", { attributeType: "boolean" }],
      [
        "Scheduled for",
        undefined,
        {
          originalValue: scheduledValueFor(originalAttributes),
          newValue: scheduledValueFor(newAttributes),
          changed:
            attributeChanged("number_after_scheduled_event") ||
            attributeChanged("unit_after_scheduled_event") ||
            attributeChanged("scheduled_event_reference"),
        },
      ],
      [
        "Normal window",
        undefined,
        {
          originalValue: normalWindowMessage(originalAttributes),
          newValue: normalWindowMessage(newAttributes),
          changed:
            attributeChanged("days_before_scheduled_date_normal") ||
            attributeChanged("days_after_scheduled_date_normal"),
        },
      ],
      [
        "Extended window",
        undefined,
        {
          originalValue: extendedWindowMessage(originalAttributes),
          newValue: extendedWindowMessage(newAttributes),
          changed:
            attributeChanged("days_before_scheduled_date_extended") ||
            attributeChanged("days_after_scheduled_date_extended"),
        },
      ],
      [
        "Event date form",
        undefined,
        {
          originalValue: eventDateFormValueFor(originalAttributes),
          newValue: eventDateFormValueFor(newAttributes),
          changed:
            originalAttributes?.event_date_form?.id?.changed ||
            newAttributes?.event_date_form?.id?.changed,
        },
      ],
      [
        "Event date field",
        undefined,
        {
          originalValue: eventDateFieldValueFor(originalAttributes),
          newValue: eventDateFieldValueFor(newAttributes),
          changed:
            originalAttributes?.event_date_field?.id?.changed ||
            newAttributes?.event_date_field?.id?.changed,
        },
      ],
      [
        "Summary fields",
        "summary_fields",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `${associatedObject.name.value} - ${associatedObject.form_field.identifier.value}`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject) =>
                  `${associatedObject.name.value} - ${associatedObject.form_field.identifier.value}`
              )
              .join(", ");
          },
        },
      ],
      ["Dependencies", "parsed_dependencies"],
      [
        "Form bindings",
        "form_bindings",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `<strong>${associatedObject.study_form.name.value} - ${
              associatedObject.study_form.identifier.value
            }</strong>${
              associatedObject.parsed_dependencies.value
                ? `<br /><small class="margin-left-15">${associatedObject.parsed_dependencies.value}</small>`
                : ""
            }`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject) =>
                  `${associatedObject.study_form.name.value} - ${
                    associatedObject.study_form.identifier.value
                  }${
                    associatedObject.parsed_dependencies.value
                      ? ` - ${associatedObject.parsed_dependencies.value}`
                      : ""
                  }`
              )
              .join(", ");
          },
          groupOnCreateAndDestroy: false,
        },
      ],
    ];

    super(change, "Event", identifier, attributeData);
  }
}

export default EventChange;
