import React, { useState, useEffect } from "react";

import { deleteRequest } from "../services/base_requests";
import { addClickListener } from "../services/browser_dom_support";

import DeletionModal from "../components/helpers/DeletionModal";

const FormBindingDeletionPage = () => {
  const [processingRequest, setProcessingRequest] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);
  const [requestURL, setRequestURL] = useState("");
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [requestMade, setRequestMade] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [referencesPreventingDelete, setReferencesPreventingDelete] = useState(
    []
  );
  const canMakeDeleteRequest =
    processingRequest == false && requestMade == false;
  const canCloseModal = processingRequest == false && requestMade == true;
  const onHide = processingRequest ? undefined : () => setConfirmShow(false);
  const onConfirm = () => makeDeleteRequest();
  const canCancel = showCancelButton && !processingRequest;

  useEffect(() => {
    addClickListener(".remove-form-binding-button", openModal);
  }, []);

  const makeDeleteRequest = () => {
    setProcessingRequest(true);
    setRequestMade(true);

    deleteRequest({
      url: requestURL,
      body: {},
    }).then(async (res) => {
      let resData = await res.json();
      setProcessingRequest(false);

      if (res.status === 200) {
        setConfirmShow(false);
        const formBindingCountForEvent = document.getElementById(
          `form_bindings-count-${resData.event_id}`
        );

        if (resData.form_binding_count == "0 forms") {
          window.location.reload();
        } else {
          formBindingCountForEvent.innerHTML = resData.form_binding_count;

          const eventSummaryFields = document.getElementById(
            `summary-fields-overview-${resData.event_id}`
          );
          if (eventSummaryFields) {
            eventSummaryFields.innerHTML = resData.summary_fields_overview;
          }

          const formBinding = document.getElementById(
            `form_binding_${resData.form_binding_id}`
          );
          formBinding.remove();
        }
      }

      if (res.status === 422) {
        setConfirmTitle("Form binding could not be removed");
        setShowCancelButton(false);
        setHasErrors(true);
        setConfirmShow(true);
        setReferencesPreventingDelete(resData.referencesPreventingDelete);
      }
    });
  };

  const openModal = (element) => {
    setConfirmTitle(element.title);
    setConfirmMessage(element.dataset.customConfirmMessage);
    setRequestURL(element.dataset.requestUrl);
    setShowCancelButton(true);
    setHasErrors(false);
    setRequestMade(false);
    setReferencesPreventingDelete({});
    setConfirmShow(true);
  };

  return (
    <DeletionModal
      title={confirmTitle}
      message={confirmMessage}
      show={confirmShow}
      onHide={onHide}
      onConfirm={onConfirm}
      processingRequest={processingRequest}
      hasErrors={hasErrors}
      referencesPreventingDelete={referencesPreventingDelete}
      canMakeDeleteRequest={canMakeDeleteRequest}
      canCloseModal={canCloseModal}
      canCancel={canCancel}
    />
  );
};

export default FormBindingDeletionPage;
