import React from "react";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import { EntityGroupPageMiddleware } from "middleware/entity_groups_page";

import {
  EntityGroupFormReducer,
  EntityGroupTableReducer,
  entityGroupFormDefaultState,
} from "reducers/entity_groups_page";

import {
  EntityGroupTableScreen,
  EntityGroupFormScreen,
} from "components/entity_groups_page";

const EntityGroupPage = ({ studyId, entityGroups }) => {
  const store = createStore(
    combineReducers({
      entityGroupForm: EntityGroupFormReducer,
      entityGroupTable: EntityGroupTableReducer,
    }),
    {
      entityGroupForm: {
        ...entityGroupFormDefaultState,
        studyId: studyId,
      },
      entityGroupTable: {
        studyId: studyId,
        entityGroups: entityGroups,
        processingRequest: false,
        entityGroupToDelete: undefined,
        entityGroupDeleteErrors: undefined,
      },
    },
    applyMiddleware(EntityGroupPageMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <EntityGroupTableScreen />
        <EntityGroupFormScreen />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default EntityGroupPage;
