import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  QuestionnairesTableScreen,
  MessageTemplateTableScreen,
  MessageTemplateFormScreen,
} from "components/questionnaires_page";
import {
  MessageTemplateTable,
  MessageTemplateForm,
  QuestionnaireTableReducer,
  messageTemplateFormDefaultState,
  messageTemplateTableDefaultState,
  questionnaireTableDefaultState,
} from "reducers/questionnaires_page";
import { QuestionnairesIndexPageMiddleware } from "middleware/questionnaires_page";

const QuestionnairesPage = ({
  studyId,
  questionnaires,
  messageTemplates,
  entityGroups,
}) => {
  const QuestionnairesStore = createStore(
    combineReducers({
      messageTemplateForm: MessageTemplateForm,
      messageTemplateTable: MessageTemplateTable,
      questionnaireTable: QuestionnaireTableReducer,
    }),
    {
      messageTemplateForm: {
        ...messageTemplateFormDefaultState,
        studyId: studyId,
      },
      messageTemplateTable: {
        ...messageTemplateTableDefaultState,
        studyId: studyId,
        messageTemplates: messageTemplates,
      },
      questionnaireTable: {
        ...questionnaireTableDefaultState,
        studyId: studyId,
        entityGroups: entityGroups,
        questionnaires: questionnaires,
      },
    },
    applyMiddleware(QuestionnairesIndexPageMiddleware)
  );

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Provider store={QuestionnairesStore}>
        <QuestionnairesTableScreen />
        <MessageTemplateTableScreen />
        <MessageTemplateFormScreen />
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default QuestionnairesPage;
