import { processRequest, dispatchWithTimeout } from "services/base_requests";
import {
  createSiteMetadatum,
  removeSiteMetadatum,
  updateSiteMetadatum,
} from "services/site_metadatum_requests";
import {
  sitePropertyActions,
  sitePropertyActionTypes,
} from "actions/sites_page";
import $ from "jquery";

export const SitePropertyMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");

    switch (action.type) {
      case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE: {
        const { studyId, values } = action;

        const request = createSiteMetadatum(studyId, values);

        const success = (...params) => {
          successHandlerFor(sitePropertyActions.newSitePropertyFormSaveSuccess)(
            ...params
          );
        };
        const failure = failureHandlerFor(
          sitePropertyActions.sitePropertyFormSaveErrors
        );

        processRequest(request, success, failure);
        break;
      }

      case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE: {
        const { studyId, values } = action;

        const request = createSiteMetadatum(studyId, values);

        const success = (...params) => {
          successHandlerFor(
            sitePropertyActions.newSitePropertyFormSaveAndCloseSuccess
          )(...params);
          $.flashAlert(
            "Site property has been created successfully",
            "alert-success"
          );
        };
        const failure = failureHandlerFor(
          sitePropertyActions.sitePropertyFormSaveErrors
        );

        processRequest(request, success, failure);
        break;
      }

      case sitePropertyActionTypes.REMOVE_SITE_PROPERTY: {
        const { studyId, sitePropertyId } = action;

        const request = removeSiteMetadatum(studyId, sitePropertyId);

        const success = () => {
          $.flashAlert(
            "Site property has been removed successfully",
            "alert-success"
          );
        };

        processRequest(request, success);
        break;
      }

      case sitePropertyActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE: {
        const { studyId, sitePropertyId, values } = action;

        const request = updateSiteMetadatum(studyId, sitePropertyId, values);

        const success = (...params) => {
          successHandlerFor(
            sitePropertyActions.editSitePropertyFormSaveSuccess
          )(...params);
          $.flashAlert(
            "Site property has been updated successfully",
            "alert-success"
          );
        };
        const failure = failureHandlerFor(
          sitePropertyActions.sitePropertyFormSaveErrors
        );

        processRequest(request, success, failure);
        break;
      }
    }

    return next(action);
  };
