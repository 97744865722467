import * as ActionTypes from "./action_types";

export const addEntityGroup = () => ({
  type: ActionTypes.ADD_ENTITY_GROUP,
});

export const orderEntityGroups = (studyId, orderedEntityGroupIds) => ({
  type: ActionTypes.ORDER_ENTITY_GROUPS,
  studyId,
  orderedEntityGroupIds,
});

export const orderEntityGroupsFailure = () => ({
  type: ActionTypes.ORDER_ENTITY_GROUPS_FAILURE,
});

export const deleteEntityGroupModalToggle = (entityGroup) => ({
  type: ActionTypes.DELETE_ENTITY_GROUP_MODAL_TOGGLE,
  entityGroup,
});

export const deleteEntityGroup = (studyId, entityGroupId) => ({
  type: ActionTypes.DELETE_ENTITY_GROUP,
  studyId,
  entityGroupId,
});

export const deleteEntityGroupSuccess = (entityGroupId) => ({
  type: ActionTypes.DELETE_ENTITY_GROUP_SUCCESS,
  entityGroupId,
});

export const formValueChange = (fieldName, value) => ({
  type: ActionTypes.ENTITY_GROUP_FORM_VALUE_CHANGE,
  fieldName,
  value,
});

export const closeForm = () => ({
  type: ActionTypes.CLOSE_ENTITY_GROUP_FORM,
});

export const newEntityGroupFormSubmit = (studyId, values) => ({
  type: ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT,
  studyId,
  values,
});

export const editEntityGroupFormSubmit = (studyId, entityGroupId, values) => ({
  type: ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT,
  studyId,
  entityGroupId,
  values,
});

export const editEntityGroup = (studyId, entityGroup) => ({
  type: ActionTypes.EDIT_ENTITY_GROUP,
  studyId,
  entityGroup,
});

export const newEntityGroupFormSubmitSuccess = (entityGroup) => ({
  type: ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT_SUCCESS,
  entityGroup,
});

export const newEntityGroupFormSubmitFailure = (errors) => ({
  type: ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT_FAILURE,
  errors,
});

export const editEntityGroupFormSubmitSuccess = (entityGroup) => ({
  type: ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT_SUCCESS,
  entityGroup,
});

export const editEntityGroupFormSubmitFailure = (errors) => ({
  type: ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT_FAILURE,
  errors,
});
