import React from "react";
import { Panel, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import FormSectionLayout from "components/layout_page/FormSectionLayout";
import DraggableFormSectionElement from "components/layout_page/DraggableFormSectionElement";
import actions from "actions/layout_page";

const LayoutScreen = ({
  study_form,
  handleMoveElement,
  handleSectionFormShow,
  handleDragStart,
}) => {
  const onDragEnd = (result) => {
    // If the dragged item has not moved, do nothing
    if (
      !result.destination ||
      (result.source.droppableId === result.destination.droppableId &&
        result.source.index === result.destination.index)
    ) {
      return;
    }

    // Identify the correct element type for the dragged element
    let elementType = result.type.split("-");

    let subformSectionId = undefined;
    if (elementType[0] === "subform") {
      subformSectionId = elementType[3];
      elementType = "SubformField";
    } else {
      elementType = elementType[0];
    }

    // Extract the detail from the droppable ID
    let sourceDetails = result.source.droppableId.split("_");
    let destinationDetails = result.destination.droppableId.split("_");

    // Construct parameters and handle the move event
    const params = {
      studyFormId: study_form.id,
      elementId: result.draggableId.replace(`${result.type}_`, ""),
      elementType: elementType,
      subformSectionId: subformSectionId,
      sourceId: sourceDetails[3],
      sourcePosition: result.source.index,
      destinationId: destinationDetails[3],
      destinationPosition: result.destination.index,
    };
    handleMoveElement(params);
  };

  const formSections = study_form.form_sections;
  const defaultFormSectionIdx = formSections.findIndex(
    (section) => section.default_form_section
  );
  const defaultFormSection = formSections[defaultFormSectionIdx];

  const DefaultFormSection = () => {
    if (defaultFormSection.sectionElements.length > 0) {
      return (
        <DragDropContext>
          <FormSectionLayout
            key={defaultFormSection.id}
            formSection={defaultFormSection}
            studyId={study_form.study_id}
            sectionClass={`bg-warning`}
          />
        </DragDropContext>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Panel bsStyle="primary" id={`panel-for-${study_form.id}`}>
      <Panel.Heading className="panel-heading-sticky">
        <ul className="panel-buttons">
          <li>
            <button
              onClick={() => {
                handleSectionFormShow();
              }}
              title={`Add a new section to ${study_form.name}`}
              className="btn btn-link"
            >
              <i className="fa fa-plus margin-right-5" />
              Add Section
            </button>
          </li>
        </ul>
        <Panel.Title componentClass="h3">
          {study_form.name}
          <small className="no-transform"> - {study_form.identifier}</small>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body className="padding-5">
        <ul className="list-unstyled margin-bottom-0 form-layout-container">
          <DefaultFormSection />
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={() => {
              handleDragStart();
            }}
          >
            <Droppable
              droppableId={`form_drop_area_${study_form.id}`}
              type="FormSection"
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {formSections.map((formSection, index) => {
                    if (!formSection.default_form_section) {
                      return (
                        <DraggableFormSectionElement
                          key={formSection.id}
                          index={index}
                          element={formSection}
                          type="FormSection"
                        >
                          <FormSectionLayout
                            key={formSection.id}
                            formSection={formSection}
                            studyId={study_form.study_id}
                            sectionClass={
                              study_form.form_sections.length == index + 1
                                ? "last-section"
                                : ""
                            }
                          />
                        </DraggableFormSectionElement>
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ul>
      </Panel.Body>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  return {
    study_form: state.formLayout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleMoveElement: actions.handleMoveElement,
      handleSectionFormShow: actions.handleSectionFormShow,
      handleDragStart: actions.handleDragStart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutScreen);
