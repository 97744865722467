import { sitePropertyActionTypes } from "actions/sites_page";

export const sitePropertiesReducerDefaultState = [];

export const SitePropertiesReducer = (
  state = sitePropertiesReducerDefaultState,
  action
) => {
  switch (action.type) {
    case sitePropertyActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE_SUCCESS:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE_SUCCESS:
    case sitePropertyActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_SUCCESS: {
      const newState = {
        ...state,
        additionalSiteProperties: action.additionalSiteProperties,
      };

      return newState;
    }

    case sitePropertyActionTypes.REMOVE_SITE_PROPERTY: {
      const newState = {
        ...state,
        additionalSiteProperties: state.additionalSiteProperties.filter(
          (siteProperty) => siteProperty.id != action.sitePropertyId
        ),
      };
      return newState;
    }
  }

  return state;
};
