import React from "react";
import { FormGroup, ControlLabel, HelpBlock } from "react-bootstrap";

import Select from "./Select";

const SelectField = ({
  fieldName,
  label,
  labelClassName,
  value,
  options,
  onChange,
  errors,
  placeholder,
  isMulti,
  isClearable,
  required,
  disabled,
  className,
  classNamePrefix,
}) => {
  const handleOnChange = (selection) => {
    if (isMulti) {
      const selectedOptions = selection
        ? selection.map((option) => option.value)
        : [];
      return onChange(selectedOptions);
    } else {
      if (selection === null) {
        return onChange({ label: "", value: null });
      } else {
        return onChange(selection);
      }
    }
  };

  const processedValue = value
    ? isMulti
      ? options.filter((option) => value.includes(option.value))
      : options.filter((option) => option.value === value)[0]
    : null;

  const controlId = `select-field-${fieldName}`;

  return (
    <FormGroup
      controlId={controlId}
      data-testid={`${controlId}`}
      validationState={errors ? "error" : null}
      className={`${controlId}-group`}
    >
      <ControlLabel className={labelClassName}>
        {required && (
          <>
            <abbr title="required">*</abbr>
            &nbsp;
          </>
        )}
        {label}
      </ControlLabel>
      <Select
        isMulti={isMulti}
        isClearable={isClearable}
        options={options}
        value={processedValue}
        onChange={handleOnChange}
        className={className || controlId}
        classNamePrefix={classNamePrefix || `select-${fieldName} react-select`}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      {errors && (
        <HelpBlock bsClass="inline-help-block">{errors.join(", ")}</HelpBlock>
      )}
    </FormGroup>
  );
};

export default SelectField;
