import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from "react-bootstrap";

const NumberField = ({
  controlId,
  fieldName,
  label,
  value,
  placeholder,
  disabled,
  errors,
  required,
  onChange,
}) => {
  return (
    <FormGroup
      controlId={controlId}
      validationState={errors ? "error" : null}
      className={`${controlId}-input`}
    >
      {label && (
        <ControlLabel>
          {required && (
            <>
              <abbr title="required">*</abbr>
              &nbsp;
            </>
          )}
          {label}
        </ControlLabel>
      )}
      <FormControl
        type="number"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(fieldName, e.target.value)}
        disabled={disabled}
      />
      {errors && (
        <HelpBlock bsClass="inline-help-block">{errors.join(", ")}</HelpBlock>
      )}
    </FormGroup>
  );
};

export default NumberField;
