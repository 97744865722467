import React from "react";

import { toSnakeCase } from "services/case";

import { TextField, SelectField } from "components/helpers";

const Expiration = ({
  values,
  saving,
  errors,
  valueFieldName,
  unitFieldName,
  updateFormValue,
  availableExpirationUnits,
  expirationLabelPrepend = "Online questionnaire link expires",
  expirationLabelAppend = "after email/SMS sent",
}) => {
  return (
    <div className="form-inline time-value-and-unit margin-bottom-20">
      <span>{expirationLabelPrepend}</span>
      <TextField
        required={false}
        controlId={toSnakeCase(valueFieldName)}
        fieldName={valueFieldName}
        label={expirationLabelPrepend}
        labelClassName="sr-only"
        className="time-value-input"
        value={values[valueFieldName]}
        disabled={saving}
        errors={errors[toSnakeCase(valueFieldName)]}
        onChange={updateFormValue}
      />
      <SelectField
        fieldName={toSnakeCase(unitFieldName)}
        label="Expiration unit"
        labelClassName="sr-only"
        className={`select-field-${toSnakeCase(
          unitFieldName
        )} time-unit-select`}
        value={values[unitFieldName]}
        disabled={saving}
        errors={errors[toSnakeCase(unitFieldName)]}
        onChange={(option) => {
          return updateFormValue(unitFieldName, option.value);
        }}
        options={availableExpirationUnits}
      />
      <span>{expirationLabelAppend}</span>
    </div>
  );
};

export default Expiration;
