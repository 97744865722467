import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ButtonToolbar, Button, Alert } from "react-bootstrap";

import actions from "actions/fields_page";
import AceEditor from "../AceEditor";

const EditDependenciesFormScreen = ({
  show,
  saving,
  field,
  values,
  errors,
  loadingFields,
  fieldsForDependencies,
  handleClose,
  updateFormValue,
  handleSubmit,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(field.study_id, field.id, values);
  };
  return (
    <Modal
      id="modalWindow"
      dialogClassName="modal-lg"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : handleClose}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>{`Edit field dependency for ${field.identifier}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.base && <Alert bsStyle="danger">{errors.base[0]}</Alert>}
          <AceEditor
            disabled={saving}
            fieldName="dependencies"
            value={values.dependencies}
            errors={errors.dependencies}
            handleUpdateValue={updateFormValue}
            loadingFields={loadingFields}
            fieldsForDependencies={fieldsForDependencies}
            setFocus={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  Saving
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  Save
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.editDependencies;

  return {
    show: relevantState.showForm,
    field: relevantState.field,
    fieldsForDependencies: relevantState.fieldsForDependencies,
    loadingFields: relevantState.loadingFields,
    values: relevantState.values,
    saving: relevantState.saving,
    errors: relevantState.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: actions.updateEditDependenciesFormValue,
      handleClose: actions.handleCloseEditDependenciesForm,
      handleSubmit: actions.handleSubmitEditDependenciesForm,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDependenciesFormScreen);
