import Attribute from "./Attribute";

export const newOrOriginalAttributes = (change) => {
  return change.new_attributes
    ? change.new_attributes
    : change.original_attributes;
};

class ChangeObject {
  constructor(change, elementType, elementLocation, attributeData) {
    this.change = change;
    this.elementType = elementType;
    this.elementLocation = elementLocation;
    this.attributes = attributeData.map(([title, identifier, extraOptions]) => {
      return Attribute.createFrom({
        change: change,
        title: title,
        identifier: identifier,
        ...extraOptions,
      });
    });
    this.attributesToDisplay = this.attributes.filter((attribute) =>
      attribute.shouldDisplay()
    );
    this.attributesToExport = this.attributes.filter((attribute) =>
      attribute.shouldDisplay()
    );
    this.groupedAttributesToExport = this.attributes.filter(
      (attribute) =>
        attribute.shouldDisplay() &&
        (change.action_type === "update" ||
          attribute.groupOnCreateAndDestroy === true)
    );
    this.ungroupedAttributesToExport = this.attributes.filter(
      (attribute) =>
        attribute.shouldDisplay() && attribute.groupOnCreateAndDestroy === false
    );
  }
}

export default ChangeObject;
