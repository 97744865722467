import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const SettingsCell = ({ field, handleOpen }) => {
  const SettingCell = ({ name, title, value }) => {
    const tooltip = () => {
      return (
        <Tooltip id={`settings-tooltip-${field.id}-${name}`}>{value}</Tooltip>
      );
    };

    return (
      <li>
        <OverlayTrigger placement="top" overlay={tooltip(name)}>
          <span className="tooltip-trigger">{title}</span>
        </OverlayTrigger>
      </li>
    );
  };

  const fieldFormatTitle = () => {
    if (field && field.field_type == "FormFields::SiteLookupType") {
      return "Site lookup";
    } else {
      return "Format";
    }
  };

  return (
    <>
      <td className="vertical-center">
        <div className="cell-with-action-button">
          <button
            onClick={() => handleOpen(field)}
            title="Edit the settings of this field"
            className="btn btn-link action-button"
          >
            <i className="fa fa-edit" />
          </button>
          <ul className="list-unstyled margin-bottom-0">
            {field.hint && (
              <SettingCell name="hint" title="Hint" value={field.hint} />
            )}
            {field.default_value && (
              <SettingCell
                name="default_value"
                title="Default"
                value={field.default_value}
              />
            )}
            {field.field_format && (
              <SettingCell
                name="field_format"
                title={fieldFormatTitle()}
                value={field.field_format}
              />
            )}
            {field.calculation && (
              <SettingCell
                name="calculation"
                title="Calculation"
                value={field.calculation}
              />
            )}
            {field.library_calculation_id && (
              <SettingCell
                name="library_calculation"
                title="Calculation"
                value={field.library_calculation_for_display}
              />
            )}
            {field.dynamic_calculation && (
              <SettingCell
                name="dyanamic_calculation"
                title="Dynamic calculation"
                value="This calculation is dynamic."
              />
            )}
            {field.notes && (
              <SettingCell name="notes" title="Notes" value={field.notes} />
            )}
            {field.exclude_from_export && (
              <SettingCell
                name="exclude_from_export"
                title="Not Exported"
                value="This field is excluded from export."
              />
            )}
            {field.do_not_show && (
              <SettingCell
                name="do_not_show"
                title="Not Shown"
                value="This field is not shown."
              />
            )}
            {field.inline_field && (
              <SettingCell
                name="inline_field"
                title="Inline"
                value="This field is inline with the next field."
              />
            )}
            {field.study_table_id && (
              <SettingCell
                name="study_table"
                title="Table"
                value={field.human_study_table}
              />
            )}
          </ul>
        </div>
      </td>
    </>
  );
};

export default SettingsCell;
