import { MessageTemplateActionTypes } from "actions/questionnaires_page";

export const messageTemplateTableDefaultState = {
  showDeleteModal: false,
  messageTemplateToDelete: undefined,
  processingDeleteRequest: false,
  messageTemplates: [],
};

export const MessageTemplateTable = (state = {}, action) => {
  switch (action.type) {
    case MessageTemplateActionTypes.HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS: {
      let newMessageTemplates = [
        ...state.messageTemplates,
        action.messageTemplate,
      ];
      return { ...state, messageTemplates: newMessageTemplates };
    }

    case MessageTemplateActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE_MODAL_TOGGLE: {
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
        messageTemplateToDelete: action.messageTemplate,
      };
    }

    case MessageTemplateActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE: {
      return {
        ...state,
        processingDeleteRequest: true,
      };
    }

    case MessageTemplateActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE_SUCCESS: {
      let newMessageTemplates = state.messageTemplates.filter(
        (messageTemplate) => messageTemplate.id != action.messageTemplateId
      );

      return {
        ...state,
        messageTemplates: newMessageTemplates,
        processingDeleteRequest: false,
        messageTemplateToDelete: undefined,
        showDeleteModal: false,
      };
    }

    case MessageTemplateActionTypes.HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS: {
      let updatedMessageTemplate = action.messageTemplate;
      let newMessageTemplates = state.messageTemplates.map(
        (messageTemplate) => {
          return messageTemplate.id === updatedMessageTemplate.id
            ? updatedMessageTemplate
            : messageTemplate;
        }
      );

      return {
        ...state,
        messageTemplates: newMessageTemplates,
      };
    }
  }
  return state;
};
