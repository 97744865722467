import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import {
  getRequest,
  putRequest,
  processRequest,
} from "../services/base_requests";
import { addClickListener } from "../services/browser_dom_support";

import AceEditor from "../components/AceEditor";
import { FaButton } from "../components/helpers";
import { defaultErrorMessage } from "../components/helpers/ErrorFallback";

const DependencyEditorPage = ({}) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [tableRowId, setTableRowId] = useState(undefined);
  const [tableRowType, setTableRowType] = useState(undefined);
  const [requestUrl, setRequestUrl] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [editorErrors, setEditorErrors] = useState(undefined);
  const [loadingFields, setLoadingFields] = useState(false);
  const [fieldsForDependencies, setFieldsForDependencies] = useState(undefined);

  useEffect(() => {
    addClickListener(".edit-dependencies-binding-button", openModal);
  }, []);

  const loadDependencies = (url) => {
    const loadDependenciesRequest = getRequest({
      url: url,
      params: {},
    });

    const success = (resData) => {
      setFieldsForDependencies(resData);
      setLoadingFields(false);
    };

    processRequest(loadDependenciesRequest, success, () => {});
  };

  const openModal = (element) => {
    setTitle(element.title);
    setTableRowId(element.dataset.tableRowId);
    setTableRowType(element.dataset.tableRowType);
    setRequestUrl(element.dataset.requestUrl);
    setEditorValue(element.dataset.editorValue);
    setEditorErrors(undefined);

    setLoadingFields(true);
    loadDependencies(element.dataset.dependencyFieldsUrl);

    setShow(true);
  };

  const onHide = () => {
    setShow(false);
  };

  const onSave = () => {
    const request = putRequest({
      url: requestUrl,
      body: {
        [tableRowType]: {
          dependencies: editorValue,
        },
      },
    });

    const tableRowSelector = `#${tableRowType}_${tableRowId}`;

    const updateDependenciesContent = (dependencyContent) => {
      // As the row is not currently in React, we update the dependency content in the DOM directly.
      const elementSelector = `${tableRowSelector} td .dependency-content`;

      $(elementSelector).html(dependencyContent);
      $(`${elementSelector} [data-remote-title-url]`).trigger(
        "init-remote-tooltip"
      );
    };

    const updateEditDependenciesButton = () => {
      // Update the data attribute of the open model button, so that it keeps the new value when re-opening the modal
      const elementSelector = `${tableRowSelector} .edit-dependencies-binding-button`;

      $(elementSelector).attr("data-editor-value", editorValue);
    };

    const success = (resData) => {
      updateDependenciesContent(resData.dependencyContent);
      updateEditDependenciesButton();

      if (tableRowType == "event") {
        $.flashAlert(
          "Event dependency has been updated successfully",
          "alert-success"
        );
      } else if (tableRowType == "form_binding") {
        $.flashAlert(
          "Form binding dependency has been updated successfully",
          "alert-success"
        );
      } else {
        $.flashAlert(
          `Unexpected tableRowType of ${tableRowType}! Please contact support.`,
          "alert-danger"
        );
      }

      onHide();
    };

    const failure = (resData) => {
      setEditorErrors(resData.dependencies);
    };

    const error = (ex) => {
      setEditorErrors([defaultErrorMessage]);
    };

    processRequest(request, success, failure, error);
  };

  return (
    <Modal
      id="dependencyEditorDialog"
      dialogClassName="modal-lg"
      backdrop="static"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AceEditor
          fieldName="dependencies"
          value={editorValue}
          errors={editorErrors}
          handleUpdateValue={(field, value) => {
            setEditorValue(value);
          }}
          loadingFields={loadingFields}
          fieldsForDependencies={fieldsForDependencies}
          setFocus={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="info" className="pull-left" onClick={onSave}>
          Save
        </Button>
        <FaButton
          icon="times"
          text="Cancel"
          bsStyle="default"
          onClick={onHide}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DependencyEditorPage;
