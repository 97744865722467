import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Modal,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
} from "react-bootstrap";

import { siteFormActions } from "actions/sites_page";
import { TextField, ButtonWithSpinner } from "components/helpers";
import AdditionalSitePropertiesFields from "./AdditionalSitePropertiesFields";
import DateField from "../helpers/DateField";

const SiteFormScreen = ({
  shown,
  editing,
  saving,
  studyId,
  siteId,
  values,
  errors,
  additionalSiteProperties,
  closeSiteForm,
  updateFormValue,
  updateFormPropertyValue,
  updateFormListValue,
  newSiteFormSave,
  newSiteFormSaveAndClose,
  editSiteFormSave,
  addListOption,
  removeListOption,
  reorderSiteFormListOption,
}) => {
  const handleSaveAndClose = () => {
    newSiteFormSaveAndClose(studyId, values);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editing) {
      editSiteFormSave(studyId, siteId, values);
    } else {
      newSiteFormSave(studyId, values);
    }
  };

  return (
    <Modal
      id="modalWindow"
      backdrop="static"
      show={shown}
      onHide={saving ? undefined : closeSiteForm}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>{editing ? "Edit site" : "New site"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <TextField
                controlId="code"
                fieldName="code"
                label="Code"
                required={true}
                value={values.code}
                disabled={saving}
                errors={errors.code}
                onChange={updateFormValue}
              />
            </Col>
            <Col md={6}>
              <TextField
                controlId="short_name"
                fieldName="short_name"
                label="Short name"
                required={true}
                value={values.short_name}
                disabled={saving}
                errors={errors.short_name}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                controlId="extra_code"
                fieldName="extra_code"
                label="Extra code"
                required={false}
                value={values.extra_code}
                disabled={saving}
                errors={errors.extra_code}
                onChange={updateFormValue}
              />
            </Col>
            <Col md={6}>
              <p className="text-muted margin-top-20">
                Used to override the site_code in individual numbers if needed
                to be different.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextField
                controlId="long_name"
                fieldName="long_name"
                label="Long name"
                required={true}
                value={values.long_name}
                disabled={saving}
                errors={errors.long_name}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <DateField
                controlId="recruitment_start_date"
                fieldName="recruitment_start_date"
                label="Recruitment start"
                required={false}
                value={values.recruitment_start_date}
                disabled={saving}
                errors={errors.recruitment_start_date}
                onChange={updateFormValue}
              />
            </Col>
            <Col md={6}>
              <DateField
                controlId="recruitment_end_date"
                fieldName="recruitment_end_date"
                label="Recruitment end"
                required={false}
                value={values.recruitment_end_date}
                disabled={saving}
                errors={errors.recruitment_end_date}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
          <AdditionalSitePropertiesFields
            additionalSiteProperties={additionalSiteProperties}
            values={values}
            errors={errors || {}}
            disabled={saving}
            propertyValueChange={updateFormPropertyValue}
            listValueChange={updateFormListValue}
            addListOption={addListOption}
            removeListOption={removeListOption}
            reorderSiteFormListOption={reorderSiteFormListOption}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <ButtonGroup>
              {editing && (
                <ButtonWithSpinner
                  saving={saving}
                  buttonText="Update"
                  faIcon="check"
                  bsStyle="primary"
                  type="submit"
                />
              )}
              {!editing && (
                <>
                  <ButtonWithSpinner
                    saving={saving}
                    buttonText="Save"
                    faIcon="check"
                    bsStyle="primary"
                    type="submit"
                  />
                  <ButtonWithSpinner
                    saving={saving}
                    buttonText="Save and close"
                    bsStyle="info"
                    onClick={() => handleSaveAndClose()}
                  />
                </>
              )}
            </ButtonGroup>
            <div className="pull-right">
              <Button
                bsStyle="default"
                disabled={saving}
                onClick={closeSiteForm}
              >
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.siteForm;
  return {
    shown: relevantState.shown,
    editing: relevantState.editing,
    saving: relevantState.saving,
    studyId: relevantState.studyId,
    siteId: relevantState.siteId,
    values: relevantState.values,
    errors: relevantState.errors,
    additionalSiteProperties: relevantState.additionalSiteProperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeSiteForm: siteFormActions.closeSiteForm,
      updateFormValue: siteFormActions.siteFormValueChange,
      updateFormPropertyValue: siteFormActions.siteFormPropertyValueChange,
      updateFormListValue: siteFormActions.siteFormListValueChange,
      newSiteFormSave: siteFormActions.newSiteFormSave,
      newSiteFormSaveAndClose: siteFormActions.newSiteFormSaveAndClose,
      editSiteFormSave: siteFormActions.editSiteFormSave,
      addListOption: siteFormActions.siteFormAddListOption,
      removeListOption: siteFormActions.siteFormRemoveListOption,
      reorderSiteFormListOption: siteFormActions.reorderSiteFormListOption,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteFormScreen);
