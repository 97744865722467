import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Modal,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Checkbox,
  Alert,
} from "react-bootstrap";

import {
  ButtonWithSpinner,
  TextField,
  TextFieldInputGroup,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";
import actions from "actions/fields_page";
import FieldTypeSettings from "./FieldTypeSettings";
import LookupSettings from "./LookupSettings";
import FieldFormatDropdown from "./FieldFormatDropdown";

const AddFieldFormScreen = ({
  currentFormOrSubform,
  studyId,
  show,
  saving,
  values,
  errors,
  handleClose,
  handleSubmit,
  handleSubmitAndClose,
  updateFormValue,
  newLookup,
  newLookupOptions,
}) => {
  const labelRef = useRef();
  useEffect(() => {
    // If we have finished saving...
    if (saving === false) {
      labelRef.current && labelRef.current.focus();
    }
  }, [saving]);

  const showingPresentValidationCheck = () => {
    return values.type && values.type !== "FormFields::BooleanType";
  };

  const showingFutureDateCheck = () => {
    return (
      values.type &&
      (values.type === "FormFields::DateType" ||
        values.type === "FormFields::PartialDateType")
    );
  };

  const showingRangeRules = () => {
    return (
      values.type &&
      (values.type === "FormFields::IntegerType" ||
        values.type === "FormFields::DecimalType")
    );
  };

  const formValues = {
    ...values,
    default_presence_validation:
      values.default_presence_validation && showingPresentValidationCheck(),
    default_disallow_future_validation:
      values.default_disallow_future_validation && showingFutureDateCheck(),
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(
      studyId,
      formValues,
      currentFormOrSubform.id,
      currentFormOrSubform.type,
      currentFormOrSubform.default_form_section_id,
      newLookup,
      newLookupOptions
    );
  };

  const handleSaveAndClose = () => {
    handleSubmitAndClose(
      studyId,
      formValues,
      currentFormOrSubform.id,
      currentFormOrSubform.type,
      currentFormOrSubform.default_form_section_id,
      newLookup,
      newLookupOptions
    );
  };

  const formErrors = errors.field;

  return (
    <Modal
      id="modalWindow"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : handleClose}
    >
      <form
        onSubmit={(e) => {
          handleFormSubmit(e);
        }}
      >
        <Modal.Header closeButton={!saving}>
          <Modal.Title>Add new field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formErrors.base && (
            <Alert bsStyle="danger">{formErrors.base[0]}</Alert>
          )}
          <TextField
            controlId="label"
            fieldName="label"
            label="Label"
            value={values.label}
            disabled={saving}
            errors={formErrors.label}
            onChange={updateFormValue}
            inputRef={(ref) => {
              labelRef.current = ref;
            }}
          />
          <TextField
            required={true}
            controlId="identifier"
            fieldName="identifier"
            label="Identifier"
            value={values.identifier}
            disabled={saving}
            pattern={IDENTIFIER_LIMIT_PATTERN}
            title={IDENTIFIER_LIMIT_TITLE}
            errors={formErrors.identifier}
            onChange={updateFormValue}
          />
          <FieldTypeSettings
            handleFieldTypeChange={(option) =>
              updateFormValue("type", option.value)
            }
            handleWidgetChange={(option) =>
              updateFormValue("widget_type", option.value)
            }
            handleUnitChange={updateFormValue}
            handlePrecisionChange={updateFormValue}
          />
          <Row>
            <FieldFormatDropdown
              colSize={12}
              handleFormatChange={(option) =>
                updateFormValue("field_format", option.value)
              }
            />
          </Row>
          <LookupSettings
            handleLookupChange={(option) =>
              updateFormValue("lookup_id", option.value)
            }
          />
          <Row>
            <Col sm={12}>
              {showingPresentValidationCheck() && (
                <Checkbox
                  checked={values.default_presence_validation}
                  validationState={
                    formErrors.default_presence_validation ? "error" : null
                  }
                  onChange={(e) =>
                    updateFormValue(
                      "default_presence_validation",
                      e.target.checked
                    )
                  }
                  disabled={saving}
                >
                  Enable soft post-entry presence validation
                </Checkbox>
              )}
              {showingFutureDateCheck() && (
                <Checkbox
                  checked={values.default_disallow_future_validation}
                  validationState={
                    formErrors.default_disallow_future_validation
                      ? "error"
                      : null
                  }
                  onChange={(e) =>
                    updateFormValue(
                      "default_disallow_future_validation",
                      e.target.checked
                    )
                  }
                  disabled={saving}
                >
                  Disallow future date
                </Checkbox>
              )}
            </Col>
          </Row>
          {showingRangeRules() && (
            <div className="new-field-range-validations">
              <h4>Range validations</h4>
              <Row>
                <Col md={6} className="field_range_min">
                  <TextFieldInputGroup
                    field="range_min"
                    label="Min"
                    value={values.range_min || ""}
                    disabled={saving}
                    hasErrors={formErrors.min_value}
                    errors={formErrors.min_value}
                    onChange={updateFormValue}
                  />
                </Col>
                <Col md={6} className="field_range_max">
                  <TextFieldInputGroup
                    field="range_max"
                    label="Max"
                    value={values.range_max || ""}
                    disabled={saving}
                    hasErrors={formErrors.max_value}
                    errors={formErrors.max_value}
                    onChange={updateFormValue}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="field_range_low">
                  <TextFieldInputGroup
                    field="range_low"
                    label="Low"
                    value={values.range_low || ""}
                    disabled={saving}
                    hasErrors={formErrors.low_value}
                    errors={formErrors.low_value}
                    onChange={updateFormValue}
                  />
                </Col>
                <Col md={6} className="field_range_high">
                  <TextFieldInputGroup
                    field="range_high"
                    label="High"
                    value={values.range_high || ""}
                    disabled={saving}
                    hasErrors={formErrors.high_value}
                    errors={formErrors.high_value}
                    onChange={updateFormValue}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <ButtonGroup>
              <ButtonWithSpinner
                saving={saving}
                buttonText="Save"
                faIcon="fa-check"
                bsStyle="primary"
                type="submit"
              />
              <ButtonWithSpinner
                saving={saving}
                buttonText="Save and close"
                bsStyle="info"
                onClick={() => handleSaveAndClose()}
              />
            </ButtonGroup>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.addField;

  return {
    currentFormOrSubform: relevantState.currentFormOrSubform,
    studyId: relevantState.studyId,
    show: relevantState.showForm,
    saving: relevantState.saving,
    values: relevantState.values,
    errors: relevantState.errors,
    newLookup: relevantState.newLookup,
    newLookupOptions: relevantState.newLookupOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: actions.updateNewFieldFormValue,
      handleClose: actions.handleCloseNewFieldForm,
      handleSubmit: actions.handleNewFieldFormSubmit,
      handleSubmitAndClose: actions.handleNewFieldFormSubmitAndClose,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFieldFormScreen);
