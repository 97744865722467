import actions, { ActionTypes } from "actions/duplicate_study_forms";

import { processRequest, dispatchWithTimeout } from "services/base_requests";
import {
  duplicateStudyForm,
  dependenciesThatCannotBeCopied,
} from "services/duplicate_study_form_requests";

export const ModalMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);

    switch (action.type) {
      case ActionTypes.HANDLE_FORM_SUBMIT: {
        const request = duplicateStudyForm(action.studyId, action.values);

        successHandlerFor(actions.handleSubmitSuccess);

        const successHandler = successHandlerFor(actions.handleSubmitSuccess);
        const failureHandler = successHandlerFor(actions.handleSubmitFailure);
        const errorHandler = (error) => {
          dispatch(actions.handleSubmitError(error.message));
        };
        processRequest(request, successHandler, failureHandler, errorHandler);
        break;
      }
      case ActionTypes.HANDLE_SELECT_FORM: {
        const request = dependenciesThatCannotBeCopied(
          action.studyId,
          action.value
        );

        const successHandler = successHandlerFor(
          actions.handleDependenciesCheckSuccess
        );
        const failureHandler = successHandlerFor(
          actions.handleDependenciesCheckFailure,
          "An error occurred, please try again and contact support if the problem persists."
        );

        processRequest(request, successHandler, failureHandler, failureHandler);
        break;
      }
    }
    return next(action);
  };
