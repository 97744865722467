import ChangeObject from "./ChangeObject";

class SiteChange extends ChangeObject {
  constructor(change) {
    const originalAttributes = change.original_attributes;
    const newAttributes = change.new_attributes;
    const shortName = newAttributes
      ? newAttributes.short_name.value
      : originalAttributes.short_name.value;

    const attributeData = [
      ["Short name", "short_name"],
      ["Code", "code"],
      ["Extra code", "extra_code"],
      ["Long name", "long_name"],
      ["Recruitment start date", "recruitment_start_date"],
      ["Recruitment end date", "recruitment_end_date"],
      [
        "Additional Properties",
        "additional_site_properties",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `${associatedObject.name.value}: ${associatedObject.value.value}`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject) =>
                  `${associatedObject.name.value}: ${associatedObject.value.value}`
              )
              .join(", ");
          },
        },
      ],
      [
        "Site-Specific Lists",
        "site_specific_lists",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: (associatedObject) => {
            return `${
              associatedObject.name.value
            }: ${associatedObject.list_options.value
              .map((option) => option.name.value)
              .join(", ")}`;
          },
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject) =>
                  `${
                    associatedObject.name.value
                  }: ${associatedObject.list_options.value
                    .map((option) => option.name.value)
                    .join(", ")}`
              )
              .join(", ");
          },
        },
      ],
    ];

    super(change, "Site", shortName, attributeData);
  }
}

export default SiteChange;
