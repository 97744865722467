import React from "react";
import { FormGroup, HelpBlock } from "react-bootstrap";

import Select from "./Select";

const PrivacyTagsSelect = ({
  collections,
  values,
  errors,
  saving,
  updateFormValue,
}) => {
  return (
    <FormGroup validationState={errors.privacy_tags ? "error" : null}>
      <Select
        classNamePrefix="select-privacy-tags"
        className="select-privacy-tags"
        value={values.privacy_tags}
        onChange={(option) => updateFormValue("privacy_tags", option)}
        options={collections.privacy_tags}
        isMulti={true}
        isDisabled={saving}
      />
      {errors.privacy_tags && (
        <HelpBlock bsClass="inline-help-block">
          {errors.privacy_tags[0]}
        </HelpBlock>
      )}
    </FormGroup>
  );
};

export default PrivacyTagsSelect;
