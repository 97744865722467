import * as ActionTypes from "./sitePropertyActionTypes";

export const openNewSitePropertyForm = () => ({
  type: ActionTypes.OPEN_NEW_SITE_PROPERTY_FORM,
});

export const openEditSitePropertyForm = (siteProperty) => ({
  type: ActionTypes.OPEN_EDIT_SITE_PROPERTY_FORM,
  siteProperty,
});

export const closeSitePropertyForm = () => ({
  type: ActionTypes.CLOSE_SITE_PROPERTY_FORM,
});

export const sitePropertyFormValueChange = (fieldName, value) => ({
  type: ActionTypes.SITE_PROPERTY_FORM_VALUE_CHANGE,
  fieldName,
  value,
});

export const addSitePropertySingleValueOption = () => ({
  type: ActionTypes.ADD_SITE_PROPERTY_SINGLE_VALUE_OPTION,
});

export const sitePropertyFormOptionValueChange = (
  optionIndex,
  fieldName,
  value
) => ({
  type: ActionTypes.SITE_PROPERTY_FORM_OPTION_VALUE_CHANGE,
  optionIndex,
  fieldName,
  value,
});

export const removeSitePropertySingleValueOption = (optionIndex) => ({
  type: ActionTypes.REMOVE_SITE_PROPERTY_SINGLE_VALUE_OPTION,
  optionIndex,
});

export const newSitePropertyFormSave = (studyId, values) => ({
  type: ActionTypes.NEW_SITE_PROPERTY_FORM_SAVE,
  studyId,
  values,
});

export const editSitePropertyFormSave = (studyId, sitePropertyId, values) => ({
  type: ActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE,
  studyId,
  sitePropertyId,
  values,
});

export const newSitePropertyFormSaveAndClose = (studyId, values) => ({
  type: ActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE,
  studyId,
  values,
});

export const newSitePropertyFormSaveSuccess = (additionalSiteProperties) => ({
  type: ActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_SUCCESS,
  additionalSiteProperties,
});

export const newSitePropertyFormSaveAndCloseSuccess = (
  additionalSiteProperties
) => ({
  type: ActionTypes.NEW_SITE_PROPERTY_FORM_SAVE_AND_CLOSE_SUCCESS,
  additionalSiteProperties,
});

export const removeSiteProperty = (studyId, sitePropertyId) => ({
  type: ActionTypes.REMOVE_SITE_PROPERTY,
  studyId,
  sitePropertyId,
});

export const editSitePropertyFormSaveSuccess = (additionalSiteProperties) => ({
  type: ActionTypes.EDIT_SITE_PROPERTY_FORM_SAVE_SUCCESS,
  additionalSiteProperties,
});

export const sitePropertyFormSaveErrors = (errors) => ({
  type: ActionTypes.SITE_PROPERTY_FORM_ERROR,
  errors,
});
