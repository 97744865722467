import {
  putRequest,
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "./base_requests";

export function orderEntityGroups(orderedEntityGroupIds, studyId) {
  return putRequest({
    url: `/study/${studyId}/entity_groups/reorder`,
    body: {
      study_id: studyId,
      entity_group: {
        order: orderedEntityGroupIds,
      },
    },
  });
}

export function deleteEntityGroup(studyId, entityGroupId) {
  return deleteRequest({
    url: `/study/${studyId}/entity_groups/${entityGroupId}`,
    body: {},
  });
}

export function createEntityGroup(studyId, values) {
  return postRequest({
    url: `/study/${studyId}/entity_groups`,
    body: { entity_group: { ...values } },
  });
}

export function updateEntityGroup(studyId, entityGroupId, values) {
  return patchRequest({
    url: `/study/${studyId}/entity_groups/${entityGroupId}`,
    body: { entity_group: { ...values } },
  });
}
