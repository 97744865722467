import * as ActionTypes from "./siteFormActionTypes";

export const openNewSiteForm = () => ({
  type: ActionTypes.OPEN_NEW_SITE_FORM,
});

export const openEditSiteForm = (site) => ({
  type: ActionTypes.OPEN_EDIT_SITE_FORM,
  site,
});

export const closeSiteForm = () => ({
  type: ActionTypes.CLOSE_SITE_FORM,
});

export const siteFormValueChange = (fieldName, value) => ({
  type: ActionTypes.SITE_FORM_VALUE_CHANGE,
  fieldName,
  value,
});

export const siteFormPropertyValueChange = (position, fieldName, value) => ({
  type: ActionTypes.SITE_FORM_PROPERTY_VALUE_CHANGE,
  position,
  fieldName,
  value,
});

export const siteFormListValueChange = (
  metadatumEntryPosition,
  optionIndex,
  fieldName,
  value
) => ({
  type: ActionTypes.SITE_FORM_LIST_VALUE_CHANGE,
  metadatumEntryPosition,
  optionIndex,
  fieldName,
  value,
});

export const siteFormRemoveListOption = (
  metadatumEntryPosition,
  optionIndex
) => ({
  type: ActionTypes.SITE_FORM_REMOVE_LIST_OPTION,
  metadatumEntryPosition,
  optionIndex,
});

export const siteFormAddListOption = (metadatumEntryPosition) => ({
  type: ActionTypes.SITE_FORM_ADD_LIST_OPTION,
  metadatumEntryPosition,
});

export const reorderSiteFormListOption = (metadatumEntryPosition, result) => ({
  type: ActionTypes.REORDER_SITE_FORM_LIST_OPTION,
  metadatumEntryPosition,
  result: result,
});

export const newSiteFormSave = (studyId, values) => ({
  type: ActionTypes.NEW_SITE_FORM_SAVE,
  studyId,
  values,
});

export const editSiteFormSave = (studyId, siteId, values) => ({
  type: ActionTypes.EDIT_SITE_FORM_SAVE,
  studyId,
  siteId,
  values,
});

export const editSiteFormSaveSuccess = (site) => ({
  type: ActionTypes.EDIT_SITE_FORM_SAVE_SUCCESS,
  site,
});

export const newSiteFormSaveAndClose = (studyId, values) => ({
  type: ActionTypes.NEW_SITE_FORM_SAVE_AND_CLOSE,
  studyId,
  values,
});

export const siteFormSaveErrors = (errors) => ({
  type: ActionTypes.SITE_FORM_ERROR,
  errors,
});

export const newSiteFormSaveSuccess = (sites) => ({
  type: ActionTypes.NEW_SITE_FORM_SAVE_SUCCESS,
  sites,
});

export const newSiteFormSaveAndCloseSuccess = (sites) => ({
  type: ActionTypes.NEW_SITE_FORM_SAVE_AND_CLOSE_SUCCESS,
  sites,
});
