ace.define(
  "ace/mode/doc_comment_highlight_rules",
  ["require", "exports", "ace/lib/oop", "ace/mode/text_highlight_rules"],
  (acequire, exports) => {
    const oop = acequire("ace/lib/oop");
    const TextHighlightRules = acequire(
      "./text_highlight_rules"
    ).TextHighlightRules;

    var DocCommentHighlightRules = function () {
      this.$rules = {
        start: [
          {
            token: "comment.doc.tag",
            regex: "@[\\w\\d_]+", // TODO: fix email addresses
          },
          DocCommentHighlightRules.getTagRule(),
          {
            defaultToken: "comment.doc",
            caseInsensitive: true,
          },
        ],
      };
    };

    oop.inherits(DocCommentHighlightRules, TextHighlightRules);

    DocCommentHighlightRules.getTagRule = function (start) {
      return {
        token: "comment.doc.tag.storage.type",
        regex: "\\b(?:TODO|FIXME|XXX|HACK)\\b",
      };
    };

    DocCommentHighlightRules.getStartRule = function (start) {
      return {
        token: "comment.doc", // doc comment
        regex: "\\/\\*(?=\\*)",
        next: start,
      };
    };

    DocCommentHighlightRules.getEndRule = function (start) {
      return {
        token: "comment.doc", // closing comment
        regex: "\\*\\/",
        next: start,
      };
    };

    exports.DocCommentHighlightRules = DocCommentHighlightRules;
  }
);

ace.define(
  "ace/mode/dd_dsl_highlight_rules",
  [
    "require",
    "exports",
    "ace/lib/oop",
    "ace/mode/doc_comment_highlight_rules",
    "ace/mode/text_highlight_rules",
  ],
  function (acequire, exports) {
    const oop = acequire("ace/lib/oop");
    const DocCommentHighlightRules = acequire(
      "ace/mode/doc_comment_highlight_rules"
    ).DocCommentHighlightRules;
    const TextHighlightRules = acequire(
      "ace/mode/text_highlight_rules"
    ).TextHighlightRules;

    var DdDslHighlightRules = function () {
      var ddDslKeywords =
        "and|or|blank|not|blank|exists|matches|contains|in|row";

      var keywordMapper = this.createKeywordMapper(
        {
          keyword: ddDslKeywords,
          constant: "false|true|null",
        },
        "identifier",
        true
      );

      function string(rule) {
        var start = rule.start;
        var escapeSeq = rule.escape;
        return {
          token: "string.start",
          regex: start,
          next: [
            { token: "constant.language.escape", regex: escapeSeq },
            { token: "string.end", next: "start", regex: start },
            { defaultToken: "string" },
          ],
        };
      }

      this.$rules = {
        start: [
          DocCommentHighlightRules.getStartRule("doc-start"),
          {
            token: "constant.numeric", // hex
            regex:
              /0[xX][0-9a-fA-F]+|[xX]'[0-9a-fA-F]+'|0[bB][01]+|[bB]'[01]+'/,
          },
          {
            token: "constant.numeric", // float
            regex: "[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b",
          },
          {
            token: keywordMapper,
            regex: "[a-zA-Z_$][a-zA-Z0-9_$]*\\b",
          },
          {
            token: "keyword.operator",
            regex:
              "\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|=",
          },
          {
            token: "paren.lparen",
            regex: "[\\(]",
          },
          {
            token: "paren.rparen",
            regex: "[\\)]",
          },
          {
            token: "text",
            regex: "\\s+",
          },
        ],
      };

      this.embedRules(DocCommentHighlightRules, "doc-", [
        DocCommentHighlightRules.getEndRule("start"),
      ]);
      this.normalizeRules();
    };

    oop.inherits(DdDslHighlightRules, TextHighlightRules);

    exports.DdDslHighlightRules = DdDslHighlightRules;
  }
);

ace.define(
  "ace/mode/behaviour/cstyle",
  [
    "require",
    "exports",
    "module",
    "ace/lib/oop",
    "ace/mode/behaviour",
    "ace/token_iterator",
    "ace/lib/lang",
  ],
  (acequire, exports) => {
    const oop = acequire("./lib/oop");
    const Behaviour = acequire("./mode/behaviour").Behaviour;
    const TokenIterator = acequire("./token_iterator").TokenIterator;
    const lang = acequire("./lib/lang");

    const SAFE_INSERT_IN_TOKENS = [
      "text",
      "paren.rparen",
      "punctuation.operator",
    ];
    const SAFE_INSERT_BEFORE_TOKENS = [
      "text",
      "paren.rparen",
      "punctuation.operator",
      "comment",
    ];

    var context;
    var contextCache = {};
    var initContext = function (editor) {
      var id = -1;
      if (editor.multiSelect) {
        id = editor.selection.index;
        if (contextCache.rangeCount != editor.multiSelect.rangeCount)
          contextCache = { rangeCount: editor.multiSelect.rangeCount };
      }
      if (contextCache[id]) return (context = contextCache[id]);
      context = contextCache[id] = {
        autoInsertedBrackets: 0,
        autoInsertedRow: -1,
        autoInsertedLineEnd: "",
        maybeInsertedBrackets: 0,
        maybeInsertedRow: -1,
        maybeInsertedLineStart: "",
        maybeInsertedLineEnd: "",
      };
    };

    var CstyleBehaviour = function () {
      this.add(
        "braces",
        "insertion",
        function (state, action, editor, session, text) {
          var cursor = editor.getCursorPosition();
          var line = session.doc.getLine(cursor.row);
          if (text == "{") {
            initContext(editor);
            var selection = editor.getSelectionRange();
            var selected = session.doc.getTextRange(selection);
            if (
              selected !== "" &&
              selected !== "{" &&
              editor.getWrapBehavioursEnabled()
            ) {
              return {
                text: "{" + selected + "}",
                selection: false,
              };
            } else if (CstyleBehaviour.isSaneInsertion(editor, session)) {
              if (
                /[\]\}\)]/.test(line[cursor.column]) ||
                editor.inMultiSelectMode
              ) {
                CstyleBehaviour.recordAutoInsert(editor, session, "}");
                return {
                  text: "{}",
                  selection: [1, 1],
                };
              } else {
                CstyleBehaviour.recordMaybeInsert(editor, session, "{");
                return {
                  text: "{",
                  selection: [1, 1],
                };
              }
            }
          } else if (text == "}") {
            initContext(editor);
            var rightChar = line.substring(cursor.column, cursor.column + 1);
            if (rightChar == "}") {
              var matching = session.$findOpeningBracket("}", {
                column: cursor.column + 1,
                row: cursor.row,
              });
              if (
                matching !== null &&
                CstyleBehaviour.isAutoInsertedClosing(cursor, line, text)
              ) {
                CstyleBehaviour.popAutoInsertedClosing();
                return {
                  text: "",
                  selection: [1, 1],
                };
              }
            }
          } else if (text == "\n" || text == "\r\n") {
            initContext(editor);
            var closing = "";
            if (CstyleBehaviour.isMaybeInsertedClosing(cursor, line)) {
              closing = lang.stringRepeat("}", context.maybeInsertedBrackets);
              CstyleBehaviour.clearMaybeInsertedClosing();
            }
            var rightChar = line.substring(cursor.column, cursor.column + 1);
            if (rightChar === "}") {
              var openBracePos = session.findMatchingBracket(
                { row: cursor.row, column: cursor.column + 1 },
                "}"
              );
              if (!openBracePos) return null;
              var next_indent = this.$getIndent(
                session.getLine(openBracePos.row)
              );
            } else if (closing) {
              var next_indent = this.$getIndent(line);
            } else {
              CstyleBehaviour.clearMaybeInsertedClosing();
              return;
            }
            var indent = next_indent + session.getTabString();

            return {
              text: "\n" + indent + "\n" + next_indent + closing,
              selection: [1, indent.length, 1, indent.length],
            };
          } else {
            CstyleBehaviour.clearMaybeInsertedClosing();
          }
        }
      );

      this.add(
        "braces",
        "deletion",
        function (state, action, editor, session, range) {
          var selected = session.doc.getTextRange(range);
          if (!range.isMultiLine() && selected == "{") {
            initContext(editor);
            var line = session.doc.getLine(range.start.row);
            var rightChar = line.substring(
              range.end.column,
              range.end.column + 1
            );
            if (rightChar == "}") {
              range.end.column++;
              return range;
            } else {
              context.maybeInsertedBrackets--;
            }
          }
        }
      );

      this.add(
        "parens",
        "insertion",
        function (state, action, editor, session, text) {
          if (text == "(") {
            initContext(editor);
            var selection = editor.getSelectionRange();
            var selected = session.doc.getTextRange(selection);
            if (selected !== "" && editor.getWrapBehavioursEnabled()) {
              return {
                text: "(" + selected + ")",
                selection: false,
              };
            } else if (CstyleBehaviour.isSaneInsertion(editor, session)) {
              CstyleBehaviour.recordAutoInsert(editor, session, ")");
              return {
                text: "()",
                selection: [1, 1],
              };
            }
          } else if (text == ")") {
            initContext(editor);
            var cursor = editor.getCursorPosition();
            var line = session.doc.getLine(cursor.row);
            var rightChar = line.substring(cursor.column, cursor.column + 1);
            if (rightChar == ")") {
              var matching = session.$findOpeningBracket(")", {
                column: cursor.column + 1,
                row: cursor.row,
              });
              if (
                matching !== null &&
                CstyleBehaviour.isAutoInsertedClosing(cursor, line, text)
              ) {
                CstyleBehaviour.popAutoInsertedClosing();
                return {
                  text: "",
                  selection: [1, 1],
                };
              }
            }
          }
        }
      );

      this.add(
        "parens",
        "deletion",
        function (state, action, editor, session, range) {
          var selected = session.doc.getTextRange(range);
          if (!range.isMultiLine() && selected == "(") {
            initContext(editor);
            var line = session.doc.getLine(range.start.row);
            var rightChar = line.substring(
              range.start.column + 1,
              range.start.column + 2
            );
            if (rightChar == ")") {
              range.end.column++;
              return range;
            }
          }
        }
      );

      this.add(
        "brackets",
        "insertion",
        function (state, action, editor, session, text) {
          if (text == "[") {
            initContext(editor);
            var selection = editor.getSelectionRange();
            var selected = session.doc.getTextRange(selection);
            if (selected !== "" && editor.getWrapBehavioursEnabled()) {
              return {
                text: "[" + selected + "]",
                selection: false,
              };
            } else if (CstyleBehaviour.isSaneInsertion(editor, session)) {
              CstyleBehaviour.recordAutoInsert(editor, session, "]");
              return {
                text: "[]",
                selection: [1, 1],
              };
            }
          } else if (text == "]") {
            initContext(editor);
            var cursor = editor.getCursorPosition();
            var line = session.doc.getLine(cursor.row);
            var rightChar = line.substring(cursor.column, cursor.column + 1);
            if (rightChar == "]") {
              var matching = session.$findOpeningBracket("]", {
                column: cursor.column + 1,
                row: cursor.row,
              });
              if (
                matching !== null &&
                CstyleBehaviour.isAutoInsertedClosing(cursor, line, text)
              ) {
                CstyleBehaviour.popAutoInsertedClosing();
                return {
                  text: "",
                  selection: [1, 1],
                };
              }
            }
          }
        }
      );

      this.add(
        "brackets",
        "deletion",
        function (state, action, editor, session, range) {
          var selected = session.doc.getTextRange(range);
          if (!range.isMultiLine() && selected == "[") {
            initContext(editor);
            var line = session.doc.getLine(range.start.row);
            var rightChar = line.substring(
              range.start.column + 1,
              range.start.column + 2
            );
            if (rightChar == "]") {
              range.end.column++;
              return range;
            }
          }
        }
      );

      this.add(
        "string_dquotes",
        "insertion",
        function (state, action, editor, session, text) {
          if (text == '"' || text == "'") {
            initContext(editor);
            var quote = text;
            var selection = editor.getSelectionRange();
            var selected = session.doc.getTextRange(selection);
            if (
              selected !== "" &&
              selected !== "'" &&
              selected != '"' &&
              editor.getWrapBehavioursEnabled()
            ) {
              return getWrapped(selection, selected, quote, quote);
            } else if (!selected) {
              var cursor = editor.getCursorPosition();
              var line = session.doc.getLine(cursor.row);
              var leftChar = line.substring(cursor.column - 1, cursor.column);
              var rightChar = line.substring(cursor.column, cursor.column + 1);

              var token = session.getTokenAt(cursor.row, cursor.column);
              var rightToken = session.getTokenAt(
                cursor.row,
                cursor.column + 1
              );
              if (leftChar == "\\" && token && /escape/.test(token.type))
                return null;

              var stringBefore = token && /string|escape/.test(token.type);
              var stringAfter =
                !rightToken || /string|escape/.test(rightToken.type);

              var pair;
              if (rightChar == quote) {
                pair = stringBefore !== stringAfter;
              } else {
                if (stringBefore && !stringAfter) return null; // wrap string with different quote
                if (stringBefore && stringAfter) return null; // do not pair quotes inside strings
                var wordRe = session.$mode.tokenRe;
                wordRe.lastIndex = 0;
                var isWordBefore = wordRe.test(leftChar);
                wordRe.lastIndex = 0;
                var isWordAfter = wordRe.test(leftChar);
                if (isWordBefore || isWordAfter) return null; // before or after alphanumeric
                if (rightChar && !/[\s;,.})\]\\]/.test(rightChar)) return null; // there is rightChar and it isn't closing
                pair = true;
              }
              return {
                text: pair ? quote + quote : "",
                selection: [1, 1],
              };
            }
          }
        }
      );

      this.add(
        "string_dquotes",
        "deletion",
        function (state, action, editor, session, range) {
          var selected = session.doc.getTextRange(range);
          if (!range.isMultiLine() && (selected == '"' || selected == "'")) {
            initContext(editor);
            var line = session.doc.getLine(range.start.row);
            var rightChar = line.substring(
              range.start.column + 1,
              range.start.column + 2
            );
            if (rightChar == selected) {
              range.end.column++;
              return range;
            }
          }
        }
      );
    };

    CstyleBehaviour.isSaneInsertion = function (editor, session) {
      var cursor = editor.getCursorPosition();
      var iterator = new TokenIterator(session, cursor.row, cursor.column);
      if (
        !this.$matchTokenType(
          iterator.getCurrentToken() || "text",
          SAFE_INSERT_IN_TOKENS
        )
      ) {
        var iterator2 = new TokenIterator(
          session,
          cursor.row,
          cursor.column + 1
        );
        if (
          !this.$matchTokenType(
            iterator2.getCurrentToken() || "text",
            SAFE_INSERT_IN_TOKENS
          )
        )
          return false;
      }
      iterator.stepForward();
      return (
        iterator.getCurrentTokenRow() !== cursor.row ||
        this.$matchTokenType(
          iterator.getCurrentToken() || "text",
          SAFE_INSERT_BEFORE_TOKENS
        )
      );
    };

    CstyleBehaviour.$matchTokenType = function (token, types) {
      return types.indexOf(token.type || token) > -1;
    };

    CstyleBehaviour.recordAutoInsert = function (editor, session, bracket) {
      var cursor = editor.getCursorPosition();
      var line = session.doc.getLine(cursor.row);
      if (
        !this.isAutoInsertedClosing(
          cursor,
          line,
          context.autoInsertedLineEnd[0]
        )
      )
        context.autoInsertedBrackets = 0;
      context.autoInsertedRow = cursor.row;
      context.autoInsertedLineEnd = bracket + line.substr(cursor.column);
      context.autoInsertedBrackets++;
    };

    CstyleBehaviour.recordMaybeInsert = function (editor, session, bracket) {
      var cursor = editor.getCursorPosition();
      var line = session.doc.getLine(cursor.row);
      if (!this.isMaybeInsertedClosing(cursor, line))
        context.maybeInsertedBrackets = 0;
      context.maybeInsertedRow = cursor.row;
      context.maybeInsertedLineStart = line.substr(0, cursor.column) + bracket;
      context.maybeInsertedLineEnd = line.substr(cursor.column);
      context.maybeInsertedBrackets++;
    };

    CstyleBehaviour.isAutoInsertedClosing = function (cursor, line, bracket) {
      return (
        context.autoInsertedBrackets > 0 &&
        cursor.row === context.autoInsertedRow &&
        bracket === context.autoInsertedLineEnd[0] &&
        line.substr(cursor.column) === context.autoInsertedLineEnd
      );
    };

    CstyleBehaviour.isMaybeInsertedClosing = function (cursor, line) {
      return (
        context.maybeInsertedBrackets > 0 &&
        cursor.row === context.maybeInsertedRow &&
        line.substr(cursor.column) === context.maybeInsertedLineEnd &&
        line.substr(0, cursor.column) == context.maybeInsertedLineStart
      );
    };

    CstyleBehaviour.popAutoInsertedClosing = function () {
      context.autoInsertedLineEnd = context.autoInsertedLineEnd.substr(1);
      context.autoInsertedBrackets--;
    };

    CstyleBehaviour.clearMaybeInsertedClosing = function () {
      if (context) {
        context.maybeInsertedBrackets = 0;
        context.maybeInsertedRow = -1;
      }
    };

    oop.inherits(CstyleBehaviour, Behaviour);

    exports.CstyleBehaviour = CstyleBehaviour;
  }
);

ace.define(
  "ace/mode/dd_dsl",
  [
    "require",
    "exports",
    "ace/lib/oop",
    "ace/mode/text",
    "ace/mode/dd_dsl_highlight_rules",
  ],
  (acequire, exports) => {
    const oop = acequire("ace/lib/oop");
    const TextMode = acequire("ace/mode/text").Mode;
    const DdDslHighlightRules = acequire(
      "ace/mode/dd_dsl_highlight_rules"
    ).DdDslHighlightRules;
    const CstyleBehaviour = acequire(
      "ace/mode/behaviour/cstyle"
    ).CstyleBehaviour;

    var Mode = function () {
      this.HighlightRules = DdDslHighlightRules;
    };
    oop.inherits(Mode, TextMode);

    (function () {
      // this.lineCommentStart = ["--", "#"]; // todo space
      // this.blockComment = {start: "/*", end: "*/"};
      this.$behaviour = new CstyleBehaviour();
      this.$id = "ace/mode/dd_dsl";
    }.call(Mode.prototype));

    exports.Mode = Mode;
  }
);
