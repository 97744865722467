import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TypeWidgetCell from "./TypeWidgetCell";
import actions from "actions/fields_page";

import DependenciesCell from "./DependenciesCell";
import SettingsCell from "./SettingsCell";
import ValidationsCell from "./ValidationsCell";

import { OverlayTrigger, Popover } from "react-bootstrap";

const FieldRow = ({
  studyId,
  field,
  fieldSelected,
  fieldHighlighted,
  widgetLabel,
  handleEditLabelIdentifierFormOpen,
  handleOpen,
  handleSelectFieldForBulkAction,
  handleRemoveSelectedFieldForBulkAction,
  handleOpenEditDependenciesForm,
  handleOpenSettingsForm,
  handleOpenNewValidationRulesForm,
  handleOpenEditValidationRulesForm,
  handleDeleteValidation,
}) => {
  const { id, identifier, label } = field;

  const sharedProps = {
    studyId: studyId,
    recordId: id,
  };

  const handleSelectField = (e, fieldId) => {
    if (e.target.checked) {
      handleSelectFieldForBulkAction(studyId, fieldId);
    } else {
      handleRemoveSelectedFieldForBulkAction(fieldId);
    }
  };

  const warningTooltip = () => {
    return (
      <Popover id={`warning-popover-${field.id}`} title="Warning">
        <p>This field is referenced by:</p>
        <ul className="margin-left-0 padding-left-20">
          {field.referred_warnings.map((warning, index) => {
            return <li key={index}>{warning}</li>;
          })}
        </ul>
        <strong>Modifying this field may make these invalid.</strong>
      </Popover>
    );
  };

  return (
    <>
      <tr
        id={`form_field_${id}`}
        className={`form_field ${
          fieldSelected ? "selected" : fieldHighlighted ? "highlighted" : ""
        }`}
      >
        <td className="vertical-center" style={{ width: "50%" }}>
          <div className="cell-with-action-button">
            <button
              onClick={() => handleEditLabelIdentifierFormOpen(field)}
              title="Edit the label/identifier of this field"
              className="btn btn-link action-button"
            >
              <i className="fa fa-edit" />
            </button>
            <span>
              {label ? (
                <strong className="field-label">{label}</strong>
              ) : (
                <em className="field-label">Empty</em>
              )}
              <em>
                <div className="field-identifier">{identifier}</div>
              </em>
            </span>
          </div>
        </td>
        <TypeWidgetCell
          field={field}
          widgetLabel={widgetLabel}
          handleOpen={handleOpen}
        />
        <DependenciesCell
          field={field}
          handleOpen={handleOpenEditDependenciesForm}
        />
        <ValidationsCell
          field={field}
          handleOpenNewForm={handleOpenNewValidationRulesForm}
          handleOpenEditForm={handleOpenEditValidationRulesForm}
          handleDelete={handleDeleteValidation}
        />
        <SettingsCell field={field} handleOpen={handleOpenSettingsForm} />
        <td className="mini vertical-center">
          {field.referred_warnings.length > 0 && (
            <OverlayTrigger placement="left" overlay={warningTooltip()}>
              <i className="fa fa-exclamation-triangle text-danger" />
            </OverlayTrigger>
          )}
          <input
            className="select-field"
            type="checkbox"
            onChange={(e) => {
              handleSelectField(e, id);
            }}
            checked={fieldSelected}
          />
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const field = ownProps.field;
  const widgetOptions = state.editTypeModal.widgetTypeMap[field.field_type];
  let widget;
  if (widgetOptions) {
    widget = widgetOptions.find((option) => {
      return option.value === field.widget_type;
    });
  }
  return {
    studyId: state.currentFormOrSubform.study_id,
    field: field,
    widgetLabel: widget ? widget.label : undefined,
    fieldHighlighted: ownProps.fieldHighlighted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleEditableUpdate: actions.handleEditableUpdate,
      handleOpen: actions.handleOpen,
      handleEditLabelIdentifierFormOpen:
        actions.handleEditLabelIdentifierFormOpen,
      handleRemoveSelectedFieldForBulkAction:
        actions.handleRemoveSelectedFieldForBulkAction,
      handleSelectFieldForBulkAction: actions.handleSelectFieldForBulkAction,
      handleOpenEditDependenciesForm: actions.handleOpenEditDependenciesForm,
      handleOpenSettingsForm: actions.handleOpenSettingsForm,
      handleOpenNewValidationRulesForm:
        actions.handleOpenNewValidationRulesForm,
      handleOpenEditValidationRulesForm:
        actions.handleOpenEditValidationRulesForm,
      handleDeleteValidation: actions.handleDeleteValidation,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldRow);
