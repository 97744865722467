import { ActionTypes } from "actions/entity_groups_page";

export const EntityGroupTableReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.ORDER_ENTITY_GROUPS: {
      const currentEntityGroups = state.entityGroups;
      const orderedEntityGroupIds = action.orderedEntityGroupIds;

      const newOrderedEntityGroups = orderedEntityGroupIds.map(
        (entityGroupId) =>
          currentEntityGroups.find(
            (entityGroup) => entityGroup.id == entityGroupId
          )
      );

      return {
        ...state,
        entityGroups: newOrderedEntityGroups,
      };
    }
    case ActionTypes.DELETE_ENTITY_GROUP_MODAL_TOGGLE: {
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
        entityGroupToDelete: action.entityGroup,
      };
    }
    case ActionTypes.DELETE_ENTITY_GROUP: {
      return {
        ...state,
        processingRequest: true,
        entityGroupDeleteErrors: undefined,
      };
    }
    case ActionTypes.DELETE_ENTITY_GROUP_SUCCESS: {
      let newEntityGroups = state.entityGroups.filter(
        (entityGroup) => entityGroup.id != action.entityGroupId
      );

      return {
        ...state,
        processingRequest: false,
        entityGroupToDelete: undefined,
        showDeleteModal: false,
        entityGroups: newEntityGroups,
      };
    }
    case ActionTypes.NEW_ENTITY_GROUP_FORM_SUBMIT_SUCCESS: {
      let newEntityGroups = [...state.entityGroups, action.entityGroup];
      return { ...state, entityGroups: newEntityGroups };
    }
    case ActionTypes.EDIT_ENTITY_GROUP_FORM_SUBMIT_SUCCESS: {
      let updatefdEntityGroup = action.entityGroup;
      let updatefdEntityGroups = state.entityGroups.map((entityGroup) => {
        return entityGroup.id === updatefdEntityGroup.id
          ? updatefdEntityGroup
          : entityGroup;
      });

      return {
        ...state,
        entityGroups: updatefdEntityGroups,
      };
    }
  }
  return state;
};
