import React from "react";
import { connect } from "react-redux";
import { Col, FormGroup, ControlLabel, HelpBlock } from "react-bootstrap";

import { Select } from "components/helpers";

const FieldFormatDropdown = ({
  colSize,
  selectLabel,
  selectPlaceholder,
  formatOptions,
  values,
  saving,
  errors,
  handleFormatChange,
  showSiteLookupError,
  studyId,
}) => {
  const selectedOption = () => {
    return formatOptions.find((option) => option.value === values.field_format);
  };

  return (
    <>
      {formatOptions && formatOptions.length > 0 && (
        <Col sm={colSize}>
          <FormGroup
            controlId="field_format"
            validationState={errors.field_format ? "error" : null}
          >
            <ControlLabel>
              <abbr title="required">*</abbr>
              &nbsp;{selectLabel}
            </ControlLabel>
            <Select
              placeholder={selectPlaceholder}
              options={formatOptions}
              classNamePrefix="select-field-format"
              className="select-field-format"
              value={selectedOption()}
              onChange={handleFormatChange}
              isDisabled={saving}
            />
            {errors.field_format && (
              <HelpBlock
                bsClass="inline-help-block"
                dangerouslySetInnerHTML={{ __html: errors.field_format[0] }}
              />
            )}
          </FormGroup>
        </Col>
      )}
      {showSiteLookupError && (
        <Col sm={colSize}>
          <div className="alert alert-danger margin-bottom-0">
            No site lookups have been defined. You can add new site lookups on
            the{" "}
            <a
              href={"/study/" + studyId + "/sites"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Sites
            </a>{" "}
            tab.
          </div>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  let modalState, fieldType, values, errors;
  let formatOptions = [];
  let selectLabel = "Format";
  let selectPlaceholder = "Select field format";
  let showSiteLookupError = false;

  if (state.addField.showForm) {
    modalState = state.addField;
    values = modalState.values;
    fieldType = values.type;
    errors = modalState.errors.field;

    if (fieldType) {
      let availableFormatOptions = modalState.availableTypes.find((option) => {
        return option.value === fieldType;
      });

      if (availableFormatOptions) {
        formatOptions = availableFormatOptions["format_options"];
      }
    }
  } else {
    modalState = state.editSettings;
    formatOptions = modalState.field.format_options;
    fieldType = modalState.field.field_type;
    values = modalState.values;
    errors = modalState.errors;
  }

  if (fieldType && fieldType === "FormFields::SiteLookupType") {
    showSiteLookupError = formatOptions.length == 0;
    selectLabel = "Site lookup";
    selectPlaceholder = "Select site lookup";
  }

  return {
    selectLabel: selectLabel,
    selectPlaceholder: selectPlaceholder,
    formatOptions: formatOptions,
    values: values,
    lookupOptions: modalState.lookupOptions,
    saving: modalState.saving,
    errors: errors,
    showSiteLookupError: showSiteLookupError,
    studyId: modalState.studyId,
  };
};

export default connect(mapStateToProps)(FieldFormatDropdown);
