export const HANDLE_OPEN_NEW_QUESTIONNAIRE_FORM =
  "HANDLE_OPEN_NEW_QUESTIONNAIRE_FORM";
export const HANDLE_OPEN_EDIT_QUESTIONNAIRE_FORM =
  "HANDLE_OPEN_EDIT_QUESTIONNAIRE_FORM";
export const HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT =
  "HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT";
export const HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT =
  "HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT";
export const HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS =
  "HANDLE_NEW_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS";
export const HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS =
  "HANDLE_EDIT_QUESTIONNAIRE_FORM_SUBMIT_SUCCESS";
export const HANDLE_QUESTIONNAIRE_FORM_SUBMIT_FAILURE =
  "HANDLE_QUESTIONNAIRE_FORM_SUBMIT_FAILURE";
export const HANDLE_QUESTIONNAIRE_FORM_VALUE_CHANGE =
  "HANDLE_QUESTIONNAIRE_FORM_VALUE_CHANGE";
export const HANDLE_SELECT_EVENTS_CHANGE = "HANDLE_SELECT_EVENTS_CHANGE";
export const HANDLE_SELECT_FORMS_CHANGE = "HANDLE_SELECT_FORMS_CHANGE";
export const HANDLE_GET_AVAILABLE_FIELDS_SUCCESS =
  "HANDLE_GET_AVAILABLE_FIELDS_SUCCESS";
export const HANDLE_MEANS_OF_DELIVERY_CHANGE =
  "HANDLE_MEANS_OF_DELIVERY_CHANGE";
export const HANDLE_MEANS_OF_DELIVERY_FIELD_VALUE_CHANGE =
  "HANDLE_MEANS_OF_DELIVERY_FIELD_VALUE_CHANGE";
export const HANDLE_QUESTIONNAIRE_MEANS_OF_DELIVERY_MESSAGE_TEMPLATE_CHANGE =
  "HANDLE_QUESTIONNAIRE_MEANS_OF_DELIVERY_MESSAGE_TEMPLATE_CHANGE";
