import { ActionTypes } from "../../actions/fields_page";

export const VALIDATION_RULE_COLLECTIONS = {
  pre_entry: [
    { label: "Off", value: "off" },
    { label: "Soft", value: "soft" },
    { label: "Hard", value: "hard" },
  ],
  post_entry: [
    { label: "Off", value: "off" },
    { label: "Soft", value: "soft" },
    { label: "Hard", value: "hard" },
  ],
  permission_level: [
    { label: "Normal", value: "normal" },
    { label: "Restricted", value: "restricted" },
    {
      label: "Validation tool",
      value: "validation_tool_permission_required",
    },
  ],
  privacy_tags: [
    { label: "Clinical", value: "clinical" },
    { label: "Personal", value: "personal" },
    { label: "Sensitive", value: "sensitive" },
    { label: "Central", value: "central" },
  ],
  priority: [
    { label: "Low", value: "low" },
    { label: "Normal", value: "normal" },
    { label: "High", value: "high" },
    { label: "Protocol non-compliance", value: "non_compliance" },
  ],
  boolean_equal: [
    { label: "True", value: true },
    { label: "False", value: false },
  ],
};

let defaultState = {
  showForm: false,
  saving: false,
  field: {},
  validation: {},
  errors: {},
  loadingValidationFields: false,
  loadingConditionFields: false,
  validationFields: [],
  conditionFields: [],
  lookupOptions: [],
  values: {
    validation_rule_type: "",
    equal_to: "",
    not_equal_to: "",
    custom_format_dsl: "",
    min_value: "",
    max_value: "",
    low_value: "",
    high_value: "",
    run_if: "",
    pre_entry: "off",
    post_entry: "soft",
    permission_level: "normal",
    privacy_tags: [],
    priority: "normal",
    use_custom_message: false,
    custom_message: "",
    min_custom_message: "",
    low_custom_message: "",
    max_custom_message: "",
    high_custom_message: "",
    unique_within_event: false,
    unique_within_subforms: false,
    unique_within_site: false,
    unique_within_individual: false,
    unique_within_study: false,
  },
  collections: VALIDATION_RULE_COLLECTIONS,
};

let appliesToDefaults = {
  range: {
    values: {
      pre_entry: "on",
      post_entry: "on",
      priority: "normal",
    },
    collections: {
      ...defaultState.collections,
      pre_entry: [
        { label: "On", value: "on" },
        { label: "Off", value: "off" },
      ],
      post_entry: [
        { label: "On", value: "on" },
        { label: "Off", value: "off" },
      ],
    },
  },
};

export const ValidationRules = (state = defaultState, action) => {
  const closeForm = () => {
    let newState = {
      ...state,
      values: {},
      errors: {},
      showForm: false,
      saving: false,
      loadingValidationFields: false,
      loadingConditionFields: false,
      validationFields: [],
      conditionFields: [],
    };
    return newState;
  };

  const setFormState = (state, values) => {
    if (appliesToDefaults[values.validation_rule_type]) {
      let valuesWithDefaults;
      if (action.fieldName == "validation_rule_type") {
        // Get the current values, and overwrite with our specific type defaults
        valuesWithDefaults = {
          ...values,
          ...appliesToDefaults[values.validation_rule_type].values,
        };
      } else {
        // Otherwise use whatever is currently set
        valuesWithDefaults = values;
      }
      return {
        ...state,
        values: valuesWithDefaults,
        collections: appliesToDefaults[values.validation_rule_type].collections,
      };
    } else {
      return {
        ...state,
        values: { ...defaultState.values, ...values },
        collections: { ...defaultState.collections },
      };
    }
  };

  switch (action.type) {
    case ActionTypes.UPDATE_VALIDATION_RULES_FORM_VALUE: {
      const values = { ...state.values };
      values[action.fieldName] = action.value;

      // Ensure the privacy tags are cleared if at non-restricted permission level
      if (
        action.fieldName === "permission_level" &&
        action.value !== "restricted"
      ) {
        values["privacy_tags"] = [];
      }

      if (action.fieldName === "use_custom_message" && action.value != true) {
        values["custom_message"] = "";
        values["min_custom_message"] = "";
        values["max_custom_message"] = "";
        values["high_custom_message"] = "";
        values["low_custom_message"] = "";
      }

      return setFormState(state, values);
    }

    case ActionTypes.HANDLE_OPEN_NEW_VALIDATION_RULES_FORM: {
      let field = action.field;
      let newState = {
        ...defaultState,
        showForm: true,
        editing: false,
        loadingValidationFields: true,
        loadingConditionFields: true,
        field: field,
        lookupOptions: field.lookup_options_labels,
      };
      return newState;
    }

    case ActionTypes.HANDLE_OPEN_EDIT_VALIDATION_RULES_FORM: {
      let field = action.field;
      let validation = action.validation;
      let newState = {
        ...defaultState,
        showForm: true,
        editing: true,
        loadingValidationFields: true,
        loadingConditionFields: true,
        field: field,
        validation: validation,
        lookupOptions: field.lookup_options_labels,
        values: {
          validation_rule_type: validation.validation_rule_type,
          equal_to: validation.parameters.value,
          not_equal_to: validation.parameters.value,
          custom_format_dsl: validation.parameters.custom_format_dsl || "",
          min_value: validation.parameters.min_value || "",
          max_value: validation.parameters.max_value || "",
          low_value: validation.parameters.low_value || "",
          high_value: validation.parameters.high_value || "",
          run_if: validation.parsed_run_if,
          pre_entry: validation.pre_entry,
          post_entry: validation.post_entry,
          permission_level: validation.permission_level,
          privacy_tags: defaultState.collections.privacy_tags.filter(
            (option) =>
              validation.privacy_tags_for_react.indexOf(option.value) !== -1
          ),
          priority: validation.priority,
          use_custom_message: validation.uses_custom_message,
          custom_message: validation.custom_message,
          min_custom_message: validation.parameters.min_custom_message || "",
          max_custom_message: validation.parameters.max_custom_message || "",
          low_custom_message: validation.parameters.low_custom_message || "",
          high_custom_message: validation.parameters.high_custom_message || "",
          unique_within_event: field.unique_within_event,
          unique_within_subforms: field.unique_within_subforms,
          unique_within_site: field.unique_within_site,
          unique_within_individual: field.unique_within_individual,
          unique_within_study: field.unique_within_study,
        },
      };
      return setFormState(newState, newState.values);
    }

    case ActionTypes.HANDLE_CLOSE_VALIDATION_RULES_FORM: {
      return closeForm();
    }

    case ActionTypes.HANDLE_GET_FIELDS_FOR_VALIDATION: {
      const newState = { ...state };
      newState.loadingValidationFields = false;
      newState.validationFields = action.fields;

      return newState;
    }

    case ActionTypes.HANDLE_GET_FIELDS_FOR_DEPENDENCIES: {
      const newState = { ...state };
      newState.loadingConditionFields = false;
      newState.conditionFields = action.fields;

      return newState;
    }

    case ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_SUBMIT: {
      const newState = { ...state };
      newState.saving = true;

      return newState;
    }

    case ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_ERROR: {
      const newState = { ...state };
      newState.saving = false;
      newState.errors = action.errors;

      return newState;
    }

    case ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_SUCCESS: {
      return closeForm();
    }

    case ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_SUBMIT: {
      const newState = { ...state };
      newState.saving = true;

      return newState;
    }

    case ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_ERROR: {
      const newState = { ...state };
      newState.saving = false;
      newState.errors = action.errors;

      return newState;
    }

    case ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_SUCCESS: {
      return closeForm();
    }
  }

  return state;
};
