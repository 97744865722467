import * as ActionTypes from "./messageTemplateActionTypes";

export const handleOpenNewMessageTemplateForm = () => ({
  type: ActionTypes.HANDLE_OPEN_NEW_MESSAGE_TEMPLATE_FORM,
});

export const handleCloseMessageTemplateForm = () => ({
  type: ActionTypes.HANDLE_CLOSE_MESSAGE_TEMPLATE_FORM,
});

export const handleMessageTemplateFormValueChange = (fieldName, value) => ({
  type: ActionTypes.HANDLE_MESSAGE_TEMPLATE_FORM_VALUE_CHANGE,
  fieldName,
  value,
});

export const handleNewMessageTemplateFormSubmit = (studyId, values) => ({
  type: ActionTypes.HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT,
  studyId,
  values,
});

export const handleNewMessageTemplateFormSubmitSuccess = (messageTemplate) => ({
  type: ActionTypes.HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS,
  messageTemplate,
});

export const handleMessageTemplateFormSubmitFailure = (errors) => ({
  type: ActionTypes.HANDLE_MESSAGE_TEMPLATE_FORM_SUBMIT_FAILURE,
  errors,
});

export const handleMessageTemplateDeleteModalToggle = (messageTemplate) => ({
  type: ActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE_MODAL_TOGGLE,
  messageTemplate,
});

export const handleMessageTemplateDelete = (studyId, messageTemplateId) => ({
  type: ActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE,
  studyId,
  messageTemplateId,
});

export const handleMessageTemplateDeleteSuccess = (messageTemplateId) => ({
  type: ActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE_SUCCESS,
  messageTemplateId,
});

export const handleMessageTemplateDeleteFailure = (failReason) => ({
  type: ActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE_FAILURE,
  failReason,
});

export const handleMessageTemplateEditClick = (messageTemplate) => ({
  type: ActionTypes.HANDLE_MESSAGE_TEMPLATE_EDIT_CLICK,
  messageTemplate,
});

export const handleUpdateMessageTemplateFormSubmit = (
  studyId,
  messageTemplateId,
  values
) => ({
  type: ActionTypes.HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT,
  studyId,
  messageTemplateId,
  values,
});

export const handleUpdateMessageTemplateFormSubmitSuccess = (
  messageTemplate
) => ({
  type: ActionTypes.HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT_SUCCESS,
  messageTemplate,
});
