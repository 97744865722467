import { ActionTypes } from "../../actions/fields_page";

const closedState = {
  shown: false,
  saving: false,
  field: {},
  widgetOptions: [],
  errors: {
    field: {},
    newLookup: {},
    newLookupOptions: {},
  },
  newLookup: { identifier: "" },
  newLookupOptions: [],
};

export const editTypeDefaultState = {
  ...closedState,
  widgetTypeMap: {},
  allLookupOptions: [],
  lookupOptions: [],
};

const lookupOptionsFor = (allLookupOptions, fieldType) => {
  const filteredLookupOptions = allLookupOptions.filter(
    (option) => option.field_type_filter === fieldType
  );
  return [
    { value: "new_lookup", label: "New lookup" },
    ...filteredLookupOptions,
  ];
};

export const editTypeReducer = (state = editTypeDefaultState, action) => {
  switch (action.type) {
    case ActionTypes.EDIT_TYPE_OPEN: {
      const field = action.field;
      const newState = {
        ...state,
        shown: true,
        saving: false,
        widgetOptions: state.widgetTypeMap[field.field_type],
        lookupOptions: lookupOptionsFor(
          state.allLookupOptions,
          field.field_type
        ),
        field: field,
        originalLookupId: field.lookup_id,
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_CLOSE: {
      const newState = {
        ...state,
        ...closedState,
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_FIELD_TYPE_CHANGE: {
      if (state.saving) return state;

      const newWidgetTypeOptions = state.widgetTypeMap[action.fieldType];
      const newField = {
        ...state.field,
        field_type: action.fieldType,
        widget_type: newWidgetTypeOptions[0].value,
      };

      const newState = {
        ...state,
        field: newField,
        widgetOptions: newWidgetTypeOptions,
        lookupOptions: lookupOptionsFor(
          state.allLookupOptions,
          action.fieldType
        ),
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_WIDGET_TYPE_CHANGE: {
      if (state.saving) return state;

      const newField = {
        ...state.field,
        widget_type: action.widgetType,
      };
      const newState = {
        ...state,
        field: newField,
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_UNIT_CHANGE: {
      if (state.saving) return state;

      const newField = {
        ...state.field,
        unit: action.unit,
      };
      const newState = {
        ...state,
        field: newField,
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_PRECISION_CHANGE: {
      if (state.saving) return state;

      const newField = {
        ...state.field,
        precision: action.precision,
      };
      const newState = {
        ...state,
        field: newField,
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_LOOKUP_CHANGE: {
      if (state.saving) return state;

      const newField = {
        ...state.field,
        lookup_id: action.lookupId,
      };
      const newState = {
        ...state,
        field: newField,
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_SUBMIT: {
      const newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.EDIT_TYPE_SUBMIT_SUCCESS: {
      const newState = {
        ...state,
        ...closedState,
        allLookupOptions: action.data.lookups,
      };
      return newState;
    }

    case ActionTypes.EDIT_FIELD_TYPE_ERROR: {
      const newState = {
        ...state,
        saving: false,
        errors: {
          field: action.field.json_errors,
          newLookup: action.field.lookup_json_errors,
          newLookupOptions: action.field.lookup_options_json_errors,
        },
      };
      return newState;
    }

    case ActionTypes.ADD_LOOKUP_OPTION: {
      const newState = { ...state };
      const lookupOptions = [...newState.newLookupOptions];
      lookupOptions.push({ identifier: "", label: "" });
      newState.newLookupOptions = lookupOptions;
      return newState;
    }

    case ActionTypes.REORDER_LOOKUP_OPTION: {
      const newState = { ...state };
      const lookupOptions = [...newState.newLookupOptions];

      const [movedRow] = lookupOptions.splice(action.result.source.index, 1);
      lookupOptions.splice(action.result.destination.index, 0, movedRow);

      newState.newLookupOptions = lookupOptions;
      return newState;
    }

    case ActionTypes.REMOVE_LOOKUP_OPTION: {
      const newState = { ...state };
      const lookupOptions = [...newState.newLookupOptions];
      lookupOptions.splice(action.index, 1);
      newState.newLookupOptions = lookupOptions;
      return newState;
    }

    case ActionTypes.HANDLE_NEW_LOOKUP_VALUE_CHANGE: {
      const newState = { ...state };
      const newLookup = { ...newState.newLookup };
      newLookup[action.field] = action.value;
      newState.newLookup = newLookup;
      return newState;
    }

    case ActionTypes.HANDLE_NEW_LOOKUP_OPTION_VALUE_CHANGE: {
      const newState = { ...state };
      const newLookupOptions = [...newState.newLookupOptions];
      newLookupOptions[action.index][action.field] = action.value;
      newState.newLookupOptions = newLookupOptions;
      return newState;
    }

    // Handle any new lookups being created on the 'Add Field' modal
    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS:
    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE: {
      let newState = {
        ...state,
        allLookupOptions: action.data.lookups,
      };
      return newState;
    }
  }

  return state;
};
