import React, { useState } from "react";

import { deleteRequest } from "../services/base_requests";
import { addClickListener } from "../services/browser_dom_support";

import DeletionModal from "../components/helpers/DeletionModal";

const EventDeletionPage = () => {
  const [processingRequest, setProcessingRequest] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);
  const [requestURL, setRequestURL] = useState("");
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [requestMade, setRequestMade] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [referencesPreventingDelete, setReferencesPreventingDelete] = useState(
    {}
  );
  const canMakeDeleteRequest =
    processingRequest == false && requestMade == false;
  const canCloseModal = processingRequest == false && requestMade == true;
  const onHide = processingRequest ? undefined : () => setConfirmShow(false);
  const onConfirm = () => makeDeleteRequest();
  const canCancel = showCancelButton && !processingRequest;

  const makeDeleteRequest = () => {
    setProcessingRequest(true);
    setRequestMade(true);

    deleteRequest({
      url: requestURL,
      body: {},
    }).then(async (res) => {
      let resData = await res.json();
      setProcessingRequest(false);

      if (res.status === 200) {
        setConfirmShow(false);

        const subformRow = document.querySelectorAll(
          `[data-sub-row-for="#event_${resData.event_id}"]`
        )[0];
        subformRow.remove();

        const studyForm = document.getElementById(`event_${resData.event_id}`);
        studyForm.remove();

        window
          .findComponentCounter("events")
          .trigger(window.COMPONENT_COUNT_DECREMENT);

        const entityGroupTableSelector = `#entity_group_${resData.entity_group_id}_table`;
        if ($(`${entityGroupTableSelector} tbody tr`).length == 0)
          $(entityGroupTableSelector).prepend(
            $("#event-widget").data("no-record-row")
          );
      }

      if (res.status === 422) {
        setConfirmTitle("Event could not be deleted");
        setShowCancelButton(false);
        setHasErrors(true);
        setConfirmShow(true);
        setReferencesPreventingDelete(resData.referencesPreventingDelete);
      }
    });
  };

  const openModal = (element) => {
    setConfirmTitle(element.title);
    setConfirmMessage(element.dataset.customConfirmMessage);
    setRequestURL(element.dataset.requestUrl);
    setShowCancelButton(true);
    setHasErrors(false);
    setRequestMade(false);
    setReferencesPreventingDelete({});
    setConfirmShow(true);
  };
  addClickListener(".delete-event-button", openModal);

  return (
    <DeletionModal
      title={confirmTitle}
      message={confirmMessage}
      show={confirmShow}
      onHide={onHide}
      onConfirm={onConfirm}
      processingRequest={processingRequest}
      hasErrors={hasErrors}
      referencesPreventingDelete={referencesPreventingDelete}
      canMakeDeleteRequest={canMakeDeleteRequest}
      canCloseModal={canCloseModal}
      canCancel={canCancel}
    />
  );
};

export default EventDeletionPage;
