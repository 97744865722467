import * as ActionTypes from "./action_types";

export const handleMoveElement = (params) => ({
  type: ActionTypes.MOVE_ELEMENT,
  params,
});

export const handleMoveElementFailure = (params) => ({
  type: ActionTypes.MOVE_ELEMENT_FAILURE,
  params,
});

export const handleBulkMove = (studyId, targetSectionId, fieldsToProcess) => ({
  type: ActionTypes.BULK_MOVE,
  studyId,
  targetSectionId,
  fieldsToProcess,
});

export const handleBulkRemove = (
  studyId,
  targetSectionId,
  fieldsToProcess
) => ({
  type: ActionTypes.BULK_REMOVE,
  studyId,
  targetSectionId,
  fieldsToProcess,
});

export const handleCancelBulkMove = () => ({
  type: ActionTypes.CANCEL_BULK_MOVE,
});

export const handleBulkMoveSuccess = (studyForm) => ({
  type: ActionTypes.BULK_MOVE_SUCCESS,
  studyForm,
});

export const handleBulkMoveFailure = () => ({
  type: ActionTypes.BULK_MOVE_FAILURE,
});

export const handleTargetSectionSelect = (targetSection) => ({
  type: ActionTypes.TARGET_SECTION_SELECTED,
  targetSection,
});

export const handleRemoveSection = (studyId, sectionId) => ({
  type: ActionTypes.REMOVE_SECTION,
  studyId,
  sectionId,
});

export const handleFormSectionForEdit = (studyId, formSection) => ({
  type: ActionTypes.FORM_SECTION_SELECTED_FOR_EDIT,
  studyId,
  formSection,
});

export const handleRemoveSectionSuccess = (studyForm, sectionId, formId) => ({
  type: ActionTypes.REMOVE_SECTION_SUCCESS,
  studyForm,
  sectionId,
  formId,
});

export const handleSectionFormClose = () => ({
  type: ActionTypes.CLOSE_SECTION_FORM,
});

export const handleSectionFormShow = () => ({
  type: ActionTypes.SHOW_SECTION_FORM,
});

export const handleSectionFormSave = (studyId, studyFormId, details) => ({
  type: ActionTypes.SAVE_SECTION_FORM,
  studyId,
  studyFormId,
  details,
});

export const handleSectionFormUpdate = (studyId, formSectionId, details) => ({
  type: ActionTypes.UPDATE_SECTION_FORM,
  studyId,
  formSectionId,
  details,
});

export const handleSectionFormSaveError = (newSection) => ({
  type: ActionTypes.SAVE_SECTION_FORM_ERROR,
  newSection,
});

export const handleSectionFormUpdateError = (formSection) => ({
  type: ActionTypes.UPDATE_SECTION_FORM_ERROR,
  formSection,
});

export const handleSectionFormUpdateSuccess = (formSection) => ({
  type: ActionTypes.UPDATE_SECTION_FORM_SUCCESS,
  formSection,
});

export const hideSectionFormSuccessNotice = () => ({
  type: ActionTypes.HIDE_SECTION_FORM_SUCCESS_NOTICE,
});

export const handleSectionFormSaveSuccess = (newSection, formId) => ({
  type: ActionTypes.SAVE_SECTION_FORM_SUCCESS,
  newSection,
  formId,
});

export const handleSectionFormSaveAndClose = (
  studyId,
  studyFormId,
  details
) => ({
  type: ActionTypes.SAVE_AND_CLOSE_SECTION_FORM,
  studyId,
  studyFormId,
  details,
});

export const handleSectionFormSaveAndCloseSuccess = (newSection, formId) => ({
  type: ActionTypes.SAVE_AND_CLOSE_SECTION_FORM_SUCCESS,
  newSection,
  formId,
});

export const handleAddFieldsToMove = (fieldsToAdd) => ({
  type: ActionTypes.ADD_FIELDS_TO_MOVE,
  fieldsToAdd,
});

export const handleRemoveFieldsToMove = (fieldsToRemove) => ({
  type: ActionTypes.REMOVE_FIELDS_TO_MOVE,
  fieldsToRemove,
});

export const handleDragStart = () => ({
  type: ActionTypes.DRAG_START,
});

export const handleNavSearch = (studyId, searchTerm) => ({
  type: ActionTypes.NAV_SEARCH,
  studyId: studyId,
  searchTerm: searchTerm,
});

export const handleNavSearchSuccess = (studyForms) => ({
  type: ActionTypes.NAV_SEARCH_SUCCESS,
  studyForms: studyForms,
});

export const handleNavSetSearchTerm = (searchTerm) => ({
  type: ActionTypes.NAV_SET_SEARCH_TERM,
  searchTerm: searchTerm,
});
