import { ActionTypes } from "../../actions/revisions_page";

export const RevisionReducer = (
  state = { committed: false, management_information: { expanded: true } },
  action
) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_EXPAND_SECTION: {
      const section = state[action.targetSection];
      const newSection = { ...section, expanded: !section.expanded };

      return { ...state, [action.targetSection]: newSection };
    }
    case ActionTypes.EXPAND_ALL_CHANGES_IN_SECTION: {
      const section = state[action.targetSection];
      const expandedChanges = section.changes.map((change) => {
        return { ...change, expanded: true };
      });
      const newSection = { ...section, changes: expandedChanges };

      return { ...state, [action.targetSection]: newSection };
    }
    case ActionTypes.COLLAPSE_ALL_CHANGES_IN_SECTION: {
      const section = state[action.targetSection];
      const expandedChanges = section.changes.map((change) => {
        return { ...change, expanded: false };
      });
      const newSection = { ...section, changes: expandedChanges };

      return { ...state, [action.targetSection]: newSection };
    }
    case ActionTypes.TOGGLE_EXPAND_CHANGE: {
      const section = state[action.targetSection];
      const expandedChanges = section.changes.map((change) => {
        return change.id === action.targetChange
          ? { ...change, expanded: !change.expanded }
          : change;
      });
      const newSection = { ...section, changes: expandedChanges };

      return { ...state, [action.targetSection]: newSection };
    }
    case ActionTypes.SAVE_SUCCESS: {
      return {
        ...state,
        management_information: { expanded: false },
      };
    }
    case ActionTypes.UPDATE_FORM:
    case ActionTypes.COMMIT_FAILURE:
    case ActionTypes.SAVE_FAILURE: {
      return {
        ...state,
        management_information: { expanded: true },
      };
    }
    default:
      return state;
  }
};
