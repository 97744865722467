import React from "react";
import { Draggable } from "react-beautiful-dnd";

const DraggableFormSectionElement = ({
  element,
  index,
  type,
  children,
  isDragDisabled,
}) => {
  return (
    <Draggable
      draggableId={`${type}_${element.id}`}
      index={index}
      type={type}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={snapshot.isDragging ? "dragging" : ""}
        >
          {React.cloneElement(children, {
            dragHandleProps: provided.dragHandleProps,
          })}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableFormSectionElement;
