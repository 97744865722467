import { ActionTypes } from "../../actions/duplicate_study_forms";

const blankValues = {
  study: null,
  form: null,
  formName: "",
  formIdentifier: "",
};

export const defaultDependencyReport = {
  excludedFields: [],
  fieldsWithNonLocalDependencies: [],
  validationRulesWithNonLocalDependencies: [],
  validationRulesToRemove: [],
  subformsWithNonLocalDependencies: [],
};

export const duplicateModelDefaultState = {
  ...defaultDependencyReport,
  show: false,
  errors: {},
  saving: false,
  studyId: null,
  studyName: null,
  studiesToCopyFrom: [],
  formsToCopy: [],
  filteredFormsToCopy: [],
  disableSubmitButtonText: "Saving",
  checkingDependencies: false,
  changedFormName: false,
  changedFormIdentifier: false,
  values: blankValues,
};

export const DuplicateModalReducer = (
  state = duplicateModelDefaultState,
  action
) => {
  switch (action.type) {
    case ActionTypes.OPEN_MODAL: {
      const newState = { ...state, show: true };
      return newState;
    }
    case ActionTypes.CLOSE_MODAL: {
      const newState = {
        ...state,
        ...defaultDependencyReport,
        show: false,
        errors: {},
        values: blankValues,
      };

      return newState;
    }
    case ActionTypes.UPDATE_FORM_VALUE: {
      const changedFormName = state.changedFormName
        ? true
        : action.fieldName == "formName";
      const changedFormIdentifier = state.changedFormIdentifier
        ? true
        : action.fieldName == "formIdentifier";
      const newValues = { ...state.values, [action.fieldName]: action.value };
      const newState = {
        ...state,
        values: newValues,
        changedFormName: changedFormName,
        changedFormIdentifier: changedFormIdentifier,
      };
      return newState;
    }

    case ActionTypes.HANDLE_SELECT_STUDY: {
      const formsInSelectedStudy = state.formsToCopy.filter((form) => {
        return form.filter_value === action.value;
      });
      const newValues = {
        ...state.values,
        study: action.value,
        form: null,
        formName: "",
        formIdentifier: "",
      };
      const newState = {
        ...state,
        changedFormName: false,
        changedFormIdentifier: false,
        filteredFormsToCopy: formsInSelectedStudy,
        errors: {},
        values: newValues,
      };
      return newState;
    }
    case ActionTypes.HANDLE_SELECT_FORM: {
      const newFormName =
        state.changedFormName == false ? action.label : state.values.formName;
      const newFormIdentifier =
        state.changedFormIdentifier == false
          ? action.identifier
          : state.values.formIdentifier;
      const newValues = {
        ...state.values,
        form: action.value,
        formName: newFormName,
        formIdentifier: newFormIdentifier,
      };
      const newState = {
        ...state,
        ...defaultDependencyReport,
        errors: {},
        values: newValues,
        disableSubmitButtonText: "Checking dependencies",
        checkingDependencies: true,
      };
      return newState;
    }
    case ActionTypes.HANDLE_DEPENDENCIES_CHECK_SUCCESS: {
      const newState = {
        ...state,
        excludedFields: action.resData.excluded_fields,
        fieldsWithNonLocalDependencies: action.resData.fields,
        validationRulesWithNonLocalDependencies:
          action.resData.validation_rules_to_edit,
        validationRulesToRemove: action.resData.validation_rules_to_remove,
        subformsWithNonLocalDependencies: action.resData.subforms,
        checkingDependencies: false,
      };
      return newState;
    }
    case ActionTypes.HANDLE_DEPENDENCIES_CHECK_FAILURE: {
      const newState = {
        ...state,
        checkingDependencies: false,
      };
      return newState;
    }
    case ActionTypes.HANDLE_FORM_SUBMIT: {
      const newState = {
        ...state,
        saving: true,
        disableSubmitButtonText: "Saving",
      };
      return newState;
    }
    case ActionTypes.HANDLE_SUBMIT_SUCCESS: {
      window.location.reload();
      break;
      // const newState = { ...state, show: false, saving: false, errors: {} };
      // return newState;
    }
    case ActionTypes.HANDLE_SUBMIT_ERROR: {
      $.flashAlert(action.message, "alert-danger");
      const newState = {
        ...state,
        ...defaultDependencyReport,
        show: false,
        saving: false,
        errors: {},
        values: blankValues,
      };
      return newState;
    }
    case ActionTypes.HANDLE_SUBMIT_FAILURE: {
      const newState = {
        ...state,
        errors: action.resData.errors,
        saving: false,
      };
      return newState;
    }
  }

  return state;
};
