import React from "react";
import { Alert } from "react-bootstrap";

const SubformWarnings = ({ subform }) => {
  return (
    <li>
      <strong>{subform.label}</strong>
      <ul>
        {subform.fields.length > 0 && (
          <li>
            <dl>
              <dt>Field dependencies:</dt>
              <dd>
                <ul>
                  {subform.fields.map((fieldIdentifier) => {
                    return (
                      <li key={fieldIdentifier}>
                        <em>{fieldIdentifier}</em>
                      </li>
                    );
                  })}
                </ul>
              </dd>
            </dl>
          </li>
        )}
        {subform.validation_rules.length > 0 && (
          <li>
            <dl>
              <dt>Field validation rules:</dt>
              <dd>
                <ul>
                  {subform.validation_rules.map((validation_rule) => {
                    const validationRuleTypes =
                      validation_rule.validation_rule_types.join(", ");
                    return (
                      <li key={validation_rule.form_field_identifier}>
                        <strong>{validationRuleTypes}</strong> on{" "}
                        <em>{validation_rule.form_field_identifier}</em>
                      </li>
                    );
                  })}
                </ul>
              </dd>
            </dl>
          </li>
        )}
      </ul>
    </li>
  );
};

const DependencyWarnings = ({
  excludedFields,
  fieldsWithNonLocalDependencies,
  validationRulesWithNonLocalDependencies,
  validationRulesToRemove,
  subformsWithNonLocalDependencies,
}) => {
  const warningsToShow =
    excludedFields.length > 0 ||
    fieldsWithNonLocalDependencies.length > 0 ||
    validationRulesWithNonLocalDependencies.length > 0 ||
    validationRulesToRemove.length > 0 ||
    subformsWithNonLocalDependencies.length > 0;

  return (
    <>
      {warningsToShow && (
        <Alert bsStyle="warning">
          {excludedFields.length > 0 && (
            <div className="excluded-field-references">
              <strong>The following fields will not be copied:</strong>
              <ul>
                {excludedFields.map((fieldIdentifier, index) => {
                  return (
                    <li key={index}>
                      <em>{fieldIdentifier}</em>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {fieldsWithNonLocalDependencies.length > 0 && (
            <div className="field-references">
              <strong>
                The dependencies of the following fields reference other
                forms/events and will not be copied:
              </strong>
              <ul>
                {fieldsWithNonLocalDependencies.map(
                  (fieldIdentifier, index) => {
                    return (
                      <li key={index}>
                        <em>{fieldIdentifier}</em>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          )}
          {validationRulesWithNonLocalDependencies.length > 0 && (
            <div className="validation-rule-references">
              <strong>
                The dependencies of the following validation rules reference
                other forms/events and will not be copied:
              </strong>
              <ul>
                {validationRulesWithNonLocalDependencies.map(
                  (validationRuleDependency, index) => {
                    const validationRules =
                      validationRuleDependency.validation_rule_types.join(", ");
                    return (
                      <li key={index}>
                        <strong>{validationRules}</strong> on{" "}
                        <em>
                          {validationRuleDependency.form_field_identifier}
                        </em>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          )}
          {validationRulesToRemove.length > 0 && (
            <div className="validation-rule-to-remove-references">
              <strong>
                The following validation rules will cause duplicate rules of the
                same type and will not be copied:
              </strong>
              <ul>
                {validationRulesToRemove.map(
                  (validationRuleDependency, index) => {
                    const validationRules =
                      validationRuleDependency.validation_rule_types.join(", ");
                    return (
                      <li key={index}>
                        <strong>{validationRules}</strong> on{" "}
                        <em>
                          {validationRuleDependency.form_field_identifier}
                        </em>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          )}
          {subformsWithNonLocalDependencies.length > 0 && (
            <div className="subform-rule-references">
              <strong>
                The following subforms have fields with dependencies or
                validation rules that reference other forms/events and will not
                be copied:
              </strong>
              <ul>
                {subformsWithNonLocalDependencies.map((subform) => {
                  return (
                    <SubformWarnings
                      key={subform.identifier}
                      subform={subform}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </Alert>
      )}
    </>
  );
};

export default DependencyWarnings;
