import _ from "lodash/core";
import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, Checkbox, Alert } from "react-bootstrap";

import * as Sentry from "@sentry/react";
import { ErrorFallback, defaultErrorMessage } from "components/helpers";

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  processRequest,
} from "../services/base_requests";
import { addClickListener } from "../services/browser_dom_support";

import { AceEditor, ConfirmationDialog } from "components";
import {
  FaButton,
  PrivacyTagsSelect,
  SelectInputGroup,
  TextAreaField,
} from "components/helpers";
import ValidationFields from "../components/fields_page/ValidationFields";

import { VALIDATION_RULE_COLLECTIONS } from "../reducers/fields_page/ValidationRules.reducer";

const FormBindingValidationRulesPage = ({ studyId }) => {
  // Constants for this modal
  const DEFAULT_VALUES = {
    priority: "normal",
    permission_level: "normal",
    produce_exceptions: "false",
  };
  const DEFAULT_STUDY_FORMS_COLLECTION = [{ label: "", value: "" }];
  const DEFAULT_STUDY_FORM_EVENTS_COLLECTION = [{ label: "Any", value: "" }];
  const DEFAULT_RUN_IF_DEPENDENCY_FIELDS_COLLECTION = [];
  const NO_YES_COLLECTION = [
    { label: "No", value: "false" },
    { label: "Yes", value: "true" },
  ];

  // Modal state initialisation
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [requestUrl, setRequestUrl] = useState(undefined);
  const [rowDomId, setRowDomId] = useState(undefined);
  const [chooseType, setChooseType] = useState(false);
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [loadingFields, setLoadingFields] = useState(false);
  const [loadedFields, setLoadedFields] = useState(false);
  const [fieldsForDependencies, setFieldsForDependencies] = useState(
    DEFAULT_RUN_IF_DEPENDENCY_FIELDS_COLLECTION
  );

  const [loadingStudyFormsCollection, setLoadingStudyFormsCollection] =
    useState(false);
  const [studyFormsCollection, setStudyFormsCollection] = useState(
    DEFAULT_STUDY_FORMS_COLLECTION
  );
  const [
    loadingStudyFormEventsCollection,
    setLoadingStudyFormEventsCollection,
  ] = useState(false);
  const [studyFormEventsCollection, setStudyFormEventsCollection] = useState(
    DEFAULT_STUDY_FORM_EVENTS_COLLECTION
  );

  // Model state variables
  const eventsCollectionEmpty = studyFormEventsCollection.length <= 1;
  const customMessageShow = values.uses_custom_message || errors.custom_message;

  // Modal state methods
  const updateFormValue = (field, value) => {
    const updatedValues = { ...values, [field]: value };
    setValues(updatedValues);
  };

  const updateFormToRunAgainst = (field, value) => {
    updateFormValue(field, value);
    loadEventsToRunAgainstCollection(value);
    loadRunIfDependencyFieldsCollection(value, values.run_against_event_id);
  };

  const updateEventToRunAgainst = (field, value) => {
    updateFormValue(field, value);
    loadRunIfDependencyFieldsCollection(
      values.run_against_study_form_id,
      value
    );
  };

  const loadStudyFormsCollection = (studyFormId, successCallback) => {
    setLoadingStudyFormsCollection(true);

    const request = getRequest({
      url: `/study/${studyId}/forms.json`,
    });

    const success = (resData) => {
      const studyFormData = resData.map((data) => {
        return { label: data.name, value: data.id };
      });
      setStudyFormsCollection(
        DEFAULT_STUDY_FORMS_COLLECTION.concat(studyFormData)
      );
      setLoadingStudyFormsCollection(false);

      // Refresh the events once the forms are loaded
      loadEventsToRunAgainstCollection(
        studyFormId || values.run_against_study_form_id
      );

      if (_.isFunction(successCallback)) successCallback();
    };

    processRequest(request, success, () => {});
  };

  const loadEventsToRunAgainstCollection = (studyFormId) => {
    // Don't load events if the form is unset
    if (_.isNaN(parseInt(studyFormId))) {
      setStudyFormEventsCollection(DEFAULT_STUDY_FORM_EVENTS_COLLECTION);
      return;
    }

    setLoadingStudyFormEventsCollection(true);

    const request = getRequest({
      url: `/study/${studyId}/forms/${studyFormId}/events.json`,
    });

    const success = (resData) => {
      const collectionValues =
        DEFAULT_STUDY_FORM_EVENTS_COLLECTION.concat(resData);
      setStudyFormEventsCollection(collectionValues);
      setLoadingStudyFormEventsCollection(false);
    };

    processRequest(request, success, () => {});
  };

  const loadRunIfDependencyFieldsCollection = (studyFormId, eventId) => {
    // Don't load the dependency fields if the form is unset
    if (_.isNaN(parseInt(studyFormId))) {
      setFieldsForDependencies(DEFAULT_RUN_IF_DEPENDENCY_FIELDS_COLLECTION);
      setLoadingFields(false);
      setLoadedFields(false);
      return;
    }

    setLoadingFields(true);
    setLoadedFields(false);

    let params = null;
    if (!_.isNaN(parseInt(eventId))) params = { event_id: eventId };

    const request = getRequest({
      url: `/study/${studyId}/forms/${studyFormId}/dependency_fields.json`,
      params: params,
    });

    const success = (resData) => {
      setFieldsForDependencies(resData);

      setLoadingFields(false);
      setLoadedFields(true);
    };

    processRequest(request, success, () => {});
  };

  // Modal display handlers
  const parseReactProps = (element) => JSON.parse(element.dataset.reactProps);

  const addValidationRule = (element) => {
    const reactProps = parseReactProps(element);

    setRowDomId(reactProps.id);
    setRequestUrl(reactProps.requestUrl);
    setTitle(element.dataset.modalTitle || element.title);
    setChooseType(true);

    loadStudyFormsCollection();

    setShow(true);
  };

  const editValidationRule = (element) => {
    const reactProps = parseReactProps(element);

    setRowDomId(reactProps.validatable_id);
    setRequestUrl(reactProps.requestUrl);
    setTitle(element.dataset.modalTitle || element.title);
    const formValues = {
      validation_rule_type: reactProps.validation_rule_type,
      run_against_study_form_id:
        reactProps.parameters.run_against_study_form_id || "",
      run_against_event_id: reactProps.parameters.run_against_event_id || "",
      priority: reactProps.priority || DEFAULT_VALUES.priority,
      permission_level:
        reactProps.permission_level || DEFAULT_VALUES.permission_level,
      produce_exceptions:
        reactProps.parameters.produce_exceptions ||
        DEFAULT_VALUES.produce_exceptions,
      privacy_tags: VALIDATION_RULE_COLLECTIONS.privacy_tags.filter(
        (option) =>
          reactProps.privacy_tags_for_react.indexOf(option.value) !== -1
      ),
      uses_custom_message: reactProps.uses_custom_message,
      custom_message: reactProps.custom_message,
      run_if: reactProps.parsed_run_if,
    };
    setValues(formValues);

    // Avoid too many concurrent requests by only loading
    // the run-if dependencies once the study forms list is loaded
    const successfulFormsCollection = () => {
      loadRunIfDependencyFieldsCollection(
        formValues.run_against_study_form_id,
        formValues.run_against_event_id
      );
    };

    loadStudyFormsCollection(
      formValues.run_against_study_form_id,
      successfulFormsCollection
    );

    setShow(true);
  };

  useEffect(() => {
    addClickListener("button.add-validation-button", addValidationRule);
    addClickListener("button.edit-validation-button", editValidationRule);
  }, []);

  const updateView = (content) => {
    // Update the content of the row
    const rowToUpdate = document.getElementById(`form_binding_${rowDomId}`);
    rowToUpdate.innerHTML = content;

    // Reinitialise the remote tooltips
    const initTooltipEvent = new Event("init-remote-tooltip", {
      bubbles: true,
    });
    rowToUpdate
      .querySelectorAll("[data-remote-title-url]")
      .forEach((element) => element.dispatchEvent(initTooltipEvent));
  };

  const onHide = () => {
    setSaving(false);
    setShow(false);
    setConfirmingDelete(false);
    setLoadingFields(false);
    setLoadedFields(false);
    setRequestUrl(undefined);
    setChooseType(false);
    setStudyFormsCollection(DEFAULT_STUDY_FORMS_COLLECTION);
    setStudyFormEventsCollection(DEFAULT_STUDY_FORM_EVENTS_COLLECTION);
    setErrors({});
    setValues(DEFAULT_VALUES);
  };

  const onSave = () => {
    setSaving(true);

    // Duplicate the values needed to send to the server
    let valuesToSend = {
      ...values,

      // These values are set via the privacy_tag_list property below
      privacy_tags: undefined,

      // This value is never used directly (it's true if custom message is set, otherwise false)
      uses_custom_message: undefined,
    };

    // Clear the custom message if the option isn't ticked
    if (!values.uses_custom_message) valuesToSend["custom_message"] = "";

    // If there are no events for this form, clear the 'run_against_event_id' field
    if (eventsCollectionEmpty) valuesToSend["run_against_event_id"] = "";

    // Convert the stored privacy tags into a list
    valuesToSend["privacy_tag_list"] = (values.privacy_tags || []).map(
      (tag) => tag.value
    );

    // Construct the request depending on if we're creating or editing the rule...
    let request;
    if (chooseType) {
      request = postRequest({
        url: requestUrl,
        body: {
          validation_rule: valuesToSend,
        },
      });
    } else {
      request = putRequest({
        url: requestUrl,
        body: {
          validation_rule: valuesToSend,
        },
      });
    }

    const success = (resData) => {
      updateView(resData.row_html);

      onHide();
    };

    const failure = (resData) => {
      setSaving(false);
      setErrors(resData);
    };

    const error = (ex) => {
      console.error(ex);
      setSaving(false);
      setErrors({ base: defaultErrorMessage });
    };

    processRequest(request, success, failure, error);
  };

  const onConfirmingDelete = () => {
    setSaving(true);
    setConfirmingDelete(true);
  };

  const onHideConfirmingDelete = () => {
    setConfirmingDelete(false);
    setSaving(false);
  };

  const onDelete = () => {
    const request = deleteRequest({
      url: requestUrl,
    });

    const success = (resData) => {
      updateView(resData.row_html);

      onHideConfirmingDelete();
      onHide();
    };

    const failure = () => {
      setErrors({ base: "The validation rule could not be removed" });
      onHideConfirmingDelete();
    };

    const error = (ex) => {
      console.error(ex);
      setErrors({ base: defaultErrorMessage });
      onHideConfirmingDelete();
    };

    processRequest(request, success, failure, error);
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <ConfirmationDialog
        title="Delete validation rule?"
        shown={confirmingDelete}
        onConfirm={onDelete}
        onHide={onHideConfirmingDelete}
      >
        Are you sure you want to remove this validation rule?
      </ConfirmationDialog>
      <Modal
        id="modalWindow"
        dialogClassName="modal-lg"
        backdrop="static"
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.base && <Alert bsStyle="danger">{errors.base}</Alert>}
          {chooseType && (
            <ValidationFields
              field={{ field_type_for_display: "FormBinding" }}
              errors={errors}
              hasValidation={() => {}}
              values={values}
              updateFormValue={updateFormValue}
            />
          )}
          {values.validation_rule_type && (
            <>
              <fieldset className="margin-top-30">
                <legend className="margin-bottom-15">
                  Run validation rule on
                </legend>
                <Row>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Form"
                      field="run_against_study_form_id"
                      value={values.run_against_study_form_id}
                      disabled={loadingStudyFormsCollection || saving}
                      errors={errors.run_against_study_form_id}
                      onChange={updateFormToRunAgainst}
                      optionsForSelect={studyFormsCollection}
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Event"
                      field="run_against_event_id"
                      value={values.run_against_event_id}
                      disabled={
                        eventsCollectionEmpty ||
                        loadingStudyFormEventsCollection ||
                        saving
                      }
                      errors={errors.run_against_event_id}
                      onChange={updateEventToRunAgainst}
                      optionsForSelect={studyFormEventsCollection}
                    />
                  </Col>
                </Row>
              </fieldset>
              <fieldset className="margin-top-30">
                <legend className="margin-bottom-15">Settings</legend>
                <Row>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Priority"
                      field="priority"
                      value={values.priority}
                      disabled={saving}
                      errors={errors.priority}
                      onChange={updateFormValue}
                      optionsForSelect={VALIDATION_RULE_COLLECTIONS.priority}
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Permission level"
                      field="permission_level"
                      value={values.permission_level}
                      disabled={saving}
                      errors={errors.permission_level}
                      onChange={updateFormValue}
                      optionsForSelect={
                        VALIDATION_RULE_COLLECTIONS.permission_level
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <SelectInputGroup
                      label="Produce exceptions"
                      field="produce_exceptions"
                      value={values.produce_exceptions}
                      disabled={saving}
                      errors={errors.produce_exceptions}
                      onChange={updateFormValue}
                      optionsForSelect={NO_YES_COLLECTION}
                    />
                  </Col>
                  {values.permission_level == "restricted" && (
                    <Col md={6}>
                      <PrivacyTagsSelect
                        collections={VALIDATION_RULE_COLLECTIONS}
                        values={values}
                        errors={errors}
                        saving={saving}
                        updateFormValue={updateFormValue}
                      />
                    </Col>
                  )}
                </Row>
              </fieldset>
              <fieldset className="margin-top-30" id="custom-message-section">
                <legend className="margin-bottom-15">Custom message</legend>
                <Checkbox
                  disabled={saving}
                  checked={customMessageShow || ""}
                  validationState={errors.uses_custom_message ? "error" : null}
                  onChange={(e) =>
                    updateFormValue("uses_custom_message", e.target.checked)
                  }
                >
                  Use custom message
                </Checkbox>
                {customMessageShow && (
                  <TextAreaField
                    rows={3}
                    controlId="custom_message"
                    fieldName="custom_message"
                    value={values.custom_message}
                    disabled={saving}
                    errors={errors.custom_message}
                    onChange={updateFormValue}
                  />
                )}
              </fieldset>
              <fieldset className="margin-top-30">
                <legend className="margin-bottom-15">Condition</legend>
                <AceEditor
                  disabled={saving}
                  fieldName="run_if"
                  value={values.run_if}
                  errors={errors.run_if}
                  handleUpdateValue={updateFormValue}
                  loadingFields={loadingFields}
                  loadedFields={loadedFields}
                  fieldsForDependencies={fieldsForDependencies}
                />
              </fieldset>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="info"
            className="pull-left"
            onClick={onSave}
            disabled={saving}
          >
            Save
          </Button>
          {!chooseType && (
            <FaButton
              icon="trash-o"
              text="Delete"
              bsStyle="danger"
              onClick={onConfirmingDelete}
              disabled={saving}
            />
          )}
          <FaButton
            icon="times"
            text="Cancel"
            bsStyle="default"
            onClick={onHide}
            disabled={saving}
          />
        </Modal.Footer>
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

export default FormBindingValidationRulesPage;
