export const OPEN_NEW_SITE_FORM = "OPEN_NEW_SITE_FORM";
export const OPEN_EDIT_SITE_FORM = "OPEN_EDIT_SITE_FORM";
export const CLOSE_SITE_FORM = "CLOSE_SITE_FORM";
export const SITE_FORM_VALUE_CHANGE = "SITE_FORM_VALUE_CHANGE";
export const SITE_FORM_PROPERTY_VALUE_CHANGE =
  "SITE_FORM_PROPERTY_VALUE_CHANGE";
export const SITE_FORM_LIST_VALUE_CHANGE = "SITE_FORM_LIST_VALUE_CHANGE";
export const SITE_FORM_REMOVE_LIST_OPTION = "SITE_FORM_REMOVE_LIST_OPTION";
export const SITE_FORM_ADD_LIST_OPTION = "SITE_FORM_ADD_LIST_OPTION";
export const REORDER_SITE_FORM_LIST_OPTION = "REORDER_SITE_FORM_LIST_OPTION";
export const NEW_SITE_FORM_SAVE = "NEW_SITE_FORM_SAVE";
export const NEW_SITE_FORM_SAVE_AND_CLOSE = "NEW_SITE_FORM_SAVE_AND_CLOSE";
export const SITE_FORM_ERROR = "SITE_FORM_ERROR";
export const NEW_SITE_FORM_SAVE_SUCCESS = "NEW_SITE_FORM_SAVE_SUCCESS";
export const NEW_SITE_FORM_SAVE_AND_CLOSE_SUCCESS =
  "NEW_SITE_FORM_SAVE_AND_CLOSE_SUCCESS";
export const EDIT_SITE_FORM_SAVE = "EDIT_SITE_FORM_SAVE";
export const EDIT_SITE_FORM_SAVE_SUCCESS = "EDIT_SITE_FORM_SAVE_SUCCESS";
