import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { NavSearch, FormsList } from "../fields_layout_pages";
import actions from "actions/layout_page";

const NavigationScreen = ({
  studyId,
  forms,
  currentFormId,
  searching,
  searchTerm,
  handleNavSearch,
  handleNavSetSearchTerm,
}) => {
  const currentFormOrSubform = {
    class: "form",
    id: currentFormId,
  };

  const handleSearch = (e, searchTerm) => {
    handleNavSearch(studyId, searchTerm);
  };

  return (
    <div id="sidebar-nav">
      <button id="sidebar-toggle" type="button" title="Show menu">
        <i className="fa fa-ellipsis-v" />
      </button>

      <NavSearch
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        handleSetSearchTerm={handleNavSetSearchTerm}
      />

      <div id="sidebar-scrolling">
        <FormsList
          studyId={studyId}
          forms={forms}
          currentForm={currentFormOrSubform}
          searchTerm={searchTerm}
          searching={searching}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    studyId: state.navigation.studyId,
    forms: state.navigation.forms,
    currentFormId: state.navigation.currentFormId,
    searching: state.navigation.searching,
    searchTerm: state.navigation.searchTerm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleNavSearch: actions.handleNavSearch,
      handleNavSetSearchTerm: actions.handleNavSetSearchTerm,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationScreen);
