import { postRequest, patchRequest, deleteRequest } from "./base_requests";

export function createSiteMetadatum(studyId, values) {
  return postRequest({
    url: `/study/${studyId}/site_metadata.json`,
    body: { metadatum: { ...values } },
  });
}

export function updateSiteMetadatum(studyId, siteMetadatumId, values) {
  return patchRequest({
    url: `/study/${studyId}/site_metadata/${siteMetadatumId}.json`,
    body: { metadatum: { ...values } },
  });
}

export function removeSiteMetadatum(studyId, siteMetadatumId) {
  return deleteRequest({
    url: `/study/${studyId}/site_metadata/${siteMetadatumId}`,
    body: {},
  });
}
