import { processRequest } from "services/base_requests";
import { search } from "services/fields_requests";
import actions, { ActionTypes } from "actions/fields_page";

export const navigationMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const fieldCounter = window.findComponentCounter("form_fields");
    const lookupCounter = window.findComponentCounter("lookups");
    const validationsCounter = window.findComponentCounter("validation_rules");

    switch (action.type) {
      case ActionTypes.HANDLE_NAV_SEARCH: {
        let request = search(action.studyId, action.navFilter);

        const success = (resData) => {
          dispatch(actions.handleNavSearchSuccess(resData));
        };

        processRequest(request, success, () => {});
        break;
      }

      case ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_SUCCESS: {
        validationsCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.responseData.total_validations
        );
        break;
      }

      case ActionTypes.HANDLE_DELETE_VALIDATION_SUCCESS: {
        validationsCounter.trigger(window.COMPONENT_COUNT_DECREMENT);
        break;
      }

      case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS:
      case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE: {
        fieldCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.data.total_number_of_fields
        );
        lookupCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.data.total_lookups
        );
        validationsCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.data.total_validations
        );
        break;
      }

      case ActionTypes.EDIT_TYPE_SUBMIT_SUCCESS: {
        lookupCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.data.lookups.length
        );
        break;
      }

      case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_SUCCESS: {
        fieldCounter.trigger(
          window.COMPONENT_COUNT_DECREMENT,
          action.removedFields.length
        );
        validationsCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.totalValidations
        );
        break;
      }
      case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_WITH_ERRORS: {
        fieldCounter.trigger(
          window.COMPONENT_COUNT_DECREMENT,
          action.successFields.length
        );
        validationsCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.totalValidations
        );
        break;
      }

      case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_SUCCESS:
      case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_WITH_ERRORS: {
        fieldCounter.trigger(
          window.COMPONENT_COUNT_INCREMENT,
          action.successFields.length
        );
        validationsCounter.trigger(
          window.COMPONENT_COUNT_UPDATE,
          action.totalValidations
        );
        break;
      }
    }

    return next(action);
  };
