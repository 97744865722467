import { ActionTypes } from "../../actions/fields_page";

const defaultState = {
  showForm: false,
  saving: false,
  fieldId: null,
  studyId: null,
  values: { identifier: "", label: "" },
  errors: {},
};

export const EditLabelIdentifier = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FORM_VALUE: {
      let values = { ...state.values };
      values[action.fieldName] = action.value;

      let newState = {
        ...state,
        values: values,
      };
      return newState;
    }

    case ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_OPEN: {
      let field = action.field;

      let newState = {
        ...state,
        showForm: true,
        fieldId: field.id,
        studyId: field.study_id,
        values: {
          identifier: field.identifier || "",
          label: field.label || "",
        },
        errors: {},
      };
      return newState;
    }

    case ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_CLOSE: {
      let newState = {
        ...state,
        values: { identifier: "", label: "" },
        showForm: false,
      };
      return newState;
    }

    case ActionTypes.HANDLE_FIELD_LABEL_OR_IDENTIFIER_FORM_SUBMIT: {
      let newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_ERROR: {
      let newState = {
        ...state,
        saving: false,
        errors: action.errors,
      };
      return newState;
    }

    case ActionTypes.EDIT_FIELD_LABEL_OR_IDENTIFIER_FORM_SUCCESS: {
      let newState = {
        ...state,
        values: { identifier: "", label: "" },
        showForm: false,
        saving: false,
      };
      return newState;
    }
  }

  return state;
};
