import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Alert } from "react-bootstrap";

import actions from "actions/fields_page";
import { referencesPreventingAction } from "services/references_preventing_action";

import { FaButton, defaultErrorMessage } from "components/helpers";

const BulkActionErrors = ({ shown, failureReason, failedFields, onHide }) => {
  const failedFieldsDueToReferences = failedFields.filter(
    (field) =>
      Object.keys(field.json_errors).length === 0 &&
      field.json_errors.constructor === Object
  );
  const failedFieldsDueToErrors = failedFields.filter(
    (field) =>
      !(
        Object.keys(field.json_errors).length === 0 &&
        field.json_errors.constructor === Object
      )
  );

  return (
    <Modal id="confirmationDialog" show={shown} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>An error has occurred</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {failureReason === "server_error" && (
          <Alert bsStyle="danger">{defaultErrorMessage}</Alert>
        )}
        {failureReason === "delete" && failedFields.length > 0 && (
          <Alert bsStyle="danger">
            <strong>
              Some of the fields you are trying to delete are referenced
              elsewhere!
            </strong>
            <p className="margin-bottom-20">
              Please resolve the following issues before trying again:
            </p>
            {referencesPreventingAction({
              fieldsPreventingDelete: failedFields,
              questionnairesPreventingDelete: [],
            })}
          </Alert>
        )}
        {failureReason === "move" && failedFields.length > 0 && (
          <Alert bsStyle="danger">
            {failedFieldsDueToReferences.length > 0 && (
              <>
                <strong>
                  Some of the fields you are trying to move are referenced
                  elsewhere!
                </strong>
                <p className="margin-bottom-20">
                  Please resolve the following issues before trying again:
                </p>
                {referencesPreventingAction({
                  fieldsPreventingDelete: failedFieldsDueToReferences,
                  questionnairesPreventingDelete: [],
                })}
              </>
            )}
            {failedFieldsDueToErrors.length > 0 && (
              <>
                <strong>Some of the fields could not be moved:</strong>
                {failedFieldsDueToErrors.map((field, index) => {
                  return (
                    <p key={index}>
                      <strong>{`${field.identifier}: `}</strong>
                      {field.json_errors.identifier[0]}
                    </p>
                  );
                })}
              </>
            )}
          </Alert>
        )}
        {failureReason === "copy" && failedFields.length > 0 && (
          <Alert bsStyle="danger">
            <strong>Some of the fields could not be copied:</strong>
            <dl>
              {failedFields.map((field, index) => {
                return (
                  <React.Fragment key={`copy-error-${index}`}>
                    <dt>{`${field.identifier}`}</dt>
                    {Object.keys(field.json_errors).map((attribute) => {
                      const details = field.json_errors[attribute].join(", ");
                      return (
                        <dd
                          key={`${attribute}`}
                        >{`${attribute}: ${details}`}</dd>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </dl>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <FaButton
          icon=""
          text="Accept"
          bsStyle="primary"
          className="pull-left confirm commit"
          onClick={onHide}
        />
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const relevantState = state.bulkActions;

  return {
    shown: relevantState.displayingError,
    failureReason: relevantState.failureReason,
    failedFields: relevantState.failedFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onHide: actions.handleHideBulkActionError,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionErrors);
