import { QuestionnaireTableActionTypes } from "../../actions/questionnaires_page";

export const questionnaireTableDefaultState = {
  studyId: null,
  questionnaires: [],
  entityGroups: [],
  showDeleteModal: false,
  questionnaireToDelete: null,
  deleting: false,
  error: "",
};

export const QuestionnaireTableReducer = (
  state = questionnaireTableDefaultState,
  action
) => {
  switch (action.type) {
    case QuestionnaireTableActionTypes.HANDLE_OPEN_DELETE_MODAL: {
      const newState = {
        ...state,
        showDeleteModal: true,
        questionnaireToDelete: action.questionnaire,
      };
      return newState;
    }

    case QuestionnaireTableActionTypes.HANDLE_CLOSE_DELETE_MODAL: {
      const newState = {
        ...state,
        showDeleteModal: false,
        questionnaireToDelete: null,
        error: "",
      };
      return newState;
    }

    case QuestionnaireTableActionTypes.HANDLE_QUESTIONNAIRE_DELETE: {
      const newState = { ...state, deleting: true };
      return newState;
    }

    case QuestionnaireTableActionTypes.HANDLE_QUESTIONNAIRE_DELETE_SUCCESS: {
      const newState = {
        ...state,
        deleting: false,
        showDeleteModal: false,
        questionnaireToDelete: null,
        questionnaires: action.responseData.questionnaires,
        error: "",
      };
      return newState;
    }

    case QuestionnaireTableActionTypes.HANDLE_QUESTIONNAIRE_DELETE_FAILURE: {
      const newState = {
        ...state,
        deleting: false,
        error: action.responseData.error_message,
      };
      return newState;
    }
  }
  return state;
};
