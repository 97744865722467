export const commitRevision = (study_id, revision_id) => ({
  type: "COMMIT_REVISION",
  study_id,
  revision_id,
});

export const updateForm = (form, details, study_id, revision_id) => ({
  type: "UPDATE_FORM",
  form,
  details,
  study_id,
  revision_id,
});

export const toggleExpandSection = (targetSection) => ({
  type: "TOGGLE_EXPAND_SECTION",
  targetSection,
});

export const expandAllChangesInSection = (targetSection) => ({
  type: "EXPAND_ALL_CHANGES_IN_SECTION",
  targetSection,
});

export const collapseAllChangesInSection = (targetSection) => ({
  type: "COLLAPSE_ALL_CHANGES_IN_SECTION",
  targetSection,
});

export const toggleExpandChange = (targetSection, targetChange) => ({
  type: "TOGGLE_EXPAND_CHANGE",
  targetSection,
  targetChange,
});

export const commitError = (errors) => ({
  type: "COMMIT_FAILURE",
  errors,
});
