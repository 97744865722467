import React from "react";
import { Alert } from "react-bootstrap";

export const defaultErrorMessage =
  "An error has occurred, please try again or contact support if the problem persists";

const ErrorFallback = ({ error }) => {
  return (
    <Alert bsStyle="danger">
      <p>
        <strong>{defaultErrorMessage}</strong>
      </p>
      <pre className="margin-top-10 margin-bottom-10">{error.message}</pre>
      <p>
        If this persists please contact &nbsp;
        <a href="mailto:support@healthresearchsuite.org.uk">support</a>.
      </p>
    </Alert>
  );
};

export default ErrorFallback;
