import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import actions from "actions/revisions_page";

import CollapsiblePanel from "components/CollapsiblePanel";
import ManagementInformationForm from "./ManagementInformationForm";

const ManagementInfoPanelScreen = ({ expanded, toggleExpandSection }) => {
  return (
    <CollapsiblePanel
      id="management"
      title="Management information"
      expanded={expanded}
      handleCollapseToggle={() => {
        toggleExpandSection("management_information");
      }}
    >
      <ManagementInformationForm />
    </CollapsiblePanel>
  );
};

const mapStateToProps = (state) => {
  return {
    expanded: state.revision.management_information.expanded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleExpandSection: actions.toggleExpandSection,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementInfoPanelScreen);
