import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, Table, ButtonGroup } from "react-bootstrap";

import { messageTemplateActions } from "actions/questionnaires_page";
import { FaButton, DeletionModal } from "../helpers";

const MessageTemplateTableScreen = ({
  studyId,
  messageTemplates,
  showDeleteModal,
  messageTemplateToDelete,
  processingDeleteRequest,
  handleOpenNewForm,
  handleDelete,
  handleDeleteModalToggle,
  handleEditClick,
}) => {
  const truncateContent = (content, length = 100) => {
    let dots = content.length > length ? "..." : "";
    return content.substring(0, length) + dots;
  };

  const handleConfirmTemplateDelete = () => {
    handleDelete(studyId, messageTemplateToDelete.id);
  };

  const deleteConfirmMessage = () => {
    return messageTemplateToDelete
      ? `Are you sure you want to remove the '${messageTemplateToDelete.name}' template?`
      : "";
  };

  return (
    <>
      <DeletionModal
        title={"Are you sure?"}
        message={deleteConfirmMessage()}
        show={showDeleteModal}
        onHide={() => handleDeleteModalToggle(messageTemplateToDelete)}
        onConfirm={handleConfirmTemplateDelete}
        processingRequest={processingDeleteRequest}
        canCancel={!processingDeleteRequest}
        canMakeDeleteRequest={!processingDeleteRequest}
      />
      <Panel className="form-panel" bsStyle="primary" id="message_templates">
        <Panel.Heading className="panel-heading-sticky">
          <Panel.Title componentClass="h3">
            Message / Script Templates
          </Panel.Title>
          <ul className="panel-buttons">
            <li>
              <FaButton
                icon="plus"
                text="Add a template"
                title="Add a new message / script template"
                className="btn btn-link"
                onClick={() => handleOpenNewForm()}
              />
            </li>
          </ul>
        </Panel.Heading>
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Identifier</th>
              <th>Content</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {messageTemplates.map((messageTemplate, index) => {
              return (
                <tr key={index} id={`message_template_${messageTemplate.id}`}>
                  <td>{messageTemplate.name}</td>
                  <td>{messageTemplate.identifier}</td>
                  <td className="message-template-content">
                    {truncateContent(messageTemplate.content, 200)}
                  </td>
                  <td className="message-template-actions">
                    <ButtonGroup>
                      <FaButton
                        icon="pencil"
                        text="Edit"
                        title="Edit template"
                        onClick={() => handleEditClick(messageTemplate)}
                      />
                      <FaButton
                        icon="trash"
                        title="Remove template"
                        onClick={() => handleDeleteModalToggle(messageTemplate)}
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Panel>
    </>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.messageTemplateTable;

  return {
    studyId: relevantState.studyId,
    messageTemplates: relevantState.messageTemplates,
    showDeleteModal: relevantState.showDeleteModal,
    messageTemplateToDelete: relevantState.messageTemplateToDelete,
    processingDeleteRequest: relevantState.processingDeleteRequest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleOpenNewForm:
        messageTemplateActions.handleOpenNewMessageTemplateForm,
      handleDelete: messageTemplateActions.handleMessageTemplateDelete,
      handleDeleteModalToggle:
        messageTemplateActions.handleMessageTemplateDeleteModalToggle,
      handleEditClick: messageTemplateActions.handleMessageTemplateEditClick,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageTemplateTableScreen);
