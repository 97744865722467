import React from "react";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { Alert, PanelGroup } from "react-bootstrap";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";
import { loadChange } from "services/revisions_page/changes";

import { RevisionFormReducer, RevisionReducer } from "reducers/revisions_page";
import {
  saveFormMiddleware,
  CommittingRevisionMiddleware,
} from "middleware/revisions_page";

import {
  RevisionHeaderScreen,
  ManagementInfoPanelScreen,
  SectionPanelsScreen,
} from "components/revisions_page";

const RevisionsPage = ({ title, changes, revision }) => {
  const entityGroupChanges = changes.filter(
    (change) => change.section === "EntityGroup"
  );
  const eventChanges = changes.filter((change) => change.section === "Event");
  const formChanges = changes.filter((change) => change.section === "Form");
  const subformTemplateChanges = changes.filter(
    (change) => change.section === "SubformTemplate"
  );
  const fieldChanges = changes.filter((change) => change.section === "Field");
  const lookupChanges = changes.filter((change) => change.section === "Lookup");
  const studyChanges = changes.filter((change) => change.section === "Study");
  const siteChanges = changes.filter((change) => change.section === "Site");
  const additionalSitePropertiesChanges = changes.filter(
    (change) => change.section === "AdditionalSiteProperty"
  );
  const validationRulesChanges = changes.filter(
    (change) => change.section === "ValidationRule"
  );
  const layoutChanges = changes.filter((change) => change.section === "Layout");

  const store = createStore(
    combineReducers({ form: RevisionFormReducer, revision: RevisionReducer }),
    {
      form: {
        values: {
          comment: revision.comment || "",
          request_source: revision.request_source || "",
          request_date: revision.request_date || "",
          ticket_number: revision.ticket_number || "",
          prospect_live_date: revision.prospect_live_date || "",
          evidence_of_testing: revision.evidence_of_testing || "",
          details_of_testing: revision.details_of_testing || "",
          checks_updated: revision.checks_updated || "",
          data_migration_required: revision.data_migration_required || "",
          details_of_data_migration: revision.details_of_data_migration || "",
          risk_to_existing_data: revision.risk_to_existing_data || "",
          details_of_risk_to_existing_data:
            revision.details_of_risk_to_existing_data || "",
        },
        errors: {},
        committing: false,
        saving: false,
      },
      revision: {
        title: title,
        studyId: revision.studyId,
        studyName: revision.studyName,
        revisionNumber: revision.versionNumber,
        revisionId: revision.id,
        committed: revision.committed,
        hasChanges: changes.length > 0,
        changeObjects: changes.map((change) => loadChange(change)),
        management_information: {
          expanded: true,
        },
        EntityGroup: {
          expanded: true,
          changes: entityGroupChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        Event: {
          expanded: true,
          changes: eventChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        Form: {
          expanded: true,
          changes: formChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        SubformTemplate: {
          expanded: true,
          changes: subformTemplateChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        Field: {
          expanded: true,
          changes: fieldChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        Lookup: {
          expanded: true,
          changes: lookupChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        Study: {
          expanded: true,
          changes: studyChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        Site: {
          expanded: true,
          changes: siteChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        AdditionalSiteProperty: {
          expanded: true,
          changes: additionalSitePropertiesChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        ValidationRule: {
          expanded: true,
          changes: validationRulesChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
        Layout: {
          expanded: true,
          changes: layoutChanges.map((change) => {
            return { ...change, expanded: false };
          }),
        },
      },
    },
    applyMiddleware(saveFormMiddleware, CommittingRevisionMiddleware)
  );

  return (
    <Provider store={store}>
      {revision.pre_revision_details_built && (
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <RevisionHeaderScreen />
          <PanelGroup id="revision-panels">
            <ManagementInfoPanelScreen />
            <SectionPanelsScreen />
          </PanelGroup>
        </Sentry.ErrorBoundary>
      )}
      {!revision.pre_revision_details_built && (
        <Alert bsStyle="warning">
          <p>
            <strong>Note:</strong> Processing revision details. Please refresh
            the page in a minute.
          </p>
          <p>
            If this persists please contact &nbsp;
            <a href="mailto:support@healthresearchsuite.org.uk">support</a>.
          </p>
        </Alert>
      )}
    </Provider>
  );
};

export default RevisionsPage;
