import { defaultErrorMessage } from "components/helpers";

import {
  processRequest,
  dispatchWithTimeout,
  errorWithDispatch,
} from "services/base_requests";
import {
  moveElement,
  removeSection,
  createSection,
  updateSection,
  moveFieldsTo,
} from "services/layout_section_requests";

import actions, { ActionTypes } from "actions/layout_page";

export const formLayoutMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");
    const defaultErrorHandlerFor = (func) =>
      failureHandlerFor(func, defaultErrorMessage);

    const sectionErrorHandlerFor = errorWithDispatch(dispatch, {
      json_errors: {
        base: [defaultErrorMessage],
      },
    });

    switch (action.type) {
      case ActionTypes.MOVE_ELEMENT: {
        const request = moveElement(action.params);

        const failure = () => {
          failureHandlerFor(
            actions.handleMoveElementFailure,
            "The layout could not be updated"
          )(action.params);
        };

        processRequest(request, () => {}, failure, failure);
        break;
      }

      case ActionTypes.REMOVE_SECTION: {
        const params = [action.studyId, action.sectionId];
        let request = removeSection(...params);

        const success = (resData) => {
          successHandlerFor(
            actions.handleRemoveSectionSuccess,
            "Form section has been successfully removed"
          )(resData, action.sectionId, resData.id);
        };

        const failure = () => {
          $.flashAlert("Form section could not be deleted", "alert-danger");
        };
        const error = () => {
          $.flashAlert(defaultErrorMessage, "alert-danger");
        };

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.SAVE_SECTION_FORM:
      case ActionTypes.SAVE_AND_CLOSE_SECTION_FORM: {
        let request = createSection(
          action.studyId,
          action.studyFormId,
          action.details
        );

        let message;
        let handler;
        if (action.type == ActionTypes.SAVE_SECTION_FORM) {
          handler = actions.handleSectionFormSaveSuccess;
        } else {
          handler = actions.handleSectionFormSaveAndCloseSuccess;
          message = "New form section added successfully";
        }

        const success = (resData) => {
          successHandlerFor(handler, message)(resData, action.studyFormId);
        };
        const failure = failureHandlerFor(actions.handleSectionFormSaveError);
        const error = sectionErrorHandlerFor(
          actions.handleSectionFormSaveError
        );

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.SAVE_SECTION_FORM_SUCCESS: {
        setTimeout(() => {
          dispatch(actions.hideSectionFormSuccessNotice());
        }, 3000);

        break;
      }

      case ActionTypes.UPDATE_SECTION_FORM: {
        let request = updateSection(
          action.studyId,
          action.formSectionId,
          action.details
        );

        const success = successHandlerFor(
          actions.handleSectionFormUpdateSuccess,
          "Form section has been updated successfully"
        );
        const failure = failureHandlerFor(actions.handleSectionFormUpdateError);
        const error = sectionErrorHandlerFor(
          actions.handleSectionFormUpdateError
        );

        processRequest(request, success, failure, error);
        break;
      }

      // BULK_REMOVE moves all the selected fields in to the unallocated section
      case ActionTypes.BULK_REMOVE:
      case ActionTypes.BULK_MOVE: {
        const params = [
          action.studyId,
          action.targetSectionId,
          action.fieldsToProcess.map((field) => field["fieldId"]),
        ];
        let request = moveFieldsTo(...params);

        const success = successHandlerFor(
          actions.handleBulkMoveSuccess,
          "Bulk move has completed successfully"
        );
        const failure = failureHandlerFor(
          actions.handleBulkMoveFailure,
          "Bulk move could not be completed successfully"
        );
        const error = defaultErrorHandlerFor(actions.handleBulkMoveFailure);

        processRequest(request, success, failure, error);
        break;
      }
    }

    return next(action);
  };
