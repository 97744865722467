import React from "react";

const ChangeTable = ({ originalAttributes, newAttributes, children }) => {
  const isNotEmpty = (attributes) => {
    return attributes && Object.keys(attributes).length !== 0;
  };

  return (
    <table className="table" style={{ tableLayout: "fixed" }}>
      <thead>
        <tr>
          <th style={{ width: "30%" }}>Property</th>
          {isNotEmpty(originalAttributes) && (
            <th>{isNotEmpty(newAttributes) ? "Original value" : "Value"}</th>
          )}
          {isNotEmpty(newAttributes) && (
            <th>{isNotEmpty(originalAttributes) ? "New value" : "Value"}</th>
          )}
        </tr>
      </thead>
      {children}
    </table>
  );
};

export default ChangeTable;
