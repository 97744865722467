import ChangeObject from "./ChangeObject";

class LayoutChange extends ChangeObject {
  constructor(change) {
    const displaySubformElement = (subformElement, index) => {
      return `<li class="${subformElement.changed ? "has-change" : ""}">${
        index + 1
      }. ${displayItem(subformElement)}</li>`;
    };

    const formOrSubformIdentifier = change.new_attributes
      ? change.new_attributes.subheading_information.identifier.value
      : change.original_attributes.subheading_information.identifier.value;

    const identifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;
    const elementType = "Layout";
    const elementLocation = `${formOrSubformIdentifier}.${identifier}`;

    const displayItem = (associatedObject) => {
      const element = associatedObject.element;
      if (element.class_name.value === "FormField") {
        return `${associatedObject?.element?.label?.value} - ${associatedObject?.element?.identifier?.value}`;
      } else {
        return `<span>SUBFORM: ${element.label.value} - ${
          element.identifier.value
        }</span><ul class="nested-item-${
          associatedObject.id.value
        }">${element.subformElements.value
          .map((subformElement, index) =>
            displaySubformElement(subformElement, index)
          )
          .join("")}</ul>`;
      }
    };

    const displayItemForExport = (associatedObject) => {
      const element = associatedObject.element;
      if (element.class_name.value === "FormField") {
        return `${element?.label?.value} - ${element?.identifier?.value}`;
      } else {
        return `SUBFORM: ${element?.label?.value} - ${
          element?.identifier?.value
        }\n${element.subformElements.value
          .map((subformElement, index) => {
            return `  ${index + 1}. ${displayItemForExport(subformElement)}`;
          })
          .join("\n")}`;
      }
    };

    const attributeData = [
      ["Name", "name"],
      ["Identifier", "identifier"],
      [
        "Items",
        "form_section_elements",
        {
          attributeType: "association",
          displayAssociatedObjectFunction: displayItem,
          displayExportFunction: (associations) => {
            return associations
              .map(
                (associatedObject, index) =>
                  `${index + 1}. ${displayItemForExport(associatedObject)}`
              )
              .join("\n");
          },
        },
      ],
      ["Description", "description"],
    ];

    super(change, elementType, elementLocation, attributeData);
  }
}

export default LayoutChange;
