import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const DependenciesCell = ({ field, handleOpen }) => {
  const dependencyTooltip = () => {
    return (
      <Tooltip id={`dependency-tooltip-${field.id}`}>
        <div>{field.parsed_dependency}</div>
      </Tooltip>
    );
  };

  const hasDependency = () => {
    return field.parsed_dependency;
  };

  // Source: https://stackoverflow.com/a/1199420
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <td className="vertical-center">
        <div className="cell-with-action-button">
          <button
            onClick={() => handleOpen(field)}
            title="Edit the dependency of this field"
            className="btn btn-link  action-button"
          >
            <i className="fa fa-edit" />
          </button>
          {(hasDependency() && (
            <OverlayTrigger placement="top" overlay={dependencyTooltip()}>
              <span className="tooltip-trigger small">
                {truncate(field.parsed_dependency, 25)}
              </span>
            </OverlayTrigger>
          )) || <span className="text-muted">None</span>}
        </div>
      </td>
    </>
  );
};

export default DependenciesCell;
