import { postRequest, patchRequest, deleteRequest } from "./base_requests";

export function createMessageTemplate(studyId, values) {
  return postRequest({
    url: `/study/${studyId}/message_templates`,
    body: { message_template: { ...values } },
  });
}

export function updateMessageTemplate(studyId, messageTemplateId, values) {
  return patchRequest({
    url: `/study/${studyId}/message_templates/${messageTemplateId}`,
    body: { message_template: { ...values } },
  });
}

export function deleteMessageTemplate(studyId, messageTemplateId) {
  return deleteRequest({
    url: `/study/${studyId}/message_templates/${messageTemplateId}`,
    body: {},
  });
}
