import * as ActionTypes from "./action_types";

export const openModal = () => ({
  type: ActionTypes.OPEN_MODAL,
});

export const closeModal = () => ({
  type: ActionTypes.CLOSE_MODAL,
});

export const updateFormValue = (fieldName, value) => ({
  type: ActionTypes.UPDATE_FORM_VALUE,
  fieldName,
  value,
});

export const handleFormSubmit = (studyId, values) => ({
  type: ActionTypes.HANDLE_FORM_SUBMIT,
  studyId: studyId,
  values: values,
});

export const handleSubmitSuccess = () => ({
  type: ActionTypes.HANDLE_SUBMIT_SUCCESS,
});

export const handleSubmitFailure = (resData) => ({
  type: ActionTypes.HANDLE_SUBMIT_FAILURE,
  resData: resData,
});

export const handleSubmitError = (message) => ({
  type: ActionTypes.HANDLE_SUBMIT_ERROR,
  message: message,
});

export const handleSelectStudy = (value) => ({
  type: ActionTypes.HANDLE_SELECT_STUDY,
  value: value,
});

export const handleSelectForm = (studyId, value, label, identifier) => ({
  type: ActionTypes.HANDLE_SELECT_FORM,
  studyId: studyId,
  value: value,
  label: label,
  identifier: identifier,
});

export const handleDependenciesCheckSuccess = (resData) => ({
  type: ActionTypes.HANDLE_DEPENDENCIES_CHECK_SUCCESS,
  resData: resData,
});

export const handleDependenciesCheckFailure = (resData) => ({
  type: ActionTypes.HANDLE_DEPENDENCIES_CHECK_FAILURE,
  resData: resData,
});
