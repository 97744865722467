import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ButtonToolbar, Button, Row, Col, Alert } from "react-bootstrap";

import actions from "actions/duplicate_study_forms";

import {
  TextField,
  SelectField,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";
import DependencyWarnings from "./DependencyWarnings";

const DuplicateStudyFormsModal = ({
  studiesToCopyFrom,
  studyName,
  studyId,
  show,
  errors,
  saving,
  study,
  form,
  filteredFormsToCopy,
  formName,
  formIdentifier,
  disableSubmitButtonText,
  checkingDependencies,
  excludedFields,
  fieldsWithNonLocalDependencies,
  validationRulesWithNonLocalDependencies,
  validationRulesToRemove,
  subformsWithNonLocalDependencies,
  handleClose,
  handleFormSubmit,
  handleSelectStudy,
  handleSelectForm,
  updateFormValue,
}) => {
  const processing = saving || checkingDependencies;

  const submitForm = (e) => {
    e.preventDefault();
    const values = {
      study_to_copy_from: study,
      form_to_copy: form,
      name: formName,
      identifier: formIdentifier,
    };
    handleFormSubmit(studyId, values);
  };

  return (
    <Modal
      id="modalWindow"
      backdrop="static"
      show={show}
      onHide={processing ? undefined : handleClose}
      bsSize="large"
    >
      <form onSubmit={submitForm}>
        <Modal.Header closeButton={!processing}>
          <Modal.Title>Copy form into {studyName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert>
            This will copy all fields, validation rules and subform elements
            contained by the form.
          </Alert>
          <Row>
            <Col md={6}>
              <SelectField
                label="Study to copy from"
                fieldName="study"
                placeholder="Select study"
                options={studiesToCopyFrom}
                className="select-study"
                classNamePrefix="select-study"
                errors={errors.study_to_copy_from}
                value={study}
                onChange={(option) => handleSelectStudy(option.value)}
                disabled={processing}
              />
            </Col>
            <Col md={6}>
              <SelectField
                label="Form to copy"
                fieldName="form"
                placeholder="Select form"
                options={filteredFormsToCopy}
                className="select-form"
                classNamePrefix="select-form"
                errors={errors.form_to_copy}
                value={form}
                onChange={(option) =>
                  handleSelectForm(
                    studyId,
                    option.value,
                    option.label,
                    option.identifier
                  )
                }
                disabled={!study || processing}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                required={true}
                controlId="name"
                fieldName="formName"
                label="Name"
                value={formName}
                disabled={!study || processing}
                errors={errors.name}
                onChange={updateFormValue}
              />
            </Col>
            <Col md={6}>
              <TextField
                required={true}
                controlId="identifier"
                fieldName="formIdentifier"
                label="Identifier"
                pattern={IDENTIFIER_LIMIT_PATTERN}
                title={IDENTIFIER_LIMIT_TITLE}
                value={formIdentifier}
                disabled={!study || processing}
                errors={errors.identifier}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
          <DependencyWarnings
            excludedFields={excludedFields}
            fieldsWithNonLocalDependencies={fieldsWithNonLocalDependencies}
            validationRulesWithNonLocalDependencies={
              validationRulesWithNonLocalDependencies
            }
            validationRulesToRemove={validationRulesToRemove}
            subformsWithNonLocalDependencies={subformsWithNonLocalDependencies}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={processing}>
              {processing && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  {disableSubmitButtonText}
                </span>
              )}
              {!processing && (
                <span>
                  <i className="fa fa-clone margin-right-5" />
                  Copy form
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button
                bsStyle="default"
                disabled={processing}
                onClick={handleClose}
              >
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const relevantState = state.Modal;

  return {
    studiesToCopyFrom: ownProps.studiesToCopyFrom,
    formsToCopy: ownProps.formsToCopy,
    studyName: ownProps.studyName,
    studyId: ownProps.studyId,
    show: relevantState.show,
    errors: relevantState.errors,
    saving: relevantState.saving,
    study: relevantState.values.study,
    form: relevantState.values.form,
    filteredFormsToCopy: relevantState.filteredFormsToCopy,
    formName: relevantState.values.formName,
    formIdentifier: relevantState.values.formIdentifier,
    disableSubmitButtonText: relevantState.disableSubmitButtonText,
    checkingDependencies: relevantState.checkingDependencies,
    excludedFields: relevantState.excludedFields,
    fieldsWithNonLocalDependencies:
      relevantState.fieldsWithNonLocalDependencies,
    validationRulesWithNonLocalDependencies:
      relevantState.validationRulesWithNonLocalDependencies,
    validationRulesToRemove: relevantState.validationRulesToRemove,
    subformsWithNonLocalDependencies:
      relevantState.subformsWithNonLocalDependencies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleClose: actions.closeModal,
      handleFormSubmit: actions.handleFormSubmit,
      handleSelectStudy: actions.handleSelectStudy,
      handleSelectForm: actions.handleSelectForm,
      updateFormValue: actions.updateFormValue,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateStudyFormsModal);
