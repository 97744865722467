import React from "react";
import { FormGroup, InputGroup, FormControl, Button } from "react-bootstrap";

const NavSearch = ({ searchTerm, handleSearch, handleSetSearchTerm }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch(e, searchTerm);
    }
  };

  return (
    <div className="">
      <form
        onKeyDown={(e) => {
          handleKeyDown(e);
        }}
      >
        <FormGroup className="margin-bottom-0 sidebar-nav-grouped-input">
          <label className="sr-only" htmlFor="nav-filter-input">
            Find a form
          </label>
          <InputGroup>
            <FormControl
              id="nav-filter-input"
              className="form-control"
              placeholder="Filter by name/identifier..."
              autoComplete="off"
              autoFocus={true}
              type="text"
              value={searchTerm}
              onChange={(e) => {
                handleSetSearchTerm(e.target.value);
              }}
            />
            <InputGroup.Button>
              <Button
                onClick={(e) => {
                  handleSearch(e, searchTerm);
                }}
                bsStyle="primary"
                id="search-nav-submit-button"
              >
                <i className="fa fa-search" />
              </Button>
              <Button
                onClick={(e) => {
                  handleSetSearchTerm("");
                  handleSearch(e, "");
                }}
                id="search-nav-reset-button"
              >
                <i className="fa fa-undo" />
              </Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>
      </form>
    </div>
  );
};

export default NavSearch;
