import React from "react";
import { Modal, Alert } from "react-bootstrap";
import parse from "html-react-parser";
import { referencesPreventingAction } from "services/references_preventing_action";

import FaButton from "./FaButton";

const DeletionModal = ({
  title,
  message,
  show,
  onHide,
  onConfirm,
  processingRequest,
  hasErrors,
  errorMessage,
  referencesPreventingDelete,
  canMakeDeleteRequest,
  canCloseModal,
  canCancel,
}) => {
  return (
    <Modal
      id="confirmationDialog"
      dialogClassName="modal-lg"
      backdrop="static"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton={!processingRequest}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!hasErrors && parse(message)}
        {hasErrors && Object.keys(referencesPreventingDelete).length > 0 && (
          <Alert bsStyle="danger">
            <p className="margin-bottom-20">
              Please resolve the following issues before trying again:
            </p>
            {referencesPreventingAction(referencesPreventingDelete)}
          </Alert>
        )}
        {hasErrors && errorMessage && (
          <Alert bsStyle="danger">
            <p className="margin-bottom-20">{errorMessage}</p>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {processingRequest == true && (
          <button
            className="btn btn-primary pull-left confirm commit"
            disabled={true}
          >
            <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
            Removing
          </button>
        )}
        {canMakeDeleteRequest && (
          <FaButton
            icon="check"
            text="Yes"
            bsStyle="primary"
            className="pull-left confirm commit"
            onClick={onConfirm}
          />
        )}
        {canCloseModal && (
          <FaButton
            icon="check"
            text="Ok"
            bsStyle="primary"
            className="pull-left confirm commit"
            onClick={onHide}
          />
        )}
        {canCancel && (
          <FaButton
            icon="remove"
            text="No"
            bsStyle="default"
            className="cancel"
            onClick={onHide}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeletionModal;
