import {
  postRequest,
  getRequest,
  patchRequest,
  deleteRequest,
} from "./base_requests";

export function createQuestionnaire(studyId, entityGroupId, values) {
  return postRequest({
    url: `/study/${studyId}/entity_groups/${entityGroupId}/questionnaires`,
    body: { questionnaire: { ...values } },
  });
}

export function deleteQuestionnaire(studyId, entityGroupId, questionnaireId) {
  return deleteRequest({
    url: `/study/${studyId}/entity_groups/${entityGroupId}/questionnaires/${questionnaireId}`,
    body: {},
  });
}

export function updateQuestionnaire(
  studyId,
  entityGroupId,
  questionnaireId,
  values
) {
  return patchRequest({
    url: `/study/${studyId}/entity_groups/${entityGroupId}/questionnaires/${questionnaireId}`,
    body: { questionnaire: { ...values } },
  });
}

export function getAvailableFields(
  studyId,
  entityGroupId,
  selectedEventsIds,
  selectedFormsIds
) {
  return getRequest({
    url: `/study/${studyId}/entity_groups/${entityGroupId}/questionnaires/available_fields`,
    params: {
      selected_events_ids: selectedEventsIds,
      selected_forms_ids: selectedFormsIds,
    },
  });
}
