import ChangeObject from "./ChangeObject";

class SubformTemplateChange extends ChangeObject {
  constructor(change) {
    const identifier = change.new_attributes
      ? change.new_attributes.identifier.value
      : change.original_attributes.identifier.value;

    const attributeData = [
      ["Identifier", "identifier"],
      ["Name", "name"],
      ["Type", "subform_type"],
    ];

    super(change, "SubformTemplate", identifier, attributeData);
  }
}

export default SubformTemplateChange;
