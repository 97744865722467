import { defaultErrorMessage } from "components/helpers";

import {
  processRequest,
  errorWithDispatch,
  dispatchWithTimeout,
  spinnerTimeoutWrapper,
} from "services/base_requests";
import actions, { ActionTypes } from "actions/fields_page";

import {
  updateDetails,
  deleteSelectedFields,
  moveSelectedFields,
  copySelectedFields,
  createField,
  getSelectDependecyOptions,
  updateDependencies,
  updateSettings,
  updateType,
  referencedFields,
} from "services/fields_requests";

import {
  getValidationFieldOptions,
  createValidation,
  updateValidation,
  deleteValidation,
} from "services/validation_requests";

export const formPageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");

    const errorHandlerFor = errorWithDispatch(dispatch, {
      base: [defaultErrorMessage],
    });

    const fieldErrorHandlerFor = errorWithDispatch(dispatch, {
      json_errors: { base: [defaultErrorMessage] },
      lookup_json_errors: {},
      lookup_options_json_errors: {},
    });

    const bulkActionError = errorWithDispatch(dispatch)(
      actions.handleBulkActionsError
    );

    switch (action.type) {
      case ActionTypes.EDIT_TYPE_SUBMIT: {
        const { studyId, fieldId } = action;
        const values = {
          field_type: action.fieldType,
          widget_type: action.widgetType,
          unit: action.unit,
          precision: action.precision,
          lookup_id: action.lookupId,
          lookup_attributes: {
            ...action.newLookup,
            lookup_options_attributes: action.newLookupOptions,
          },
        };
        const request = updateType(studyId, fieldId, values);

        const success = successHandlerFor(
          actions.handleSubmitSuccess,
          "Form field has been updated successfully"
        );
        const failure = failureHandlerFor(actions.handleEditFieldTypeError);
        const error = fieldErrorHandlerFor(actions.handleEditFieldTypeError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_FIELD_LABEL_OR_IDENTIFIER_FORM_SUBMIT: {
        const { studyId, fieldId, values } = action;
        const request = updateDetails(studyId, fieldId, values);

        const success = successHandlerFor(
          actions.handleUpdateDetailsSuccess,
          "Form field has been updated successfully"
        );
        const failure = failureHandlerFor(actions.handleUpdateDetailsError);
        const error = errorHandlerFor(actions.handleUpdateDetailsError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS: {
        const { studyId, fields } = action;
        const request = deleteSelectedFields(studyId, fields);

        // The Bulk actions returns a 200 even if it failed to process some or all of the fields
        const success = (resData) => {
          spinnerTimeoutWrapper(() => {
            if (resData.failedFields.length > 0) {
              dispatch(
                actions.handleDeleteSelectedFieldWithErrors(
                  resData.successFields
                )
              );
            } else {
              $.flashAlert(
                "Selected fields have been deleted successfully",
                "alert-success"
              );
              dispatch(actions.handleDeleteSelectedFieldSuccess(resData));
            }
          });
        };

        processRequest(request, success, bulkActionError, bulkActionError);
        break;
      }

      case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS: {
        const {
          studyId,
          target_id,
          target_type,
          source_id,
          source_type,
          fields,
        } = action;
        const request = moveSelectedFields(
          studyId,
          target_id,
          target_type,
          source_id,
          source_type,
          fields
        );

        // The Bulk actions returns a 200 even if it failed to process some or all of the fields
        const success = (resData) => {
          spinnerTimeoutWrapper(() => {
            if (resData.failedFields.length > 0) {
              dispatch(
                actions.handleMoveSelectedFieldsWithErrors(
                  resData,
                  target_id,
                  target_type
                )
              );
            } else {
              $.flashAlert(
                "Selected fields have been moved successfully",
                "alert-success"
              );
              dispatch(
                actions.handleMoveSelectedFieldSuccess(
                  resData.successFields,
                  target_id,
                  target_type
                )
              );
            }
          });
        };

        processRequest(request, success, bulkActionError, bulkActionError);
        break;
      }

      case ActionTypes.HANDLE_COPY_SELECTED_FIELDS: {
        const {
          studyId,
          target_id,
          target_type,
          source_id,
          source_type,
          fields,
          nTimes,
        } = action;

        const request = copySelectedFields(
          studyId,
          target_id,
          target_type,
          source_id,
          source_type,
          fields,
          nTimes
        );
        const success = (resData) => {
          spinnerTimeoutWrapper(() => {
            if (resData.failedFields.length > 0) {
              dispatch(
                actions.handleCopySelectedFieldsWithErrors(
                  resData,
                  target_id,
                  target_type
                )
              );
            } else {
              $.flashAlert(
                "Selected fields have been copied successfully",
                "alert-success"
              );
              dispatch(
                actions.handleCopySelectedFieldSuccess(
                  resData,
                  target_id,
                  target_type
                )
              );
            }
          });
        };

        processRequest(request, success, bulkActionError, bulkActionError);
        break;
      }

      case ActionTypes.HANDLE_NEW_FIELD_FORM_SUBMIT: {
        const {
          studyId,
          values,
          fieldableId,
          fieldableType,
          formSectionId,
          newLookup,
          newLookupOptions,
        } = action;
        const request = createField(
          studyId,
          values,
          fieldableId,
          fieldableType,
          formSectionId,
          newLookup,
          newLookupOptions
        );

        const success = (resData) => {
          spinnerTimeoutWrapper(() => {
            dispatch(actions.handleNewFieldFormSuccess(resData, fieldableId));
          });
        };
        const failure = failureHandlerFor(actions.handleNewFieldFormError);
        const error = fieldErrorHandlerFor(actions.handleNewFieldFormError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_NEW_FIELD_FORM_SUBMIT_AND_CLOSE: {
        const {
          studyId,
          values,
          fieldableId,
          fieldableType,
          formSectionId,
          newLookup,
          newLookupOptions,
        } = action;
        const request = createField(
          studyId,
          values,
          fieldableId,
          fieldableType,
          formSectionId,
          newLookup,
          newLookupOptions
        );

        const success = (resData) => {
          spinnerTimeoutWrapper(() => {
            $.flashAlert(
              "Form field has been added successfully",
              "alert-success"
            );
            dispatch(
              actions.handleNewFieldFormSuccessAndClose(resData, fieldableId)
            );
          });
        };
        const failure = failureHandlerFor(actions.handleNewFieldFormError);
        const error = fieldErrorHandlerFor(actions.handleNewFieldFormError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_OPEN_EDIT_DEPENDENCIES_FORM: {
        const request = getSelectDependecyOptions(action.field);

        const success = successHandlerFor(
          actions.handleGetFieldsForDependencies
        );
        processRequest(request, success, () => {});
        break;
      }

      case ActionTypes.HANDLE_SUBMIT_EDIT_DEPENDENCIES_FORM: {
        const { studyId, fieldId, values } = action;
        const request = updateDependencies(studyId, fieldId, values);

        const success = successHandlerFor(
          actions.handleEditDependenciesFormSuccess,
          "Field dependencies have been updated successfully"
        );
        const failure = failureHandlerFor(
          actions.handleEditDependenciesFormError
        );
        const error = errorHandlerFor(actions.handleEditDependenciesFormError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_SUBMIT_SETTINGS_FORM: {
        const { studyId, fieldId, values } = action;
        const request = updateSettings(studyId, fieldId, values);

        const success = successHandlerFor(
          actions.handleSettingsFormSuccess,
          "Field settings have been updated successfully"
        );
        const failure = failureHandlerFor(actions.handleSettingsFormError);
        const error = errorHandlerFor(actions.handleSettingsFormError);

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_OPEN_EDIT_VALIDATION_RULES_FORM:
      case ActionTypes.HANDLE_OPEN_NEW_VALIDATION_RULES_FORM: {
        const validationFieldRequest = getValidationFieldOptions(action.field);
        const validationFieldSuccess = (resData) => {
          spinnerTimeoutWrapper(() => {
            dispatch(actions.handleGetFieldsForValidation(resData));
          });
        };
        processRequest(
          validationFieldRequest,
          validationFieldSuccess,
          () => {}
        );

        const conditionFieldRequest = getSelectDependecyOptions(action.field);
        const conditionFieldSuccess = (resData) => {
          spinnerTimeoutWrapper(() => {
            dispatch(actions.handleGetFieldsForDependencies(resData));
          });
        };
        processRequest(conditionFieldRequest, conditionFieldSuccess, () => {});

        break;
      }

      case ActionTypes.HANDLE_NEW_VALIDATION_RULES_FORM_SUBMIT: {
        const { studyId, fieldId, values } = action;
        const request = createValidation(studyId, fieldId, values);

        const success = successHandlerFor(
          actions.handleNewValidationRulesFormSuccess,
          "Field validation rule has been created successfully"
        );
        const failure = failureHandlerFor(
          actions.handleNewValidationRulesFormError
        );
        const error = errorHandlerFor(
          actions.handleNewValidationRulesFormError
        );

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_EDIT_VALIDATION_RULES_FORM_SUBMIT: {
        const { validationRuleId, values } = action;
        const request = updateValidation(validationRuleId, values);

        const success = successHandlerFor(
          actions.handleEditValidationRulesFormSuccess,
          "Field validation rule has been updated successfully"
        );
        const failure = failureHandlerFor(
          actions.handleEditValidationRulesFormError
        );
        const error = errorHandlerFor(
          actions.handleEditValidationRulesFormError
        );

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.HANDLE_DELETE_VALIDATION: {
        const { validationRuleId } = action;
        const request = deleteValidation(validationRuleId);

        const success = successHandlerFor(
          actions.handleDeleteValidationSuccess
        );
        const failure = () => {
          spinnerTimeoutWrapper(() => {
            $.flashAlert(defaultErrorMessage, "alert-danger");
          });
        };

        processRequest(request, success, failure, failure);
        break;
      }

      case ActionTypes.HANDLE_SELECT_FIELD_FOR_BULK_ACTION:
      case ActionTypes.HANDLE_SELECT_ALL_FIELDS_FOR_BULK_ACTION: {
        const { studyId, field, fields } = action;
        const fieldsToCheck = field ? [field] : fields;
        const request = referencedFields(studyId, fieldsToCheck);

        const success = successHandlerFor(
          actions.handleReferencedFieldsSuccess
        );
        const failure = failureHandlerFor(
          actions.handleReferencedFieldsFailure
        );

        processRequest(request, success, failure, failure);
        break;
      }
    }

    return next(action);
  };
