import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
} from "./base_requests";

function setValidationRuleParams(values) {
  let { privacy_tags, ...formValues } = values;

  if (privacy_tags) {
    formValues["privacy_tag_list"] = privacy_tags.map((tag) => tag.value);
  } else {
    formValues["privacy_tag_list"] = [];
  }

  return formValues;
}

export function getValidationFieldOptions(field) {
  return getRequest({
    url: `/study/${field.study_id}/fields/${field.id}/validation_rules/validation_field_list`,
    params: {},
  });
}

export function createValidation(studyId, fieldId, values) {
  return postRequest({
    url: `/study/${studyId}/fields/${fieldId}/validation_rules`,
    body: {
      validation_rule: setValidationRuleParams(values),
    },
  });
}

export function updateValidation(validationRuleId, values) {
  return patchRequest({
    url: `/study/validation_rules/${validationRuleId}`,
    body: {
      validation_rule: setValidationRuleParams(values),
    },
  });
}

export function deleteValidation(validationRuleId) {
  return deleteRequest({
    url: `/study/validation_rules/${validationRuleId}`,
    body: {},
  });
}
