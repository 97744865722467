import { processRequest, dispatchWithTimeout } from "services/base_requests";

import {
  createMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
} from "services/message_template_requests";

import { deleteQuestionnaire } from "services/questionnaires_requests";

import {
  messageTemplateActions,
  MessageTemplateActionTypes,
  questionnaireTableActions,
  QuestionnaireTableActionTypes,
} from "actions/questionnaires_page";

export const QuestionnairesIndexPageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-warning");

    switch (action.type) {
      case MessageTemplateActionTypes.HANDLE_NEW_MESSAGE_TEMPLATE_FORM_SUBMIT: {
        const { studyId, values } = action;
        const request = createMessageTemplate(studyId, values);

        const success = successHandlerFor(
          messageTemplateActions.handleNewMessageTemplateFormSubmitSuccess,
          "Message template has been successfully created"
        );
        const failure = failureHandlerFor(
          messageTemplateActions.handleMessageTemplateFormSubmitFailure
        );

        processRequest(request, success, failure);
        break;
      }

      case MessageTemplateActionTypes.HANDLE_MESSAGE_TEMPLATE_DELETE: {
        const { studyId, messageTemplateId } = action;
        const request = deleteMessageTemplate(studyId, messageTemplateId);

        const success = successHandlerFor(
          messageTemplateActions.handleMessageTemplateDeleteSuccess,
          "Message template has been successfully deleted"
        );
        const failure = failureHandlerFor(
          messageTemplateActions.handleMessageTemplateDeleteFailure
        );

        processRequest(request, success, failure);
        break;
      }

      case MessageTemplateActionTypes.HANDLE_UPDATE_MESSAGE_TEMPLATE_FORM_SUBMIT: {
        const { studyId, messageTemplateId, values } = action;
        const request = updateMessageTemplate(
          studyId,
          messageTemplateId,
          values
        );

        const success = successHandlerFor(
          messageTemplateActions.handleUpdateMessageTemplateFormSubmitSuccess,
          "Message template has been successfully updated"
        );
        const failure = failureHandlerFor(
          messageTemplateActions.handleMessageTemplateFormSubmitFailure
        );

        processRequest(request, success, failure);
        break;
      }

      case QuestionnaireTableActionTypes.HANDLE_QUESTIONNAIRE_DELETE: {
        const { studyId, entityGroupId, questionnaireId } = action;

        const request = deleteQuestionnaire(
          studyId,
          entityGroupId,
          questionnaireId
        );

        const success = successHandlerFor(
          questionnaireTableActions.handleQuestionnaireDeleteSuccess,
          "Questionnaire has been successfully deleted."
        );
        const failure = failureHandlerFor(
          questionnaireTableActions.handleQuestionnaireDeleteFailure
        );

        processRequest(request, success, failure);
      }
    }
    return next(action);
  };
