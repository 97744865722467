import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Modal,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Checkbox,
  Alert,
} from "react-bootstrap";

import {
  TextField,
  ButtonWithSpinner,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";

import { sitePropertyActions } from "actions/sites_page";
import SelectField from "../helpers/SelectField";

const SiteFormScreen = ({
  sitePropertyDataTypes,
  shown,
  editing,
  saving,
  studyId,
  sitePropertyId,
  values,
  errors,
  closeSitePropertyForm,
  newSitePropertyFormSaveAndClose,
  newSitePropertyFormSave,
  editSitePropertyFormSave,
  updateFormValue,
  addSingleValueOption,
  updateOptionValue,
  removeSingleValueOption,
}) => {
  const handleSaveAndClose = () => {
    newSitePropertyFormSaveAndClose(studyId, values);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editing) {
      editSitePropertyFormSave(studyId, sitePropertyId, values);
    } else {
      newSitePropertyFormSave(studyId, values);
    }
  };
  return (
    <Modal
      id="modalWindow"
      backdrop="static"
      show={shown}
      onHide={saving ? undefined : closeSitePropertyForm}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>
            {editing ? "Edit additional property" : "New additional property"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="additional-property-values">
            <Row>
              <Col md={12}>
                <TextField
                  controlId="name"
                  fieldName="name"
                  label="Name"
                  required={true}
                  value={values.name}
                  disabled={saving}
                  errors={errors.name}
                  onChange={updateFormValue}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <TextField
                  controlId="identifier"
                  fieldName="identifier"
                  label="Identifier"
                  pattern={IDENTIFIER_LIMIT_PATTERN}
                  title={IDENTIFIER_LIMIT_TITLE}
                  required={true}
                  value={values.identifier}
                  disabled={saving}
                  errors={errors.identifier}
                  onChange={updateFormValue}
                />
              </Col>
            </Row>
            {!editing && (
              <Row>
                <Col md={12}>
                  <SelectField
                    fieldName="data_type"
                    required={true}
                    label="Type"
                    className="select-field-data_type react-select"
                    value={values.data_type}
                    disabled={saving}
                    errors={errors.data_type}
                    onChange={(option) => {
                      updateFormValue("data_type", option.value);
                    }}
                    options={sitePropertyDataTypes}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={12}>
                <Checkbox
                  checked={values.required}
                  validationState={null}
                  onChange={(e) =>
                    updateFormValue("required", e.target.checked)
                  }
                  disabled={saving}
                >
                  Required field
                </Checkbox>
              </Col>
            </Row>
          </div>
          {values.data_type == "single_value" && (
            <fieldset className="margin-top-10">
              <legend>Field options</legend>
              <div className="well well-sm margin-bottom-0">
                {values.single_value_options
                  .filter((option) => !option.marked_for_destruction)
                  .map((option, index) => {
                    return (
                      <Row id={`row${index}`} key={index}>
                        <Col md={10}>
                          <TextField
                            controlId="name"
                            fieldName="name"
                            placeholder="Name"
                            required={true}
                            value={option.name}
                            disabled={saving}
                            errors={
                              errors.single_value_options &&
                              errors.single_value_options[index]
                                ? errors.single_value_options[index].name
                                : undefined
                            }
                            onChange={(fieldName, value) => {
                              updateOptionValue(index, fieldName, value);
                            }}
                          />
                        </Col>
                        <Col md={2} className="text-right">
                          <Button
                            onClick={() => {
                              removeSingleValueOption(index);
                            }}
                            disabled={saving}
                            bsStyle="link"
                            title="Remove this option"
                          >
                            <i className="fa fa-trash-o text-danger text-large" />
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                <Button
                  className="margin-top-10"
                  onClick={() => {
                    addSingleValueOption();
                  }}
                  disabled={saving}
                  bsStyle="success"
                  bsSize="xs"
                  title="Add an option"
                >
                  <i className="fa fa-plus  margin-right-5" />
                  Add an option
                </Button>
                {errors && errors.base && (
                  <Alert bsStyle="danger" className="margin-top-10">
                    {errors.base[0]}
                  </Alert>
                )}
              </div>
            </fieldset>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <ButtonGroup>
              {editing && (
                <ButtonWithSpinner
                  saving={saving}
                  buttonText="Update"
                  faIcon="check"
                  bsStyle="primary"
                  type="submit"
                />
              )}
              {!editing && (
                <>
                  <ButtonWithSpinner
                    saving={saving}
                    buttonText="Save"
                    faIcon="check"
                    bsStyle="primary"
                    type="submit"
                  />
                  <ButtonWithSpinner
                    saving={saving}
                    buttonText="Save and close"
                    bsStyle="info"
                    onClick={() => handleSaveAndClose()}
                  />
                </>
              )}
            </ButtonGroup>
            <div className="pull-right">
              <Button
                bsStyle="default"
                disabled={saving}
                onClick={closeSitePropertyForm}
              >
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.sitePropertyForm;
  return {
    sitePropertyDataTypes: relevantState.sitePropertyDataTypes,
    sitePropertyId: relevantState.sitePropertyId,
    shown: relevantState.shown,
    editing: relevantState.editing,
    saving: relevantState.saving,
    studyId: relevantState.studyId,
    values: relevantState.values,
    errors: relevantState.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeSitePropertyForm: sitePropertyActions.closeSitePropertyForm,
      updateFormValue: sitePropertyActions.sitePropertyFormValueChange,
      newSitePropertyFormSave: sitePropertyActions.newSitePropertyFormSave,
      newSitePropertyFormSaveAndClose:
        sitePropertyActions.newSitePropertyFormSaveAndClose,
      editSitePropertyFormSave: sitePropertyActions.editSitePropertyFormSave,
      addSingleValueOption:
        sitePropertyActions.addSitePropertySingleValueOption,
      updateOptionValue: sitePropertyActions.sitePropertyFormOptionValueChange,
      removeSingleValueOption:
        sitePropertyActions.removeSitePropertySingleValueOption,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteFormScreen);
