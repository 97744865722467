class Attribute {
  constructor(
    title,
    identifier,
    actionType,
    originalValue,
    newValue,
    changed,
    attributeType = "default",
    displayFunction = undefined,
    displayAssociatedObjectFunction = undefined,
    displayExportFunction = undefined,
    groupOnCreateAndDestroy = true
  ) {
    this.title = title;
    this.identifier = identifier;
    this.actionType = actionType;
    this.originalValue = originalValue;
    this.newValue = newValue;
    this.changed = changed;
    this.attributeType = attributeType;
    this.displayFunction = displayFunction;
    this.displayAssociatedObjectFunction = displayAssociatedObjectFunction;
    this.displayExportFunction = displayExportFunction;
    this.groupOnCreateAndDestroy = groupOnCreateAndDestroy;
  }

  static createFrom(props) {
    const {
      change,
      title,
      identifier,
      attributeType,
      displayFunction,
      displayAssociatedObjectFunction,
      displayExportFunction,
    } = props;
    const originalValue =
      props.originalValue || change.original_attributes?.[identifier]?.value;
    const newValue =
      props.newValue || change.new_attributes?.[identifier]?.value;
    const changed =
      props.changed === undefined
        ? change.original_attributes?.[identifier]?.changed ||
          change.new_attributes?.[identifier]?.changed
        : props.changed;
    const groupOnCreateAndDestroy =
      props.groupOnCreateAndDestroy === undefined
        ? true
        : props.groupOnCreateAndDestroy;

    return new Attribute(
      title,
      identifier,
      change.action_type,
      originalValue,
      newValue,
      changed,
      attributeType || "default",
      displayFunction,
      displayAssociatedObjectFunction,
      displayExportFunction,
      groupOnCreateAndDestroy
    );
  }

  isNonEmpty(value) {
    if (this.attributeType === "association") {
      return value && value.length > 0;
    } else {
      return !!value;
    }
  }

  shouldDisplay() {
    if (this.actionType == "create") {
      return this.isNonEmpty(this.newValue);
    } else if (this.actionType == "delete") {
      return this.isNonEmpty(this.originalValue);
    } else if (this.actionType == "update") {
      return this.changed;
    }
  }

  displayForExport(value) {
    if (value === undefined || value === null) {
      return "";
    }

    if (this.displayExportFunction === undefined) {
      switch (this.attributeType) {
        case "boolean":
          return value ? "True" : "False";
        default:
          return String(value);
      }
    } else {
      return this.displayExportFunction(value);
    }
  }

  displayOriginalValueForExport() {
    return this.displayForExport(this.originalValue).replaceAll('"', '""');
  }

  displayNewValueForExport() {
    return this.displayForExport(this.newValue).replaceAll('"', '""');
  }
}

export default Attribute;
