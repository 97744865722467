import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const validationDetailsTooltip = (validation) => {
  return (
    <Tooltip id={`validation-tooltip-${validation.id}`}>
      {validation.tooltip.map((tooltip, index) => {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: tooltip }}></div>
        );
      })}
    </Tooltip>
  );
};

const ValidationsCell = ({
  field,
  handleOpenNewForm,
  handleOpenEditForm,
  handleDelete,
}) => {
  return (
    <>
      <td className="vertical-center">
        <div className="cell-with-action-button">
          <button
            onClick={() => handleOpenNewForm(field)}
            title="Add validation rules for this field"
            className="btn btn-link  action-button"
          >
            <i className="fa fa-plus-square" />
          </button>
          <div className="validation-rules">
            {field.validation_rules
              .sort((a, b) => {
                if (a.name > b.name) {
                  return 1;
                } else if (a.name < b.name) {
                  return -1;
                }
                return 0;
              })
              .map((validation, index) => {
                return (
                  <span
                    key={index}
                    id={`validation_${validation.id}`}
                    className="validation"
                  >
                    <i
                      className={`fa fa-circle validation-indicator ${validation.pre_entry}`}
                      title={`Point of entry validation: ${
                        validation.pre_entry || "none"
                      }`}
                    />
                    <i
                      className={`fa fa-circle validation-indicator ${validation.post_entry}`}
                      title={`Post-entry validation: ${
                        validation.post_entry || "none"
                      }`}
                    />
                    <OverlayTrigger
                      placement="left"
                      trigger={["hover", "focus"]}
                      overlay={validationDetailsTooltip(validation)}
                    >
                      <button
                        onClick={() => handleOpenEditForm(field, validation)}
                        className="btn btn-link margin-left-5 margin-right-15 edit-validation-button"
                      >
                        {validation.name}
                      </button>
                    </OverlayTrigger>
                    <button
                      onClick={() => handleDelete(validation.id)}
                      title="Remove validation rule"
                      className="btn btn-link remove-validation-button"
                      data-confirm={`Are you sure you want to remove this '${validation.name}' validation from '${field.label}'?`}
                    >
                      <i className="fa fa-trash-o text-danger" />
                    </button>
                  </span>
                );
              })}
          </div>
        </div>
      </td>
    </>
  );
};

export default ValidationsCell;
