import { postRequest, patchRequest, deleteRequest } from "./base_requests";

const convertValuesForController = (values) => {
  return {
    code: values.code,
    short_name: values.short_name,
    extra_code: values.extra_code,
    long_name: values.long_name,
    recruitment_start_date: values.recruitment_start_date,
    recruitment_end_date: values.recruitment_end_date,
    metadatum_entries_attributes: values.metadatum_entries.map((entry) => {
      return {
        id: entry.id,
        metadatum_id: entry.metadatum_id,
        string_value: entry.string_value,
        metadatum_field_option_id: entry.metadatum_field_option_id,
        metadatum_list_options_attributes: entry.metadatum_list_options
          ? entry.metadatum_list_options.map((option) => {
              return {
                id: option.id,
                identifier: option.identifier,
                name: option.name,
                position: option.position,
                _destroy: option.marked_for_destruction,
              };
            })
          : [],
      };
    }),
  };
};

export function createSite(studyId, values) {
  return postRequest({
    url: `/study/${studyId}/sites.json`,
    body: { site: convertValuesForController(values) },
  });
}

export function updateSite(studyId, siteId, values) {
  return patchRequest({
    url: `/study/${studyId}/sites/${siteId}.json`,
    body: { site: convertValuesForController(values) },
  });
}

export function removeSite(studyId, siteId) {
  return deleteRequest({
    url: `/study/${studyId}/sites/${siteId}`,
    body: {},
  });
}
