import React from "react";

const SubformRow = ({ subform }) => {
  return (
    <tr>
      <td colSpan={6}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <i className="fa fa-xs fa-list-alt margin-right-10" title="Subform" />
          <span>
            <strong>{subform.label}</strong>
            <em>
              <div>{subform.identifier}</div>
            </em>
          </span>
        </div>
      </td>
    </tr>
  );
};

export default SubformRow;
