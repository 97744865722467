import React from "react";
import { ButtonGroup, Alert, Well, Row, Col } from "react-bootstrap";
import AceEditor from "../AceEditor";

import { toSnakeCase } from "services/case";

import { ToggleButton, SelectField } from "components/helpers";
import { Expiration } from "components/questionnaires_page";

const MeansOfDelivery = ({
  messageTemplates,
  values,
  errors,
  section,
  meansOfDeliveryToggled,
  handleMeansOfDeliveryFieldValueChange,
  handleMessageTemplateChange,
  saving,
  loadingFieldsForInclusionCriteria,
  fieldsForDeliveryCriteria,
  updateFormValue,
  availableExpirationUnits,
}) => {
  let meansOfDeliveryFieldName;
  if (section === "request") {
    meansOfDeliveryFieldName = "meansOfInitialAttemptDelivery";
  } else if (section === "reminder") {
    meansOfDeliveryFieldName = "meansOfReminderAttemptDelivery";
  }
  const meansOfDelivery = values[meansOfDeliveryFieldName];
  const baseErrors = errors[`${toSnakeCase(meansOfDeliveryFieldName)}_base`];

  const conditionChangeHandlerFunction = (deliveryType) => {
    return (fieldName, value) => {
      handleMeansOfDeliveryFieldValueChange(
        meansOfDeliveryFieldName,
        deliveryType,
        fieldName,
        value
      );
    };
  };

  const selectedMeansOfDelivery = meansOfDelivery
    .filter((means) => means.selected === true)
    .map((means) => means.type);

  return (
    <div data-testid={meansOfDeliveryFieldName}>
      <h4>Means of Delivery</h4>
      {baseErrors && <Alert bsStyle="danger">{baseErrors.join(", ")}</Alert>}
      <ButtonGroup className="means-of-administration-buttons margin-bottom-20">
        {meansOfDelivery.map((means) => {
          return (
            <ToggleButton
              key={means.type}
              fieldName={means.type}
              value={means.selected}
              label={means.label}
              onChange={meansOfDeliveryToggled}
            />
          );
        })}
      </ButtonGroup>
      {selectedMeansOfDelivery.length > 0 && section === "request" && (
        <Expiration
          expirationLabelPrepend="Becomes a Non-responder"
          expirationLabelAppend="after initial sending"
          valueFieldName="nonResponderValue"
          unitFieldName="nonResponderUnit"
          values={values}
          saving={saving}
          errors={errors}
          updateFormValue={updateFormValue}
          availableExpirationUnits={availableExpirationUnits}
        />
      )}
      {selectedMeansOfDelivery.length > 0 && section === "reminder" && (
        <Expiration
          expirationLabelPrepend="Reminder due"
          expirationLabelAppend="after initial sending"
          valueFieldName="reminderDueValue"
          unitFieldName="reminderDueUnit"
          values={values}
          saving={saving}
          errors={errors}
          updateFormValue={updateFormValue}
          availableExpirationUnits={availableExpirationUnits}
        />
      )}
      {/* Commented out until phase 3 */}
      {/* {onlineMeansOfDeliverySelected && (
        <Expiration
          valueFieldName={`${section}ExpirationValue`}
          unitFieldName={`${section}ExpirationUnit`}
          values={values}
          saving={saving}
          errors={errors}
          updateFormValue={updateFormValue}
          availableExpirationUnits={availableExpirationUnits}
        />
      )} */}
      {meansOfDelivery.map((means) => {
        const deliveryType = means.type;
        if (means.selected === true) {
          const errorKey = `${toSnakeCase(meansOfDeliveryFieldName)}.${
            means.type
          }.condition`;
          return (
            <Well
              bsSize="sm"
              key={`${deliveryType}`}
              id={`${means.type}-container`}
            >
              <h4 className="margin-top-0 margin-bottom-20">{means.label}</h4>
              <h5>Message / script template</h5>
              <Row className="margin-bottom-20">
                <Col md={6}>
                  <SelectField
                    fieldName="message-template"
                    label="Message / script template"
                    labelClassName="sr-only"
                    value={means.messageTemplateId}
                    options={messageTemplates}
                    onChange={(selection) => {
                      handleMessageTemplateChange(
                        meansOfDeliveryFieldName,
                        deliveryType,
                        selection.value
                      );
                    }}
                    placeholder="Select message / script template"
                    disabled={saving}
                    isClearable={true}
                    className="select-message-template"
                    classNamePrefix="select-message-template"
                  />
                </Col>
              </Row>
              <h5>Condition</h5>
              <AceEditor
                disabled={saving}
                fieldName="condition"
                testId={`${deliveryType}-condition`}
                value={means.condition}
                errors={errors[errorKey]}
                handleUpdateValue={conditionChangeHandlerFunction(deliveryType)}
                loadingFields={loadingFieldsForInclusionCriteria}
                fieldsForDependencies={fieldsForDeliveryCriteria}
                setFocus={false}
                height="50px"
              />
            </Well>
          );
        }
      })}
    </div>
  );
};

export default MeansOfDelivery;
