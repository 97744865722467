import ChangeObject from "./ChangeObject";

class EntityGroupChange extends ChangeObject {
  constructor(change) {
    const originalAttributes = change.original_attributes;
    const newAttributes = change.new_attributes;
    const attributeChanged = (identifier) => {
      return (
        originalAttributes?.[identifier]?.changed ||
        newAttributes?.[identifier]?.changed
      );
    };

    const identifier = newAttributes
      ? newAttributes.identifier.value
      : originalAttributes.identifier.value;

    const attributeData = [
      ["Identifier", "identifier"],
      ["Name", "name"],
      ["Description", "description"],
      [
        "Entity Identifier",
        undefined,
        {
          originalValue: `${originalAttributes?.entity_identifier_name?.value} (${originalAttributes?.entity_identifier_format.value})`,
          newValue: `${newAttributes?.entity_identifier_name?.value} (${newAttributes?.entity_identifier_format.value})`,
          changed:
            attributeChanged("entity_identifier_name") ||
            attributeChanged("entity_identifier_format"),
        },
      ],
      ["Entity Term", "entity_term_name"],
    ];

    super(change, "Entity group", identifier, attributeData);
  }
}

export default EntityGroupChange;
