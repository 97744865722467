import React from "react";

const OrderingChangesList = ({ originalOrderedModels, newOrderedModels }) => {
  // Create a hash to combine the original and new models, and make it easier to map over them.
  let combindedAttributes = {};
  originalOrderedModels.forEach((model, index) => {
    combindedAttributes[index + 1] = { original: model };
  });
  newOrderedModels.forEach((model, index) => {
    combindedAttributes[index + 1] = combindedAttributes[index + 1] || {};
    combindedAttributes[index + 1]["new"] = model;
  });

  return (
    <table className="table" style={{ tableLayout: "fixed" }}>
      <thead>
        <tr>
          <th style={{ width: "30%" }}>Original</th>
          <th>Updated</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(combindedAttributes).map((index) => {
          const originalModel = combindedAttributes[index]["original"];
          const newModel = combindedAttributes[index]["new"];

          const hasChange =
            (originalModel && originalModel.changed_position) ||
            (newModel && newModel.changed_position);

          return (
            <tr key={index} className={hasChange ? "has-change" : ""}>
              <td className="original-order-attribute">
                {originalModel ? `${index}. ${originalModel.name.value}` : ""}
              </td>
              <td className="updated-order-attribute">
                {newModel ? `${index}. ${newModel.name.value}` : ""}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrderingChangesList;
