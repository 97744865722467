import React from "react";
import { Panel } from "react-bootstrap";

export const Chevron = ({ expanded }) => {
  if (expanded) {
    return <i className="fa fa-chevron-up pull-right" />;
  } else {
    return <i className="fa fa-chevron-down pull-right" />;
  }
};

const CollapsiblePanel = ({
  id,
  title,
  children,
  expanded,
  handleCollapseToggle,
}) => {
  return (
    <Panel
      id={id}
      expanded={expanded}
      bsStyle="primary"
      onToggle={handleCollapseToggle}
    >
      <Panel.Heading>
        <Panel.Title componentClass="h2">
          {title}
          <Panel.Toggle componentClass="a">
            <Chevron expanded={expanded} />
          </Panel.Toggle>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>{children}</Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};

export default CollapsiblePanel;
