export const updateFormValue = (name, value) => ({
  type: "UPDATE_FORM_VALUE",
  name,
  value,
});

export const createForm = (form, details) => ({
  type: "CREATE_FORM",
  form,
  details,
});

export const updateForm = (form, details) => ({
  type: "UPDATE_FORM",
  form,
  details,
});

export const saveSuccess = (savedObject) => ({
  type: "SAVE_SUCCESS",
  savedObject,
});

export const saveError = (errors) => ({
  type: "SAVE_FAILURE",
  errors,
});

export const cancelEdit = () => ({
  type: "CANCEL_EDIT",
});
