import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ButtonToolbar, Button, Row, Col, Alert } from "react-bootstrap";

import {
  TextField,
  TextAreaField,
  IDENTIFIER_LIMIT_PATTERN,
  IDENTIFIER_LIMIT_TITLE,
} from "components/helpers";
import { messageTemplateActions } from "actions/questionnaires_page";
import { messageTemplateFormDefaultState } from "reducers/questionnaires_page";

const MessageTemplateFormScreen = ({
  show,
  editing,
  studyId,
  messageTemplateId,
  saving,
  values,
  errors,
  handleClose,
  updateFormValue,
  handleNewSubmit,
  handleUpdateSubmit,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (editing) {
      handleUpdateSubmit(studyId, messageTemplateId, values);
    } else {
      handleNewSubmit(studyId, values);
    }
  };

  return (
    <Modal
      id="modalWindow"
      dialogClassName="modal-lg"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : handleClose}
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>
            {editing
              ? "Edit message / script template"
              : "New message / script template"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <TextField
                required={true}
                controlId="name"
                fieldName="name"
                label="Name"
                value={values.name}
                disabled={saving}
                errors={errors.name}
                onChange={updateFormValue}
              />
            </Col>
            <Col md={6}>
              <TextField
                required={true}
                controlId="identifier"
                fieldName="identifier"
                label="Identifier"
                pattern={IDENTIFIER_LIMIT_PATTERN}
                title={IDENTIFIER_LIMIT_TITLE}
                value={values.identifier}
                disabled={saving}
                errors={errors.identifier}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Alert bsStyle="info">
                <p>Default contact fields available are:</p>
                <ul>
                  <li>contact_first_name</li>
                  <li>contact_last_name</li>
                  <li>contact_email</li>
                  <li>contact_phone_number</li>
                  <li>contact_landline_number</li>
                  <li>contact_address1</li>
                  <li>contact_address2</li>
                  <li>contact_town_city</li>
                  <li>contact_postcode</li>
                </ul>
                <p className="margin-top-20">
                  You can reference these in the message content like so:
                </p>
                <p>
                  <strong>{"Dear {contact_first_name} ..."}</strong>
                </p>
              </Alert>
              <TextAreaField
                required={true}
                rows={10}
                controlId="content"
                fieldName="content"
                label="Message content"
                value={values.content}
                disabled={saving}
                errors={errors.content}
                onChange={updateFormValue}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  Saving
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  Save
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.messageTemplateForm;

  return {
    studyId: relevantState.studyId,
    show: relevantState.showForm,
    editing: relevantState.editing,
    messageTemplateId: relevantState.messageTemplateId,
    values: relevantState.values,
    saving: relevantState.saving,
    errors: relevantState.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue:
        messageTemplateActions.handleMessageTemplateFormValueChange,
      handleClose: messageTemplateActions.handleCloseMessageTemplateForm,
      handleNewSubmit:
        messageTemplateActions.handleNewMessageTemplateFormSubmit,
      handleUpdateSubmit:
        messageTemplateActions.handleUpdateMessageTemplateFormSubmit,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageTemplateFormScreen);
