import React from "react";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";
import { applyMiddleware, combineReducers, createStore } from "redux";

import { QuestionnairesPageMiddleware } from "middleware/questionnaires_page";
import {
  QuestionnaireFormReducer,
  questionnaireFormDefaultState,
} from "reducers/questionnaires_page";
import { QuestionnaireFormScreen } from "components/questionnaires_page";

const NewQuestionnairePage = ({
  studyId,
  entityGroupId,
  events,
  messageTemplates,
  studyForms,
}) => {
  const store = createStore(
    combineReducers({
      questionnaireForm: QuestionnaireFormReducer,
    }),
    {
      questionnaireForm: {
        ...questionnaireFormDefaultState,
        studyId: studyId,
        entityGroupId: entityGroupId,
        events: events,
        messageTemplates: messageTemplates,
        studyForms: studyForms,
      },
    },
    applyMiddleware(QuestionnairesPageMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <QuestionnaireFormScreen />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default NewQuestionnairePage;
