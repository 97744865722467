import { getRequest, postRequest } from "./base_requests";

export function duplicateStudyForm(studyId, values) {
  return postRequest({
    url: `/study/${studyId}/duplicate_study_form`,
    body: {
      duplicate_study_form_form: { ...values },
    },
  });
}

export function dependenciesThatCannotBeCopied(studyId, value) {
  return getRequest({
    url: `/study/${studyId}/duplicate_study_form/${value}/dependencies_that_cannot_be_copied.json`,
  });
}
