import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Modal, ButtonToolbar, Button } from "react-bootstrap";

import ColumnProperties from "./ColumnProperties";
import TableProperties from "./TableProperties";

import actions from "actions/tables_page";

const TableModalScreen = ({
  studyId,
  studyTableId,
  show,
  editing,
  values,
  saving,
  disableSubmitButtonText,
  handleClose,
  handleNewFormSubmit,
  handleEditFormSubmit,
}) => {
  const submitForm = (e) => {
    e.preventDefault();
    if (editing === true) {
      handleEditFormSubmit(studyId, studyTableId, values);
    } else {
      handleNewFormSubmit(studyId, values);
    }
  };

  return (
    <Modal
      id="modalWindow"
      backdrop="static"
      show={show}
      onHide={saving ? undefined : handleClose}
      bsSize="large"
    >
      <form onSubmit={submitForm}>
        <Modal.Header closeButton={!saving}>
          <Modal.Title>{editing ? "Edit table" : "New table"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableProperties />
          <ColumnProperties />
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button bsStyle="primary" type="submit" disabled={saving}>
              {saving && (
                <span>
                  <i className="fa fa-circle-o-notch fa-spin margin-right-5" />
                  {disableSubmitButtonText}
                </span>
              )}
              {!saving && (
                <span>
                  <i className="fa fa-check margin-right-5" />
                  Save
                </span>
              )}
            </Button>
            <div className="pull-right">
              <Button bsStyle="default" disabled={saving} onClick={handleClose}>
                <i className="fa fa-times" /> Cancel
              </Button>
            </div>
          </ButtonToolbar>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const relevantState = state.tableModal;
  return {
    studyId: relevantState.studyId,
    studyTableId: relevantState.studyTableId,
    show: relevantState.show,
    editing: relevantState.editing,
    values: relevantState.values,
    saving: relevantState.saving,
    disableSubmitButtonText: relevantState.disableSubmitButtonText,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleClose: actions.closeModal,
      handleNewFormSubmit: actions.handleNewFormSubmit,
      handleEditFormSubmit: actions.handleEditFormSubmit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TableModalScreen);
