$ ->
  reset_autopopulate = () ->
    # Don't autopopulate if the page has reloaded with any errors
    $(':input[name$="[identifier]"]').each ->
      $(this).data('changed', $('.has-error').length > 0)

    # Don't autopopulate if the page has reloaded with any errors
    $(':input[name$="[entity_identifier_name]"]').each ->
      $(this).data('changed', $('.has-error').length > 0)

    # Don't autopopulate if the page has reloaded with any errors
    $(':input[name$="[entity_term_name]"]').each ->
      $(this).data('changed', $('.has-error').length > 0)

  reset_autopopulate()

  $(document).on 'reset-autopopulate', ->
    reset_autopopulate()
    
  $(document).on 'blur', '[data-populate-identifier-field]', ->
    $identifierField = $($(this).data('populate-identifier-field'))
    unless $identifierField.data('changed')
      $identifierField.val($(this).val().toLowerCase().replace /\W+/g, '_')
      $identifierField.data('changed', true) # Only auto-populate once

  $(document).on 'blur', '[data-populate-entity-identifier-field]', ->
    $sourceField = $(this)
    $destField = $($sourceField.data('populate-entity-identifier-field'))
    if !$destField.data('changed') && $sourceField.val() isnt ''
      $destField.val(pluralize($sourceField.val(), 1) + " ID")
      $destField.data('changed', true) # Only auto-populate once

  $(document).on 'blur', '[data-populate-entity-term-field]', ->
    $sourceField = $(this)
    $destField = $($sourceField.data('populate-entity-term-field'))
    if !$destField.data('changed') && $sourceField.val() isnt ''
      $destField.val(pluralize($sourceField.val(), 1))
      $destField.data('changed', true) # Only auto-populate once
