import { postRequest, patchRequest } from "./base_requests";

export function createTable(studyId, values) {
  return postRequest({
    url: `/study/${studyId}/study_tables`,
    body: {
      study_table: { ...values },
    },
  });
}

export function updateTable(studyId, studyTableId, values) {
  return patchRequest({
    url: `/study/${studyId}/study_tables/${studyTableId}`,
    body: { ...values },
  });
}
