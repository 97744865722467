import { ActionTypes } from "../../actions/layout_page";

export const NavigationReducer = (
  state = { searching: false, searchTerm: "", forms: [] },
  action
) => {
  const updateNavbar = (action, userAction) => {
    let newState = { ...state };
    let formOfRemovedSection = newState.forms.filter(
      (form) => form.id == action.formId
    )[0];
    if (userAction == "add") {
      formOfRemovedSection.countForNav = formOfRemovedSection.countForNav + 1;
    } else if (userAction == "remove") {
      formOfRemovedSection.countForNav = formOfRemovedSection.countForNav - 1;
    }

    return {
      ...state,
      forms: newState.forms.map((form) =>
        form.id == action.formId ? formOfRemovedSection : form
      ),
    };
  };

  switch (action.type) {
    case ActionTypes.NAV_SEARCH: {
      let newState = { ...state };
      newState.searching = true;
      return newState;
    }
    case ActionTypes.NAV_SET_SEARCH_TERM: {
      let newState = { ...state };
      newState.searchTerm = action.searchTerm;
      return newState;
    }
    case ActionTypes.NAV_SEARCH_SUCCESS: {
      let newState = {
        ...state,
        searching: false,
        forms: action.studyForms,
      };
      return newState;
    }
    case ActionTypes.REMOVE_SECTION_SUCCESS: {
      return updateNavbar(action, "remove");
    }
    case ActionTypes.SAVE_SECTION_FORM_SUCCESS: {
      return updateNavbar(action, "add");
    }
    case ActionTypes.SAVE_AND_CLOSE_SECTION_FORM_SUCCESS: {
      return updateNavbar(action, "add");
    }
  }

  return state;
};
