import _ from "lodash/core";
import { ActionTypes } from "actions/tables_page";

const blankValues = {
  identifier: "",
  lead_column_header: "",
  lead_column_name: "",
  columns: [],
};

export const tableModalDefaultState = {
  editing: false,
  show: false,
  studyId: null,
  studyTableId: null,
  saving: false,
  disableSubmitButtonText: "Saving",
  columnsInUse: [],
  values: blankValues,
  errors: {},
};

export const TableModalReducer = (state = tableModalDefaultState, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_ADD_TABLE_MODAL: {
      const newState = { ...state, editing: false, show: true };
      return newState;
    }
    case ActionTypes.OPEN_EDIT_TABLE_MODAL: {
      const newValues = {
        identifier: action.props.identifier || "",
        lead_column_header: action.props.lead_column_header || "",
        lead_column_name: action.props.lead_column_name || "",
        columns: action.props.columns || "",
      };
      const newState = {
        ...state,
        show: true,
        editing: true,
        studyTableId: action.props.id,
        columnsInUse: action.props.columns_in_use,
        values: newValues,
      };
      return newState;
    }
    case ActionTypes.CLOSE_MODAL: {
      const newState = { ...tableModalDefaultState, studyId: state.studyId };
      return newState;
    }
    case ActionTypes.UPDATE_FORM_VALUE: {
      const newValues = { ...state.values, [action.fieldName]: action.value };
      const newState = {
        ...state,
        values: newValues,
      };
      return newState;
    }
    case ActionTypes.HANDLE_ADD_COLUMN: {
      const newColumns = [...state.values.columns, ""];
      const newValues = { ...state.values, columns: newColumns };
      const newState = { ...state, values: newValues };
      return newState;
    }
    case ActionTypes.HANDLE_CHANGE_COLUMN: {
      const newColumns = [...state.values.columns];
      newColumns[action.index] = action.value;

      var leadColumnName = state.values["lead_column_name"];
      if (
        !_.isEmpty(leadColumnName) &&
        leadColumnName == state.values.columns[action.index]
      )
        leadColumnName = action.value;

      const newValues = {
        ...state.values,
        columns: newColumns,
        lead_column_name: leadColumnName,
      };
      const newState = { ...state, values: newValues };
      return newState;
    }
    case ActionTypes.HANDLE_REORDER_COLUMNS: {
      const newColumns = [...state.values.columns];
      const [movedRow] = newColumns.splice(action.result.source.index, 1);
      newColumns.splice(action.result.destination.index, 0, movedRow);

      const newValues = { ...state.values, columns: newColumns };
      const newState = { ...state, values: newValues };
      return newState;
    }
    case ActionTypes.HANDLE_REMOVE_COLUMN: {
      const newColumns = [...state.values.columns];
      newColumns.splice(action.index, 1);

      var leadColumnName = state.values["lead_column_name"];
      if (!newColumns.includes(leadColumnName)) leadColumnName = "";

      const newValues = {
        ...state.values,
        columns: newColumns,
        lead_column_name: leadColumnName,
      };
      const newState = { ...state, values: newValues };
      return newState;
    }
    case ActionTypes.HANDLE_NEW_FORM_SUBMIT:
    case ActionTypes.HANDLE_EDIT_FORM_SUBMIT: {
      const newState = {
        ...state,
        saving: true,
        disableSubmitButtonText: "Saving",
      };
      return newState;
    }
    case ActionTypes.HANDLE_SUBMIT_SUCCESS: {
      window.location.reload();
      break;
    }
    case ActionTypes.HANDLE_SUBMIT_FAILURE: {
      const newState = {
        ...state,
        errors: action.resData.json_errors,
        saving: false,
      };
      return newState;
    }
  }

  return state;
};
