import React from "react";
import { Button } from "react-bootstrap";

const FaButton = ({ icon, iconClass = "", text, ...other }) => {
  return (
    <Button {...other}>
      <i className={`fa fa-${icon} ${iconClass}`} /> {text}
    </Button>
  );
};

export default FaButton;
