import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Panel, Table, ButtonGroup } from "react-bootstrap";
import { FaButton } from "components/helpers";

import { sitePropertyActions } from "actions/sites_page";

const AdditionalSitePropertiesTable = ({
  additionalSiteProperties,
  openNewSitePropertyForm,
  openEditSitePropertyForm,
  removeSiteProperty,
}) => {
  return (
    <Panel bsStyle="primary">
      <Panel.Heading className="panel-heading-sticky">
        <ul className="panel-buttons">
          <li>
            <FaButton
              icon="plus"
              bsStyle="link"
              text="Add"
              title="Add a new site metadata"
              onClick={() => openNewSitePropertyForm()}
            />
          </li>
        </ul>
        <Panel.Title componentClass="h2">
          <i className="fa fa-reorder margin-right-5" />
          Additional Site Properties
        </Panel.Title>
      </Panel.Heading>
      <Table id="metadata-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Identifier</th>
            <th>Required?</th>
            <th>Type</th>
            <th>Field Options</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {additionalSiteProperties.map((metadatum) => {
            return (
              <tr key={`additional-property-${metadatum.identifier}`}>
                <td>{metadatum.name}</td>
                <td>{metadatum.identifier}</td>
                <td>{metadatum.required ? "Yes" : "No"}</td>
                <td>{metadatum.human_data_type}</td>
                <td>
                  {metadatum.metadatum_field_options.length === 0 ? (
                    <span className="text-muted">N/A</span>
                  ) : (
                    metadatum.metadatum_field_options
                      .map((option) => option.name)
                      .join(", ")
                  )}
                </td>
                <td style={{ width: "120px" }}>
                  <ButtonGroup>
                    <FaButton
                      icon="pencil"
                      text="Edit"
                      title={`Edit ${metadatum.name}`}
                      onClick={() => {
                        openEditSitePropertyForm(metadatum);
                      }}
                    />
                    {metadatum.enable_delete && (
                      <button
                        onClick={() => {
                          removeSiteProperty(metadatum.study_id, metadatum.id);
                        }}
                        title={`Remove ${metadatum.name}`}
                        className="btn btn-default"
                        data-confirm="Are you sure you want to delete this site property?"
                      >
                        <i className="fa fa-trash" />
                      </button>
                    )}
                  </ButtonGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Panel>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.siteProperties;

  return {
    additionalSiteProperties: relevantState.additionalSiteProperties,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      openNewSitePropertyForm: sitePropertyActions.openNewSitePropertyForm,
      openEditSitePropertyForm: sitePropertyActions.openEditSitePropertyForm,
      removeSiteProperty: sitePropertyActions.removeSiteProperty,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalSitePropertiesTable);
