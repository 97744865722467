import { ActionTypes } from "../../actions/fields_page";

export const NavigationReducer = (
  state = { searching: false, searchTerm: "", forms: [] },
  action
) => {
  const alterFieldCount = (newState, id, isSubform, amount) => {
    if (isSubform) {
      let subformToUpdate = newState.subforms.find(
        (subform) => subform.id === id
      );
      subformToUpdate.countForNav = subformToUpdate.countForNav + amount;

      return {
        ...newState,
        subforms: newState.subforms.map((subform) =>
          subform.id == id ? subformToUpdate : subform
        ),
      };
    } else {
      let formToUpdate = newState.forms.find((form) => form.id === id);
      formToUpdate.countForNav = formToUpdate.countForNav + amount;

      return {
        ...newState,
        forms: newState.forms.map((form) =>
          form.id == id ? formToUpdate : form
        ),
      };
    }
  };

  const alterTargetFieldCount = (newState, target_id, target_type, amount) => {
    const isSubform = target_type === "Subform";
    return alterFieldCount(newState, target_id, isSubform, amount);
  };

  const alterSourceFieldCount = (newState, source_id, source_type, amount) => {
    const isSubform = source_type === "Subform";
    return alterFieldCount(newState, source_id, isSubform, amount);
  };

  switch (action.type) {
    case ActionTypes.HANDLE_NAV_SEARCH: {
      let newState = { ...state };
      newState.searching = true;
      return newState;
    }
    case ActionTypes.HANDLE_SET_NAV_FILTER: {
      let newState = { ...state };
      newState.navFilter = action.navFilter;
      return newState;
    }
    case ActionTypes.HANDLE_NAV_SEARCH_SUCCESS: {
      let newState = {
        ...state,
        searching: false,
        forms: action.searchData.study_forms,
        subforms: action.searchData.subform_templates,
        highlightedFields: action.searchData.highlighted_form_fields,
      };
      return newState;
    }

    case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_SUCCESS: {
      let newState = { ...state };
      const currentFormOrSubform = newState.currentFormOrSubform;

      // decrease source form / subform
      newState = alterSourceFieldCount(
        newState,
        currentFormOrSubform.id,
        currentFormOrSubform.type,
        -action.removedFields.length
      );
      return newState;
    }

    case ActionTypes.HANDLE_DELETE_SELECTED_FIELDS_WITH_ERRORS: {
      let newState = { ...state };
      const currentFormOrSubform = newState.currentFormOrSubform;

      // decrease source form / subform
      newState = alterSourceFieldCount(
        newState,
        currentFormOrSubform.id,
        currentFormOrSubform.type,
        -action.successFields.length
      );
      return newState;
    }

    case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_SUCCESS: {
      let newState = { ...state };
      const actionedFields = action.fields.length;

      // decrease source form / subform
      const currentFormOrSubform = state.currentFormOrSubform;
      newState = alterSourceFieldCount(
        newState,
        currentFormOrSubform.id,
        currentFormOrSubform.type,
        -actionedFields
      );

      // increase the target form / subform
      const target_id = action.target.id;
      const target_type = action.target.type;
      newState = alterTargetFieldCount(
        newState,
        target_id,
        target_type,
        actionedFields
      );

      return newState;
    }

    case ActionTypes.HANDLE_MOVE_SELECTED_FIELDS_WITH_ERRORS: {
      let newState = { ...state };
      const actionedFields = action.moveData.successFields.length;

      // decrease source form / subform
      const currentFormOrSubform = state.currentFormOrSubform;
      newState = alterSourceFieldCount(
        newState,
        currentFormOrSubform.id,
        currentFormOrSubform.type,
        -actionedFields
      );

      // increase the target form / subform
      const target_id = action.target.id;
      const target_type = action.target.type;
      newState = alterTargetFieldCount(
        newState,
        target_id,
        target_type,
        actionedFields
      );

      return newState;
    }

    case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_SUCCESS: {
      let newState = { ...state };
      const actionedFields = action.successFields.length;
      const target_id = action.target.id;
      const target_type = action.target.type;

      // increase the target form / subform
      return alterTargetFieldCount(
        newState,
        target_id,
        target_type,
        actionedFields
      );
    }

    case ActionTypes.HANDLE_COPY_SELECTED_FIELDS_WITH_ERRORS: {
      let newState = { ...state };
      const actionedFields = action.successFields.length;
      const target_id = action.target.id;
      const target_type = action.target_type;

      // increase the target form / subform
      return alterTargetFieldCount(
        newState,
        target_id,
        target_type,
        actionedFields
      );
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS: {
      let newState = { ...state };

      // increase the source form / subform
      const currentFormOrSubform = state.currentFormOrSubform;
      return alterSourceFieldCount(
        newState,
        currentFormOrSubform.id,
        currentFormOrSubform.type,
        1
      );
    }

    case ActionTypes.HANDLE_NEW_FIELD_FORM_SUCCESS_AND_CLOSE: {
      let newState = { ...state };

      // increase the source form / subform
      const currentFormOrSubform = state.currentFormOrSubform;
      return alterSourceFieldCount(
        newState,
        currentFormOrSubform.id,
        currentFormOrSubform.type,
        1
      );
    }
  }

  return state;
};
