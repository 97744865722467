import React, { useEffect } from "react";

const FormsList = ({ studyId, forms, currentForm, searchTerm, searching }) => {
  const activeForm = React.createRef();

  useEffect(() => {
    if (activeForm.current) {
      activeForm.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeForm]);

  const urlFor = (form) => {
    if (searchTerm) {
      return `/study/${studyId}/${form.class}s/${form.id}/${form.view}?nav_filter=${searchTerm}`;
    } else {
      return `/study/${studyId}/${form.class}s/${form.id}/${form.view}`;
    }
  };

  const FormTitle = () => {
    return (
      <div className="sidebar-title">
        {searching && (
          <span id="nav-filter-spinner" className="pull-right">
            <i className="fa fa-circle-o-notch fa-spin" />
          </span>
        )}
        Forms
      </div>
    );
  };

  const FormList = () => {
    if (forms && forms.length > 0) {
      return (
        <ul className={`nav nav-forms-list`}>
          {forms.map((form) => {
            const isActive = currentForm.id === form.id;

            return (
              <li
                key={form.id}
                className={isActive ? "active" : ""}
                ref={isActive ? activeForm : undefined}
              >
                <a
                  href={urlFor(form)}
                  className={`${isActive ? "active" : ""}`}
                >
                  {form.name}{" "}
                  <span className="text-muted">{`(${form.countForNav})`}</span>
                </a>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <ul className="nav">
          <li className="no-results">No Results</li>
        </ul>
      );
    }
  };

  return (
    <>
      <FormTitle />
      <FormList />
    </>
  );
};

export default FormsList;
