import React from "react";

const PanelSubheading = ({ title, children }) => {
  return (
    <div className="panel-subheading">
      <h4 className="panel-title">
        <strong>{title}</strong>
      </h4>
      {children}
    </div>
  );
};

export default PanelSubheading;
