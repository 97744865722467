import React from "react";

import ButtonWithSpinner from "../helpers/ButtonWithSpinner";

const CopyTo = ({
  copying,
  copyNTimes,
  confirmCopy,
  setCopyNTimes,
  disabled,
}) => {
  return (
    <div className="input-group">
      <select
        id="bulk-copy-times"
        className="form-control"
        value={copyNTimes}
        onChange={(e) => {
          setCopyNTimes(e.target.value);
        }}
        disabled={disabled}
        style={{ zIndex: 0 }}
      >
        <option value="1">Once</option>
        <option value="2">Twice</option>
        <option value="3">3 times</option>
        <option value="4">4 times</option>
        <option value="5">5 times</option>
        <option value="6">6 times</option>
        <option value="7">7 times</option>
        <option value="8">8 times</option>
        <option value="9">9 times</option>
        <option value="10">10 times</option>
      </select>
      <div className="input-group-btn">
        <ButtonWithSpinner
          bsStyle="default"
          faIcon="copy"
          buttonText="Copy to"
          savingText="Copying..."
          title="Copy selected fields to the target form"
          onClick={() => confirmCopy()}
          saving={copying}
          disabled={disabled}
          style={{ zIndex: 0 }}
        />
      </div>
    </div>
  );
};

export default CopyTo;
